<div *ngIf="!data.name" data-automation-attribute="div-registration-confirm">
  <div class="modal-header modalHeaderDefault">
    <h6 class="modal-title">{{ "common.confirmation" | translate }}</h6>
    <span
      aria-label="Close"
      class="close"
      (click)="onNoClick()"
      data-automation-attribute="button-close-dialog"
    >
      <mat-icon  matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
    </span>
  </div>

  <div class="modal-body bodyCreatePasscode">
    <p>{{ "Emergancy.conformMessage" | translate }}</p>
  </div>

  <div class="modal-footer modalFooterRegistrationSuccess">
    <div mat-dialog-actions>
      <button
        type="submit"
        mat-mini-fab
        class="btnCyan"
        data-automation-attribute="button-yes"
      >
        <mat-icon
          matTooltip="{{ 'payerInfo.yes' | translate }}"
          (click)="onNoClick('yes')"
          >done</mat-icon
        >
      </button>
      <button mat-mini-fab class="btnRed" data-automation-attribute="button-no">
        <mat-icon
          matTooltip="{{ 'payerInfo.no' | translate }}"
          (click)="onNoClick('No')"
          >close</mat-icon
        >
      </button>
    </div>
  </div>
</div>

<div *ngIf="data.name" data-automation-attribute="div-registration-success">
  <div class="modal-header modalHeaderDefault">
    <h6 class="modal-title">Registration Successful</h6>
    <span
      aria-label="Close"
      class="close"
      (click)="onNoClick('close')"
      data-automation-attribute="button-close-dialog"
    >
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div class="modal-body bodyCreatePasscode">
    <b>{{ data.name }}</b> <br />
    <p>Do you want to continue with encounter ?</p>
  </div>

  <div class="modal-footer modalFooterRegistrationSuccess">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        (click)="goForEncounter('encounterYes', data.name)"
        class="btnYes btnGreen"
        data-automation-attribute="button-yes"
      >
        Yes
      </button>
      <button
        mat-flat-button
        (click)="goForEncounter('encounterNO')"
        class="btnNo btnRed"
        data-automation-attribute="button-no"
      >
        No
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="data.name == 'update'"
  data-automation-attribute="div-records-updates"
>
  <div class="modal-header modalHeaderDefault">
    <h6 class="modal-title">Record Updated Successful</h6>
    <span
      aria-label="Close"
      class="close"
      (click)="onNoClick('close')"
      data-automation-attribute="button-close-dialog"
    >
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div class="modal-body bodyCreatePasscode">
    <p>{{ data.name }}</p>
  </div>

  <div class="modal-footer modalFooterRegistrationConfirm">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        (click)="onNoClick('yes')"
        class="btnYes btnGreen"
        data-automation-attribute="button-ok"
      >
        Ok
      </button>
    </div>
  </div>
</div>
