<app-patient-search
  class="mb-10"
  [search]="searchCriteria"
  [list]="patientList"
  [refreshTime]="refreshTime"
  [hideAfter]="hidePatientSearch"
  (onActionClick)="patientOnSelect($event)"
></app-patient-search>
<div class="registration_nav" *ngIf="patientForm"></div>
<div class="patient-detail fixed-height" >
  <div class="flex">
    <div class="col-8">
      <div *ngIf="patientInfoDisplay" style="width: 180vh;">
        <img
          class="user-img"
          [src]="patientImage ? patientImage : '../assets/images/user.png'"
        />
        <label class="patient-name-label"> {{ patientInfoDisplay }}</label>&nbsp;
        <img
          class="user-img"
          [src]="isVip ? '../assets/images/email-icons/vip-icon.png':null"
          *ngIf="isVip"
          style="height: 28px;"
        />
        </div>          
      <div *ngIf="!patientInfoDisplay">
        <label class="patient-name-label">{{
          "RegistrationPage.noPatient" | translate
        }}</label>
      </div>
    </div>
    <div class="col-4 right">
      <button
        id="Add Patient"
        (click)="resetRegisterationForm()"
        class="btnOrange"
        mat-mini-fab
        *ngIf="!patientForm && !patientId"
      >
        <mat-icon matTooltip="{{ 'payerInfo.addPatient' | translate }}"
          >add</mat-icon
        >
      </button>
      <button
        id="Edit Patient"
        (click)="patientForm = !patientForm; editPatientClicked()"
        class="btnOrange"
        mat-mini-fab
        *ngIf="!patientForm && patientId"
      >
        <mat-icon matTooltip="{{ 'payerInfo.editPatient' | translate }}"
          >edit</mat-icon
        >
      </button>
    </div>
  </div>
</div>

<div *ngIf="patientForm" id="registration">
  <form
    [formGroup]="formRegistration"
    #patientRegForm="ngForm"
    novalidate
    class="mt-10"
    attr.data-automation-attribute="formUserPersonalDetails"
  >
    <div class="form-container">
      <div class="flex">
        <div class="col-2">
          <div class="center">
            <img
              [src]="'assets/images/noavatar_new.png'"
              alt="Patient"
              title="Patient"
              *ngIf="!patientImage"
              class="patient-avatar"
              (click)="uploadImageDialog()"
              attr.data-automation-attribute="imgDefaultUser"
            />
            <img
              [src]="patientImage"
              *ngIf="patientImage"
              alt="Patient"
              title="Patient"
              class="patient-avatarupload"
              (click)="uploadImageDialogForEdit(patientImage)"
              attr.data-automation-attribute="imgDefaultUser"
            />
          </div>
        </div>
        <div class="col-10">
          <b class="section-head">{{
            "RegistrationPage.basicInformation" | translate
          }}</b>
          <div class="section-vip">
            <mat-checkbox formControlName="isVip" [required]="mandatoryField.isVip">{{
              "RegistrationPage.vipPatient" | translate
            }}</mat-checkbox>
          </div>
          <div class="section-born">
            <mat-checkbox
              formControlName="isNewborn"
              style="visibility: hidden"
              >{{ "RegistrationPage.newborn" | translate }}</mat-checkbox
            >
          </div>
          <mat-grid-list cols="12" rowHeight="50px">
            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "RegistrationPage.primaryID" | translate
                }}</mat-label>
                <mat-select
                  required="mandatoryField.identificationType"
                  attr.data-automation-attribute="encounterDoctor"
                  formControlName="primaryId"
                  (selectionChange)="primaryIdentificationOnChange($event)"
                  [readonly]="isDisabled"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="primaryIdSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let option of primaryIdFilteredList"
                    [value]="option.code"
                  >
                    {{ option.desc }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label
                  >{{ "RegistrationPage.primaryDocNo" | translate }}
                </mat-label>
                <input
                  [pattern]="primaryIdRegex"
                  matInput
                  formControlName="primaryNumber"
                  maxlength="25"
                  [required]="mandatoryField.identificationNo"
                  [placeholder]="'RegistrationPage.primaryDocNo' | translate"
                  attr.data-automation-attribute="txtPrimaryIDDesc"
                  (keyup)="primaryNumberOnKeyUp($event.target.value)"
                  pattern="[a-zA-Z0-9]+"
                  (focusout)="onFocusOutEvent($event.target.value)"
                
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label
                  >{{ "RegistrationPage.expiryDate" | translate }}
                </mat-label>
                <input
                  [required]="isexpiryDate"
                  matInput
                  formControlName="expiryDate"
                  [min]="now"
                  [max]="maxExpiry"
                  [matDatepicker]="picker2"
                  [placeholder]="'RegistrationPage.expiryDate' | translate"
                  attr.data-automation-attribute="dateExpiry"
                  name="acb"
                  [disabled]="!isexpiryDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <app-master-form-field
                [isRequired]="mandatoryField.issuingCountry"
                [label]="'RegistrationPage.idCountry' | translate"
                [masterName]="masterAPI.COUNTRY_MASTER"
                [formControlName]="'issuedCountryPrimary'"
                [defaultValue]="selectedPatient ? selectedPatient.idIssueCountry : ''"
                [parentForm]="formRegistration"
                [isSubmitted]="patientRegForm.submitted"
              >
              </app-master-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="12" rowHeight="50px">
            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline" >
                <mat-label>{{
                  "RegistrationPage.secondaryId" | translate
                }}</mat-label>
                <mat-select
                  attr.data-automation-attribute="sltSecondaryID"
                  formControlName="secondaryId"
                  (selectionChange)="secondartyIdentificationOnChange($event)"
                  [required]="mandatoryField.secIdentificationType"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="secondaryIdSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>None</mat-option>
                  <mat-option
                    *ngFor="let option of secondaryIdFilteredList"
                    [value]="option.code"
                  >
                    {{ option.desc }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline" id="validate">
                <mat-label>{{
                  "RegistrationPage.secondaryDocNo" | translate
                }}</mat-label>
                <input
                  [pattern]="secondaryIdeRegex"
                  matInput
                  formControlName="secondaryNumber"
                  maxlength="25"
                  (keyup)="secondaryNumberOnKeyUp($event.target.value)"
                  [required]="mandatoryField.secIdentificationNo"
                  (focusout)="onFocusOutEventUpdate('')"
                  [placeholder]="'RegistrationPage.secondaryDocNo' | translate"
                  attr.data-automation-attribute="txtSecondaryIDDesc"
                  (blur)="checkValidEmployee()"
                />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label
                  >{{ "RegistrationPage.secondaryexpiryDate" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="secondaryExpirydate"
                  [min]="now"
                  [required]="isSeondaryExpiryDate"
                  [max]="maxExpiry"
                  [matDatepicker]="picker3"
                  [placeholder]="
                    'RegistrationPage.secondaryexpiryDate' | translate
                  "
                  attr.data-automation-attribute="dateExpiry"
                  name="acb"
                  [disabled]="!isSeondaryExpiryDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
              <app-master-form-field
              [isRequired]="mandatoryField.faculty"
                [label]="'RegistrationPage.faculty' | translate"
                [masterName]="masterAPI.FACULTY_MASTER"
                [formControlName]="'faculty'"
                [defaultValue]="selectedPatient ? selectedPatient.faculty : ''"
                [parentForm]="formRegistration"
                [isSubmitted]="patientRegForm.submitted"
              >
              </app-master-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1">
              <mat-form-field
                appearance="outline"
                class="full-width"
                style="visibility: hidden"
              >
                <mat-label>Birth Time</mat-label>
                <input
                  matInput
                  id="Break Start Time"
                  type="time"
                  formControlName="birthTime"
                  matNativeControl
                  placeholder="Birth Time"
                  data-automation-attribute="text-from-time"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </div>
    <div class="form-container" attr.data-automation-attribute="divIDDetails">
      <b class="section-head">{{
        "RegistrationPage.personalInformation" | translate
      }}</b>
      <mat-grid-list cols="12" rowHeight="50px">
        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.title"
            [label]="'RegistrationPage.prefix' | translate"
            [masterName]="masterAPI.PREFIX_MASTER"
            [formControlName]="'prefix'"
            [defaultValue]="titleValue"
            [parentForm]="formRegistration"
            (itemSelected)="getGender($event)"
            (autoLoadCompleted)="getGender($event)"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="4">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ "RegistrationPage.name" | translate }}</mat-label>
            <input
              pattern="([a-zA-Z,'-._/!@#$%^&*\s]+)"
              matInput
              maxlength="100"
              style="text-transform: uppercase;"
              formControlName="firstName"
              [placeholder]="'RegistrationPage.name' | translate"
              [required]="mandatoryField.fullName"
              attr.data-automation-attribute="txtFirstName"
            />
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.gender"
            [label]="'RegistrationPage.gender' | translate"
            [masterName]="masterAPI.GENDER_MASTER"
            [formControlName]="'gender'"
            [defaultValue]="genderValue"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
          [isRequired]="mandatoryField.martial"
            [label]="'RegistrationPage.maritalStatus' | translate"
            [masterName]="masterAPI.MARITAL_MASTER"
            [formControlName]="'martial'"
            [defaultValue]="
              selectedPatient ? selectedPatient.martialStatus : ''
            "
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>
        
        <!-- [defaultValue]="selectedPatient ? selectedPatient.religion : ''" -->
        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.religion"
            [label]="'RegistrationPage.religion' | translate"
            [masterName]="masterAPI.RELIGION_MASTER"
            [formControlName]="'religion'"
            [defaultValue]="religionValue"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="12" rowHeight="50px">
        <mat-grid-tile [colspan]="2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ "RegistrationPage.dobForm" | translate }}</mat-label>
            <input
              matInput
              formControlName="dateOfBirth"
              [min]="minDate"
              [max]="maxDate"
              [matDatepicker]="picker1"
              [required]="mandatoryField.dateOfBirth"
              [placeholder]="'RegistrationPage.dobForm' | translate"
              attr.data-automation-attribute="dateDOB"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="3">
          <div class="patient-age">
            <div class="year">
              <input
                type="number"
                min="0"
                readonly
                matInput
                value="{{ patientAge?.years }}"
              />
              <span matSuffix>Y</span>
            </div>
            <!-- (keyup)="calculateDob($event.target.value, 'year')" -->
            <div class="months">
              <input
                type="number"
                min="0"
                readonly
                matInput
                value="{{ patientAge?.months }}"
              />
              <span matSuffix>M</span>
            </div>
            <div class="days">
              <input
                type="number"
                min="0"
                matInput
                readonly
                value="{{ patientAge?.days }}"
              />
              <span matSuffix>D</span>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <mat-checkbox formControlName="isDeceased" [required]="mandatoryField.isDeceased" (change)="deceasedRemove($event)">
            {{ "RegistrationPage.isDeceased" | translate }}
          </mat-checkbox>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.patientCategory"
            [label]="'RegistrationPage.patientCategory' | translate"
            [masterName]="masterAPI.PATIENT_CATEGORY_MASTER"
            [formControlName]="'patientCategory'"
            [defaultValue]="
              selectedPatient ? selectedPatient.patientCategory : ''
            "
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
            (selectionChange)="getTariffList()"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.prefLang"
            [label]="'RegistrationPage.preferedLanguage' | translate"
            [masterName]="masterAPI.LANGUAGE_MASTER"
            [formControlName]="'preferredLanguage'"
            [defaultValue]="
              selectedPatient ? selectedPatient.preferedLanguage : ''
            "
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2"  *ngIf="isNationDependant==true" >
          <app-master-form-field 
          [isRequired]="mandatoryField.nation"
            [label]="'RegistrationPage.nationality' | translate"
            [masterName]="masterAPI.NATIONALITY_MASTER"
            [formControlName]="'nation'"
            [defaultValue]="selectedPatient ? selectedPatient.nationality : defaultNationality"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
            (itemSelected)="selectNationality($event)"
          >
        </app-master-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" *ngIf="isNationDependant==false">
          <app-master-form-field 
          [isRequired]="mandatoryField.nation"
            [label]="'RegistrationPage.nationality' | translate"
            [masterName]="masterAPI.NATIONALITY_MASTER"
            [formControlName]="'nation'"
            [defaultValue]="selectedPatient ? selectedPatient.nationality : defaultNationality"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
        </app-master-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="12" rowHeight="50px">
        <mat-grid-tile [colspan]="2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{
              "RegistrationPage.motherName" | translate
            }}</mat-label>
            <input
            [required]="mandatoryField.motherName"
              matInput
              maxlength="25"
              formControlName="motherName"
              [placeholder]="'RegistrationPage.motherName' | translate"
              attr.data-automation-attribute="
                registration - form - motherName
              "
              (input)="inputValidation.replaceSpaces($event)"
            />
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.education"
            [label]="'RegistrationPage.education' | translate"
            [masterName]="masterAPI.EDUCATION_MASTER"
            [formControlName]="'education'"
            [defaultValue]="selectedPatient ? selectedPatient.education : ''"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.occupation"
            [label]="'RegistrationPage.occupation' | translate"
            [masterName]="masterAPI.OCCUPATION_MASTER"
            [formControlName]="'occupation'"
            [defaultValue]="selectedPatient ? selectedPatient.occupation : ''"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>



        <!-- <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="false"
            [label]="'RegistrationPage.race' | translate"
            [masterName]="masterAPI.RACE_MASTER"
            [formControlName]="'race'"

            [defaultValue]="'nationalityCode'"

            [defaultValue]="raceValue"

            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>  -->
        <mat-grid-tile [colspan]="2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Race </mat-label>
            <mat-select formControlName="race"
            [required]="mandatoryField.race">
            <ngx-mat-select-search
            [formControl]="raceMasterSearch"
            [placeholderLabel]="'RegistrationPage.search' | translate"
          ></ngx-mat-select-search>
              <mat-option>{{"RegistrationPage.none" | translate }}</mat-option>
              <mat-option
              *ngFor="let option of raceFilterList"
                [value]="option.code"
              >
                {{ option.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.ethnic"
            [label]="'RegistrationPage.ethnic' | translate"
            [masterName]="masterAPI.ETHNIC_MASTER"
            [formControlName]="'ethnic'"
            [defaultValue]="selectedPatient ? selectedPatient.ethnic : ''"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{
              "RegistrationPage.birthplace" | translate
            }}</mat-label>
            <input
              matInput
              [required]="mandatoryField.birthPlace"
              formControlName="birthplace"
              [placeholder]="'RegistrationPage.birthplace' | translate"
              attr.data-automation-attribute="birthplace"
              pattern="[a-zA-Z0-9-.,/#'_@\s]+"
            />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="12" rowHeight="50px">
        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.bloodGroup"
            [label]="'RegistrationPage.bloodGroup' | translate"
            [masterName]="masterAPI.BLOOD_GROUP_MASTER"
            [formControlName]="'bloodGroup'"
            [defaultValue]="selectedPatient ? selectedPatient.bloodGroup : ''"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.source"
            [label]="'EncounterPage.source' | translate"
            [masterName]="masterAPI.PATIENT_SOURCE_MASTER"
            [formControlName]="'sourceType'"
            [defaultValue]="selectedPatient ? selectedPatient.source : ''"
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2">
          <app-master-form-field
            [isRequired]="mandatoryField.EmploymentStatus"
            [label]="'RegistrationPage.employmentStatus' | translate"
            [masterName]="masterAPI.EMPLOYMENT_STATUS_MASTER"
            [formControlName]="'employmentStatus'"
            [defaultValue]="
              selectedPatient ? selectedPatient.employmentStatus : ''
            "
            [parentForm]="formRegistration"
            [isSubmitted]="patientRegForm.submitted"
          >
          </app-master-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Patient Notes</mat-label>
            <input
              [required]="mandatoryField.patientNotes"
              formControlName="patientNotes"
              matInput
              placeholder="Patient Notes"
              attr.data-automation-attribute="txtPermanentAddress"
            />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <mat-tab-group class="tabGroupPersonalDetails mt-10">
      <!-- Registration: Address and Contact Tab -->
      <mat-tab class="tabSubPersonalDetails">
        <ng-template mat-tab-label>
          {{ "RegistrationPage.address&contactDetails" | translate }}
        </ng-template>
        <!-- Registration: Address Details -->
        <div class="flex">
          <div class="div-lg">
            <div
              class="form-container"
              style="margin-right: 10px"
              attr.data-automation-attribute="divPersonalDetails"
            >
              <b class="section-head">{{
                "RegistrationPage.address" | translate
              }} <mat-checkbox 
              style="margin-left: 75%;color: black;"
              (change)="searchByPostCode($event)">{{
                "RegistrationPage.SearchByPostcode" | translate
              }}</mat-checkbox>
            </b>
              
              <div class="full-content">
                <mat-grid-list cols="12" rowHeight="50px">
                  <mat-grid-tile [colspan]="12">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.permanentAddress" | translate
                      }}</mat-label>
                      <input
                        matInput
                        style="text-transform: uppercase;"
                        [required]="mandatoryField.addressPrimary"
                        formControlName="addressPrimary"
                        [placeholder]="
                          'RegistrationPage.permanentAddress' | translate
                        "
                        attr.data-automation-attribute="txtAddress"
                        pattern="[a-zA-Z0-9-.,/#'_@\s]+"
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>

              <div class="full-content">
                <mat-grid-list cols="15" rowHeight="50px">
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.countryPrimary"
                      [label]="'RegistrationPage.country' | translate"
                      [masterName]="masterAPI.COUNTRY_MASTER"
                      [formControlName]="'countryPrimary'"
                      [parentForm]="formRegistration"
                      [defaultValue]="countryValue?countryValue:defaultCountry"
                      (itemSelected)="getStatePrimaryList($event, false);getCountryCode($event, true)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.statePrimary"
                      [label]="'RegistrationPage.state' | translate"
                      [masterName]="masterAPI.STATE_MASTER_QUERY"
                      [formControlName]="'statePrimary'"
                      [parentForm]="formRegistration"
                      [params]="CountryCode"
                      [defaultValue]="stateValue"
                      (itemSelected)="getCityPrimaryList($event)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.cityPrimary"
                      [label]="'RegistrationPage.city' | translate"
                      [masterName]="masterAPI.CITY_MASTER_QUERY"
                      [formControlName]="'cityPrimary'"
                      [params]="StateCode"
                      [defaultValue]="cityValue"
                      [parentForm]="formRegistration"
                      (itemSelected)="getSubDistrictPrimaryList($event)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                    [isRequired]="mandatoryField.areaPrimary"
                      [label]="'RegistrationPage.district' | translate"
                      [masterName]="masterAPI.DIST_MASTER_QUERY"
                      [formControlName]="'subDistrictPrimary'"
                      [params]="CityCode"
                      [defaultValue]="districtValue"
                      [parentForm]="formRegistration"
                      [isSubmitted]="patientRegForm.submitted"
                      (itemSelected)="primaryDistrictSelected($event)"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.zipcode" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="pincodePrimary"
                        numberOnly
                        class="example-right-align"
                        [placeholder]="'RegistrationPage.zipcode' | translate"
                        attr.data-automation-attribute="txtPincode"
                        [required]="mandatoryField.pincodePrimary"
                        pattern="[a-zA-Z0-9]+"
                        (focusout)="getAddressByPostCode($event.target.value)"
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>

              <div class="full-content">
                <mat-grid-list cols="12" rowHeight="50px">
                  <mat-grid-tile [colspan]="9">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.localAddress" | translate
                      }}</mat-label>
                      <input
                        [required]="mandatoryField.addressSecondary"
                        formControlName="addressSecondary"
                        matInput
                        style="text-transform: uppercase;"
                        [placeholder]="
                          'RegistrationPage.localAddress' | translate
                        "
                        attr.data-automation-attribute="txtPermanentAddress"
                        pattern="[a-zA-Z0-9-.,/#'_@\s]+"
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <mat-checkbox
                      (change)="bothAddressSame($event)"
                      [placeholder]="
                        'RegistrationPage.sameAsPermanentAddress' | translate
                      "
                      attr.data-automation-attribute="chkSameAsLocalAddress"
                      [(ngModel)]="isBothAddressSame"
                      [ngModelOptions]="{ standalone: true }"
                      >{{
                        "RegistrationPage.sameAsPermanentAddress" | translate
                      }}</mat-checkbox
                    >
                  </mat-grid-tile>
                </mat-grid-list>
              </div>

              <div class="full-content">
                <mat-grid-list cols="15" rowHeight="50px">
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.countrySecondary"
                      [label]="'RegistrationPage.country' | translate"
                      [masterName]="masterAPI.COUNTRY_MASTER"
                      [formControlName]="'countrySecondary'"
                      [parentForm]="formRegistration"
                      [defaultValue]="countrySecondaryValue"
                      (itemSelected)="getStateSecondaryList($event, false)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.stateSecondary"
                      [label]="'RegistrationPage.state' | translate"
                      [masterName]="masterAPI.STATE_MASTER_QUERY"
                      [formControlName]="'stateSecondary'"
                      [parentForm]="formRegistration"
                      [defaultValue]="stateSecondaryValue"
                      [params]="secondaryCountryCode"
                      (itemSelected)="getCitySecondaryList($event)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                      [isRequired]="mandatoryField.citySecondary"
                      [label]="'RegistrationPage.city' | translate"
                      [masterName]="masterAPI.CITY_MASTER_QUERY"
                      [formControlName]="'citySecondary'"
                      [parentForm]="formRegistration"
                      [defaultValue]="citySecondaryValue"
                      [params]="secondaryStateCode"
                      (itemSelected)="getSubDistrictSecondaryList($event)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <app-master-form-field
                    [isRequired]="mandatoryField.areaSecondary"
                      [label]="'RegistrationPage.subArea' | translate"
                      [masterName]="masterAPI.DIST_MASTER_QUERY"
                      [formControlName]="'subDistrictSecondary'"
                      [parentForm]="formRegistration"
                      [defaultValue]="districtSeoncdaryValue"
                      [params]="secondaryDistrictCode"
                      [isSubmitted]="patientRegForm.submitted"
                      (itemSelected)="secondaryDistrictSelected($event)"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="3">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.zipcode" | translate
                      }}</mat-label>
                      <input
                        [required]="mandatoryField.pincodeSecondary"
                        matInput
                        type="text"
                        numberOnly
                        formControlName="pincodeSecondary"
                        class="example-right-align"
                        [placeholder]="'RegistrationPage.zipcodeP' | translate"
                        attr.data-automation-attribute="txtPermanentPincode"
                        pattern="[a-zA-Z0-9]+"
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>
          </div>

          <!-- Registration: Contact Info -->
          <div class="div-sm">
            <div
              class="form-container"
              attr.data-automation-attribute="formUserContactDetails"
            >
              <b class="section-head">{{
                "RegistrationPage.contactInformation" | translate
              }}</b>
              <mat-grid-list cols="12" rowHeight="50px">
                <mat-grid-tile [colspan]="6">
                  <app-master-form-field
                    [isRequired]="mandatoryField.CountryCode"
                    [label]="'EncounterPage.countryCode' | translate"
                    [masterName]="masterAPI.COUNTRY_CODE_MASTER"
                    [formControlName]="'countryCodemobile'"
                    [defaultValue]="countryCodeValue"
                    [parentForm]="formRegistration"
                    [isSubmitted]="patientRegForm.submitted"
                    (itemSelected)="setPatternToMobileNumber($event)"
                  >
                  </app-master-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.handphoneNo" | translate
                    }}</mat-label>
                    <input
                      matInput                      
                      [minlength]="mobileNoMin"
                      [maxlength]="mobileNoMax"
                      [required]="mandatoryField.mobileNumber"
                      formControlName="mobileNumber"
                      attr.data-automation-attribute="
                        registration - form - localcontact
                      "
                    />
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>

              <mat-grid-list cols="12" rowHeight="50px">
                <mat-grid-tile [colspan]="6">
                  <app-master-form-field
                    [isRequired]="mandatoryField.mobileNoRelation"
                    [label]="'EncounterPage.relation' | translate"
                    [masterName]="masterAPI.RELATION_MASTER"
                    [formControlName]="'contactRelation'"
                    [defaultValue]="
                      selectedPatient ? selectedPatient.relation : ''
                    "
                    [parentForm]="formRegistration"
                    [isSubmitted]="patientRegForm.submitted"
                  >
                  </app-master-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.emailID" | translate
                    }}</mat-label>
                    <input
                      [required]="mandatoryField.emailId"
                      matInput
                      formControlName="emailId"
                      [placeholder]="'RegistrationPage.emailID' | translate"
                      attr.data-automation-attribute="txtEmail"
                      pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]+[a-zA-Z0-9-.]+$"
                    />
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>

              <mat-grid-list cols="12" rowHeight="50px">
                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.personalFaxno" | translate
                    }}</mat-label>
                    <input
                      matInput
                      [required]="mandatoryField.personalFax"
                      [pattern]="faxRegex"
                      formControlName="personalFax"
                      (keyup)="mobileFormat($event.target.value, 'personalfax')"
                      [placeholder]="
                        'RegistrationPage.personalFaxno' | translate
                      "
                      attr.data-automation-attribute="
                        registration - form - personalFax
                      "
                      pattern="[a-zA-Z0-9]+"
                    />
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.workFaxno" | translate
                    }}</mat-label>
                    <input
                      matInput
                      [required]="mandatoryField.workFax"
                      [pattern]="faxRegex"
                      formControlName="workFax"
                      (keyup)="mobileFormat($event.target.value, 'workfax')"
                      [placeholder]="'RegistrationPage.workFaxno' | translate"
                      attr.data-automation-attribute="
                        registration - form - workFax
                      "
                      pattern="[a-zA-Z0-9]+"
                    />
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>

              <mat-grid-list cols="12" rowHeight="50px">
                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.officeContact" | translate
                    }}</mat-label>
                    <input
                      (keyup)="mobileFormat($event.target.value, 'work')"
                      formControlName="workContact"
                      [required]="mandatoryField.workContact"
                      matInput
                      [placeholder]="
                        'RegistrationPage.officeContact' | translate
                      "
                      attr.data-automation-attribute="registration - form - email"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "RegistrationPage.localContact" | translate
                    }}</mat-label>
                    <input
                      matInput
                      [required]="mandatoryField.isHandphone"
                      formControlName="homeContact"
                      minlength="10"
                      maxlength="14"
                      (keyup)="mobileFormat($event.target.value, 'home')"
                      [placeholder]="
                        'RegistrationPage.localContact' | translate
                      "
                      attr.data-automation-attribute="
                        registration - form - handphone
                      "
                    />
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Registration: Employment Details Tab -->
      <mat-tab class="tabSubPersonalDetails">
        <ng-template mat-tab-label>
          {{ "RegistrationPage.employmentDetails" | translate }}
        </ng-template>
        <div class="flex">
          <div class="div-lgp">
            <div
              class="form-container"
              style="margin-right: 10px"
              attr.data-automation-attribute="divPersonalDetails"
            >
              <div class="full-content">
                <mat-grid-list cols="12" rowHeight="50px">
                  <!-- Bind Dropdown -->
                  <div>
                    <mat-grid-tile [colspan]="2">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>
                          {{ "RegistrationPage.employer" | translate }}
                        </mat-label>
                        <mat-select
                          id="Employer"
                          attr.data-automation-attribute="employerName"
                          formControlName="employerName"
                        >
                          <mat-option>{{
                            "RegistrationPage.none" | translate
                          }}</mat-option>
                          <mat-option
                            *ngFor="let option of employerList"
                            [value]="option.code"
                          >
                            {{ option.desc }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-grid-tile>
                  </div>

                  <div *ngIf="employer">
                    <mat-grid-tile [colspan]="2">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>
                          {{ "RegistrationPage.others" | translate }}
                        </mat-label>
                        <input
                          matInput
                          type="text"
                          maxlength="100"
                          formControlName="employerNameOthers"
                          attr.data-automation-attribute="employerNameOthers"
                        />
                      </mat-form-field>
                    </mat-grid-tile>
                  </div>
                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>
                        {{ "RegistrationPage.employmentNo" | translate }}
                      </mat-label>
                      <input
                        matInput
                        type="text"
                        maxlength="25"
                        formControlName="employmentNo"
                        attr.data-automation-attribute="employmentNo"
                        (input)="inputValidation.replaceSpaces($event)"
                      />
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>
                        {{ "RegistrationPage.workingFrom" | translate }}
                      </mat-label>
                      <input
                        matInput
                        formControlName="workingFrom"
                        [max]="now"
                        [matDatepicker]="picker4"
                        [placeholder]="
                          'RegistrationPage.workingFrom' | translate
                        "
                        attr.data-automation-attribute="workingFromDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker4"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>
                        {{ "RegistrationPage.contactNo" | translate }}
                      </mat-label>
                      <input
                        matInput
                        type="text"
                        maxlength="15"
                        formControlName="employmentContactNo"
                        attr.data-automation-attribute="employmentContactNo"
                        [pattern]="'[0-9+-]*'" 
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>

                <mat-grid-list cols="12" rowHeight="50px">
                  <mat-grid-tile [colspan]="4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.employmentAddress" | translate
                      }}</mat-label>
                      <input
                        matInput
                        maxlength="100"
                        formControlName="employmentAddress"
                        [placeholder]="
                          'RegistrationPage.permanentAddress' | translate
                        "
                        attr.data-automation-attribute="employmentAddress"
                        (input)="inputValidation.replaceSpaces($event)"
                      />
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <app-master-form-field
                      [label]="'RegistrationPage.country' | translate"
                      [masterName]="masterAPI.COUNTRY_MASTER"
                      [formControlName]="'employmentCountry'"
                      [defaultValue]="countryEmploymentValue"
                      [parentForm]="formRegistration"
                      (itemSelected)="getStateEmploymentList($event, false)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <app-master-form-field
                      [label]="'RegistrationPage.state' | translate"
                      [masterName]="masterAPI.STATE_MASTER_QUERY"
                      [formControlName]="'employmentState'"
                      [defaultValue]="stateEmploymentValue"
                      [parentForm]="formRegistration"
                      [params]="employmentCountryCode"
                      (itemSelected)="getCityEmploymentList($event)"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <app-master-form-field
                      [label]="'RegistrationPage.city' | translate"
                      [masterName]="masterAPI.CITY_MASTER_QUERY"
                      [formControlName]="'employmentCity'"
                      [defaultValue]="cityEmploymentValue"
                      [parentForm]="formRegistration"
                      [params]="employmentStateCode"
                      [isSubmitted]="patientRegForm.submitted"
                    >
                    </app-master-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "RegistrationPage.zipcode" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="employmentPostCode"
                        numberOnly
                        maxlength="15"
                        class="example-right-align"
                        [placeholder]="'RegistrationPage.zipcode' | translate"
                        attr.data-automation-attribute="employmentPostCode"
                        pattern="[a-zA-Z0-9]+"
                      />
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Registration: Health Tourist Details Tab -->
      <mat-tab class="tabSubPersonalDetails">
        <ng-template mat-tab-label>
          Health Tourist Details
        </ng-template>
        <div class="form-container"
              style="margin-right: 10px">
        <form [formGroup]="formHealthTourist" #regHealthForm="ngForm" 
        attr.data-automation-attribute="formUserPersonalDetails">
        <mat-grid-list cols="14" rowHeight="50px">
          
          <mat-grid-tile [colspan]="2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label> Health Tourist Code</mat-label>
              <input matInput  formControlName="code"/>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="2">
            <app-master-form-field
            [isRequired]="true"
              [label]="'Agent Identification Type'"
              [masterName]="masterAPI.IDENTIFICATION_TYPE_MASTER"
              [formControlName]="'identificationType'"
              [parentForm]="formHealthTourist"
              [isSubmitted]="regHealthForm.submitted"
              >
            </app-master-form-field>
          </mat-grid-tile>
          
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Agent Identification No</mat-label>
                <input matInput type="number" formControlName="identificationNumber" />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Agent Name</mat-label>
                <input matInput formControlName="agentName"
                oninput="this.value = this.value.toUpperCase()" />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
            
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Start - End Date</mat-label>
                <mat-date-range-input [rangePicker]="picker5" required>
                  <input matStartDate placeholder="Start date" formControlName="startDate">
                  <input matEndDate placeholder="End date" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-date-range-picker #picker5></mat-date-range-picker>
              
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Contact No</mat-label>
                <input matInput type="number" formControlName="contactNo" />
              </mat-form-field>
            </mat-grid-tile>

        </mat-grid-list>
        <mat-grid-list cols="14" rowHeight="50px">
              <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Email Id</mat-label>
                <input matInput formControlName="emailId" />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Address </mat-label>
                <input matInput formControlName="address" />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <app-master-form-field
              [label]="'RegistrationPage.country' | translate"
              [masterName]="masterAPI.COUNTRY_MASTER"
              [formControlName]="'country'"
              [parentForm]="formHealthTourist"
              (itemSelected)="getStatePrimaryList($event, false)"
              [isSubmitted]="regHealthForm.submitted"
            >
            </app-master-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <app-master-form-field
              [label]="'RegistrationPage.state' | translate"
              [masterName]="masterAPI.STATE_MASTER_QUERY"
              [formControlName]="'state'"
              [parentForm]="formHealthTourist"
              [params]="CountryCode"
              (itemSelected)="getCityPrimaryList($event)"
              [isSubmitted]="regHealthForm.submitted"
            >
            </app-master-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <app-master-form-field
              [label]="'RegistrationPage.city' | translate"
              [masterName]="masterAPI.CITY_MASTER_QUERY"
              [formControlName]="'city'"
              [params]="StateCode"
              [parentForm]="formHealthTourist"
              [isSubmitted]="regHealthForm.submitted"
            >
            </app-master-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> Post Code </mat-label>
                <input matInput  formControlName="postcode" />
              </mat-form-field>
            </mat-grid-tile>
          

            <mat-grid-tile [colspan]="1">
              <button
              class="btnOrange"
              mat-mini-fab
              (click)="addHealthTourist(formHealthTourist.value)"
            >
              <mat-icon matTooltip="Add Health Tourist List"
                >add</mat-icon
              >
            </button>
            </mat-grid-tile>
          </mat-grid-list>
        </form>
          <div class="Health-table">
          <table
          id="healthTourist"
          mat-table
          [dataSource]="healthTouristSource"
          class="mat-elevation-z8"
         >
          <ng-container matColumnDef="touristCode">
            <th mat-header-cell *matHeaderCellDef > Tourist Code</th>
            <td mat-cell *matCellDef="let element;">{{element.code}}
            </td>
          </ng-container>
          <ng-container matColumnDef="identificationType">
            <th mat-header-cell *matHeaderCellDef > Identification Type</th>
            <td mat-cell *matCellDef="let element;">{{element.identificationType.desc?element.identificationType.desc:""}}
            </td>
          </ng-container>
          <ng-container matColumnDef="identificationNo">
            <th mat-header-cell *matHeaderCellDef > Identification No</th>
            <td mat-cell *matCellDef="let element;">{{element.identificationNumber}}
            </td>
          </ng-container>
          <ng-container matColumnDef="agentName">
            <th mat-header-cell *matHeaderCellDef >Agent Name </th>
            <td mat-cell *matCellDef="let element;">{{element.agentName}}
            </td>
          </ng-container>
          <ng-container matColumnDef="startEndDate">
            <th mat-header-cell *matHeaderCellDef > Start & End Date</th>
            <td mat-cell *matCellDef="let element;">{{element.startDate|date: "dd/MM/yyy"}} - {{element.endDate|date: "dd/MM/yyy"}}
            </td>
          </ng-container>
          <ng-container matColumnDef="contactNo">
            <th mat-header-cell *matHeaderCellDef > Contact No</th>
            <td mat-cell *matCellDef="let element;">{{element.contactNo}}
            </td>
          </ng-container>
          <ng-container matColumnDef="emailId">
            <th mat-header-cell *matHeaderCellDef > Email Id</th>
            <td mat-cell *matCellDef="let element;">{{element.emailId}}
            </td>
          </ng-container>
          <ng-container matColumnDef="addedOn">
            <th mat-header-cell *matHeaderCellDef > Added On</th>
            <td mat-cell *matCellDef="let element;">{{element.currentDate  |date :"dd/MM/yyyy hh:mm aa"}}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef > Action</th>
            <td mat-cell *matCellDef="let element;">
              <mat-icon (click)="deleteHealthTourist(element)">delete</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="healthTouristColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: healthTouristColumns"></tr>
        </table>
        </div>
        </div>
      </mat-tab>

      <!-- Registration: Documents Tab -->
      <mat-tab class="tabSubPersonalDetails">
        <ng-template mat-tab-label>
          {{ "EncounterPage.documents" | translate }}
        </ng-template>
        <div *ngIf="patientForm" class="flex">
          <div class="div-lgp">
            <div
              class="form-container"
              style="margin-right: 10px"
              attr.data-automation-attribute="divPersonalDetails"
            >
              <div class="file-table">
                <table
                  mat-table
                  [dataSource]="fileSourceReg"
                  class="mat-elevation-z8"
                >
                  <ng-container matColumnDef="docType" >
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "DocumentUpload.documentType" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="custom-cell">
                      <mat-form-field class="full-width"  appearance="outline" >
                        <mat-select [(ngModel)]="element.doctype" [ngModelOptions]="{ standalone: true }"
                        [disabled]="true"
                       > 
                        <mat-option>None</mat-option>
                           <mat-option *ngFor="let type of docListRegistration" [value]="type.code">
                             {{type.desc}}
                           </mat-option>
                         </mat-select>
                       </mat-form-field> 
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="docName" >
                    <th mat-header-cell *matHeaderCellDef >
                      {{ "DocumentUpload.documentName" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="custom-cell">
                      <input
                      type="text"
                      [(ngModel)]="element.fileDescription"
                      [disabled]="element.isUploaded"
                      (keyup)="
                        updateFiledesc($event.target.value, element, true)
                      "
                      name="fileDescription"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef >
                      {{ "DocumentUpload.fileName" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="custom-cell">
                      {{ element.fileName }}
                    </td>
                  </ng-container>
                  
                  <ng-container matColumnDef="fileType">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "DocumentUpload.fileType" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="custom-cell">
                      <mat-form-field class="full-width"  appearance="outline" >
                      <mat-select
                        [(ngModel)]="element.docFileType"
                        [required]="true"
                        [disabled]="element.isUploaded"
                        (selectionChange)="
                          upadteFileList(element.docFileType, element, true)
                        "
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="docTypeSearch"
                            [placeholderLabel]="
                              'RegistrationPage.search' | translate
                            "
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option>{{
                          "RegistrationPage.none" | translate
                        }}</mat-option>
                        <mat-option
                          *ngFor="let option of documentTypelist"
                          [value]="option.code"
                        >
                          {{ option.desc }}
                        </mat-option>
                      </mat-select>
                      </mat-form-field>
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="custom-cell">
                      {{ "DocumentUpload.action" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="custom-cell">
                      <div class="center">
                        <mat-icon
                        *ngIf="element.isUploaded"
                        style="font-size: 18px"
                        matTooltip="View"
                        (click)="viewFile(element)"
                        >remove_red_eye</mat-icon>
                        <mat-icon
                        *ngIf="element.isUploaded"
                          (click)="downloadFile(element)"
                          style="font-size: 18px"
                          matTooltip="{{
                            'DocumentUpload.downloadFile' | translate
                          }}"
                        >
                          file_download
                        </mat-icon>
                        <mat-icon
                          (click)="confirmDelete(element, true)"
                          style="font-size: 18px; color: red"
                          matTooltip="{{ 'payerInfo.removeFile' | translate }}"
                        >
                          remove_circle
                        </mat-icon>

                        
                      </div>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="fileUploadColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: fileUploadColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>

<div
  *ngIf="patientForm"
  attr.data-automation-attribute="divFooterAction"
>
<mat-grid-list cols="12" rowHeight="50px">
  <mat-grid-tile [colspan]="1">
    <button
    class="action-btn"
    mat-button
    data-automation-attribute="btn"
    (click)="getPatientAuditTrails()"
  >
    <img
      [style.width]="'25px'"
      matTooltip="{{ 'MergeListPage.PatientAuditTrails' | translate }}"
      class="img"
      [src]="'../assets/images/email-icons/patientAudit.png'"
    /></button
  ></mat-grid-tile>
  <mat-grid-tile [colspan]="10"></mat-grid-tile>
  <mat-grid-tile [colspan]="1">
  <button *ngIf="!isBackAllow" class="btnOrange" mat-mini-fab type="button">
    <mat-icon
      matTooltip="{{ 'payerInfo.back' | translate }}"
      (click)="transactionIncompleteDilog()"
      attr.data-automation-attribute="btnReset"
      >undo
    </mat-icon>
  </button>
  &nbsp;
  <button
    id="Upload Document"
    (click)="fileUploadDialog(true)"
    class="btnBlue"
    mat-mini-fab
  >
    <mat-icon matTooltip="{{ 'payerInfo.uploadDocument' | translate }}"
      >backup</mat-icon
    >
  </button>
  &nbsp;

  <button
    *ngIf="!isRegistered && !isEncounterAllow"
    id="Register"
    class="btnCyan"
    mat-mini-fab
    type="submit"
    attr.data-automation-attribute="btnSave"
    (click)="openRegisterConfirmDialog()"
  >
    <mat-icon matTooltip="{{ 'payerInfo.save' | translate }}"
      >sim_card</mat-icon
    >
  </button>
  </mat-grid-tile>
  </mat-grid-list>
</div>

<div id="#encounter" *ngIf="!patientForm">
  <div *ngIf="selectedPatient" class="mt-10 div-table-encounters">
    <ng-container>
      <table
        mat-table
        [dataSource]="dataSourceEncounters"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="VisitDate">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.visitDate" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.VisitDate | date: "dd/MM/yyy hh:mm aa" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="visitType">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.visitType" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.visitType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="VisitNumber">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.visitNo" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.VisitNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Department">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.speciality" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Department }}
          </td>
        </ng-container>

        <ng-container matColumnDef="DoctorName">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.doctor" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.DoctorName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="payer">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.payer" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.payer }}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="OutstandingAmount">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.outstandingAmt" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.OutstandingAmount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="BillNumber">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.invoiceNo" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.BillNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="BillAmount">
          <th mat-header-cell *matHeaderCellDef>
            {{ "EncounterPage.billAmt" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngStyle]="{ 'text-align': center }"
          >
            {{ element.BillAmount }}
          </td>
        </ng-container> -->

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsEncounters; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsEncounters"
        ></tr>
      </table>

      <div class="center" *ngIf="dataSourceEncounters.data.length == 0">
        {{ "EncounterPage.noEncounters" | translate }}
      </div>
    </ng-container>
  </div>
  <mat-tab-group class="tabGroupPersonalDetails mt-10" (selectedTabChange)="onTabChange($event)">
    <mat-tab class="tabSubPersonalDetails">
      <ng-template mat-tab-label>
        {{ "EncounterPage.encounterHeader" | translate }}
      </ng-template>
      <div class="section-grey">
        <form
          [formGroup]="formEncounter"
          #patientEncounterForm="ngForm"
          novalidate
          attr.data-automation-attribute="formUserPersonalDetails"
        >
          <div class="flex">
            <div class="col-6">
              <div class="flex">
                <div class="col-6">
                  <label class="green ml-10">{{
                    "EncounterPage.encounterDetails" | translate
                  }}</label>
                </div>
                <div class="col-6 right">
                  <mat-checkbox formControlName="visitToDepartment">
                    {{ "EncounterPage.departmentVisit" | translate }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-4">
                  <app-master-form-field
                    [isRequired]="true"
                    [label]="'EncounterPage.encounterType' | translate"
                    [masterName]="masterAPI.ENCOUNTER_TYPE_MASTER"
                    [formControlName]="'encounterType'"
                    [parentForm]="formEncounter"
                    [defaultValue]="encounterTypeValue"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-4">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.Speciality/Deparment" | translate
                    }}</mat-label>
                    <mat-select
                      [(ngModel)]="departmentValue"
                      [required]="true"
                      attr.data-automation-attribute="speciality"
                      formControlName="department"
                      (selectionChange)="getDoctorList($event, false)"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="dipartmentSearch"
                          [placeholderLabel]="'Department'"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option>{{
                        "RegistrationPage.none" | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let option of departmentFilterList"
                        [value]="option"
                      >
                        {{ option.desc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
               
                </div>
                <div class="col-3">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.appointmentSlots" | translate
                    }}</mat-label>
                    <input
                      readonly
                      formControlName="appointmentSlots"
                      type="text"
                      matInput
                    />
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <div class="divbottom">
                    <button
                      id="Add Appointment"
                      (click)="addAppointment()"
                      class="btnBlack"
                      mat-mini-fab
                    >
                      <mat-icon
                        matTooltip="{{
                          'EncounterPage.addAppointment' | translate
                        }}"
                        class="divbottom"
                        >calendar_today</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-4">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.doctor" | translate
                    }}</mat-label>
                    <mat-select
                      id="Doctor"
                      [required]="isDepartment"
                      attr.data-automation-attribute="encounterDoctor"
                      formControlName="doctor"
                      (scroll)="getScreenConfigurations()"
                      (selectionChange)="validationOnDoctor();getSpeciality($event)"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="doctorSearch"
                          [placeholderLabel]="'Doctor'"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option>{{
                        "RegistrationPage.none" | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let option of doctorFilteredList"
                        [value]="option.code"
                      >
                        {{ option.desc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <app-master-form-field
                    [isRequired]="isClinicReqired"
                    [label]="'EncounterPage.clinic' | translate"
                    [masterName]="masterAPI.CABIN_MASTER"
                    [formControlName]="'clinic'"
                    [parentForm]="formEncounter"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-4">
                  <app-master-form-field
                    [isRequired]="mandatoryField.encounterType"
                    [label]="'EncounterPage.cashCounter' | translate"
                    [masterName]="masterAPI.CASH_COUNTER_MASTER"
                    [formControlName]="'cashCounter'"
                    [parentForm]="formEncounter"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-8">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.encounterremarks" | translate
                    }}</mat-label>
                    <textarea
                      attr.data-automation-attribute="encounterRemark"
                      formControlName="remarks"
                      matInput
                      pattern="[a-zA-Z0-9\s]+"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Queue No</mat-label>
                    <input
                          formControlName="tokenNo"
                          [(ngModel)]="tokenNoValue"
                          matInput
                          placeholder="Queue No"
                          (focusout)="onFocusOutEventOnKey($event.target.value)"
                        />
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <div class="divbottom">
                    <button
                      id="Add Notes"
                      (click)="addInternalNotes()"
                      class="btnBlack"
                      mat-mini-fab
                    >
                      <mat-icon
                        matTooltip="{{
                          'AddInternalNotes.internalNotes' | translate
                        }}"
                        class="divbottom"
                        >note_add</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="medicalReport">
                <div class="col-6">
                  <label class="green mt-10">Medical Report Details</label>
                </div>

                <div class="col-12">
                  <mat-grid-list cols="12" rowHeight="70px">
                    <mat-grid-tile [colspan]="6" [rowspan]="1">
                      <app-master-form-field
                        [isRequired]="mandatoryField.encounterType"
                        [label]="'EncounterPage.reportType' | translate"
                        [masterName]="masterAPI.REPORTTYPE_MASTER"
                        [formControlName]="'reportTypeMrd'"
                        [parentForm]="formEncounter"
                        [isRequired]="medicalReport"
                        [isSubmitted]="patientEncounterForm.submitted"
                      >
                      </app-master-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="1">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label
                          >{{ "EncounterPage.reportReason" | translate }}
                        </mat-label>
                        <mat-select
                          formControlName="reportResonMrd"
                          [required]="medicalReport"
                        >
                          <mat-option>
                            <ngx-mat-select-search
                              [formControl]="reportResonMrdSearch"
                              [placeholderLabel]="
                                'RegistrationPage.search' | translate
                              "
                            >
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option>{{
                            "RegistrationPage.none" | translate
                          }}</mat-option>
                          <mat-option
                            *ngFor="let reasonMaster of reportReasonFilterdList"
                            [value]="reasonMaster.code"
                          >
                            {{ reasonMaster.desc }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="flex">
                <div class="col-12">
                  <label class="green ml-10">{{
                    "EncounterPage.referralDetails" | translate
                  }}</label>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-6">
                  <app-master-form-field
                    [label]="'EncounterPage.visitReason' | translate"
                    [masterName]="masterAPI.VISIT_REASON_MASTER"
                    [formControlName]="'visittype'"
                    [parentForm]="formEncounter"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-6">
                  <app-master-form-field
                    [label]="'RegistrationPage.patientCategory' | translate"
                    [masterName]="masterAPI.PATIENT_CATEGORY_MASTER"
                    [formControlName]="'patientCategory'"
                    [parentForm]="formEncounter"
                    [defaultValue]="
                      selectedPatient ? selectedPatient.patientCategory : ''
                    "
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
              </div>
              <div class="row mt-10" *ngIf="isAppointmentHsc">
                <div class="col-4">
                  <app-master-form-field
                    [label]="'EncounterPage.referralType' | translate"
                    [masterName]="masterAPI.REFERRAL_TYPE_MASTER"
                    [formControlName]="'referralType'"
                    [parentForm]="formEncounter"
                    (itemSelected)="getReferralList($event)"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-4">
                  <app-master-form-field
                    [label]="'EncounterPage.referral' | translate"
                    [masterName]="masterAPI.REFERRAL_MASTER"
                    [formControlName]="'referral'"
                    [parentForm]="formEncounter"
                    [params]="referralTypeCode"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-4">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Health Package</mat-label>
                    <mat-select
                      formControlName="healthPackage"
                      (selectionChange)="pakageChecked($event.value)"
                      multiple
                    >
                      <mat-option
                        *ngFor="let package of healthPackageList"
                        [value]="package.code"
                      >
                        {{ package.desc }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row mt-10" *ngIf="!isAppointmentHsc">
                <div class="col-6">
                  <app-master-form-field
                    [label]="'EncounterPage.referralType' | translate"
                    [masterName]="masterAPI.REFERRAL_TYPE_MASTER"
                    [formControlName]="'referralType'"
                    [parentForm]="formEncounter"
                    (itemSelected)="getReferralList($event)"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
                <div class="col-6">
                  <app-master-form-field
                    [label]="'EncounterPage.referral' | translate"
                    [masterName]="masterAPI.REFERRAL_MASTER"
                    [formControlName]="'referral'"
                    [parentForm]="formEncounter"
                    [params]="referralTypeCode"
                    [isSubmitted]="patientEncounterForm.submitted"
                  >
                  </app-master-form-field>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.reportRemark" | translate
                    }}</mat-label>
                    <textarea
                      formControlName="reportRemarks"
                      (focusout)="onFocusOutEventUpdate('PatientNotes')"
                      attr.data-automation-attribute="encounterreportRemark"
                      matInput
                    ></textarea>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{
                      "EncounterPage.referralRemarks" | translate
                    }}</mat-label>
                    <textarea
                      formControlName="referralRemarks"
                      attr.data-automation-attribute="encounterreferralRemarks"
                      matInput
                      pattern="^[\s\S]+$"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="medicalReport">
                <div class="col-6">
                  <label class="mt-10"></label>
                </div>
                <div class="col-2">
                  <label class="mt-2"></label>
                </div>
                <div class="col-12">
                  <mat-grid-list
                    cols="12"
                    style="margin-top: -4px"
                    rowHeight="70px"
                  >
                    <mat-grid-tile [colspan]="12" [rowspan]="1">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label
                          >{{ "mrd.reportRemarks" | translate }}
                        </mat-label>
                        <input
                          [required]="false"
                          formControlName="reportRemarkMrd"
                          matInput
                          placeholder="Report Remark"
                          pattern="[a-zA-Z0-9\s]+"
                        />
                      </mat-form-field>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="flex mt-10">
          <div class="col-6">
            <label class="green mt-10">{{
              "EncounterPage.payerDetails" | translate
            }}</label>
          </div>
          <div class="col-6 right">
            <button
              id="Add Payer"
              (click)="addPayer()"
              class="btnOrange"
              mat-mini-fab
            >
              <mat-icon matTooltip="{{ 'payerInfo.addPayer' | translate }}"
                >add</mat-icon
              >
            </button>
          </div>
        </div>

        <div class="gl-table mt-15">
          <table
            mat-table
            [dataSource]="dataSourcePayers"
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="Priority">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.priority" | translate }}
              </th>
              <td
                mat-cell
                class="center"
                *matCellDef="let element; let index = index"
              >
                <label>{{ index + 1 }}</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="PayerType">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.payerType" | translate }}
                <span class="red">*</span>
              </th>

              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="payer-info"
              >
                <mat-select
                  [(ngModel)]="element.payerTypeCode"
                  class="table-text full-width"
                  [disabled]="element.payerTypeCode == 'SELF'"
                  (selectionChange)="getPayerTypePayer(element.payerTypeCode,index);
                  "
                  attr.data-automation-attribute="encounterPayer{{ index }}"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="payerTypeSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>{{
                    "RegistrationPage.none" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let option of payerTypeList"
                    [disabled]="option.isSelf"
                    [value]="option.code"
                  >
                    {{ option.desc }} ({{ option.code }})
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="Payer">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.payer" | translate }}
                <span class="red">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="payer-info"
              >
                <mat-select
                  [(ngModel)]="element.payerCode"
                  [disabled]="element.isSelf"
                  class="table-text full-width"
                  attr.data-automation-attribute="encounterPayer{{ index }}"
                  (selectionChange)="validatePayer(element, index);getContractPayer(element.payerCode,index);"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="payerSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>{{
                    "RegistrationPage.none" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let option of element.payerList"
                    [value]="option.code"
                  >
                    {{ option.desc }} ({{ option.code }})
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="Tariff">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.tariff" | translate }}
                <span class="red">*</span>
              </th>
              <td mat-cell   class="payer-info" *matCellDef="let element; let index = index">
                <mat-select
                  [(ngModel)]="element.tariffCode"
                  class="table-text full-width"
                  attr.data-automation-attribute="encounterTariff{{ index }}"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="tariffSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let option of tariffList"
                    [value]="option.code"
                    >{{ option.desc }}</mat-option
                  >
                </mat-select>
              </td>
            </ng-container>

            <ng-container matColumnDef="Contract">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.contract" | translate }}
              </th>
              <td mat-cell  class="payer-info" *matCellDef="let element; let index = index">
                <mat-select
                  [(ngModel)]="element.contractCode"
                  class="table-text full-width"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterContract{{
                    index + 1
                  }}"
                  (selectionChange)="getTariffContract(element.contractCode,index);"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="contractCodeSearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>{{
                    "RegistrationPage.none" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let option of element.contractList"
                    [value]="option.code"
                    >{{ option.desc }}</mat-option
                  >
                </mat-select>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="AssociatedCompany">
              <th mat-header-cell *matHeaderCellDef>
                {{ "billing.associatedCompany" | translate }}
              </th>
              <td mat-cell  class="payer-info" *matCellDef="let element; let index = index">
                <mat-select
                  [(ngModel)]="element.associatedCompanyCode"
                  class="table-text full-width"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterAssociatedCompany{{
                    index
                  }}"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="associatedCompanySearch"
                      [placeholderLabel]="'RegistrationPage.search' | translate"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>{{
                    "RegistrationPage.none" | translate
                  }}</mat-option>

                  <mat-option
                    *ngFor="let option of associatedCompanyList"
                    [value]="option.code"
                    >{{ option.desc }}</mat-option
                  >
                </mat-select>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="gLRefNo">
              <th mat-header-cell *matHeaderCellDef>
                {{ "billing.gLRefNo" | translate }}
              </th>
              <td mat-cell   class="payer-info" *matCellDef="let element; let index = index" >
                <mat-select
                  [(ngModel)]="element.glReferenceNo"
                  (selectionChange)="glDetails(element.glReferenceNo,index,element.referralNoList,true)"
                  class="table-text full-width"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterGlType{{ index }}"
                >
                  <mat-option value="">None</mat-option>
                  <mat-option
                    *ngFor="let option of element.referralNoList"
                    [value]="option.glrefno"
                    >{{ option.glrefno }}</mat-option
                  >
                </mat-select>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="GLType">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.glType" | translate }}
              </th>
              <td mat-cell   class="payer-info" *matCellDef="let element; let index = index">
                <mat-select
                  [(ngModel)]="element.glTypeCode"
                  [disabled]="true"
                  class="table-text full-width"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterGlType{{ index }}"
                >
                  <mat-option value="">Select</mat-option>
                  <mat-option
                    *ngFor="let option of glTypeList"
                    [value]="option.code"
                    >{{ option.desc }}</mat-option
                  >
                </mat-select>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="PolicyNumber">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.policyNumber" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="policy-number"
              >
                <input
                  [(ngModel)]="element.policyNumber"
                  type="text"
                  class="policy-number"
                  value=""
                  maxlength="20"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterPolicyNumber{{
                    index
                  }}"
                  pattern="[a-zA-Z0-9]+"
                />

                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="EffectiveDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.effectiveDate" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="effective-date"
              >
                <input
                  matInput
                  [matDatepicker]="pickerEff"
                  [(ngModel)]="element.effectiveDate"
                  class="table-text full-width effective-date-input"
                  (click)="pickerEff.open()"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterEffectiveDate{{
                    index
                  }}"
                />
                <mat-datepicker #pickerEff></mat-datepicker>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="expiryDate">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.glexpirydate" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="effective-date"
              >
                <input
                  matInput
                  [matDatepicker]="pickerEff"
                  [(ngModel)]="element.expiryDate"
                  [disabled]="element.isexpirypayerDate"
                  class="table-text full-width effective-date-input"
                  (click)="pickerEff.open()"
                  *ngIf="!element.isSelf"
                  attr.data-automation-attribute="encounterEffectiveDate{{
                    index
                  }}"
                />
                <mat-datepicker #pickerEff></mat-datepicker>
                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="glBalance">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.glBalance" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let index = index"
                class="policy-number"
              >
                <input
                  [(ngModel)]="element.glAmount"
                  type="text"
                  class="policy-number"
                  value=""
                  *ngIf="!element.isSelf"
                  [disabled]="true"
                  attr.data-automation-attribute="encounterPolicyNumber{{
                    index
                  }}"
                />

                <label *ngIf="element.isSelf">NA</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef>
                {{ "EncounterPage.action" | translate }}
              </th>
              <td mat-cell *matCellDef="let element; let index = index" style="max-width: 120px; position: sticky;">
                <div style="display: flex;">
                <button mat-icon-button>
                  <mat-icon
                    *ngIf="element.isSelf == false"
                    (click)="removePayer(element)"
                    matTooltip="{{ 'payerInfo.delete' | translate }}"
                    >delete
                  </mat-icon>
                </button>
                &nbsp;
                <button
                  *ngIf="element.isSelf == false"
                  (click)="changePayerPriority(true, index)"
                  class="btnCyan"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                &nbsp;
                <button
                  *ngIf="element.isSelf == false"
                  (click)="changePayerPriority(false, index)"
                  class="btnCyan"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPayers"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsPayers"
            ></tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="tabSubPersonalDetails">
      <ng-template mat-tab-label>
        Dependent
      </ng-template>
      <div id="#referral">
        <form
          [formGroup]="formNextOfKin"
          #patientKinForm="ngForm"
          novalidate
          attr.data-automation-attribute="formUserPersonalDetails"
        >
          <div class="section-grey">
            <div class="flex">
              <div class="col-6">
                <div class="flex">
                  <div class="col-12">
                    <label class="green ml-10">Dependent Details</label>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-3">
                    <app-master-form-field
                      [label]="'RegistrationPage.prefix' | translate"
                      [masterName]="masterAPI.PREFIX_MASTER"
                      [formControlName]="'prefix'"
                      [parentForm]="formNextOfKin"
                      [isSubmitted]="patientKinForm.submitted"
                    >
                    </app-master-form-field>
                  </div>
                  <div class="col-5">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "EncounterPage.name" | translate
                      }}</mat-label>
                      <input
                        formControlName="name"
                        type="text"
                        matInput
                        oninput="this.value = this.value.toUpperCase()"
                        maxlength="100"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <app-master-form-field
                      [label]="'EncounterPage.primaryID' | translate"
                      [masterName]="masterAPI.IDENTIFICATION_TYPE_MASTER"
                      [formControlName]="'identificationType'"
                      [parentForm]="formNextOfKin"
                      [isSubmitted]="patientKinForm.submitted"
                    >
                    </app-master-form-field>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-3">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "EncounterPage.primaryDocNo" | translate
                      }}</mat-label>
                      <input
                        [required]="false"
                        formControlName="identificationNumber"
                        type="text"
                        maxlength="25"
                        matInput
                        pattern="([a-zA-Z0-9,'\-._/!@#$%^&*\s]+)"
                        (input)="inputValidation.replaceSpaces($event)"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-3">
                    <app-master-form-field
                      [label]="'EncounterPage.relation' | translate"
                      [masterName]="masterAPI.RELATION_MASTER"
                      [formControlName]="'relation'"
                      [parentForm]="formNextOfKin"
                      [isSubmitted]="patientKinForm.submitted"
                    >
                    </app-master-form-field>
                  </div>
                  <div class="col-3">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "EncounterPage.phone" | translate
                      }}</mat-label>
                      <input
                        formControlName="phoneNumber"
                        type="text"
                        pattern="[0-9]+"
                        matInput
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-3">
                    <div class="mt-10">
                      <mat-checkbox formControlName="isGuarantor">{{
                        "EncounterPage.isguarantor" | translate
                      }}</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="flex">
                  <div class="col-6">
                    <label class="green ml-10">{{
                      "EncounterPage.addressDetails" | translate
                    }}</label>
                  </div>
                  <div class="col-6 right">
                    <button
                      (click)="addNextOfKin()"
                      class="btnOrange"
                      mat-mini-fab
                    >
                      <mat-icon
                        matTooltip="{{ 'payerInfo.addReferral' | translate }}"
                        >add</mat-icon
                      >
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "EncounterPage.address" | translate
                      }}</mat-label>
                      <input
                        formControlName="address"
                        type="text"
                        matInput
                        pattern="[a-zA-Z0-9-.,/#'_@\s]+"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <app-master-form-field
                      [label]="'EncounterPage.country' | translate"
                      [masterName]="masterAPI.COUNTRY_MASTER"
                      [formControlName]="'country'"
                      [parentForm]="formNextOfKin"
                      (itemSelected)="getNOKStateList($event)"
                      [isSubmitted]="patientKinForm.submitted"
                    >
                    </app-master-form-field>
                  </div>
                  <div class="col-4">
                    <app-master-form-field
                      [label]="'EncounterPage.state' | translate"
                      [masterName]="masterAPI.STATE_MASTER_QUERY"
                      [formControlName]="'state'"
                      [parentForm]="formNextOfKin"
                      [params]="nokCountryCode"
                      (itemSelected)="getNOKCityList($event)"
                      [isSubmitted]="patientKinForm.submitted"
                    >
                    </app-master-form-field>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-4">
                    <app-master-form-field
                      [label]="'EncounterPage.city' | translate"
                      [masterName]="masterAPI.CITY_MASTER_QUERY"
                      [formControlName]="'city'"
                      [parentForm]="formNextOfKin"
                      [params]="nokStateCode"
                      [isSubmitted]="patientKinForm.submitted"
                      (itemSelected)="getSubDistrictForNOK($event)"
                    >
                    </app-master-form-field>
                  </div>
                  <div class="col-4">
                    <app-master-form-field
                      [label]="'RegistrationPage.subArea' | translate"
                      [masterName]="masterAPI.DIST_MASTER_QUERY"
                      [formControlName]="'district'"
                      [parentForm]="formNextOfKin"
                      [params]="nokCityCode"
                      [isSubmitted]="patientKinForm.submitted"
                      (itemSelected)="NOKDistrictSelected($event)"
                    >
                    </app-master-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{
                        "EncounterPage.zipcode" | translate
                      }}</mat-label>
                      <input
                        formControlName="postcode"
                        pattern="[a-zA-Z0-9]+"
                        type="text"
                        matInput
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="gl-table mt-10">
              <table
                mat-table
                [dataSource]="dataSourceNextOfKin"
                class="mat-elevation-z8"
              >
                       
              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <mat-checkbox [checked]="element.checked" (change)="getNokforSave($event,element)"></mat-checkbox>
                </td>
              </ng-container>
                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.name" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name | NameFormat}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Relation">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.relation" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.relation
                        ? element.relation.code
                          ? element.relation.code.desc
                          : ""
                        : ""
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="IdentificationType">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.primaryID" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.identificationType && element.identificationType.code
                        ? element.identificationType.code.desc
                          ? element.identificationType.code.desc
                          : ""
                        : element.identificationType
                          ? element.identificationType.desc
                          : ""
                    }}
                    
                  </td>
                </ng-container>

                <ng-container matColumnDef="IdentificationNo">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.primaryDocNo" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.idNumber }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Mobile">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.mobile" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.phoneNumber }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="IsGuarator">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.isguarantor" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <!-- {{ element.isGuarantor ? "Yes" : "No" }} -->
                    <mat-radio-button (change)="setGuarantorValue(element)"  [checked]="element.isGuarantor?element.isGuarantor:false">
                  </mat-radio-button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "EncounterPage.action" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon
                      matTooltip="{{ 'payerInfo.delete' | translate }}"
                      (click)="deleteNextOfKin(element)"
                      >delete</mat-icon
                    >
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsNOKs"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsNOKs"
                ></tr>
              </table>

              <div *ngIf="dataSourceNextOfKin.data.length == 0" class="center">
                {{ "EncounterPage.noRecords" | translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab class="tabSubPersonalDetails">
      <ng-template mat-tab-label>
        {{ "EncounterPage.documents" | translate }}
      </ng-template>

      <input type="file" id="fileUpload" style="display: none" />
      <div class="file-table">
        <table
          mat-table
          [dataSource]="fileSource"
          class="mat-elevation-z8"
         
        >
          <ng-container matColumnDef="docType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "DocumentUpload.documentType" | translate }}
              <span class="red">*</span>
            </th>
            <td mat-cell *matCellDef="let element" class="custom-cell">
              <mat-form-field class="full-width"  appearance="outline"  >
                <mat-select [(ngModel)]="element.doctype" [disabled]="element.isUploaded"
                [ngModelOptions]="{ standalone: true }"
               > 
                <mat-option>None</mat-option>
                   <mat-option *ngFor="let type of docListEncounter" [value]="type.code">
                     {{type.desc}}
                   </mat-option>
                 </mat-select>
               </mat-form-field> 
            </td>
          </ng-container>

          <ng-container matColumnDef="docName" >
            <th mat-header-cell *matHeaderCellDef>
              {{ "DocumentUpload.documentName" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="custom-cell">
              <input
              type="text"
              [(ngModel)]="element.fileDescription"
              [disabled]="element.isUploaded"
              name="fileDescription"
              pattern="[a-zA-Z0-9]+"
            />
            </td>
          </ng-container>

          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef>
              {{ "DocumentUpload.fileName" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="custom-cell">
              {{ element.fileName }}
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="fileType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "DocumentUpload.description" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <input
                type="text"
                pattern="[a-zA-Z0-9\s]+"
                [(ngModel)]="element.fileDescription"
                (keyup)="
                  updateFiledesc(element.fileDescription, element, false)
                "
                name="fileDescription"
              />
            </td>
          </ng-container> -->

          <ng-container matColumnDef="fileType">
            <th mat-header-cell *matHeaderCellDef>
              {{ "DocumentUpload.fileType" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="custom-cell">
              <mat-form-field class="full-width"  appearance="outline"  >
              <mat-select
                [(ngModel)]="element.docFileType"
                [required]="true"
                [disabled]="element.isUploaded"
                (selectionChange)="
                  upadteFileList(element.docFileType, element, true)
                "
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="docTypeSearch"
                    [placeholderLabel]="
                      'RegistrationPage.search' | translate
                    "
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option>{{
                  "RegistrationPage.none" | translate
                }}</mat-option>
                <mat-option
                  *ngFor="let option of documentTypelist"
                  [value]="option.code"
                >
                  {{ option.desc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
              </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef >
              {{ "DocumentUpload.action" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" >
              <a>
                <mat-icon
                  (click)="removeFile(element, false)"
                  style="font-size: 18px; color: red"
                  matTooltip="{{ 'payerInfo.removeFile' | translate }}"
                >
                  remove_circle
                </mat-icon>
              </a>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="fileUploadColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: fileUploadColumns"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab class="tabSubPersonalDetails"
    data-automation-attribute="tab-outstnading-bill">

      <ng-template mat-tab-label>
        <span style="display: inline-block; margin-right: 14px;">{{ "EncounterPage.pendingOrders" | translate }}</span>
      <span *ngIf="showBadge" matBadge="{{pendingOrderCount}}" matBadgeColor="warn" ></span>
      </ng-template>
      <div
      class="example-container mat-elevation-z8 pendingOrder-table"
      data-automation-attribute="div-worklist"
            >
            <table id="customers" data-automation-attribute="table-test-details">
              <tr style="font-family: 'Roboto', sans-serif; background-color: black;">
                <th  style="width: 12px;background-color: black;border-left: 1px solid #949090 !important;"></th>
                <th  style="width: 220px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 20px;">{{ "DocumentUpload.description" | translate }}</th>
                <th  style="width: 50px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 5px;">{{ "adt.priority" | translate }}</th>
                <th  style="width: 120px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 5px;">{{ "RegistrationPage.doctor" | translate }}</th>
                <th  style="width: 120px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 5px;">{{ "labPage.OrderedDate" | translate }}</th>
                <th  style="width: 120px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 5px;">{{ "EncounterPage.OrderUser" | translate }}</th>
                <th  style="width: 120px;text-align:left;background-color: black;border-left: 1px solid #949090 !important;padding-left: 5px;">{{ "mrd.remark" | translate }}</th>
               
              </tr>
            </table>
          
    <mat-accordion multi="true" *ngFor="let category of objectKeys(groupedItems)">
      <table class="table-css">
        <th> 
          <span style="margin-left: 5px; margin-top: 10px;"></span>
          <mat-checkbox (change)="selectPending(category, $event)" style="height: 25px;margin-top: 10px" [checked]="isCategory"></mat-checkbox>  <span style="margin-left: 15px;">{{ category }}</span></th>
      </table>
        <table  mat-table #myTable  [dataSource]="groupedItems[category]">
           <!-- ID Column -->
           <ng-container matColumnDef="isChecked">
            <th mat-header-cell *matHeaderCellDef hidden> </th>
            <td mat-cell *matCellDef="let element" > 
              <mat-checkbox [(ngModel)]="element.isSelected" class="custom-cell"></mat-checkbox></td>
          </ng-container>
          <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef hidden></th>
            <td mat-cell *matCellDef="let element" class="custom-cell">
              {{ element.serviceMaster.desc }}
            </td>
          </ng-container>
     
           <!-- Name Column -->
           <ng-container matColumnDef="Priority">
            <th mat-header-cell *matHeaderCellDef hidden>  </th>
            <td
            mat-cell *matCellDef="let element" class="custom-cell"
          >
            <div
              class="chip-worklist {{ element.priorityMaster }}"
              *ngIf="element.priorityMaster.desc == 'ROUTINE'|| element.priorityMaster.desc == 'Routine'"
            >
              <span
                class="span-dot-lab"
                style="background-color: rgb(36, 36, 177)"
                >R</span
              >
            </div>
            <div
              class="chip-worklist {{ element.priorityMaster }}"
              *ngIf="element.priorityMaster.desc == 'URGENT'|| element.priorityMaster.desc == 'Urgent'"
            >
              <span
                class="span-dot-lab"
                style="background-color: rgb(36, 177, 36)"
                >U</span
              >
            </div>
            <div
              class="chip-worklist {{ element.priorityMaster }}"
              *ngIf="element.priorityMaster.desc == 'STAT'|| element.priorityMaster.desc == 'Stat'"
            >
              <span
                class="span-dot-lab"
                style="background-color: rgb(229, 20, 20)"
                >S</span
              >
            </div>
          </td>
           </ng-container>
           <ng-container matColumnDef="Doctor">
            <th mat-header-cell *matHeaderCellDef hidden>  </th>
            <td mat-cell *matCellDef="let element" class="custom-cell" > {{element.doctorMaster?.desc}} </td>
          </ng-container>
          <ng-container matColumnDef="OrderDate">
            <th mat-header-cell *matHeaderCellDef hidden></th>
            <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.orderDate | date: "dd/MM/yyy hh:mm aa"}} </td>
          </ng-container>
          <ng-container matColumnDef="OrderUser">
            <th mat-header-cell *matHeaderCellDef hidden> </th>
            <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.orderUser}} </td>
          </ng-container>
          <ng-container matColumnDef="Remark" >
            <th mat-header-cell *matHeaderCellDef hidden>  </th>
            <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.remark}} </td>
          </ng-container>
           <tr mat-header-row *matHeaderRowDef="displayColumnsPendingOrders"></tr>
           <tr mat-row *matRowDef="let row; columns:displayColumnsPendingOrders;"></tr>
         </table>
 
    </mat-accordion>
    </div>
</mat-tab>
  </mat-tab-group>
  <div class="section-grey" [ngClass]="{ 'dflex': isDefaultDeposit && showDefaultDeposit }">
    <mat-form-field appearance="outline" *ngIf="isDefaultDeposit && showDefaultDeposit">
      <mat-label>Default Deposit</mat-label>
      <input
            [(ngModel)]="defaultDeposit"
            matInput
            type="number"
            (input)="inputValidation.limitInputLength($event,10)"
            placeholder="Default Deposit"
          />
    </mat-form-field>
    <div class="right mt-2">
    <button
      (click)="confirmClearPatientEncounter()"
      class="btnOrange"
      mat-mini-fab
    >
      <mat-icon matTooltip="{{ 'payerInfo.reset' | translate }}"
        >refresh</mat-icon
      >
    </button>
    &nbsp;
    <button
      id="Upload Document"
      (click)="fileUploadDialog(false)"
      class="btnBlue"
      mat-mini-fab
    >
      <mat-icon matTooltip="{{ 'payerInfo.uploadDocument' | translate }}"
        >backup</mat-icon
      >
    </button>
    &nbsp;
    <button
    *ngIf="!isEncounterAllow"
      id="Save Encounter"
      (click)="openEncounterConfirmDialog()"
      class="btnCyan"
      mat-mini-fab
    >
      <mat-icon matTooltip="{{ 'payerInfo.save' | translate }}"
        >sim_card</mat-icon
      >
    </button>
  </div>
  </div>
</div>
