import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {AppBaseService} from "../../../../services/http.service";
import {interval} from "rxjs";
import {timeInterval} from "rxjs/operators";
import {PushNotificationService} from "../../service/push-notification.service";

/**
 *
 */
@Component({
  selector: 'app-single-push-notification',
  templateUrl: './single-push-notification.component.html',
  styleUrls: ['./single-push-notification.component.scss']
})
export class SinglePushNotificationComponent implements OnInit {


  /**
   *
   */
  public pushNotificationDefaultLevelClass: string = 'divNotificationBlack';

  /**
   *
   */
  public pushNotificationLevelClass: any = {
    error: 'divNotificationRed',
    success: 'divNotificationSuccess',
    info: 'divNotificationInfo',
    warning: 'divNotificationWarning',
    default: 'divNotificationGrey'
  };

  /**
   *
   */
  @Input() notification: any;

  /**
   * Output Emitter
   */
  @Output() closeNotification = new EventEmitter<any>();

  /**
   *
   * @param el
   * @param viewContainer
   * @param pushNotificationService
   * @param route
   * @param appBaseService
   * @param authService
   */
  constructor(
    private readonly el:ElementRef,
    private readonly pushNotificationService: PushNotificationService,
    private readonly route: Router,
    private readonly appBaseService: AppBaseService,
    private authService: AuthService,
  ) { }

  /**
   *
   */
  ngOnInit() {
    interval(10000).pipe(
      timeInterval()
    ).subscribe(res => {
      this.closePushNotification();
    });
  }

  /**
   *
   * @param notification
   */
  public createdAtTime(notification) {
    return new Date(notification.createdAt).valueOf() ;
  }

  /**
   *
   */
  public getStatusClass() {
    let statusClass = this.pushNotificationLevelClass[this.notification.body.level];
    return  statusClass === undefined ? this.pushNotificationDefaultLevelClass : statusClass;
  }

  /**
   *
   */
  public clickPushNotification() {
    if (this.notification.type === 'actionAble') {
      if (this.notification.body.actionUrl !== '') {
        this.notificationClick(this.notification.body);
      }
    }
    if (this.notification.status === 'unread') {
      this.notification.status = 'read';
      this.authService.getUser().then((res: any) => {
        this.pushNotificationService.markPushNotificationRead(
          this.notification.notificationId
          //,res.userId
        );
      });
    }
    this.closePushNotification();
  }

  /**
   *
   */
  public closePushNotification() {
    this.el.nativeElement.remove();
    this.closeNotification.emit(this.notification);
  }

  /**
   *
   * @param buttonAction
   */
  public notificationClick(buttonAction) {
    switch (buttonAction.action) {
      case 'get':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService
          .getResource(buttonAction.actionUrl+"?"+this.serialize(buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'post':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.postResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'patch':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.patchResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'put':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.putResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'redirect':
        this.route.navigate(
          [buttonAction.actionUrl],
          {
            queryParams:buttonAction.param
          }
        );
        break;
    }
  }

  /**
   *
   * @param param
   */
  public serialize(param) {
    var str = [];
    for (var p in param)
      if (param.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(param[p]));
      }
    return str.join("&");
  }

}
