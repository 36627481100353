import { Component, OnInit } from '@angular/core';

import { Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-unsaved-form-dilog',
  templateUrl: './unsaved-form-dilog.component.html',
  styleUrls: ['./unsaved-form-dilog.component.scss']
})
export class UnsavedFormDilogComponent  {

  constructor(
    public dialogRef: MatDialogRef<UnsavedFormDilogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(type): void {
    this.dialogRef.close(type);
  }

}
