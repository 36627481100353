<!-- <div>
  <mat-grid-list cols="13" rowHeight="60px">
    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.effectiveStartEndDate" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" required>
          <input matStartDate placeholder="From date" />
          <input matEndDate placeholder="To date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.mRN" | translate }}</mat-label>
        <input
          matInput
          #inputSearch
          placeholder="{{ 'billing.mRN' | translate }}"
          data-automation-attribute="text-remarks"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.patientName" | translate }}</mat-label>
        <input
          matInput
          #inputSearch
          placeholder="{{ 'billing.patientName' | translate }}"
          data-automation-attribute="text-remarks"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.doctor" | translate }}</mat-label>
        <input
          matInput
          #inputSearch
          placeholder="{{ 'billing.doctor' | translate }}"
          data-automation-attribute="text-remarks"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.age" | translate }}</mat-label>
        <input
          matInput
          #inputSearch
          placeholder="{{ 'billing.age' | translate }}"
          data-automation-attribute="text-remarks"
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="2">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ "billing.gender" | translate }}</mat-label>
        <input
          matInput
          #inputSearch
          placeholder="{{ 'billing.gender' | translate }}"
          data-automation-attribute="text-remarks"
        />
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <a>
        <mat-icon matTooltip="{{ 'billing.search' | translate }}"
          >search</mat-icon
        >
      </a>
    </mat-grid-tile>
  </mat-grid-list>
</div> -->
<!-- <mat-grid-list cols="18" rowHeight="100px">
  <mat-grid-tile [colspan]="3" *ngFor="let data of counterList">
    <div class="count-div">
      <span class="count-label" [style.color]="data.color">
        {{ data.label }} </span
      ><br />
      <span class="count">{{ data.count }}</span>yes
    </div>
  </mat-grid-tile>
</mat-grid-list> -->
<div class="control-section">
  <ejs-dashboardlayout
    [columns]="columns"
    [cellSpacing]="cellSpacing"
    [cellAspectRatio]="cellAspectRatio"
  >
    <e-panels>
      <e-panel
        *ngFor="let card of configList.configuration; let index = index"
        [sizeX]="columnSizeXX"
        [sizeY]="columnSizeYY"
        [row]="card.row"
        [col]="card.col"
        [id]="card.id"
      >
        <ng-template #header>
          <div
            class="title"
            id="header1"
            style="font-size: 15px; font-weight: bold"
          >
            {{ card.header }}
            <span style="float: right">
              <mat-icon (click)="maximize(card, index)"
                >open_in_new</mat-icon
              ></span
            >
          </div>
        </ng-template>
        <ng-template #content>
          <app-bar-chart
            *ngIf="card.widget == 'barChart'"
            [data]="card"
            [isMaximize]="card.isMaximize"
          ></app-bar-chart>
          <app-pie-chart
            [data]="card"
            *ngIf="card.widget == 'pieChart'"
            [isMaximize]="card.isMaximize"
          ></app-pie-chart>
          <app-negative-stack
            idValue="id1"
            *ngIf="card.widget == 'nstack'"
            [isMaximize]="card.isMaximize"
          ></app-negative-stack>
          <app-pie-radius
            [data]="card"
            *ngIf="card.widget == 'pieradius'"
            [isMaximize]="card.isMaximize"
          ></app-pie-radius>
          <app-range-column
            [data]="card"
            *ngIf="card.widget == 'range'"
            [isMaximize]="card.isMaximize"
          ></app-range-column>
          <app-progress-bar
            [data]="card"
            *ngIf="card.widget == 'progressBar'"
            [isMaximize]="card.isMaximize"
          ></app-progress-bar>
          <app-counter
            [data]="card"
            *ngIf="card.widget == 'count'"
            [isMaximize]="card.isMaximize"
          >
          </app-counter>
          <app-donut [data]="card" *ngIf="card.widget == 'donut'"></app-donut>
          <app-table [data]="card" *ngIf="card.widget == 'table'"></app-table>
        </ng-template>
      </e-panel>
    </e-panels>
  </ejs-dashboardlayout>
</div>
