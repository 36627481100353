<div class="divfilter"></div>
<app-patient-search
  [search]="patientSearchList"
  [refreshTime]="refreshTime"
  (onActionClick)="patientOnSelect($event)"
  [hideAfter]="hidePatientSearch"
></app-patient-search>

<div class="patient-detail fixed-height">
  <div class="flex">
    <div class="col-8">
      <div *ngIf="patientInfoDisplay">
        <img
          [style.width]="'30px'"
          [src]="patientImage ? patientImage : '../assets/images/user.png'"
        />
        <label class="patient-name-label"> {{ patientInfoDisplay }}</label>
      </div>
      <div *ngIf="!patientInfoDisplay">
        <label class="patient-name-label">{{ "RegistrationPage.noPatient" | translate }}</label>
      </div>
    </div>
    <div class="col-4 right">
    </div>
  </div>
</div>

<div class="mt-10 encounter-list" data-automation-attribute="div-update-sponsor">
  <mat-table #table [dataSource]="dataSourceEncounter">
    <ng-container matColumnDef="radio">
      <mat-header-cell *matHeaderCellDef class="col-check-us">
        &nbsp;
      </mat-header-cell>
      <mat-cell *matCellDef="let element;let i = index" class="col-check-us">
        <mat-radio-group id="PayerTariff" [(ngModel)]="selectedPayer" matTooltip="{{'update.payertariff' | translate}}" (click)="getPayers(element, i)">
          <mat-radio-button [value]="element"></mat-radio-button>
        </mat-radio-group>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="visitDate">
      <mat-header-cell *matHeaderCellDef>
       {{'update.visitdate' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.encounterDate | date: "dd/MM/yyyy hh:mm a" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="visitType">
      <mat-header-cell *matHeaderCellDef>
        {{'update.visittype' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.visitType }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="visitNo">
      <mat-header-cell *matHeaderCellDef>
        {{'update.visitno' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.encounterNumber }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="department">
      <mat-header-cell *matHeaderCellDef>
        {{'update.department' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.department }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="doctorName">
      <mat-header-cell *matHeaderCellDef>
        {{'update.doctorname' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.doctorName }}
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayColumnsEncounter; sticky: true"
      class="tableHeader"
    ></mat-header-row>
    <mat-row
      class="table-row"
      tabindex="1"
      *matRowDef="let row; columns: displayColumnsEncounter"
    ></mat-row>
  </mat-table>

  <div *ngIf="dataSourceEncounter.data.length == 0" class="center">
    {{'update.norecordsfound' | translate}}
  </div>
</div>

<div *ngIf="patientId" class="right mt-10">
  <button
    id="Add Sponsor"
    class="btnOrange"
    mat-mini-fab
    data-automation-attribute="button-add-payment"
    (click)="validatePatientSponsor(patientInfoDisplay)"
  >
    <mat-icon matTooltip="{{'update.addsponsor'| translate}}">add</mat-icon>
  </button>
</div>
<div class="mt-10 encounter-list">
  <table mat-table [dataSource]="dataSourcePayers" class="mat-elevation-z8">
    <ng-container matColumnDef="Priority">
      <th mat-header-cell *matHeaderCellDef>{{'update.priority' | translate}}</th>
      <td
        mat-cell
        class="center"
        *matCellDef="let element; let priority = index"
      >
        <label>{{priority + 1}}</label> 
      </td>
    </ng-container>

    <ng-container matColumnDef="payerType">
      <th mat-header-cell *matHeaderCellDef>
        {{'update.payertype' | translate}} <span class="red">*</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <label>{{ (element.payerType && element.payerType.display)? element.payerType.display : "" }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="Payer">
      <th mat-header-cell *matHeaderCellDef>
        {{'update.payer' | translate}} <span class="red">*</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <label>{{ (element.payer && element.payer.display)? element.payer.display : "" }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="Contract">
      <th mat-header-cell *matHeaderCellDef>{{'update.contract' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="element.payer && element.payer.code != selfPayer">
          {{ 
            (element.contract && element.contract.display)? element.contract.display : ""
          }}
        </label>
        <label *ngIf="element.payer && element.payer.code == selfPayer">
          N/A
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="Tariff">
      <th mat-header-cell *matHeaderCellDef>
        {{'update.tariff' | translate}} <span class="red">*</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <label>{{ (element.tariff && element.tariff.display)? element.tariff.display : "" }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="AssociatedCompany">
      <th mat-header-cell *matHeaderCellDef>{{'update.association' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="element.payer && element.payer.code != selfPayer">
          {{ 
            (element.associatedCompany && element.associatedCompany.display)? element.associatedCompany.display : ""
          }}</label>
          <label *ngIf="element.payer && element.payer.code == selfPayer">
            N/A
          </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="GLType">
      <th mat-header-cell *matHeaderCellDef>
        {{'update.gltype' | translate}}
      </th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="element.payer && element.payer.code != selfPayer">
          {{ 
            (element.glType && element.glType.display)? element.glType.display : ""
          }}
        </label>
        <label *ngIf="element.payer && element.payer.code == selfPayer">
          N/A
        </label>
      </td>
    </ng-container>

    <ng-container matColumnDef="PolicyNumber">
      <th mat-header-cell *matHeaderCellDef>{{'update.policynumber' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <label>
          {{ (element.payer && element.payer.code != selfPayer)? element.policyNo : "N/A" }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="EffectiveDate">
      <th mat-header-cell *matHeaderCellDef>{{'update.effectivedate' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <label>{{(element.payer && element.payer.code != selfPayer)? (element.effectiveDate | date: "dd/MM/YYYY") : "N/A" }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>{{'update.status' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <label>{{ element.status }}</label>
      </td>
      <!-- <td mat-cell class="col-norwap" *matCellDef="let element">
        <span class="dot-status dot-{{  this.getStatusColor(element.status) }}">&nbsp;</span>
    </td> -->
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef>
        {{ "EncounterPage.action" | translate }}
      </th>
      <td mat-cell *matCellDef="let element; let index = index">
        <button mat-icon-button>
          <mat-icon
            *ngIf="!element.isSelf"
            (click)="removePayer(element)"
            matTooltip="{{ 'payerInfo.delete' | translate }}"
          >delete</mat-icon>
        </button>
        &nbsp;
        <button
          *ngIf="!element.isSelf"
          (click)="changePayerPriority(true, index)"
          class="btnCyan"
          mat-mini-fab
          type="button"
        >
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        &nbsp;
        <button
          *ngIf="!element.isSelf"
          (click)="changePayerPriority(false, index)"
          class="btnCyan"
          mat-mini-fab
          type="button"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        &nbsp;
        <button mat-icon-button>
          <mat-icon
            *ngIf="!element.isSelf && element.payer.code != selfPayer && element.id != null"
            (click)="openGlAttachment(element)"
            matTooltip="{{ 'payerInfo.glAttachment' | translate }}"
          >description</mat-icon>
        </button>
      </td>
      
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsPayers"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsPayers"></tr>
  </table>
 
  <div *ngIf="dataSourcePayers.data.length == 0" class="center">
    {{'update.norecordsfound' | translate}}
  </div>
</div>
<div *ngIf="patientId" class="right mt-10">
  <button
    class="btnOrange"
    mat-mini-fab
    data-automation-attribute="button-reset-payment"
    (click)="cleanfields()"
    matTooltip="{{'update.reset' | translate}}"
  >
    <mat-icon>loop</mat-icon>
  </button>
  &nbsp;
  <button
    id="Save Sponsor"
    class="btnCyan"
    *ngIf="addPayer || removepayer || changedPayer"
    mat-mini-fab
    data-automation-attribute="button-add-payment"
    (click)="saveDialogService()"
    matTooltip="{{'update.savesponsor' | translate}}"
  >
    <mat-icon>sim_card</mat-icon>
  </button>
</div>
