<div>
<div class="modal-header modalHeaderBlack">
    <h6 class="modal-title">{{ 'payerInfo.glAttachment' | translate }}</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()">
          <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
    </span>
  </div>
  <!--accordion code ends here-->
  <div class="modal-body divFullWidth" data-automation-attribute="div-discount-details">
    <form
      [formGroup]="glApprovalForm"
      (ngSubmit)="saveGLApproval()"
      data-automation-attribute="form-glapproval"
    >
      <div class="divGL">
        <b class="gl-head-green">{{
          "billing.gLAuthorizationDetails" | translate
        }}</b>
        <div class="form-container-gl">
          <mat-grid-list cols="14" rowHeight="62px">
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.policyNumber" | translate }}</mat-label>
                <input
                  matInput
                  placeholder="Policy Number"
                  formControlName="policyNumber"
                  maxlength="25"
                  data-automation-attribute="text-discount-percentage"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.policyDate" | translate }}</mat-label>
                <input
                  id="policystart"
                  matInput
                  [matDatepicker]="picker1"
                  formControlName="policyDate"
                  data-automation-attribute="dateDOB"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="4">
              <div class="custom-mat-form-field">
                <label>Patient Type <span style="color:red;" *ngIf="requiredFlg">*</span></label>
                  <mat-radio-group
                  formControlName="patientType"
                  [required]="requiredFlg"
                  (change)="changePatientType($event)"
                  aria-labelledby="radioGroup" class="mat-form-field-flex"
                  data-automation-attribute="radiogrp-patient-type"
                  >
                  <mat-radio-button value="IP" data-automation-attribute="radio-new">
                    In-Patient
                  </mat-radio-button>
                  <mat-radio-button value="OP" data-automation-attribute="radio-current">
                    Out-Patient
                  </mat-radio-button>
                  <mat-radio-button value="ALL" data-automation-attribute="radio-existing">
                    All
                  </mat-radio-button>
                  </mat-radio-group>
              </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="4">
              <mat-checkbox formControlName="inclusiveCoverage" (change)="onChange($event)">
                All-Inclusive Coverage
              </mat-checkbox>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2"></mat-grid-tile>

          </mat-grid-list>

          <mat-grid-list cols="14" rowHeight="62px">
            <mat-grid-tile [colspan]="4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.holderName" | translate }}</mat-label>
                <input
                  matInput
                  placeholder="Holder Name"
                  formControlName="holderName"
                  [required]="requiredFlg"
                  maxlength="100"
                  data-automation-attribute="text-discount-percentage"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.contactNumber" | translate }}</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="contactNo"
                  placeholder="Contact Number"
                  [required]="requiredFlg"
                  data-automation-attribute="dateDOB"
                  (keyup)="mobileFormat($event.target.value)"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.relation" | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="relation"
                  formControlName="relation"
                  placeholder="Relation"
                >
                  <mat-option>
                    <ngx-mat-select-search
                    [formControl]="relationFilterCtrl"
                    class="full-width"
                    ngDefaultControl
                    [placeholderLabel]="'billing.search' | translate"
                  >
                  </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let type of filteredRelation | async"
                    [value]="type.id"
                    >
                      {{ type.desc }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="4">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.employeeName" | translate }}</mat-label>
                <input
                  matInput
                  maxlength="100"
                  formControlName="employeeName"
                  placeholder="Employee Name"
                  data-automation-attribute="employeeName"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.employeeNo" | translate }}</mat-label>
                <input
                  matInput 
                  placeholder="Employee Number"
                  formControlName="employeeNo"
                  maxlength="50"
                  data-automation-attribute="text-discount-percentage"
                />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="14" rowHeight="62px">

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.department" | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="department"
                  formControlName="department"
                  [placeholder]="department"
                >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="departmentNameFilterCtrl"
                    class="full-width"
                    ngDefaultControl
                    [placeholderLabel]="'billing.search' | translate"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let dept of filteredDepartmentName | async"
                  [value]="dept.code"
                >
                  {{ dept.desc }}
                </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.status" | translate }}</mat-label>
                <mat-select
                  (selectionChange)="AddMandatory($event)"
                  [(ngModel)]="status"
                  formControlName="status"
                  [placeholder]="status"
                  [required]="requiredFlg"
                >
                <mat-option>
                  <ngx-mat-select-search
                  [formControl]="gltypeFilterCtrl"
                  class="full-width"
                  ngDefaultControl
                  [placeholderLabel]="'billing.search' | translate"
                >
                </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let type of filteredGltypes | async"
                  [value]="type.code"
                  >
                    {{ type.desc }}
                </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.gLReferenceNo" | translate
                }}</mat-label>
                <input
                  matInput
                  [required]="requiredFlg"
                  placeholder="GL Reference No"
                  formControlName="glReferenceNumber"
                  maxlength="50"
                  data-automation-attribute="text-discount-percentage"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.gLDate" | translate }}</mat-label>
                <input
                  matInput
                  [min]="minGeExp"
                  [matDatepicker]="picker3"
                  (ngModelChange)="setGlExpDate($event)"
                  formControlName="glDate"
                  [required]="requiredFlg"
                  data-automation-attribute="dateDOB"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.gLEffectiveDate" | translate
                }}</mat-label>
                <input
                  matInput
                  [min]="minGxExp"
                  [matDatepicker]="picker4"
                  (ngModelChange)="setExpDate($event)"
                  formControlName="glEffectiveDate"
                  [required]="requiredFlg"
                  data-automation-attribute="dateDOB"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker4"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.expiryDate" | translate }}</mat-label>
                <input
                  matInput
                  [min]="minExpDate"
                  (ngModelChange)="calculateDuration()"
                  formControlName="expiryDate"
                  [matDatepicker]="picker5"
                  [required]="requiredFlg"
                  data-automation-attribute="dateDOB"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker5"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
            
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.durationDays" | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="duration"
                  placeholder="Duration (In Days)"
                  (input)="calculateDuration($event)"
                  maxlength="20"
                  data-automation-attribute="duration"
                />
              </mat-form-field>
            </mat-grid-tile>

          </mat-grid-list>

          <mat-grid-list cols="14" rowHeight="62px">
            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.gLAmount" | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="glAmount"
                  placeholder="GL Amount"
                  [required]="inclusiveFlag"
                  maxlength="20"
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.coverageAmount" | translate
                }}</mat-label>
                <input
                  matInput
                  [required]="coverageRequiredFlg"
                  (input)="calculateAvailableAmt()"
                  type="number"
                  maxlength="20"
                  formControlName="coverageAmount"
                  placeholder="Coverage Amount"
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.noOfVisits" | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  maxlength="2"
                  formControlName="noOfVisits"
                  (input)="calculateBalanceVisit($event)"
                  [required]="inclusiveFlag"
                  placeholder="Number Of Visits"
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.balanceVisits" | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  maxlength="2"
                  formControlName="noOfBalVisits"
                  placeholder="Balance Visits"
                  readonly
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{
                  "billing.maxAmtLimits" | translate
                }}</mat-label>
                <input
                  matInput
                  type="number"
                  maxlength="2"
                  formControlName="maxLimitAmt"
                  placeholder="Max Amount Limits"
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.customerGroup" | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="customerGroup"
                  formControlName="customerGroup"
                  placeholder="Customer Group"
                >
                  <mat-option
                    *ngFor="let type of customerGroupList"
                    [value]="type.id"
                  >
                    {{ type.desc }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{ "billing.customerName" | translate }}</mat-label>
                <input
                  matInput
                  maxlength="100"
                  formControlName="customerName"
                  placeholder="Customer Name"
                  data-automation-attribute="dateDOB"
                />
              </mat-form-field>
            </mat-grid-tile>

          </mat-grid-list>

        </div>

        <div class="flex">
          <div class="divide">
            <b class="gl-head-green">{{ "billing.gLRemarks" | translate }}</b>

            <mat-grid-list cols="12" rowHeight="62px">
              <mat-grid-tile [colspan]="8">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{ "billing.remarks" | translate }}</mat-label>
                  <input
                    matInput
                    maxlength="250"
                    [(ngModel)]="remark"
                    formControlName="remark"
                    placeholder="Remark"
                    data-automation-attribute="dateDOB"
                  />
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile [colspan]="3">
                <div class="div-btn">
                  <button
                    id="Add GL Remarks"
                    class="mat-mini-fab btnCyan"
                    mat-button
                    type="button"
                    (click)="addRemark()"
                    data-automation-attribute="btnCloseRecords"
                  >
                    <mat-icon
                      matTooltip="{{ 'billing.addGlRemarks' | translate }}"
                      >add</mat-icon
                    >
                  </button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>

            <table
              mat-table
              [dataSource]="dataSourceRemarks"
              class="divFullWidth"
              data-automation-attribute="table-pending-refund-list"
            >
              <ng-container matColumnDef="addedon">
                <th mat-header-cell *matHeaderCellDef class="headerPhoto">
                  {{ "billing.date" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="headerPhoto">
                  {{ element.createdDate | date: "dd/MM/yyyy" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="addedby">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "billing.addedBy" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.addedBy }}
                </td>
              </ng-container>

              <ng-container matColumnDef="remarks">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "billing.remark" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.remarkText }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsRemarks"
                class="tableHeader"
                data-automation-attribute="header-pending-refund-list"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsRemarks"
                class="tableRow"
                data-automation-attribute="row-pending-refund-list"
              ></tr>
            </table>
          </div>
          <div class="divide">
            <b class="gl-head-green">{{
              "billing.gLAttachmentDocumentList" | translate
            }}</b>

            <mat-grid-list cols="12" rowHeight="62px">
              <mat-grid-tile [colspan]="8">
              </mat-grid-tile>

              <mat-grid-tile [colspan]="4">
                <div class="div-btn">
                  <button
                    id="Attach Document"
                    class="mat-mini-fab btnCyan"
                    mat-button
                    type="button"
                    data-automation-attribute="btnCloseRecords"
                    (click)="selectFile()"
                  >
                    <mat-icon
                      matTooltip="{{ 'billing.attachDocument' | translate }}"
                      >backup</mat-icon
                    >
                  </button>
                  <input
                    type="file"
                    class="hide"
                    id="fileAttachment"
                    multiple="multiple"
                    accept="application/pdf"
                    (change)="handleFileInput($event.target.files)"
                  />
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <table
              mat-table
              [dataSource]="dataSourceGLAttachment"
              class="divFullWidth"
              data-automation-attribute="table-pending-refund-list"
            >
            <ng-container matColumnDef="docType">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DocumentUpload.documentType" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field class="full-width"  appearance="outline" >
                  <mat-select [(ngModel)]="element.doctype" [ngModelOptions]="{ standalone: true }"
                  [disabled]="true"
                 > 
                  <mat-option>None</mat-option>
                     <mat-option *ngFor="let type of docList" [value]="type.code">
                       {{type.desc}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field> 
              </td>
            </ng-container>
  
            <ng-container matColumnDef="docName">
              <th mat-header-cell *matHeaderCellDef >
                {{ "DocumentUpload.documentName" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <input
                type="text"
                pattern="[a-zA-Z0-9\s]+"
                [(ngModel)]="element.fileDescription"
                [disabled]="element.isUploaded"
                [ngModelOptions]="{ standalone: true }"
                
              /></td>
            </ng-container>
  
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DocumentUpload.fileName" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.fileName }}
              </td>
            </ng-container>  
            <ng-container matColumnDef="fileType">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DocumentUpload.fileType" | translate }}
              </th>
              <td mat-cell *matCellDef="let element; let index = index">
                <mat-select
                  [(ngModel)]="element.docFileType"
                  [required]="true"
                  [disabled]="element.isUploaded"
                  (selectionChange)="
                    upadteFileList(element.docFileType, element, true)
                  "
                  class="table-text full-width"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="docTypeSearch"
                      [placeholderLabel]="
                        'RegistrationPage.search' | translate
                      "
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option>{{
                    "RegistrationPage.none" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let option of documentTypelist"
                    [value]="option.code"
                  >
                    {{ option.desc }}
                  </mat-option>
                </mat-select>
                </td>
            </ng-container>
  
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DocumentUpload.action" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon
                *ngIf="element.isUploaded"
                style="font-size: 18px"
                matTooltip="{{ 'common.view' | translate }}"
                (click)="viewFile(element)"
                >remove_red_eye</mat-icon
              >
              
                <mat-icon matTooltip="Download" style="font-size: 18px"
                (click)="downloadDocument(element)" download="file.txt"
                 *ngIf="element.isUploaded">file_download</mat-icon>
    
                <mat-icon
                  (click)="confirmDelete(element)"
                  style="font-size: 18px; color: red"
                  matTooltip="{{ 'payerInfo.removeFile' | translate }}"
                >
                  remove_circle
                </mat-icon>
              </td>
            </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsGLAttachment"
                class="tableHeader"
                data-automation-attribute="header-pending-refund-list"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsGLAttachment"
                class="tableRow"
                data-automation-attribute="row-pending-refund-list"
              ></tr>
            </table>
          </div>
        </div>
      </div>

      <table
      class="glDetailsFooter"
      data-automation-attribute="table-footer-actions"
    >
      <tr class="patientDetailsFooterBtns">
        <td class="centerTd btnFooterContact">
          <button
            class="btnCyan"
            mat-mini-fab
            type="submit"
            data-automation-attribute="button-save"
          >
            <mat-icon matTooltip="{{ 'billing.save' | translate }}"
              >sim_card</mat-icon
            >
          </button>
        </td>
      </tr>
    </table>
    </form>
  </div>
 
</div>