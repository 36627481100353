<div style="padding: 5px; overflow: auto; overflow-x: hidden" id="counter">
  <mat-grid-list cols="12" rowHeight="120px">
    <mat-grid-tile [colspan]="6" *ngFor="let data of dataSource">
      <div class="count-div" id="main-counter-div" style="padding: 20px">
        <span class="count-label"> {{ data.type }} </span><br />
        <span class="count">{{ data.count }}</span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
