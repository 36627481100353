import {Component, Input, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import {AppBaseService} from "../../../../services/http.service";

/**
 *
 */
@Component({
  selector: 'app-push-notification-button',
  templateUrl: './push-notification-button.component.html',
  styleUrls: ['./push-notification-button.component.scss']
})
export class PushNotificationButtonComponent implements OnInit {

  /**
   *
   */
  @Input() notification: any;

  /**
   *
   * @param route
   * @param appBaseService
   */
  constructor(
    private readonly route: Router,
    private readonly appBaseService: AppBaseService,
  ) { }

  /**
   *
   */
  ngOnInit() {
  }

  /**
   *
   * @param buttonAction
   */
  public buttonColor(buttonAction) {
    return (buttonAction.color === 'error') ? 'btnRed' : 'btnCyan';
  }

  /**
   *
   * @param buttonAction
   */
  public buttonClick(buttonAction) {
    this.route.navigate([buttonAction], {});
  }

  /**
   *
   * @param param
   */
  public serialize(param) {
    var str = [];
    for (var p in param)
      if (param.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(param[p]));
      }
    return str.join("&");
  }
}
