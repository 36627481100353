export const authProperties = {
  passcode: "Enter Password",
  username: "Username",
  enterusername: "Enter Username",
  firsttimepasscode: "First Time Password",
  enterpassword: "Enter Password",
  enterpasscode: "Enter Passcode",
  confirmpasscode: "Confirm Passcode",
  emailid: "Email ID",
  enteremailID: "Enter Email ID",
  emailnotfound: "Email not found. Please contact administrator.",
  verificationemail: "A verification has been send to your email. Please check your email.",
  backgroundImage: "../assets/images/BG.jpg",
  resetPassword: "Provide your accounts email for which you want to reset your password!",
  verificationCode: "Please enter the verification code sent to:",
  updatePassword: "Your identity has been verified! Please Set your New Password",
  updatePasscode: "Your identity has been verified! Please Set your New Passcode",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  loginTypePasscode: "PASSCODE",
  loginTypePassword: "keycloak",
  loginTypeActiveDirectory: "AD"
};
