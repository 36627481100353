import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  AppBaseService,
  ConfirmDynamicDialogComponent,
  MICROSERVICES,
} from "medcare-core-ui";
import { DatePipe } from "@angular/common";
import { Subject, Subscription, takeUntil } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { AccountPayerModel } from "../sponsormodels/MsponsorModel";
import { MasterUrlService } from "../../regservices/SmasterUrl.service";
import { OPD_API_URL } from "../../regproperties/opd-url-properties";
import { DialogData } from "../sponsorinterfaces/IdialogData";
import { Codable } from "../sponsormodels/McodableModel";
import { OPD_CONST } from "../../regproperties/opd-static-properties";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "add-sponsor-dialog",
  templateUrl: "add-sponsor.dialog.html",
  styleUrls: ["./update-sponsor.component.scss"],
  providers: [DatePipe],
})
export class AddSponsorDialogComponent implements OnInit, OnDestroy {
  tariff: Codable;
  payer: Codable;
  AssociateCompany: Codable = new Codable();
  GLType: Codable = new Codable();
  policyNo: string = "";
  GLLimit: number;
  GLEffectiveDate: any;
  GLExpiryDate: Date = new Date();
  GLReferenceNo: string = "";

  formPayer: FormGroup;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();

  tariffs: any[] = [];
  tariffData: any[] = [];
  payers: any[] = [];
  payerAccountList: any[] = [];
  accountDetails: any[] = [];
  glAccounts: any[] = [];
  Prioritys: any[] = [1];
  adAssociateCompanys: any[] = [];
  GLTypes: any[] = [];
  minGeExp = new Date(this.year, this.month, this.day);
  minGxExp = new Date(this.year, this.month, this.day);
  payerList: any[] = [];
  payerListByType: any[] = [];
  glTypeList: any[] = [];
  tariffList: any[] = [];
  associatedCompanyList: any[] = [];
  contractList: any[] = [];
  payerTypeList: any[] = [];
  payerTypeFilteredList:any;
  payerFilteredList: any;
  tariffFilteredList: any;
  contractCodeList: any;
  associatedCompanyCodeList: any;

  private subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  payerTypeSearch: FormControl = new FormControl();
  payerSearch:FormControl = new FormControl();
  tariffSearch:FormControl = new FormControl();
  contractCodeSearch:FormControl = new FormControl();
  associatedCompanySearch:FormControl = new FormControl();


  constructor(
    public dialogRef: MatDialogRef<AddSponsorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private httpService: AppBaseService,
    public masterApiService: MasterUrlService,
    private datepipe: DatePipe,
    public dialog: MatDialog, 
    public snackBar: MatSnackBar,
    private langService: MultiLanguageService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formPayer = this.formBuilder.group({
      payerType: ["", Validators.required],
      payer: ["", Validators.required],
      tariff: ["", Validators.required],
      contract: [""],
      AssociateCompany: [""],
      GLType: [""],
      policyNo: [""],
      GLEffectiveDate: ["", Validators.required],
    });
    this.getPayerTypeList();
    this.getPayerList();
    this.getContractList();
    this.getTariffList();
    this.getAssociatedCompanyList();
    this.getGLTypeList();
    this.formPayer.controls.GLType.disable();
    this.onChanges();
    this.minGeExp = new Date(this.data.visitDate);
  }

   /**
   *onchanges chekcing multiple conditions
   *
   * @memberof AddSponsorDialogComponent
   */
   onChanges() {
    this.subscriptionList.add(
      this.payerTypeSearch.valueChanges.subscribe((value) => {
        this.payerTypeList = this.payerTypeFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.payerSearch.valueChanges.subscribe((value) => {
        this.payerList = this.payerFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.contractCodeSearch.valueChanges.subscribe((value) => {
        this.contractList = this.contractCodeList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.associatedCompanySearch.valueChanges.subscribe((value) => {
        this.associatedCompanyList = this.associatedCompanyCodeList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.tariffSearch.valueChanges.subscribe((value) => {
        this.tariffList = this.tariffFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
   }

  /**
   *Destroy method
   *
   * @memberof AddSponsorDialogComponent
   */
  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  /**
   * To get payer list
   *
   * @memberof AddSponsorDialogComponent
   */
  getPayerList() {
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_COMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.payerList = res;
        this.payerFilteredList=res;
      });
  }

  /**
   * To get GL types
   *
   * @memberof AddSponsorDialogComponent
   */
  getGLTypes() {
    this.GLTypes = [];
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_GLTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.glTypeList = res;
        this.GLType = res[0];
      });
  }

  /**
   * To get tariff list
   *
   * @memberof AddSponsorDialogComponent
   */
  getTariffList() {
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_TARIFF)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.tariffList = res;
        this.tariffFilteredList=res;
      });
  }

  /**
   * To get Associated Company list
   *
   * @memberof AddSponsorDialogComponent
   */
  getAssociatedCompanyList() {
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_ASSOCIATECOMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.associatedCompanyList = res;
        this.associatedCompanyCodeList=res;
      });
  }

  /**
   * To get contract list
   *
   * @memberof AddSponsorDialogComponent
   */
  getContractList() {
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_CONTRACT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.contractList = res;
        this.contractCodeList=res;
      });
  }

  /**
   * It is the source of getGLTypeList data.
   *
   * @memberof AddSponsorDialogComponent
   */
  getGLTypeList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_GLTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.glTypeList = data;
        this.formPayer.patchValue({ GLType: OPD_CONST.pendingGL });
      });
  }

  /**
   * On close click
   *
   * @memberof AddSponsorDialogComponent
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * To add sponser information
   *
   * @memberof AddSponsorDialogComponent
   */
  addSponsor() {
    if (this.formPayer.valid) {
      const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
        width: "20pc",
        data: { displayText: "doyouwanttoproceed" },
      });
      this.subscriptionList.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result.confirm == "yes") {
            let accountPayerModel = new AccountPayerModel();
            accountPayerModel.tariffcode = this.formPayer.value.tariff.code;
            accountPayerModel.tariffname = this.formPayer.value.tariff.desc;
            accountPayerModel.payerId = this.formPayer.value.payer.id;
            accountPayerModel.payercode = this.formPayer.value.payer.code;
            accountPayerModel.payersname = this.formPayer.value.payer.desc;
            accountPayerModel.payerTypeId = this.formPayer.value.payerType.id;
            accountPayerModel.payerTypeCode =
              this.formPayer.value.payerType.code;
            accountPayerModel.payerTypeName =
              this.formPayer.value.payerType.desc;
            accountPayerModel.contractId = this.formPayer.value.contract
              ? this.formPayer.value.contract.id
              : "";
            accountPayerModel.contractCode = this.formPayer.value.contract
              ? this.formPayer.value.contract.code
              : "";
            accountPayerModel.contractName = this.formPayer.value.contract
              ? this.formPayer.value.contract.desc
              : "";
            accountPayerModel.policyNo = this.formPayer.value.policyNo;
            accountPayerModel.effectiveDate = this.datepipe.transform(
              this.formPayer.value.GLEffectiveDate,
              "yyyy-MM-dd'T'HH:mm:ss.SSSZ"
            );
            accountPayerModel.AssociateCompandCode = this.formPayer.value
              .AssociateCompany
              ? this.formPayer.value.AssociateCompany.code
              : "";
            accountPayerModel.associatedCompanyId = this.formPayer.value
              .AssociateCompany
              ? this.formPayer.value.AssociateCompany.id
              : "";
            accountPayerModel.associatedCompanyName = this.formPayer.value
              .AssociateCompany
              ? this.formPayer.value.AssociateCompany.desc
              : "";
            accountPayerModel.glTypeCode = OPD_CONST.pendingGL;
            accountPayerModel.glTypeName = OPD_CONST.pendingGL;
            this.dialogRef.close({
              payer: accountPayerModel,
            });
          }
        })
      );
    }else{
      this.snackBar.open(
        this.langService.get("CommonAppoinment.pleasefillallmandatory"),
          this.langService.get("CommonAppoinment.warning"),
           {
        duration: 3000,
      });
    }
  }

  /**
   * It is the source of payerType data.
   *
   * @memberof AddSponsorDialogComponent
   */
  getPayerTypeList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_COMPANYTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.payerTypeList = data;
        this.payerTypeFilteredList=data;
      });
  }

  /**
   *Passing payerCode and index to get the contractList
   *
   * @param {*} payerCode
   * @param {*} index
   * @memberof AddSponsorDialogComponent
   */
  getPayerListData(event) {
    let payerListByType = [];
    this.payerList.forEach((element) => {
      if (element.companyTypeMaster.code == event.value.code)
        payerListByType.push(element);
    });
    this.payerListByType = payerListByType;
  }
}
