import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {EnvoirnmentService} from "../../../../services/envoirnment.service";
import {AuthService} from "../../../../services/auth.service";
import {take} from "rxjs/operators";
import {AllPushNotificationsService} from "../../service/all-push-notifications.service";
import {PushNotificationService} from "../../service/push-notification.service";
import {ShowAllPushNotificationComponent} from "../../shared/dialog/show-all-push-notification/show-all-push-notification.component";

/**
 *
 */
@Component({
  selector: 'app-push-notification-list',
  templateUrl: './push-notification-list.component.html',
  styleUrls: ['./push-notification-list.component.scss']
})
export class PushNotificationListComponent implements OnInit {
  /**
   *
   */
  public allNotificationList: any;

  /**
   *
   */
  public userInfo : any = null;

  /**
   *
   */
  public unitInfo: any = null;

  /**
   *
   *
   * @type {*}
   * @memberof PushNotificationListComponent
   */
  public roleInfo: any = null;

  /**
   *
   * @param dialog
   * @param pushNotificationService
   * @param allPushNotificationsService
   * @param authService
   */
  constructor(
    private dialog: MatDialog,
    private pushNotificationService: PushNotificationService,
    private allPushNotificationsService: AllPushNotificationsService,
    private authService: AuthService,
    private readonly env: EnvoirnmentService,
  ) {
  }

  /**
   *
   */
  ngOnInit() {
    if(this.env.showPushNotification) {
      Promise.all([this.authService.getUser(), this.authService.getUnit()])
        .then((values) => {
          this.userInfo = values[0];
          this.unitInfo = values[1];
          this.roleInfo = this.unitInfo.userRole;
          
          this.allPushNotificationsService.getMenuPushNotification(
            this.userInfo.userId,
            this.roleInfo.roleId
          );
          this.allPushNotificationsService.allNotificationList$
            .subscribe( (res: any[]) => {
            this.allNotificationList = res;
          });
        });
    }
  }

  /**
   *
   * @param notification
   */
  public rowClicked(notification) {
    if(!notification.isRead) {
      notification.isRead = true;
      this.pushNotificationService.markPushNotificationRead(notification.id);
    }
  }

  /**
   *
   */
  public showAllPushNotification() {
    const dialogRef = this.dialog.open(ShowAllPushNotificationComponent, {
      width:  "900px",
      data: { }
    });

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(result => {
          console.log(result);
      });
  }

  /**
   *
   *
   * @memberof PushNotificationListComponent
   */
  public showMorePushNotifications() {
    this.allPushNotificationsService.getMoreNotifications(
      this.userInfo.userId,
      this.roleInfo.roleId
    );
    this.allPushNotificationsService.allNotificationList$
      .subscribe( (res: any[]) => {
      this.allNotificationList = res;
    });

  }

  /**
   *
   *
   * @memberof PushNotificationListComponent
   */
  public markAllMessagesRead() {
    this.allPushNotificationsService.markAllRead(
      this.userInfo.userId,
      this.roleInfo.roleId
    );
  }

  /**
   *
   * @param notification
   */
  public createdAtTime(notification) {
      return new Date(notification.createdAt).valueOf() ;
  }
}
