<div class="backgroud-div">
    <!-- <div  *ngIf="currentStep == 'Step 1'">
        Welcome
    </div><br> -->

    <a style="width: 30%;" *ngIf="currentStep == 'Step 1'" (click)="currentStep = 'Step 2'">
        <div class="card-label">
            Tap To Start
        </div>
    </a>

    <div *ngIf="currentStep == 'Step 2'" style="    width: 50%;">
        <div class="marginLeft10">
            <button class="mat-mini-fab btnOrange back-btn " mat-button type="submit"
                data-automation-attribute="btn-save" (click)="currentStep = 'Step 1'">
                <mat-icon matTooltip="{{ 'billing.back' | translate }}"
                    data-automation-attribute="icon-save">arrow_back_ios</mat-icon>
            </button>
            <label class="select-service-label">Please select the service</label>
        </div>
        <div>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="6" *ngFor="let service of services">
                    <a (click)="currentStep = 'Step 3'; selectedService= service" class="width95">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                {{service.desc}}
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div *ngIf="currentStep == 'Step 3'" style=" width: 50%;">
        <div class="marginLeft10" style=" display: flex; align-items: center">
            <button class="mat-mini-fab btnOrange back-btn " mat-button type="submit"
                data-automation-attribute="btn-save" (click)="currentStep = 'Step 2'">
                <mat-icon matTooltip="{{ 'billing.back' | translate }}"
                    data-automation-attribute="icon-save">arrow_back_ios</mat-icon>
            </button>

            <label class="select-service-label">If you have Patient MRN or else please scan your
                patient card. If both are not present then proceed with out documents</label>
        </div>
        <div>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="6">
                    <a class="width95" (click)="currentStep = 'mrn'">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                MRN No.
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <a class="width95" (click)="updateStep('patientCard')">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                Scan Patient Card
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="12" style="margin-top: 5px;">
                    <a class="width95" (click)="currentStep = 'withoutDoc'">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                Proceed without documents
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div *ngIf="currentStep == 'mrn'" style=" width: 50%;">
        <div class="marginLeft10">
            <button class="mat-mini-fab btnOrange back-btn " mat-button type="submit"
                data-automation-attribute="btn-save" (click)="currentStep = 'Step 3'">
                <mat-icon matTooltip="{{ 'billing.back' | translate }}"
                    data-automation-attribute="icon-save">arrow_back_ios</mat-icon>
            </button>
            <label class="select-service-label">Please Enter your MRN No.</label>
        </div>
        <div>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="12">
                    <mat-form-field class="full-width queue-label" appearance="outline">
                        <mat-label style="font-size: 24px;margin-bottom: 10px;">{{ "billing.mRN" | translate
                            }}</mat-label>
                        <input matInput placeholder="Patient MRN" style="font-size: 28px;" [(ngModel)]="patientMrn" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <a class="width95" (click)="getSearchResultPatient()">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                Generate Token
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div *ngIf="currentStep == 'withoutDoc'" style=" width: 50%;">
        <div class="marginLeft10">
            <button class="mat-mini-fab btnOrange back-btn " mat-button type="submit"
                data-automation-attribute="btn-save" (click)="currentStep = 'Step 3'">
                <mat-icon matTooltip="{{ 'billing.back' | translate }}"
                    data-automation-attribute="icon-save">arrow_back_ios</mat-icon>
            </button>
            <label class="select-service-label">Please Enter your details below</label>
        </div>
        <div>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="12">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" required style="font-size: 28px;" [(ngModel)]="fullName">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="6">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" style="font-size: 28px;" [(ngModel)]="email">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Mobile No.</mat-label>
                        <input matInput type="number" required style="font-size: 28px;" [(ngModel)]="mobileNo">
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="12" rowHeight="150px">

                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <a class="width95" (click)="generateTokenForVisitor()">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                Generate Token
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div *ngIf="currentStep == 'token'" style=" width: 50%;">
        <div>
            <label class="select-service-label">Token Details</label>
        </div>
        <div>
            <div>
                <label>{{selectedPatient?.patientName}}</label>
            </div>
            <mat-grid-list cols="12" rowHeight="150px">
                <mat-grid-tile [colspan]="12">
                    <div class="token-number">
                        {{tokenDetails?.tokenNo}}
                    </div>

                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
                <mat-grid-tile [colspan]="6">
                    <a class="width95">
                        <div class="grid-tile-card">
                            <div class="service-label">
                                Print Token
                            </div>
                        </div>
                    </a>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="3">
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>

    <div class="modal-body bodyCreatePasscode" *ngIf="currentStep == 'patientCard'"
        style=" width: 50%; align-items: center;margin-bottom: 10px;">
        <div class="marginLeft10">
            <button class="mat-mini-fab btnOrange back-btn " mat-button type="submit"
                data-automation-attribute="btn-save" (click)="updateStep('Step 3')">
                <mat-icon matTooltip="{{ 'billing.back' | translate }}"
                    data-automation-attribute="icon-save">arrow_back_ios</mat-icon>
            </button>
            <label class="select-service-label">Please Hold QR Code in front of camera</label>
        </div>
        <button mat-raised-button color="accent" (click)="onCameraStart(action)" style="width: min-content;"
            *ngIf="!action.isStart">Open Camera</button>
        <button mat-raised-button color="warn" (click)="action.stop()" style="width: min-content;"
            *ngIf="action.isStart">Stop Camera</button>
        <mat-card class="matcard-camera mat-elevation-z4" style="height: min-content;margin-top: 10px;">
            <ngx-scanner-qrcode 
                #action="scanner" 
                (event)="onBarcodeScanned(action, $event)" 
                [isBeep]="false"
                [constraints]="{audio:false,video:true}"
                [canvasStyles]="[{ lineWidth: 1, strokeStyle: 'green', fillStyle: '#55f02880' },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }]">
            </ngx-scanner-qrcode>
            <p *ngIf="action.isLoading"> ⌛ Loading... </p>
        </mat-card>
    </div>
</div>