export const APPLICATIONSERVICE = {
  ADMISSION_ENCOUNTER_GET_LIST: "admissionEncounter/getList",
  ADMISSION_ENCOUNTER_FIND_CURRENT_ADMITTED_PATIENT:
    "AdmissionEncounter/findCurrentAdmittedPatient",
  EXTENDED_PATIENT: "extendedPatient/",
  EXTENDED_PATIENT_GET: "extendedPatient/get/",
  EXTENDED_PATIENT_FILTER: "extendedPatient/filter?operation=search&",
  ADMISSION_ENCOUNTER_UPDATE_ENCOUNTER: "admissionEncounter/updateEncounter/",
  ADMISSION_ENCOUNTER_PATCH: "AdmissionEncounter/patch/",
  ADMISSION_ENCOUNTER_ADVANCED_SEARCH: "admissionEncounter/advancedSearch",
  ADMISSION_GET_DOCTORS: "employee/getAll",
  ADMISSION_GET_DOCTORSUNITID: "employee/list/",
  DEPOSIT_PAYER_SELF_ALL: "deposit/payer/SELF/all",
  ADMISSION_ENCOUNTER_GET_PATIENT_DATA: "admissionEncounter/getPatientData/",
  ADMISSION_REQ_FORM: "admissionReqForm/",
  EXTENDED_ENCOUNTER_FILTER: "extendedencounter/filter",
  ADMISSION_ENCOUNTER_SAVE: "extendedEncounter/createEncounter",
  FILE_UPLOAD: "file/uploadMultipleFiles",
  REPORT_PDPA_FORM_NEW: "export?report=PdpaFormnew",
  DEPOSIT_SELF: "/deposit/self/",
  ADMISSION_REQ_FORM_ADVANCED_SEARCH: "admissionReqForm/advancedSearch",
  ADMISSION_REQ_FORM_GET_LIST_BY_STATUS: "admissionReqForm/getListByStatus",
  ADMISSION_REQ_FORM_GET_LIST_BY_BUSINESS_STATUS:
    "admissionReqForm/getListByBusinessStatus",
  ADMISSION_REQ_FORM_UPDATE:
    "extendedAdmissionRequest/cancelAdmissionRequestStatus/",
  ADMISSION_REQ_FORM_PATCH: "admissionReqForm/patch/",
  EXTENDED_PATIENT_ADVANCE_SEARCH: "extendedPatient/advanceSearch",
  ADMISSON_REQ_FORM_SAVE: "extendedAdmissionRequest/new",
  ADMISSON_REQ_FORM_UPDATE: "extendedAdmissionRequest/update/",
  ADMISSION_ENCOUNTER_ADD_BED_COMPONENT: "admissionEncounter/addBedComponent/",
  BLOCKED_BED_LIST: "blockedBed/list",
  BLOCKED_BED_SAVE: "blockedBed/save",
  BLOCKED_BED_PATCH: "blockedBed/patch/",
  ADMISSION_ENCOUNTER_BED_DATA: "admissionEncounter/bedData",
  ADMISSION_ENCOUNTER_FIND_BY_CURRENT_BED_ID:
    "admissionEncounter/findByCurrentBedId/",
  TRANSFER_REQUEST_PATCH: "transferRequest/patch/",
  REPORT_ADT_BARCODE: "export?report=Adtbarcode",
  ADMISSION_ENCOUNTER_FIN_CLASS_UPDATE: "admissionEncounter/finClassUpdate/",
  DEPOSIT_PATIENT_BY: "deposit/patientby/",
  ADMISSION_ENCOUNTER_SEARCH: "admissionEncounter/search",
  EXTENDED_ENCOUNTER_SEARCH: "extendedEncounter/search",
  ADMISSION_ENCOUNTER_ADD_VISITORS: "AdmissionEncounter/addVisitors/",
  REPORT_ADT_WRIST_BAND: "export?report=Adtwristband",
  ADMISSION_ENCOUNTER_ADD_ABSCOND: "AdmissionEncounter/addAbscond/",
  DEPOSIT_NEW: "deposit/new",
  ADMISSION_ENCOUNTER_FIND_CURRENT_ADMITTED_FEMALE_PATIENT:
    "AdmissionEncounter/findCurrentAdmittedFemalePatient",
  EXTENDED_PATIENT_CREATE_NEW_BORN: "extendedPatient/createNewBorn",
  ADMISSION_ENCOUNTER_ADMIT_NEW_BORN: "AdmissionEncounter/admitNewBorn",
  PHARMACY_MEDICATION_ORDER_LIST: "pharmacy/medicationOrder/list",
  MENUMASTERLIST: "menus/menumasterlist",
  ROLES: "roles",
  ROLE: "roles/",
  ROLES_SEARCH: "roles/rolesearch",
  ROLES_STATUS_CHANGE: "roles/statusChange/",
  ROLES_ALL: "roles/all",
  DASHBOARD_MENU: "dashBoardMenu",
  ROLES_SAVE_DASHBOARD_WITH_ROLE: "roles/saveDashBoardWithRole/",
  QUICK_LINK_MENU: "quickLinkMenu",
  ROLES_SAVE_MENU_WITH_ROLE: "roles/saveMenuWithRole",
  USER_SEARCH: "users/search",
  USER_ALL: "users/all",
  INVENTORY_COMMON_GET_STORES_LIST: "inventory/common/getStoresList/",
  USER_STATUS_CHANGE: "users/statusChange/",
  USERS: "users/",
  USER_DELETE_ROLE: "users/deleteRole/",
  EMPLOYEE_GET_BY_TYPE: "employee/getByType",
  PROCUREMENT_CONSIGNMENT_CONVERSION: "procurement/consignmentConversion/",
  PROCUREMENT_CONSIGNMENT_BATCH_BARCODE:
    "procurement/consignmentBatch/getBatchByBarcode",
  PROCUREMENT_CONSIGNMENT_BATCH_SMART_ISSUE:
    "procurement/consignmentBatch/smartIssue",
  PROCUREMENT_CONSIGNMENT_CONVERSION_LIST:
    "procurement/consignmentConversion/list",
  PROCUREMENT_REPORT_CONSIGNMENT_CONVERSION_DETAIL:
    "procurement/reports/getConsignmentConversionDetail/",
  PROCUREMENT_CONSIGNMENT_CONVERSION_DETAILBYID:
    "Procurement/ConsignmentConversion/detailById/",
  PROCUREMENT_CONSIGNMENT_INWARD: "procurement/consignmentInward/",
  PROCUREMENT_CONSIGNMENT_INWARD_DETAILBYID:
    "/procurement/consignmentInward/detailById/",
  PROCUREMENT_CONSIGNMENT_INWARD_LIST: "procurement/consignmentInward/list",
  PROCUREMENT_CONSIGNMENT_INWARD_CANCEL: "procurement/consignmentInward/cancel",
  PROCUREMENT_CONSIGNMENT_GRN_CANCEL:
    "/procurement-service/procurement/grn/cancel",
  PROCUREMENT_CONSIGNMENT_INWARD_SUBMIT: "procurement/consignmentInward/submit",
  PROCUREMENT_REPORTS_GET_CONSIGNMENT_INWARD_DETAILS:
    "/procurement/reports/getConsignmentInwardDetail/",
  PROCUREMENT_CONSIGNMENT_INWARD_SUBMIT_BY_DETAIL:
    "procurement/consignmentInward/submitByDetail/",
  PROCUREMENT_CONSIGNMENT_OUTWARD_DETAILBYID:
    "procurement/consignmentOutward/detailById/",
  INVENTORY_COMMON_GET_UNIT_LIST: "inventory/common/getUnitList",
  SCHEDULE_APPOINTMENT: "/schedule/Appointments",
  APPOINTMENT_FILTER: "Appointment/filter?",
  PROCUREMENT_CONSIGNMENT_OUTWARD: "procurement/consignmentOutward/",
  PROCUREMENT_CONSIGNMENT_OUTWARD_LIST: "procurement/consignmentOutward/list",
  PROCUREMENT_CONSIGNMENT_OUTWARD_SUBMIT:
    "procurement/consignmentOutward/submit",
  PROCUREMENT_REPORT_GET_CONSIGNMENT_OUTWARD_DETAIL:
    "procurement/reports/getConsignmentOutwardDetail/",
  PROCUREMENT_CONSIGNMENT_OUTWARD_CANCEL:
    "procurement/consignmentOutward/cancel",
  PROCUREMENT_CONSIGNMENT_CONVERSION_CC_WITHOUT_PO_LIST:
    "procurement/consignmentConversion/ccWithoutPOList",
  PROCUREMENT_CONSIGNMENT_PO_REQUEST: "procurement/consignmentPO/request",
  PROCUREMENT_CONSIGNMENT_PO_LIST: "procurement/consignmentPO/list",
  PROCUREMENT_REPORT_CONSIGNMENT_PO_DETAILS_BY_ID:
    "procurement/reports/consignmnetPODetailById/",
  PROCUREMENT_CONSIGNMENT_PO_GET_DETAIL_BY_ID:
    "procurement/consignmentPO/getDetailById/",
  PROCUREMENT_CONTRACT_DETAIL_BY_ID: "procurement/contract/detailById/",
  PROCUREMENT_CONTRACT: "procurement/contract/",
  PROCUREMENT_REPORTS_CONTRACT_DETAILS_BY_ID:
    "Procurement/Reports/contractDetailById/",
  PROCUREMENT_CONTRACT_CANCEL: "procurement/contract/cancel",
  PROCUREMENT_CONTRACT_SUBMIT: "procurement/contract/submit",
  PROCUREMENT_CONTRACT_LIST: "procurement/contract/list",
  PROCUREMENT_CONTRACT_APPROVAL_LIST: "procurement/contractApproval/list",
  PROCUREMENT_CONTRACT_APPROVAL_SUBMIT: "/procurement/contractApproval/submit",
  PROCUREMENT_CONTRACT_APPROVAL: "procurement/contractApproval/",
  PROCUREMENT_CONTRACT_LIST_FOR_CONTRACT_DASHBOARD:
    "procurement/contract/listForContractDashboard",
  PROCUREMENT_CONTRACT_DASHBOARD_DETAILS:
    "procurement/contract/dashboardDetails/",
  PROCUREMENT_CONTRACT_APPROVAL_DISCONTINUOUS:
    "/procurement/contractApproval/discontinuous",
  PROCUREMENT_COSTING_APPROVAL_LIST: "procurement/costingApproval/list",
  PROCUREMENT_COSTING_APPROVAL_SUBMIT: "procurement/costingApproval/submit",
  PROCUREMENT_COSTING_APPROVAL_ROLLBACK: "Procurement/CostingApproval/rollback",
  PROCUREMENT_COSTING_APPROVAL_UPDATE: "procurement/costingApproval/update",
  PROCUREMENT_COSTING_APPROVAL_DETAIL_BY_ID:
    "procurement/costingApproval/detailById/",
  INVENTORY_COMMON_GET_TEMPLATE_DETAILS: "inventory/common/getTemplateDetails",
  INDENT_TEMPLATE_LIST: "indentTemplate/list",
  PROCUREMENT_LOANOUT_LIST_FOR_LOANOUT_RECEIVE:
    "/procurement/loanOut/listForLoanOutReceive",
  PROCUREMENT_LOANOUT_DETAIL_BY_ID_FOR_LOANOUT_RECEIVE:
    "/procurement/loanOut/detailByIdForLoanOutReceive/",
  INVENTORY_COMMON_GET_ITEM_LIST: "inventory/common/getItemList",
  INVENTORY_COMMON_ITEM_LIST: "inventory/common/ItemList/",
  INVENTORY_COMMON_GET_ITEM_BY_IDS: "inventory/common/getItemByIds",
  INVENTORY_COMMON_GET_ITEM_DETAILS_BY_IDS_FOR_PO:
    "inventory/common/getItemDetailsByIdsForPo",
  INVENTORY_COMMON_GET_ITEM_DETAILS_BY_IDS:
    "inventory/common/getItemDetailsByIds",
  PROCUREMENT_PURCHASE_ORDER_APPROVAL_DETAIL_BY_ID_FOR_SERVICE:
    "/procurement/purchaseOrderApproval/detailByIdForService/",
  PROCUREMENT_PURCHASE_ORDER_APPROVAL_LIST_FOR_GRN:
    "procurement/purchaseOrderApproval/listForGRN",
  INVENTORY_COMMON_GET_BATCH_LIST: "/inventory/common/getBatchList",
  PROCUREMENT_CONSIGNMENT_CONVERSION_GET_ALL_PENDING_INDENT_LIST:
    "procurement/consignmentConversion/getAllPendingIndentList",
  PROCUREMENT_CONSIGNMENT_CONVERSION_GET_INDENT_DETAIL:
    "procurement/consignmentConversion/getIndentDetail",
  PROCUREMENT_GRN_RETURN_APPROVAL_LIST_FOR_INWARD:
    "/procurement/grnReturnApproval/listForInward",
  PROCUREMENT_PURCHASE_REQUEST_APPROVAL_LIST_FOR_PO:
    "procurement/purchaseRequestApproval/listForPO",
  PROCUREMENT_PURCHASE_REQUEST_APPROVAL_DETAIL_BY_ID_FOR_PO:
    "/procurement/purchaseRequestApproval/detailByIdForPO/",
  PROCUREMENT_GRN_RETURN_APPROVAL_GET_DETAIL_BY_ID_INWARD:
    "procurement/grnReturnApproval/getDetailByIdForInward/",
  PROCUREMENT_GRN_RETURN_APPROVAL_GRN_RETURN_LIST_FOR_REMINDER_LETTER:
    "procurement/grnReturnApproval/grnReturnListForReminderLetter",
  PROCUREMENT_GRN_RETURN_APPROVAL_DETAIL_BY_ID_FOR_GRN_RETURN:
    "/procurement/grnApproval/detailByIdForGrnReturn/",
  PROCUREMENT_GRN_APPROVAL_LIST_FOR_GRN_RETURN:
    "/procurement/grnApproval/listForGrnReturn",
  PROCUREMENT_PURCHASE_ORDER_APPROVAL_DETAIL_BY_ID_FOR_GRN:
    "/procurement/purchaseOrderApproval/detailByIdForGrn/",
  PROCUREMENT_PURCHASE_ORDER_APPROVAL_POLIST_FOR_REMINDER_LETTER:
    "/procurement/purchaseOrderApproval/polistForReminderLetter",
  PROCUREMENT_QUOTATION_LIST_FOR_PO: "procurement/quotation/listForPO",
  PROCUREMENT_QUOTATION_DETAIL_BY_ID_FOR_PO:
    "/procurement/quotation/detailByIdForPO/",
  INVENTORY_COMMON_GET_REASON_LIST: "/Inventory/Common/getReasonList/",
  PROCUREMENT_CONSIGNMENT_BATCH_ITEM_LIST:
    "procurement/consignmentBatch/itemList",
  INVENTORY_STORE_INDENT_APPROVAL_DETAIL_BY_ID_FOR_PR:
    "/inventory/storeIndent/approval/detailByIdForPR/",
  INVENTORY_STORE_INDENT_APPROVAL_GET_STORE_INDENT_APPROVAL_LIST_PO:
    "Inventory/StoreIndent/Approval/getStoreIndentApprovalListPO/",
  PROCUREMENT_MATERIAL_INWARD_UPDATE_RCN:
    "procurement/materialInward/updateRcn",
  PROCUREMENT_LOANIN_DETAIL_BY_ID_FOR_LOANIN_RETURN:
    "procurement/loanIn/detailByIdForLoanInReturn/",
  PROCUREMENT_LOANIN_LIST_FOR_LOANIN_RETURN:
    "Procurement/LoanIn/listForLoanInReturn",
  PROCUREMENT_GRN_UPDATE_DASHBOARD: "procurement/grn/updateDashboard",
  PROCUREMENT_PURCHASE_REQUEST_PR_DETAIL_BY_ITEM_ID:
    "procurement/purchaseRequest/prDetailByItemId/",
  PROCUREMENT_FILLIN_QUOTATION_DETAIL_BY_ID:
    "procurement/fillInQuotation/detailById/",
  PROCUREMENT_SEND_TO_SUPPLIER_QUOTATION_LIST_FOR_FILLIN:
    "Procurement/SendToSupplierQuotation/listForFillIn",
  PROCUREMENT_FILLIN_QUOTATION: "procurement/fillInQuotation/",
  PROCUREMENT_SEND_TO_SUPPLIER_QUOTATION_DETAIL_BY_ID_FOR_FILLIN:
    "Procurement/SendToSupplierQuotation/detailByIdForFillIn/",
  PROCUREMENT_FILLIN_QUOTATION_SUBMIT: "procurement/fillInQuotation/submit",
  PROCUREMENT_REPORTS_GET_FILLIN_SERVICE_REPORT_BY_ID:
    "procurement/reports/getFillInServiceReportById/",
  PROCUREMENT_REPORTS_GET_FILLIN_REPORT_BY_ID:
    "/procurement/reports/getFillInReportById/",
  PROCUREMENT_FILLIN_QUOTATION_LIST: "procurement/fillInQuotation/list",
  PROCUREMENT_GRN_RETURN_GET_GRN_SUPPLIER_BY_BATCH_ID:
    "procurement/grnReturn/getGrnSupplierByBatchId/",
  PROCUREMENT_GRN_RETURN_GET_DETAIL_BY_ID:
    "procurement/grnReturn/getDetailById/",
  PROCUREMENT_GRN_RETURN: "procurement/grnReturn/",
  PROCUREMENT_GRN_RETURN_LIST: "procurement/grnReturn/list",
  PROCUREMENT_GRN_RETURN_SUBMIT: "procurement/grnReturn/submit",
  PROCUREMENT_GRN_RETURN_CANCEL: "procurement/grnReturn/cancel",
  PROCUREMENT_REPORT_GRN_RETURN_DETAIL_BY_ID:
    "Procurement/Reports/grnReturnDetailById/",
  PROCUREMENT_GRN: "procurement/grn/",
  PROCUREMENT_CONTRACT_BONUS_QUANTITY_LIST:
    "procurement/contract/bonusQuantityList/",
  PROCUREMENT_CONTRACT_GET_CONTRACT_RATE_FOR_PO:
    "procurement/contract/getContractRateForPo",
  PROCUREMENT_PURCHASE_ORDER_GET_PO_HISTORY:
    "procurement/purchaseOrder/getPoHistory",
  PROCUREMENT_GRN_DETAIL_BY_ID: "procurement/grn/detailById/",
  PROCUREMENT_GRN_LIST: "procurement/grn/list",
  PROCUREMENT_GRN_SUBMIT: "procurement/grn/submit",
  PROCUREMENT_GRN_CANCEL: "procurement/grn/cancel",
  PROCUREMENT_REPORT_GRN_DETAIL_BY_ID: "Procurement/Reports/grnDetailById/",
  PROCUREMENT_GRN_RETURN_APPROVAL_LIST_APPROVAL_DASHBOARD:
    "procurement/grnReturnApproval/listApprovalDashboard",
  PROCUREMENT_MATERIAL_INWARD_GET_INWARD_FOR_GRN_RETURN_DASHBOARD:
    "procurement/materialInward/getInwardForGrnReturnDashboard/",
  PROCUREMENT_GRN_RETURN_APPROVAL_DISCONTINUOUS:
    "/procurement/grnReturnApproval/discontinuous",
  PROCUREMENT_GRN_RETURN_APPROVAL_LIST: "procurement/grnReturnApproval/list",
  PROCUREMENT_GRN_RETURN_APPROVAL_APPROVE:
    "procurement/grnReturnApproval/approve",
  PROCUREMENT_GRN_RETURN_APPROVAL: "procurement/grnReturnApproval/",
  PROCUREMENT_GRN_APPROVAL_LIST: "procurement/grnApproval/list",
  PROCUREMENT_GRN_APPROVAL_APPROVE: "procurement/grnApproval/approve/",
  INVENTORY_REPORTS_PRINT_BARCODE: "inventory/reports/printBarcode/",
  PROCUREMENT_GRN_APPROVAL: "procurement/grnApproval/",
  PROCUREMENT_GRN_APPROVAL_DETAIL_BY_ID: "procurement/grnApproval/detailById/",
  PROCUREMENT_ITEM_PENDING_LIST: "procurement/itemPending/list",
  PROCUREMENT_ITEM_PENDING_DETAIL_BY_ID: "procurement/itemPending/detailById/",
  PROCUREMENT_REPORTS_ITEM_REQUEST_DETAIL_BY_ID:
    "Procurement/Reports/itemRequestDetailById/",
  PROCUREMENT_ITEM_REQUEST: "/procurement/itemRequest/",
  PROCUREMENT_ITEM_REQUEST_LIST: "procurement/itemRequest/list",
  PROCUREMENT_ITEM_REQUEST_SUBMIT: "/procurement/itemRequest/submit",
  PROCUREMENT_ITEM_REQUEST_CANCEL: "/procurement/itemRequest/cancel",
  PROCUREMENT_ITEM_REQUEST_ITEM_LIST_BY_NAME:
    "procurement/itemRequest/itemListByName",
  PROCUREMENT_ITEM_REQUEST_MANUFACTURER_LIST_BY_NAME:
    "procurement/itemRequest/manufacturerListByName",
  PROCUREMENT_ITEM_REQUEST_GET_REQUESTED_ITEM_BY_NAME:
    "procurement/itemRequest/getRequestedItemByName",
  PROCUREMENT_ITEM_REQUEST_DETAIL_BY_ID: "/procurement/itemRequest/detailById/",
  PROCUREMENT_REPORT_ITEM_REQUEST_DETAIL_BY_ID:
    "Procurement/Reports/itemRequestDetailById/",
  PROCUREMENT_ITEM_REQUEST_APPROVAL_DISCONTINUOUS:
    "procurement/itemRequestApproval/discontinuous",
  PROCUREMENT_ITEM_REQUEST_APPROVAL_APPROVE:
    "/procurement/itemRequestApproval/approve",
  PROCUREMENT_ITEM_REQUEST_APPROVAL_LIST:
    "/procurement/itemRequestApproval/list",
  PROCUREMENT_ITEM_REQUEST_APPROVAL_UPDATE:
    "/procurement/itemRequestApproval/update",
  PROCUREMENT_ITEM_REQUEST_APPROVAL_DETAIL_BY_ID:
    "/procurement/itemRequestApproval/detailById/",
  PROCUREMENT_LOANIN_DETAIL_BY_ID: "procurement/loanIn/detailById/",
  PROCUREMENT_LOANIN: "procurement/loanIn/",
  PROCUREMENT_LOANIN_LIST: "Procurement/LoanIn/list",
  PROCUREMENT_LOANIN_CANCEL: "/procurement/loanIn/cancel",
  PROCUREMENT_LOANIN_SUBMIT: "/procurement/loanIn/submit",
  PROCUREMENT_REPORT_LOANIN_DETAIL_BY_ID:
    "Procurement/Reports/loanInDetailById/",
  PROCUREMENT_LOANIN_DISCONTINUE: "/procurement/loanIn/discontinue",
  PROCUREMENT_LOANIN_RETURN_DETAIL_BY_ID:
    "procurement/loanInReturn/detailById/",
  PROCUREMENT_LOANIN_RETURN: "procurement/loanInReturn/",
  PROCUREMENT_LOANIN_RETURN_LIST: "procurement/loanInReturn/list",
  PROCUREMENT_LOANIN_RETURN_CANCEL: "/procurement/loanInReturn/cancel",
  PROCUREMENT_LOANIN_RETURN_SUBMIT: "/procurement/loanInReturn/submit",
  PROCUREMENT_REPORT_LOANIN_RETURN_DETAIL_BY_ID:
    "Procurement/Reports/loanInReturnDetailById/",
  PROCUREMENT_LOANOUT_DETAIL_BY_ID: "procurement/loanOut/detailById/",
  PROCUREMENT_LOANOUT: "procurement/loanOut/",
  PROCUREMENT_LOANOUT_LIST: "procurement/loanOut/list",
  PROCUREMENT_LOANOUT_CANCEL: "/procurement/loanOut/cancel",
  PROCUREMENT_LOANOUT_SUBMIT: "/procurement/loanOut/submit",
  PROCUREMENT_REPORT_LOANOUT_DETAIL_BY_ID:
    "Procurement/Reports/loanOutDetailById/",
  PROCUREMENT_LOANOUT_DISCONTINUE: "/procurement/loanOut/discontinue",
  INVENTORY_COMMON_GET_BATCH_BY_BARCODE: "inventory/common/getBatchByBarcode",
  PROCUREMENT_LOANOUT_RECEIVE_DETAIL_BY_ID:
    "procurement/loanOutReceive/detailById/",
  PROCUREMENT_LOANOUT_RECEIVE: "procurement/loanOutReceive/",
  PROCUREMENT_LOANOUT_RECEIVE_LIST: "procurement/loanOutReceive/list",
  PROCUREMENT_LOANOUT_RECEIVE_CANCEL: "/procurement/loanOutReceive/cancel",
  PROCUREMENT_LOANOUT_RECEIVE_SUBMIT: "/procurement/loanOutReceive/submit",
  PROCUREMENT_REPORT_LOANOUT_RECEIVE_DETAIL_BY_ID:
    "Procurement/Reports/loanOutReceiveDetailById/",
  PROCUREMENT_LOANOUT_RECEIVE_DISCONTINUE:
    "/procurement/loanOutReceive/discontinue",
  PROCUREMENT_NONRETURNABLE_APPROVAL_LIST:
    "procurement/nonReturnableApproval/list",
  PROCUREMENT_NONRETURNABLE_APPROVAL_APPROVE:
    "procurement/nonReturnableApproval/approve",
  PROCUREMENT_REPORTS_GET_NONRETURNABLE_GATEPASS_REPORT:
    "/procurement/reports/getNonReturanbleGatePassReport/",
  PROCUREMENT_NONRETURNABLE_CANCEL: "procurement/nonReturnable/cancel",
  PROCUREMENT_NONRETURNABLE_APPROVAL: "procurement/nonReturnableApproval/",
  PROCUREMENT_NONRETURNABLE_LIST: "procurement/nonReturnable/list",
  PROCUREMENT_NONRETURNABLE_APPROVAL_GET_DETAIL_BY_ID:
    "procurement/nonReturnableApproval/getDetailById/",
  PROCUREMENT_NONRETURNABLE_GET_DETAIL_BY_ID:
    "procurement/nonReturnable/getDetailById/",
  PROCUREMENT_NONRETURNABLE_SUBMIT: "procurement/nonReturnable/submit",
  PROCUREMENT_NONRETURNABLE: "procurement/nonReturnable/",
  PROCUREMENT_MATERIAL_INWARD_GET_DETAIL_BY_ID:
    "procurement/materialInward/getDetailById/",
  PROCUREMENT_REPORT_MATERIAL_INWARD_DETAILs_BY_ID:
    "Procurement/Reports/materialInwardDetailsById/",
  PROCUREMENT_MATERIAL_INWARD_CANCEL: "procurement/materialInward/cancel",
  PROCUREMENT_MATERIAL_INWARD_SUBMIT: "procurement/materialInward/submit",
  PROCUREMENT_MATERIAL_INWARD_LIST: "procurement/materialInward/list",
  PROCUREMENT_MATERIAL_INWARD: "procurement/materialInward/",
  PROCUREMENT_GRN_RETURN_APPROVAL_GET_TAX_DETAILS:
    "procurement/grnReturnApproval/getTaxDetails/",
  INVENTORY_COMMON_GET_PRIORITY_LIST: "/Inventory/Common/getPriorityList",
  PROCUREMENT_QUOTATION_DETAIL_BY_ID: "procurement/quotation/detailById/",
  PROCUREMENT_QUOTATION: "procurement/quotation/",
  PROCUREMENT_QUOTATION_LIST: "procurement/quotation/list",
  PROCUREMENT_QUOTATION_SUBMIT: "procurement/quotation/submit",
  PROCUREMENT_QUOTATION_CANCEL: "procurement/quotation/cancel",
  PROCUREMENT_REPORTS_QUOTATION_SERVICE_REPORT_BY_ID:
    "/Procurement/Reports/quotationServiceReportById/",
  PROCUREMENT_REPORTS_QUOTATION_REPORT_BY_ID:
    "/Procurement/Reports/quotationReportById/",
  PROCUREMENT_PURCHASE_ORDER_APPROVAL_PO_LIST_FOR_REMINDER_LETTER:
    "/procurement/purchaseOrderApproval/polistForReminderLetter",
  PROCUREMENT_REMINDER_LETTER: "/procurement/reminderLetter/",
  PROCUREMENT_REMINDER_LETTER_GET_REMINDER_HISTORY:
    "procurement/reminderLetter/getReminderHistory/",
  PROCUREMENT_REMINDER_LETTER_LIST: "procurement/reminderLetter/list",
  PROCUREMENT_REMINDER_LETTER_SUBMIT: "procurement/reminderLetter/submit",
  PROCUREMENT_REMINDER_LETTER_CANCEL: "/procurement/reminderLetter/cancel",
  PROCUREMENT_REPORTS_REMINDER_LETTER_DETAIL_BY_ID:
    "/Procurement/Reports/reminderLetterDetailById/",
  PROCUREMENT_REMINDER_LETTER_DETAIL_BY_ID:
    "/procurement/reminderLetter/detailById",
  ROLES_SAVE_ROLE_WITH_MENU_ACCESS: "roles/saveroleWithMenuAccess",
  ROLES_UPDATE_ROLE_WITH_MENU_ACCESS: "roles/updateroleWithMenuAccess",
  ROLES_SAVE_ROLE_WITH_UNIT_ACCESS: "roles/saveroleWithUnitAccess",
  ROLES_ROLE_MASTER: "roles/roleMaster/",
  USER_USER_SEARCH: "user/usersearch",
  USER_CREATE_USER_WITH_UNIT_ACCESS: "user/createUserWithUnitAccess",
  USER_CREATE_USER_WITH_ROLE_ACCESS: "user/createUserWithRoleAccess",
  USER_USER_MASTER: "user/userMaster/",
  MASTERS_STORE_MASTER: "Masters/StoreMaster",
  MASTERS_STATION_MASTER: "Masters/StationMaster",
  MASTERS_EMPLOYEE_TYPE_MASTER: "Masters/EmployeeTypeMaster",
  MASTERS_DEPARTMENT_MASTER: "Masters/DepartmentMaster",
  MASTERS_SUB_DEPARTMENT_MASTER: "Masters/SubDepartmentMaster",
  USER_CREATE_USER_CONFIGURATION: "user/createUserConfiguration",
  SERVICE_MAPPER_GET_LIST: "service-mapper/getServiceList",
  SERVICE_MAPPER_GET_UNIT: "service-mapper/getUnit/",
  SERVICE_MAPPER_GET_ALL_BY_UNIT: "service-mapper/getAllByUnit",
  SERVICE_MAPPER_STATUS_CHANGE: "service-mapper/statusChange/",
  SERVICE_MAPPER_SAVE_UPDATE: "service-mapper/save-update",
  SERVICE_DEACTIVATE: "service-mapper/deActive/",
  SERVICE_MAPPER_SEARCH: "service-mapper/search",
  SERVICES_SEARCH: "services/search",
  SERVICE_TARIFF_SEARCH: "service-tariff/search",
  SERVICE_TARIFF_UNIT_TARIFF_RATE: "/service-tariff/deActive/",
  SERVICE_TARIFF_GET_GLOBAL_TARIFF: "service-tariff/getGlobalTariffList",
  SERVICE_TARIFF_GET_LIST_BY_UNIT: "service-tariff/getListByUnit",
  SERVICE_MAPPER_UPDATE_UNIT_SERVICE: "service-mapper/updateUnitService/",
  ORDER_SET_GET: "order-set/get?",
  ORDER_SET_SAVE: "order-set/save/",
  ORDER_SET_UPDATE: "order-set/update/",
  ORDER_SET_DEACTIVATE: "order-set/deActive/",
  TIME_OFF_FILTER: "timeOff/filter?",
  TIME_OFF_CREATE: "timeOff/create",
  TIME_OFF_STATUS: "timeOff/updateTimeOffStatus/",
  IP_BEDCHARGES_SAVE: "/ipChargeMaster/save/",
  IP_BEDCHARGES_GET: "/ipChargeMaster/get",
  IP_BEDCHARGES_STATUS: "ipChargeMaster/deActive/",
  REPORT_GENERATE_COMMON: "reports/generateCommonReport",
  REPORT_GENERATE: "reports/generateReport",
  REPORT_GENERATE_VIEW: "reports/getReport",
  REPORT_GET_COMMON_REPORT: "reports/getCommonReport",
  REPORT_GENERATE_PHARMACY: "reports/generatePharmacyReport",
  REPORT_GENERATE_PHARMACY_VIEW: "reports/getPharmacyReport",
  REPORT_BILL_GENERATE: "reports/export?report=",
  PARAMETER_LIST: "parameter/list",
  PARAMETER_UPDATE_STATUS: "parameter/updateStatus/",
  PARAMETER_SAVE: "parameter/save",
  PARAMETER_UPDATE: "parameter/update/",
  PANEL_LIST: "panel/list",
  PANEL_SAVE: "panel/save",
  PANEL_UPDATE_STATUS: "panel/updateStatus/",
  PANEL_UPDATE: "panel/update/",
  TEST_LIST: "test/list",
  TEST_SAVE: "test/save",
  TEST_UPDATE: "test/update/",
  TEST_UPDATE_STATUS: "/test/updateStatus/",
  REPORT_GENERATE_MIS_VIEW: "/report/getReport",
  REPORT_GENERATE_MIS: "report/generateReport",
  TEMPLATE_LIST: "TemplateMaster/list",
  TEMPLATE_SAVE: "TemplateMaster",
  TEMPLATE_UPDATE: "TemplateMaster/",
  TEMPLATE_UPDATE_STATUS: "TemplateMaster/updateStatus/",
  LOCATION_GET_LOCATION: "location/getLocationList/",
  LOCATION_GET_LOCATION_IS_CLINICAL: "location/getLocationList/?isClinical=",
  LOCATION_COMMON_IS_CLINICAL: "location/common/list/?isClinical=",
  LOCATION_GET_LOCATION_DETAIL: "location/getLocationDetailList/",
  LOCATION_GET_LOCATION_DETAIL_LOCATION_ID:
    "location/getLocationDetailList/?locationId=",
  LOCATION_GET_SUB_LOCATION: "location/getSubLocationList/",
  LOCATION_GET_SUB_LOCATION_LOCATION_ID:
    "location/getSubLocationList/?locationId=",
  BED_GET_BED_LIST_BY_WARD_ID: "bed/getBedListByWardId/",
  HOUSEKEEPING_CLEANING_LIST: "housekeeping/cleaning/list",
  HOUSEKEEPING_CLEANING_ACCEPT: "housekeeping/cleaning/accept",
  HOUSEKEEPING_CLEANING_REJECT: "housekeeping/cleaning/reject",
  HOUSEKEEPING_CLEANING_CANCEL: "housekeeping/cleaning/cancel",
  HOUSEKEEPING_CLEANING_SUBMIT: "housekeeping/cleaning/submit",
  HOUSEKEEPING_CLEANING_DETAIL_BY_ID: "housekeeping/cleaning/detailById/",
  HOUSEKEEPING_CLEANING_SAVE: "housekeeping/cleaning/save",
  HOUSEKEEPING_CLEANING_UPDATE: "housekeeping/cleaning/update",
  HOUSEKEEPING_CLEANING_SAVE_SUBMIT: "housekeeping/cleaning/saveSubmit",
  HOUSEKEEPING_DASHBOARD_LOCATION: "housekeeping/dashboard/location",
  HOUSEKEEPING_DASHBOARD_CLEANING_AREA: "housekeeping/dashboard/cleaningArea",
  HOUSEKEEPING_DASHBOARD_WORKLOAD_LOCATION:
    "housekeeping/dashboard/workloadLocation",
  HOUSEKEEPING_PENDING_REQUEST_LIST: "housekeeping/pendingRequest/list",
  HOUSEKEEPING_PENDING_REQUEST_UPDATE: "housekeeping/pendingRequest/update",
  HOUSEKEEPING_PENDING_REQUEST_HOLD: "housekeeping/pendingRequest/hold",
  HOUSEKEEPING_PENDING_REQUEST_REJECT: "housekeeping/pendingRequest/reject",
  HOUSEKEEPING_ASSIGNED_REQUEST_LIST: "housekeeping/assignedRequest/list",
  HOUSEKEEPING_WORKLIST_LIST: "housekeeping/worklist/list",
  HOUSEKEEPING_WORKLIST_SAVE: "housekeeping/worklist/save",
  HOUSEKEEPING_WORKLIST_UPDATE: "housekeeping/worklist/update",
  HOUSEKEEPING_WORKLIST_SUBMIT: "housekeeping/worklist/submit",
  HOUSEKEEPING_WORKLIST_DETAIL_BY_ID: "housekeeping/worklist/detailById/",
  HOUSEKEEPING_WORKLOAD_LIST: "housekeeping/workload/list",
  HOUSEKEEPING_WORKLOAD_SUBMIT: "housekeeping/workload/submit",
  HOUSEKEEPING_WORKLOAD_REASSIGN: "housekeeping/workload/reassign",
  HOUSEKEEPING_REQUEST_DETAIL_BY_ID: "housekeeping/request/detailById/",
  HOUSEKEEPING_REQUEST_LIST: "housekeeping/request/list",
  HOUSEKEEPING_REQUEST_CANCEL: "housekeeping/request/cancel",
  HOUSEKEEPING_REQUEST_SAVE: "housekeeping/request/save",
  HOUSEKEEPING_REQUEST_UPDATE: "housekeeping/request/update",
  HOUSEKEEPING_REQUEST_SUBMIT: "housekeeping/request/submit",
  HOUSEKEEPING_REQUEST_SAVE_SUBMIT: "housekeeping/request/saveSubmit",
  HOUSEKEEPING_ROSTER_DETAIL: "housekeeping/roster/getDetail/",
  HOUSEKEEPING_ROSTER_BLOCK: "housekeeping/roster/block",
  HOUSEKEEPING_ROSTER_UNBLOCK: "housekeeping/roster/unblock",
  HOUSEKEEPING_ROSTER_DEACTIVATE: "housekeeping/roster/deactivate",
  HOUSEKEEPING_ROSTER_LIST: "housekeeping/roster/list",
  HOUSEKEEPING_ROSTER_SAVE: "housekeeping/roster/save",
  HOUSEKEEPING_ROSTER_UPDATE: "housekeeping/roster/update",
  MASTERS_SPECIAL_RIGHTS_MASTER: "Masters/SpecialRightsMaster",
  SUPPLIER_LIST: "supplier/list",
  SUPPLIER_LIST_ALL: "supplier/listAll",
  SUPPLIER_UPDATE_STATUS: "supplier/updateActiveStatus/",
  SUPPLIER_UPDATE: "supplier/updateSupplier",
  SUPPLIER_ADD: "supplier/addSupplier",
  STORE_MASTER_SEARCH: "StoreMaster/search",
  STORE_MASTER_SAVE: "StoreMaster/save",
  STORE_PRODUCT: "storeProduct/",
  ITEM_RESTRICTION: "itemRestriction/",
  STORE_PRODUCT_LIST: "storeProduct/list",
  ITEM_RESTRICTION_LIST: "itemRestriction/list",
  ITEM_TEMPLATE: "itemTemplate/",
  ITEM_TEMPLATE_TEMPLATE_LIST: "itemTemplate/templateList",
  ITEM_TEMPLATE_UPDATE_STATUS: "itemTemplate/updateStatus/",
  ITEM_TEMPLATE_TEMPLATE_TYPE_LIST: "itemTemplate/templateTypeList",
  ITEM_TEMPLATE_SAVE: "itemTemplate/save",
  ITEM_TEMPLATE_UPDATE: "itemTemplate/update",
  GET_ENCOUNTER_SUMMARY: "extendedencounter/getEncounterSummary",
  CASH_COUNTER_MASTER: "cashCounter/getCashCounterByUnit",
  GET_CASH_COUNTER_LIST: "cashCounter/getCashCounterList",
  STORE_MASTER: "store/getStoreByUnit",
  STATION_MASTER: "nursingStation/getNursingStationByUnit",
  DEPARTMENT_MASTER: "department/getDeptByUnit",
  SUB_DEPARTMENT_MASTER: "subDepartment/getSubDepartmentByUnit",
  SAVE_ROLE_WITH_NOTIFICATION_ACCESS: "roles/saveroleWithNotificationAccess",
  MASTERS_NOTIFICATION_MASTER: "Masters/NotificationMaster",
  NOTIFICATION_TYPE_MASTER: "Masters/NotificationTypeMaster",
  NOTIFICATION_SAVE_LIST: "notification/save",
  NOTIFICATION_SAVE_UPDATE_LIST: "notification/update/",
  NOTIFICATION_DESTINATION: "Masters/NotificationDestinationMaster",
  GET_NOTIFICATION: "notification/get?limit=10&offset=1",
  NOTIFICATION_DEACTIVATE: "notification/deActive/",
  NOTIFICATION_TRIGGERID_MASTER: "Masters/NotificationTriggerMaster",
  GET_VISIT_LIST_BY_PATIENT_ID: "extendedEncounter/getVisitListByPatientId/",
  ORDER_GET: "order/get",
  DASHBOARD_API:"dashboard/getDashboard",
  PATIENT_STATUS_FILTER:"/extendedPatient/getValidationByPatientId?patientId=",
  DOCUMENT_TYPE_MASTER:"Masters/DocumentTypeMaster",
  NEXT_TO_KIN_EXTENDED_ENCOUNTER:"extendedEncounter/getNextToKinDetails?patientId=",
  GET_MIS_REPORT:"menus/reportmenumasterlist",
  SCREEN_LOCK_UPDATE: "screenAuthentication/update",
  SCREEN_LOCK_GET: "screenAuthentication/get",
  //GET_MACHINE_IP: "https://jsonip.com",
  USER_UPDATE_PASSWORD: "user/updatePassword",
  USER_RESET_PASSWORD: "user/resetPassword",
  USER_CREATE_USER_NAME_BY_EMAIL: "user/createUserName?emailId=",
  USER_CHECK_DUPLICATE_USER_NAME: "user/checkDuplicateUserName?userName=",
  SERVICE_MAPPER_SAVE_SERVICE_PRICE: "service-mapper/saveServicePrice",
  DOCUMENT_TYPE_CATEGORY_PAT:"file/getByCategoryCode?categoryCode=PAT",
  DOCUMENT_TYPE_CATEGORY_VIS:"file/getByCategoryCode?categoryCode=VIS",
  GET_DOCUMENT:"file/getFilteredFiles"

};
