export const opdNavigation = {
    OPD_APPOINTMENT_NAVIGATE: "/OPD/new-appointment/patient-appointment",
    OPD_PATIENT_NAVIGATE:"/OPD/patient/patient-encounter",
    ADT_NAVIGATE:"../adt/admission-form-new",
    DayCare_NAVIGATE:"/daycare/admission-form",
    REPORT_NAVIGATE:"#/Dashboard/pdfViewer",
    PATIENT_AUDIT:"/OPD/Registration/PatientAudit"
}

export const shortcutNavigation = {
    OPD_MODULE: "OPD/new-appointment/appointment-list",
    REGISTRATION_SCREEN: "/OPD/patient/patient-encounter",
    APPOINTMENT_SCREEN: "OPD/new-appointment/patient-appointment",
    BILLING_MODULE: "/billing/bill/billing-summary",
    OP_BILL_SCREEN: "/billing/bill/opbill",
    DEBIT_NOTE_SCREEN: "/billing/bill/debit-note",
    CREDIT_NOTE_SCREEN: "/billing/bill/credit-note",
    IP_CHARGE_SCREEN: "/billing/bill/ipcharge",
    BILL_SUMMARY_SCREEN: "/billing/bill/billing-summary"
}
