export enum CurrencyCode {
    Ringgit = "MYR",
    Hongkong = "HKD",
    US = "USD"
}


export enum CurrencyRate {
  HongtoRing = "HKD_MYR:0.5343",
  UStoRing = "USD_MYR:4.191503",
  RingtoHong = "MYR_HKD:1.871599",
  RingtoUS = "MYR_USD:0.238578"
}
  