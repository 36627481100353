import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {AppBaseService} from "../../../services/http.service";

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  /**
   *
   */
  public unreadPushNotificationCount$: Subject<number> = new Subject<number>();

  /**
   *
   */
  private unreadPushNotificationCount: number = 0;

  /**
   *
   * @param appBaseService
   * @param userDataService
   */
  constructor(
    private appBaseService: AppBaseService,
  ) { }

  /**
   *
   *
   * @param {string} notificationId
   * @return {*} 
   * @memberof PushNotificationService
   */
  public markPushNotificationRead(notificationId: string) {
    this.appBaseService.setResourceURL("/notification-service/");
    return this.appBaseService.postResource(
      "notification/updateIsRead/" + notificationId + "?read=true",
      {}
    ).subscribe(res => {
      if (res.isRead) {
        this.decreaseUnreadPushNotificationCount();
      }
    });
  }

  /**
   *
   *
   * @param {*} unreadCount
   * @memberof PushNotificationService
   */
  public getUnreadPushNotificationCount(unreadCount) {
      this.unreadPushNotificationCount = unreadCount;
      this.unreadPushNotificationCount$.next(this.unreadPushNotificationCount);
  }

  /**
   *
   *
   * @memberof PushNotificationService
   */
  public decreaseUnreadPushNotificationCount() {
    if (this.unreadPushNotificationCount > 0) {
      this.unreadPushNotificationCount--;
      this.unreadPushNotificationCount$.next(this.unreadPushNotificationCount);
    }
  }

  /**
   *
   *
   * @memberof PushNotificationService
   */
  public increaseUnreadPushNotificationCount() {
      this.unreadPushNotificationCount++;
      this.unreadPushNotificationCount$.next(this.unreadPushNotificationCount);
  }
}
