import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { groupBioSampleList, LAB_TABLE_PROPS, sampleDetails, sampleDisplayListObj, sampleMasterResponseDTOList, searchGlobalPayload, searcPayloadOrders, SubDepartmentEnum } from './lab-sample-orders';
import { AppBaseService, Configuration, ConfirmDynamicDialogComponent, MICROSERVICES, PatientService, UICONFIGSERVICE, UiConfigService } from 'medcare-core-ui';
import { Subject, takeUntil } from 'rxjs';
import { priorityType, ScreenStatus } from '../enums/master-menus.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-lab-clinical-orders',
  templateUrl: './lab-clinical-orders.component.html',
  styleUrls: ['./lab-clinical-orders.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("*", style({ height: "*", visibility: "visible" })),
      transition("void <=> *", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class LabClinicalOrdersComponent implements OnInit,OnDestroy {

  _onDestroy: Subject<boolean> = new Subject<boolean>();
  dataSource: MatTableDataSource<sampleDisplayListObj>;
  searchPayload: searcPayloadOrders;
  totalPatientSample: sampleDisplayListObj[];
  allprocedures: sampleDetails[] = [];
  isDisplay:boolean=false;
  selectMRNNumbers:string[]=[];
  selectVisitNumbers:number[]=[];
  selectedProcIds: number[] = [];
  displayedColumns: string[] = LAB_TABLE_PROPS.phlebotomyColumnName;
  mrn:string="";
  visitNo:string="";
  
  constructor(
    private appBaseService: AppBaseService,
    private patientService: PatientService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cookieService: CookieService,
  ) { }
  ngOnDestroy(): void {

    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    const mrn = this.cookieService.get("MRN");
    const visitNo = this.cookieService.get("visitNo");
    this.mrn =global.atob(mrn);
    this.visitNo=global.atob(visitNo);
   
    localStorage.setItem("lan", "en");
    this.getInitialSample();
  }
  getInitialSample() {

    this.searchPayload = {
      offset: 0,
      limit: Configuration.PAGINATOR_DEFAULT,
      fromDate: 0,
      toDate: 0,
      screenStatus: "O",
      typeCode:"CTR",
      visitTypeId:0,
      visitTypeCode:"ALL",
      mrn:this.mrn?this.mrn:null,
      visitNo:this.visitNo?this.visitNo:null
    };
    if(this.mrn!="" && this.visitNo!="")
    this.getSampleList(this.searchPayload);
  else
  {
    this.totalPatientSample=[];
    this.dataSource = new MatTableDataSource(this.totalPatientSample);
    this.isDisplay=true;
  }
  }

  getSampleList(payload: searchGlobalPayload) {
    //this.clear();
    this.dataSource = new MatTableDataSource(this.totalPatientSample);
    this.appBaseService.setResourceURL(MICROSERVICES.LAB_SERVICE);
    this.appBaseService
      .postResource(UICONFIGSERVICE.GET_CURRENT_ORDERS, payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.totalElements > 0) {
          this.totalPatientSample=[];
          res.doctorOrderMasterResponseList.forEach(
            (sample: sampleMasterResponseDTOList) => {
              this.totalPatientSample.push(this.getPatientList(sample));
            }
          );
          this.dataSource = new MatTableDataSource(this.totalPatientSample);
        }
        if(this.dataSource.data.length==0)
          this.isDisplay=true;
      });
  }

  getPatientList(sample: sampleMasterResponseDTOList): sampleDisplayListObj {
    let sampleDetailsObj: sampleDisplayListObj = {
      mrn: sample.mrn,
      patientName: sample.patientName,
      identificationType:sample.identificationType,
      identificationNo: sample.identificationNo,
      gender: sample.gender,
      age: sample.age,
      location:sample.location,
      patientId:sample.patientId,
      priority: {
        routine: 0,
        urgent: 0,
        stat: 0,
      },
      listSampleDetails: sample.doctorOrderDetailsResponseList,
      checked: false,
      listSampleDetailsGroup: this.getGroupBioList(
        sample.doctorOrderDetailsResponseList
      ),
      collectedC: {
        count: 0,
        color: "#00FF00",
      },
      holdC: {
        count: 0,
        color: "#FF0000",
      },
      otherStatus: {
        count: 0,
        color: "#FFA500",
      },
      rejectedC: {
        count: 0,
        color: "#FF0000",
      },
    };

    sample.doctorOrderDetailsResponseList.forEach((element: sampleDetails) => {
      if (element.priority == priorityType.routine) {
        sampleDetailsObj.priority.routine += 1;
      }
      if (element.priority == priorityType.urgent) {
        sampleDetailsObj.priority.urgent += 1;
      }
      if (element.priority== priorityType.stat) {
        sampleDetailsObj.priority.stat += 1;
      }
      if (element.businessStatus) {
        if (element.businessStatus.desc == ScreenStatus.collected) {
          sampleDetailsObj.collectedC.count += 1;
          sampleDetailsObj.collectedC.color = element.businessStatus.colourCode;
        } else if (element.businessStatus.desc == ScreenStatus.rejected) {
          sampleDetailsObj.rejectedC.count += 1;
          sampleDetailsObj.rejectedC.color = element.businessStatus.colourCode;
        } else if (element.businessStatus.desc == ScreenStatus.hold) {
          sampleDetailsObj.holdC.count += 1;
          sampleDetailsObj.holdC.color = element.businessStatus.colourCode;
        } else {
          sampleDetailsObj.otherStatus.count += 1;
          sampleDetailsObj.otherStatus.color =
            element.businessStatus.colourCode;
        }
      }
    });
    return sampleDetailsObj;
  }

  getGroupBioList(listSampleDetails: sampleDetails[]): any[] {
    let availableGroup = [];
    let returnBioList: groupBioSampleList = {
      biochemistry: [],
      hematology: [],
      microbiology: [],
      histopathology: [],
      histology: [],
      cytology: [],
      virology: [],
      serology: [],
      otherGroup: [],
    };
    listSampleDetails.forEach((eachtest) => {
      this.allprocedures.push(eachtest);
      if (
        eachtest.subDepartmentMaster.toUpperCase() ==
        SubDepartmentEnum.biochemistry
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.biochemistry.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() ==
        SubDepartmentEnum.haematoloty
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.hematology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() ==
        SubDepartmentEnum.microbiology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.microbiology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() ==
        SubDepartmentEnum.histopathology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.histopathology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() ==
        SubDepartmentEnum.histology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.histology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() == SubDepartmentEnum.cytology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.cytology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() == SubDepartmentEnum.virology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.virology.push(eachtest);
      } else if (
        eachtest.subDepartmentMaster.toUpperCase() == SubDepartmentEnum.serology
      ) {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.serology.push(eachtest);
      } else {
        eachtest.isPselect = false;
        eachtest.departmentflag = false;
        returnBioList.otherGroup.push(eachtest);
      }
    });
    if (returnBioList.biochemistry.length > 0) {
      availableGroup.push(returnBioList.biochemistry);
    }
    if (returnBioList.hematology.length > 0) {
      availableGroup.push(returnBioList.hematology);
    }
    if (returnBioList.microbiology.length > 0) {
      availableGroup.push(returnBioList.microbiology);
    }
    if (returnBioList.histopathology.length > 0) {
      availableGroup.push(returnBioList.histopathology);
    }
    if (returnBioList.histology.length > 0) {
      availableGroup.push(returnBioList.histology);
    }
    if (returnBioList.cytology.length > 0) {
      availableGroup.push(returnBioList.cytology);
    }
    if (returnBioList.virology.length > 0) {
      availableGroup.push(returnBioList.virology);
    }
    if (returnBioList.serology.length > 0) {
      availableGroup.push(returnBioList.serology);
    }
    if (returnBioList.otherGroup.length > 0) {
      availableGroup.push(returnBioList.otherGroup);
    }
    return availableGroup;
  }

  selectdepart(
    depart: string,
    mrn: string,
    element: sampleDisplayListObj,
    status: string,
    screeenStatus:string
  ) {
    if (element.checked) {
      let list:any;
      list=this.totalPatientSample;
      list.forEach((element) => {
        if (element.mrn == mrn) {
          element.listSampleDetails.forEach((procedure) => {
            if (procedure.subDepartmentMaster == depart) {
               this.selectedProcIds.push(procedure.id);
               this.selectVisitNumbers.push(procedure.visitId);
               this.selectMRNNumbers.push(mrn);
              procedure.isPselect = true;
            }
          });
        }
      });
    } else {
      let list:any;
      list=this.totalPatientSample;
      list.forEach((element) => {
        if (element.mrn == mrn) {
          element.listSampleDetails.forEach((procedure) => {
            this.removeIdFromList(procedure.id,mrn,procedure.visitId,procedure.serviceId);
          });
        }
      });
    }
  }
  removeIdFromList(elementId: number, mrn: string, visitNo:number,serviceId:number) {
    const index = this.selectedProcIds.indexOf(elementId, 0);
    if (index > -1) {
      this.selectedProcIds.splice(index, 1);
    }
    let fIndex = this.selectMRNNumbers.findIndex(
      (s) => s == mrn
    );
    if (fIndex > -1) {
      this.selectMRNNumbers.splice(fIndex, 1);
    }
    let fIndexVisitNo = this.selectVisitNumbers.findIndex(
      (s) => s == visitNo
    );
    if (fIndexVisitNo > -1) {
      this.selectVisitNumbers.splice(fIndexVisitNo, 1);
    }
  }
  selectprocedureRequest(
    depart: string,
    dataId: number,
    mrn: string,
    element: sampleDisplayListObj,
    status: string,
    visitId:number,
    serviceId:number,
    screeenStatus:string
  ) {
    if (!element.checked) {
      this.removeIdFromList(dataId,mrn,visitId,serviceId);
      this.totalPatientSample.forEach((element) => {
        if (element.mrn == mrn) {
          element.listSampleDetails.forEach((procedure) => {
            if (procedure.subDepartmentMaster == depart) {
              procedure.departmentflag = false;
            }
          });
        }
      });
    } else {
       this.selectedProcIds.push(dataId);
      this.selectVisitNumbers.push(visitId);
      this.selectMRNNumbers.push(mrn);
    
    }
  }
 save()
 {
  const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
    width: "30%",
    data: { displayText: "printSubmit" },
  });
 
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && result.confirm && result.confirm == "yes") {
         this.saveOrder();
      }
    })

 }
  saveOrder()
  {
    let patientData:any;
    let uniqMrn =this.selectMRNNumbers.filter((value, index, self) => self.indexOf(value) === index);
    let uniqVisitNo = this.selectVisitNumbers.filter((value, index, self) => self.indexOf(value) === index);
    patientData=this.dataSource.data.filter(s=>s.mrn==uniqMrn[0]);
    let payload = [
      {
        patientId: patientData[0].patientId,
        remark:"",
        sampleDtlsRequestList: this.getRequestList(uniqVisitNo[0]),
      },
    ];
    this.appBaseService.setResourceURL(MICROSERVICES.LAB_SERVICE);
    this.appBaseService
      .postResource(UICONFIGSERVICE.UPDATE_ORDERS, payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res) {
          this.snackBar.open("Warning", "Sample Saved SuccessFully", {
            duration: 3000,
          });
          let printSampleDetailsId = res.sampleDtlsId.toString();
         this.printSampleBarCode(printSampleDetailsId);
          setTimeout(() => {
            this.printLabForm(printSampleDetailsId);
          }, 4000);
          
          this.getSampleList(this.searchPayload);
        }
      });
  }
  getRequestList(visitId) {
    let list = [];
    let result:any;
     result=this.dataSource.data;
    result.forEach((element) => {
      element.listSampleDetails.forEach(result => {
        if (result.isPselect) {
          let requestList = {
            visitId:visitId,
            doctorOrderId: Number(result.id),
            id: null,
            serviceId: Number(result.serviceId),
            doctorId: result.doctorId
              ? Number(result.doctorId)
              : 0,
            clinicareOrderId: result.clinicareOrderid,
            priorityId: result.priorityId
              ? Number(result.priorityId)
              : 0,
          };
          list.push(requestList);
        }
      });
     
    });
    return list;
  }

  printSampleBarCode(sampleDetailsIds: string) {
      
    let reqPayload = {
        transactionId: sampleDetailsIds,
        type: "pdf",
        reportName: "SAMPLEBARCODE",
        isReports: false,
        isLab:true,
        isBarcode: false,
      };
      localStorage.setItem("reportPayload", JSON.stringify(reqPayload));
      window.open("#/Dashboard/pdfViewer");
}
printLabForm(sampleDetailsIds: string) {
      
  let reqPayload = {
      transactionId: sampleDetailsIds,
      type: "pdf",
      reportName: "LABORDERFORM",
      isReports: false,
      isLab:true,
      isBarcode: false,
    };
    localStorage.setItem("reportPayload", JSON.stringify(reqPayload));
    window.open("#/Dashboard/pdfViewer");
}


}
