import { Injectable } from "@angular/core";
import { Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class UiConfigService {
  constructor(private http: HttpClient, @Inject("env") private env) {}

  getConfigResource() {
    const data =
      "./assets/configuration/" + `${this.env.client_name}` + ".config.json";
    return this.http.get(data, { responseType: "json" }).pipe(map((res: any) => res));
  }
}
