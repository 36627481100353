import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppBaseService } from '../../services/http.service';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { authProperties } from '../authentication-properties';
import { FormControl, Validators } from '@angular/forms';
import { APPLICATIONSERVICE, MICROSERVICES } from 'medcare-core-ui';

/**
 *
 *
 * @export
 * @enum {number}
 */
export enum passwordResetState {
  ENTER_EMAIL,
  ENTER_OTP,
  RESET_PASSWORD,
  RESET_PASSCODE
} 

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();

  loginType: string = "";
  pin: string = "";
  enterPasscodeText: string = "";
  confirmPasscodeText: string = "";
  resetPasswordText: string = "";
  updatePasscodeText: string = "";
  verificationCodeText: string = "";
  updatePasswordText: string = "";
  emailidText: string = "";
  enteremailIDText: string = "";
  newPasswordText: string = "";
  confirmPasswordText: string = "";
  autoGeneratedPin: string = "";
  passcodeResetPassword: string = "";

  firstTimeLogin: boolean = false;
  nPasswordBtn: boolean = false;
  cPasswordBtn: boolean = false;

  useremailValue = new FormControl('', [Validators.required, Validators.email]);
  newPassword = new FormControl('', [Validators.required]);
  confirmPassword = new FormControl('', [Validators.required]);
  newPin = new FormControl('', [Validators.required]);
  confirmPin = new FormControl('', [Validators.required]);

  otpInputArray: number[] = [1, 2, 3, 4, 5, 6];
  passcodePinInputArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8];

  resetPasswordEnum = passwordResetState;
  resetState: passwordResetState = passwordResetState.ENTER_EMAIL;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appBaseService: AppBaseService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
      this.autoGeneratedPin = "";
      this.loginType = data.loginType;

      this.enterPasscodeText = authProperties.enterpasscode;
      this.confirmPasscodeText = authProperties.confirmpasscode;
      this.resetPasswordText = authProperties.resetPassword;
      this.verificationCodeText = authProperties.verificationCode;
      this.updatePasswordText = authProperties.updatePassword;
      this.updatePasscodeText = authProperties.updatePasscode;
      this.emailidText = authProperties.emailid;
      this.enteremailIDText = authProperties.enteremailID;
      this.newPasswordText = authProperties.newPassword;
      this.confirmPasswordText = authProperties.confirmPassword;
    }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  ngOnInit(): void {
    if(this.data && this.data.firstTimeLogin) {
      this.resetState = this.resetPasswordEnum.RESET_PASSWORD;
      this.firstTimeLogin = true;
    } else if (this.data && this.data.userReset) {
      this.useremailValue.setValue(this.data.email);
      this.resetState = this.resetPasswordEnum.RESET_PASSWORD;
      this.firstTimeLogin = false;
    } else {
      this.resetState = this.resetPasswordEnum.ENTER_EMAIL;
      this.firstTimeLogin = false;
    } 
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  validateEmail() {
    if(this.useremailValue.valid) {
      this.appBaseService.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.appBaseService
        .getResource("user/byEmail/" + this.useremailValue.value.toString())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if(res) {
            this.snackBar.open("Success", res.message, { duration: 2000 });
            this.resetState = this.resetPasswordEnum.ENTER_OTP;
          }
        });  
    } else {
      this.snackBar.open("Error", "Please add a Valid Email", { duration: 2000 });
    }
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  validateOTP() {
    let pin: string = "";
    for (let i = 1; i <= 6; i++) {
      let textbox: any = document.getElementById("otpTab" + i);
      if (textbox) pin = pin + textbox.value;
    }

    if (pin && pin.length == 6) {
      this.appBaseService.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
      this.appBaseService
        .getResource(
          "user/verifyOTP?email=" + this.useremailValue.value.toString() 
          + "&otp=" + pin 
          + "&loginType=" + this.loginType.toUpperCase()
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if(res) {
            this.snackBar.open("Success", res.message, { duration: 2000 });

            if(this.loginType == authProperties.loginTypePassword) {
              this.resetState = this.resetPasswordEnum.RESET_PASSWORD;
            } else if(this.loginType == authProperties.loginTypePasscode) {
              this.autoGeneratedPin = res.userPin.toString();
              this.resetState = this.resetPasswordEnum.RESET_PASSCODE;
            } 
          } else {
            this.snackBar.open("Warning", "Incorrect Verification Code Entered", { duration: 2000 }); 
          }
        });
    }
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  validateNewPassword() {
    if(this.newPassword.value && this.confirmPassword.value) {
      if(this.newPassword.invalid || this.confirmPassword.invalid) {
        this.snackBar.open("Warning", "Invalid Password Characters Used", { duration: 2000 });
      } else if(this.newPassword.value != this.confirmPassword.value) {
        this.snackBar.open("Warning", "Confirmation Password must Match", { duration: 2000 });
        this.confirmPassword.setErrors({'incorrect': true});
      }
    }
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  validateNewPasscode() {
    if(this.newPin.value && this.confirmPin.value) {
      if(this.newPin.invalid || this.confirmPin.invalid) {
        this.snackBar.open("Warning", "Invalid Passcode Characters Used", { duration: 2000 });
      } else if(this.newPin.value != this.confirmPin.value) {
        this.snackBar.open("Warning", "Confirmation Passcode must Match", { duration: 2000 });
        this.confirmPin.setErrors({'incorrect': true});
      }
    }
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  updatePassword() {
    this.validateNewPassword();
    if(this.newPassword.valid && this.confirmPassword.valid) {
      let payload = {
        loginType: this.loginType,
        userId: this.data.userReset ? this.data.userId : null,
        username: this.firstTimeLogin ? this.data.email : this.useremailValue.value.toString(),
        password: this.firstTimeLogin ? this.data.password : null,
        newpassword: this.newPassword.value.toString()
      }

      this.updateAPI(payload);
    }
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  updatePasscode() {
    this.validateNewPasscode();
    if(this.newPin.valid && this.confirmPin.valid) {
      let payload = {
        loginType: this.loginType,
        username: this.firstTimeLogin ? this.data.email : this.useremailValue.value.toString(),
        passCode: this.newPin.value.toString(),
      }

      this.updateAPI(payload);
    }
  }

  /**
   *
   *
   * @param {*} payload
   * @memberof ResetPasswordComponent
   */
  updateAPI(payload) {
    let apiUrl = APPLICATIONSERVICE.USER_UPDATE_PASSWORD;
    if(this.data.userReset) apiUrl = APPLICATIONSERVICE.USER_RESET_PASSWORD;

    this.appBaseService.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
    this.appBaseService
      .putResource(apiUrl, payload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if(res) {
          this.snackBar.open("Success", res.message, { duration: 2000 });
          this.onNoClick();
        }
      }, (error) => {
        this.snackBar.open("Error", error.error.error_description, { duration: 3000 });
      });
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  toggleNButton() {
    if(this.nPasswordBtn) this.nPasswordBtn = false;
    else this.nPasswordBtn = true;
  }

  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  toggleCButton() {
    if(this.cPasswordBtn) this.cPasswordBtn = false;
    else this.cPasswordBtn = true;
  }
  
  /**
   *
   *
   * @param {*} id
   * @param {*} nextInputIndex
   * @memberof ResetPasswordComponent
   */
  onBackspace(id, nextInputIndex) {
    let nexInput = nextInputIndex - 1;
    let newID = id + nexInput;
    let textbox: any = document.getElementById(newID);
    if (textbox) {
      textbox.value = "";
      textbox.focus();
    }
  }

  /**
   *
   *
   * @param {*} id
   * @param {*} nextInputIndex
   * @memberof ResetPasswordComponent
   */
  onInputEntry(id, nextInputIndex) {
    let nexInput = +nextInputIndex + 1;
    let newID = id + nexInput;
    let textbox = document.getElementById(newID);
    if (textbox) textbox.focus();
  }
  
  /**
   *
   *
   * @memberof ResetPasswordComponent
   */
  onNoClick(): void {
    this.useremailValue.setValue("");
    this.newPassword.setValue("");
    this.confirmPassword.setValue("");
    this.dialogRef.close();
  }
}
