<div class="toolbar-parent-newmail">
  <div class="modal-header modalHeaderBlack">
    <h6 class="modal-title">Compose Mail</h6>
    <span aria-label="Close" class="close" (click)="buttonClickNo('no')">
      <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}"
        >clear</mat-icon
      >
    </span>
  </div>
</div>
<div class="mail-content-div customScrollBar">
  <div class="mail-id" style="display: flex; height: 50px">
    <button
      ejs-button
      id="btnTo"
      style="width: 50px; height: 35px; float: left"
    >
      To
    </button>
    <mat-form-field class="example-chip-list">
      <mat-label></mat-label>
      <mat-chip-list #chipGrid>
        <mat-chip
          *ngFor="let email of emails"
          (removed)="remove(email)"
          [editable]="true"
          (edited)="edit(email, $event)"
          [aria-description]="'press enter to edit ' + email"
        >
          {{ email }}
          <button matChipRemove [attr.aria-label]="'remove ' + email">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>

        <input
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [formControl]="emailFormControl"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div class="mail-id" style="display: flex; height: 50px">
    <button ejs-button id="btnCc" style="width: 50px; float: left">Cc</button>
    <mat-form-field class="example-chip-list">
      <mat-label></mat-label>
      <mat-chip-list #chipGridcc>
        <mat-chip
          *ngFor="let email of ccEmails"
          (removed)="removeCC(email)"
          [editable]="true"
          (edited)="editCC(email, $event)"
          [aria-description]="'press enter to edit ' + email"
        >
          {{ email }}
          <button matChipRemove [attr.aria-label]="'remove ' + email">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          [matChipInputFor]="chipGridcc"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addCC($event)"
          [formControl]="emailccFormControl"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div class="mail-subject e-input-group">
    <input
      type="text"
      [(ngModel)]="subject"
      id="txtSubject"
      style="width: 100%"
      placeholder="Enter Subject Here"
      class="e-input"
    />
  </div>
  <div class="text-editor">
    <app-text-editor-template [inputText]="message" (changedText)="updateTemplateData($event)"></app-text-editor-template>
  </div>

  <div>
    <button
      ejs-button
      id="btnSend"
      style="
        margin: 8px 0px;
        background-color: #0078d7;
        color: #fff;
        float: right;
        height: 30px;
      "
      (click)="sendMail()"
    >
      Send
    </button>
  </div>
</div>
