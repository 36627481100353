export enum MasterMenus {
  OPD = "OPD",
  Billing = "Billing",
  Lab = "Lab"
}
export enum priorityType {
  routine = "ROUTINE",
  urgent = "URGENT",
  stat = "STAT"
}
export enum ScreenStatus {
  ordered = "ORDERED",
  rejected = "REJECTED",
  collected = "COLLECTED",
  accepted = "ACCEPTED",
  cancelled = "CANCELLED",
  hold = "HOLD",
  received = "RECEIVED"
}
