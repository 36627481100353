<div *ngIf="!hideAfter">
  <!-- <div *ngIf="!showTable && name" class="selected-patient">
    <span>MRN: {{ mrnNumber }}</span> | <span>{{ name }}</span> |
    <span>{{ gender }}</span> | <span>{{ age }}</span>
  </div> -->
  <div *ngIf="!showTable && name" class="selected-patient">
    <span>
      <img
              src="{{
                photo ? photo : '../assets/images/user.png'
              }}"
              class="height30 rounded-circle"
              alt="user"
            />
    </span>  <span> {{ mrnNumber }}</span> / <span>{{ name }}</span> /
    <span>{{ gender }}</span> / <span>{{ birthOfDate }}</span> <span> ({{ ages }})</span>
  </div>
  <div *ngIf="showTable">
    <div
      class="no-patients"
      *ngIf="!firstChange && dataSource.data.length == 0"
    >
      Searching Patients
    </div>
    <div class="patient-table" [ngClass]="heightClass" (scroll)="onTableScroll($event)">
      <table
        mat-table
        #table
        class="width100"
        [dataSource]="dataSource"
        *ngIf="dataSource.data.length > 0"
      >
        <!-- Code Column -->
        <ng-container matColumnDef="selectRadio">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="headerPhoto selectRadio"
          ></th>
          <td mat-cell *matCellDef="let element" class="headerPhoto">
            <div class="center">
              <mat-radio-button (click)="patientOnSelect(element)">
              </mat-radio-button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="photo">
          <th mat-header-cell *matHeaderCellDef class="headerPhoto">
            {{ "SearchHeader.photo" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="headerPhoto searchPatientPhoto center"
          >
            <img
              src="{{
                element.image ? element.image : 'assets/images/user-black.png'
              }}"
              class="height30 rounded-circle"
              alt="user"
            />
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="MRN">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="headerRegisteredNo headerLeft"
          >
            MRN
          </th>
          <td mat-cell *matCellDef="let element" class="headerRegisteredNo">
            {{ element.mrn }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef class="headerName headerLeft">
            {{ "SearchHeader.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="headerName">
            {{ element.fullName }}
          </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef class="headerUser headerLeft">
            {{ "SearchHeader.gender" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="headerUser">
            {{ element.gender }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="phoneNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="headerContact headerLeft"
          >
          {{ "SearchHeader.phone" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="headerContact">
            {{ element.mobileNumber }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef class="headerDOB headerLeft">
            {{ "SearchHeader.dob" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="headerDOB">
            {{ element.dateOfBirth  | date: "dd/MM/yyy" }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="identification">
          <th mat-header-cell *matHeaderCellDef class="headerIDType headerLeft">
            {{ "SearchHeader.idType" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="headerIDType">
            {{ element.identifierType }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="docID">
          <th mat-header-cell *matHeaderCellDef class="headerLeft">
            {{ "SearchHeader.docNo" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.idNo }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="tableHeader"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="tableRow"
        ></tr>
      </table>
    </div>
  </div>
  <div *ngIf="hasSearched && dataSource.data.length == 0" class="no-patients">
    {{ "SearchHeader.recordsNotFound" | translate }}
  </div>
</div>
