<div class="content-quick-links">
  <b>OPD</b>
  <div class="flex mt-10">
    <div *ngFor="let quickLink of quickLinkList" class="quick-link-block">
      <mat-icon>{{ quickLink.icon }}</mat-icon>
      <label>{{ quickLink.title }}</label>
    </div>
  </div>
  <div class="mt-10">
    <b>Billing</b>
    <div class="flex mt-10">
      <div *ngFor="let quickLink of quickLinkList2" class="quick-link-block">
        <mat-icon>{{ quickLink.icon }}</mat-icon>
        <label>{{ quickLink.title }}</label>
      </div>
    </div>
    <div class="flex mt-10">
      <div *ngFor="let quickLink of quickLinkList3" class="quick-link-block">
        <mat-icon>{{ quickLink.icon }}</mat-icon>
        <label>{{ quickLink.title }}</label>
      </div>
    </div>
  </div>
</div>
