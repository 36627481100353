export const mandatoryFields = {
  identificationType: false,
  identificationNo: false,
  secIdentificationType: false,
  secIdentificationNo: false,
  issuingCountry: false,
  faculty: false,
  birthTime: false,
  isNewBorn: false,
  isVip: false,
  title: false,
  fullName: false,
  gender: false,
  patientCategory: false,
  dateOfBirth: false,
  nation: false,
  martial: false,
  religion: false,
  isReal: false,
  isDeceased: false,
  prefLang: false,
  motherName: false,
  education: false,
  occupation: false,
  race: false,
  ethnic: false,
  birthPlace: false,
  bloodGroup: false,
  source: false,
  blockPatient: false,
  ctosPatient: false,
  patientNotes: false,
  addressPrimary: false,
  countryPrimary: false,
  statePrimary: false,
  cityPrimary: false,
  pincodePrimary: false,
  addressSecondary: false,
  countrySecondary: false,
  stateSecondary: false,
  citySecondary: false,
  pincodeSecondary: false,
  mobileNumber: false,
  mobileNoRelation: false,
  isHandphone: false,
  emailId: false,
  personalFax: false,
  workContact: false,
  clinic:false,
  EmploymentStatus:false,
  CountryCode:false,
  workFax:false,
  areaPrimary:false,
  areaSecondary:false
};
