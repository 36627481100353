import { PatientDTOObject } from "medcare-core-ui";
import { ExtendedAddress } from "projects/medcare-core-ui/src/app/core/patient-search/patient-search.component";

export class patientGetDataservice {
    
    patientList: any[];

/**
 *Patient Display List
 *
 * @param {any[]} patients
 * @return {*} 
 * @memberof patientGetDataservice
 */
displayList(patients: any[]) {
    this.patientList = [];
    let healthTouristList=[];
    patients.forEach(patient => {
      try {
        if (patient.id && patient.name) {
          let data = new PatientDTOObject();
          data.id = patient.id;
          data.mrn=patient.mrn;
          data.identifierType = patient.primaryIdentifier?patient.primaryIdentifier.code:""; 
          data.idNo = patient.primaryIdentifier?patient.primaryIdentifier.value:"";
          data.expiryDate=patient.primaryIdentifier?patient.primaryIdentifier.expiryDate:""
          data.secondaryIdType=patient.secondaryIdentifier?patient.secondaryIdentifier.code:"";
          data.secondaryNo=patient.secondaryIdentifier?patient.secondaryIdentifier.value:"";
          data.idIssueCountry=patient.primaryIdentifier?(patient.primaryIdentifier.issuingCountryList?patient.primaryIdentifier.issuingCountryList[0].code:""):"";
          data.faculty=patient.faculty?patient.faculty.code:"";
          data.newBorn=patient.isNewBornBaby?patient.isNewBornBaby:false;
          data.birthTime=patient.birthTime?patient.birthTime:"";
          data.vip=patient.vip?patient.vip:false;
          data.countryCode=patient.mobileNumber?patient.mobileNumber.countryCode:"";
          data.seondaryExpiryDate=patient.secondaryIdentifier?patient.secondaryIdentifier.expiryDate:"";
          data.photo=patient.photo && patient.photo[0].url ?patient.photo[0].url:patient.photo[0].aws3FileUrl;
      

           data.title =patient.title?patient.title.code:"";
           data.fullName = patient.patientName?patient.patientName:"";
           data.gender =patient.gender ? patient.gender.display:"";
           data.martialStatus=patient.maritalStatus ? patient.maritalStatus.code : "";
           data.religion=patient.religion ? patient.religion.code : "";
           data.dateOfBirth = patient.birthDate?patient.birthDate:"";
           data.real=patient.isReal?patient.isReal:false;
           data.patientCategory = patient.patientCategory ? patient.patientCategory.code : "";
           data.preferedLanguage=patient.preferedLanguage ? patient.preferedLanguage.code : "";
           data.nationality=patient.nationality?patient.nationality.code:"";
           data.motherName = patient.motherName?patient.motherName:"";
           data.education=patient.education?patient.education.code:"";
           data.occupation=patient.occupation?patient.occupation.code:"";
           data.race=patient.race?patient.race.code:"";
           data.birthlocation=patient.birthLocation?patient.birthLocation:"";
           data.ethnic=patient.ethnic?patient.ethnic.code:"";
           data.bloodGroup=patient.bloodGroup?patient.bloodGroup.code:"";
           data.source=patient.source?patient.source.code:"";
           data.deceased=patient.deceasedPatient?patient.deceasedPatient.deceased:false;
           data.blocked=patient.isBlocked?patient.isBlocked:false;
           data.ctos=patient.isCTOS?patient.isCTOS:false;
           data.patientNotes=patient.patientNotes?patient.patientNotes.note:"";

        
            data.extendedAddress=new ExtendedAddress();
            data.correspondingAddress= new ExtendedAddress();
          data.extendedAddress.addressLine=patient.extendedAddress 
          ? patient.extendedAddress.addressLine
          : "";
          data.extendedAddress.country =    patient.extendedAddress && patient.extendedAddress.country? patient.extendedAddress.country.code: "",
          data.extendedAddress.state =   patient.extendedAddress && patient.extendedAddress.state
          ? patient.extendedAddress.state.code
          : "";
          data.extendedAddress.city =   patient.extendedAddress && patient.extendedAddress.city
          ? patient.extendedAddress.city.code
          : "";
          data.extendedAddress.area = patient.extendedAddress && patient.extendedAddress.area
          ? patient.extendedAddress.area.code
          : "";
          data.extendedAddress.postCode = patient.extendedAddress
          ? patient.extendedAddress.postCode
          : "";
          data.correspondingAddress.addressLine=patient.correspondingAddress
          ? patient.correspondingAddress.addressLine
          : "";
          data.correspondingAddress.country =  patient.correspondingAddress &&
          patient.correspondingAddress.country
            ? patient.correspondingAddress.country.code
            : "";
          data.correspondingAddress.state =  patient.correspondingAddress && patient.correspondingAddress.state
          ? patient.correspondingAddress.state.code
          : "";
          data.correspondingAddress.city =   patient.correspondingAddress && patient.correspondingAddress.city
          ? patient.correspondingAddress.city.code
          : "";
          data.correspondingAddress.area =  patient.correspondingAddress && patient.correspondingAddress.area
          ? patient.correspondingAddress.area.code
          : "";
          data.correspondingAddress.postCode = patient.correspondingAddress
          ? patient.correspondingAddress.postCode
          : "";
         
          data.mobileNumber=patient.mobileNumber?patient.mobileNumber.mobileNumber:"";
          data.relation=patient.mobileNumber?(patient.mobileNumber.relationList?patient.mobileNumber.relationList[0].code:""):"";
          data.emailId =patient.emailId?patient.emailId:"";
          data.homeContactNo=patient.homeContactNo?patient.homeContactNo:"";
          data.workContactNo= patient.workContactNo?patient.workContactNo:"";
          data.personalFax= patient.personalFax?patient.personalFax:"";
          data.workFax=  patient.workFax?patient.workFax:"";
          if (patient.healthTouristDetailsDTO) {
         patient.healthTouristDetailsDTO.forEach(res => {
          let obj={
            healthTouristId:res.healthTouristId?res.healthTouristId:"",
            healthTouristCode:res.healthTouristCode?res.healthTouristCode:"",
            agentIdentificationType:res.agentIdentificationType?res.agentIdentificationType.display:"",
            agentIdentificationNo:res.agentIdentificationNo?Number(res.agentIdentificationNo):"",
            agentName:res.agentName?res.agentName:"",
            startDate:res.startDate?res.startDate:"",
            endDate:res.endDate?res.endDate:"",
            contactNo:res.contactNo?Number(res.contactNo):"",
            emailId:res.emailId?res.emailId:"",
            currentDate: res.addedOn?res.addedOn:"",
            }
           healthTouristList.push(obj);
         });
         data.healthTouristDetailsDTO=healthTouristList;
        }else{
          data.healthTouristDetailsDTO = null;
        }
        if(patient.employeeDetails){
          data.employeeDetailsDTO=patient.employeeDetails;
        }else{
          data.employeeDetailsDTO=null
        }
          this.patientList.push(data);
        }
      } catch (ex) {}
    });
    return this.patientList;
   
  }
}

