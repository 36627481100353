import { Component, EventEmitter, Output, Input } from "@angular/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  @Input() mode: string;
  @Output() toggleSidebar = new EventEmitter<void>();
  constructor(public authService: AuthService) {}
  userType: any;
  ngOnInit(): void {
    this.authService.getUserInfo().then((value: any) => {
      this.userType = value.userTypeImage? value.userTypeImage:"HIS";
    });
  }
}
