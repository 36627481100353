<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>

<div
  class="d-flex align-items-stretch min-height-100 h-100 d-none bg-cover bg-1"
  *ngIf="backgroundImage"
  [ngStyle]="{
    background: 'url(./assets/images/' + LoginBackgroundImage + ')'
  }"
  data-automation-attribute="div-hospital-backImg"
>
  <div class="col-lg-8 col-md-7 col-sm-12 pt-3">
    <!-- <img
      src="assets/images/mykare.png"
      class="logo-login medcare-logo"
      alt="Medcare Logo"
    /> -->
  </div>
  <div class="col-lg-4 col-md-5 col-sm-12 pt-3 divBakcgroundGradient">
    <img
    src="assets/images/mykare.png"
      class="logo-login"
      alt="SD Global Logo"
    />
  </div>
</div>

<div class="signForm" data-automation-attribute="div-signin-form">
    <mat-tab-group>
    <mat-tab *ngIf="isPasscode">
    <div
      class="divContainerRight"
      data-automation-attribute="div-passcode"
    >
      <div class="divSection">
        <div *ngIf="process" class="center">
          <mat-spinner
            style="display: inline-block;"
            [diameter]="15"
          ></mat-spinner>
        </div>
        <div class="mt-10">
          <ng-container *ngFor="let input of inputArray">
            <input
              autofocus
              id="tab{{ input }}"
              type="password"
              class="input-pin"
              maxlength="1"
              (keyup.backspace)="onBackspace('tab', input)"
              (input)="onInputEntry('tab', input)"
              (focus)="$event.target.select()"
              (keyup)="loginMethod()"
              #myInput
            />
          </ng-container>
        </div>
        <table class="tablePasscode" data-automation-attribute="table-passcode">
          <tr>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-one"
                (click)="numberClick(1)"
              >
                1
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-two"
                (click)="numberClick(2)"
              >
                2
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-three"
                (click)="numberClick(3)"
              >
                3
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-four"
                (click)="numberClick(4)"
              >
                4
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-five"
                (click)="numberClick(5)"
              >
                5
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-six"
                (click)="numberClick(6)"
              >
                6
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-seven"
                (click)="numberClick(7)"
              >
                7
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-eight"
                (click)="numberClick(8)"
              >
                8
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-nine"
                (click)="numberClick(9)"
              >
                9
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-clear"
                (click)="clearNumber()"
              >
                <mat-icon matTooltip="Cancel">clear</mat-icon>
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-zero"
                (click)="numberClick(0)"
              >
                0
              </button>
            </td>
            <td>
              <button
                mat-button
                class="depth"
                data-automation-attribute="button-enter"
              >
                <mat-icon>keyboard_backspace</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngIf="isQuickLogin">
      <div class="divContainerRight" data-automation-attribute="div-passcode">
        <div class="divSection">
          <div class="headingSignin"><b>Log In</b></div>
          
            <mat-grid-list cols="12" rowHeight="65px">
              <mat-grid-tile [colspan]="12">
                <input matInput placeholder="User Name" [(ngModel)]="usernameToken" type="text" class="custom-input" />
              </mat-grid-tile>
            </mat-grid-list>
             
            <mat-grid-list cols="12" rowHeight="65px" >
              <mat-grid-tile [colspan]="12">
                <input matInput placeholder="Password" class="custom-input"  [(ngModel)]="passwordToken" (keyup.enter)="loginMethodSubmit('KEYCLOAK')"  type="password"/>
              </mat-grid-tile>
            </mat-grid-list>
  
            <mat-grid-list cols="12" rowHeight="40px">
              <mat-grid-tile [colspan]="7">
                <a routerLink="" (click)="openForgotPasswordDialog()">
                  Forgot Password?
                </a>
              </mat-grid-tile>
            </mat-grid-list>

            <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
              <div class="right">
                <button (click)="loginMethodSubmit('KEYCLOAK')"  mat-flat-button data-automation-attribute="button-login">
                  login
                </button>
              </div>
            </table>
        </div>
      </div>
  </mat-tab>
  
  <mat-tab *ngIf="isActiveDirectory">
      <div class="divContainerRight" data-automation-attribute="div-passcode">
        <div class="divSection">
          <span class="headingSignin"><b>Log In</b></span>
            <mat-grid-list cols="12" rowHeight="65px">
              <mat-grid-tile [colspan]="12">
                <input matInput placeholder="User Name" class="custom-input" [(ngModel)]="usernameToken" type="text"/>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="12" rowHeight="65px">
              <mat-grid-tile [colspan]="12">
                <input matInput placeholder="Password" class="custom-input" [(ngModel)]="passwordToken" (keyup.enter)="loginMethodSubmit('AD')" type="password"/>
              </mat-grid-tile>
            </mat-grid-list>
  
            <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
              <div class="right">
                <button (click)="loginMethodSubmit('AD')" mat-flat-button data-automation-attribute="button-login">
                  login
                </button>
              </div>
            </table>
        </div>
      </div>    
  </mat-tab>
  </mat-tab-group>
  <table
  style="margin-left: 14%;"
  data-automation-attribute="table-passcode-actions"
  >
  <tr>
    <td class="btnCreatePasscode">
      <button
      mat-button
        (click)="dilogCreatePasscode()"
        data-automation-attribute="button-create-passcode"
      >
        Create
      </button>
    </td>
    <td class="blankTD">&nbsp;</td>
    <td class="btnCreatePasscode">
      <button
      mat-button
        (click)="openForgotPasswordDialog()"
        data-automation-attribute="button-forget-passcode"
      >
        Forgot
      </button>
    </td>
  </tr>

  <tr>
    <td class="btnCreatePasscode">      
      <div *ngIf="hasLoginPassword && showPasswordButton">
        <button
        mat-button
        (click)="toggleQuickLogin()"
        data-automation-attribute="button-one"
        >
          Password
        </button>
      </div>

      <div *ngIf="hasLoginPassCode && showPassCodeButton && !showPasswordButton">
        <button
        mat-button
        (click)="togglePassCodeLogin()"
        data-automation-attribute="button-one"
        >
          Passcode
        </button>
      </div>
    </td>

    <td class="blankTD">&nbsp;</td>
    <td class="btnCreatePasscode">
      <div *ngIf="hasLoginActiveDirectory && showActiveDirectoryButton">
        <button
        mat-button
        (click)="toggleActiveDirectoryLogin()"
        data-automation-attribute="button-one"
        >
          Active Directory
        </button>
      </div>

      <div *ngIf="hasLoginPassCode && showPassCodeButton && !showActiveDirectoryButton">
        <button
        mat-button
        (click)="togglePassCodeLogin()"
        data-automation-attribute="button-one"
        >
          Passcode
        </button>
      </div>
    </td>
  </tr>
</table>
</div>

<div class="footerDiv">
  <div class="col-lg-12 col-md-12 m-0">
    <span style="color: white; font-family:initial; margin-right:5%;"
      >Copyright &copy;2023 Hati International | All Rights Reserved</span
    >
  </div>
  <span style="color: white; font-family:initial;margin-right: 14%;">
   Version : {{version}}</span
>
</div>