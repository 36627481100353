import { Component, Inject, OnDestroy, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { FormControl, Validators } from "@angular/forms";
@Component({
  selector: 'app-compose-mail-dialog',
  templateUrl: './compose-mail-dialog.component.html',
  styleUrls: ['./compose-mail-dialog.component.scss']
})
export class ComposeMailDialogComponent implements OnInit, OnDestroy {
  subject: any = "";
  message: string = "";
  emails: string[] = [];
  ccEmails: string[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  sampleId: any;
  templateName:string;
  emailFormControl = new FormControl('', [Validators.email]);
  emailccFormControl = new FormControl('', [Validators.email]);

  constructor(
    public snackBar: MatSnackBar,
    private langService: MultiLanguageService,
    public dialogRef: MatDialogRef<ComposeMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    let emailData = this.data.patientData;
    this.sampleId = this.data.sampleId;
    this.getData(emailData);
    this.templateName =emailData.templateName?emailData.templateName:"";
  }

  /**
   *
   * @param emailData
   */
  getData(emailData) {
    if (emailData) {
      this.message = emailData.content;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        emailData.subject.toString(),
        "text/html"
      );
      this.subject = doc.body.textContent;
      if(emailData.email)
      this.emails.push(emailData.email);
    }
  }

  /**
   * 
   * @param element 
   * @param templateText 
   */
  updateTemplateData( templateText: string) {
   this.message= templateText;
  }
  /**
   *
   */
  sendMail() {
    if (this.emails.length > 0) {
      let reqPayload
      if(this.templateName=="BILLDETAILS"){
        reqPayload ={
          parameterList: [ {
            parameter: "id",
            value: this.sampleId,
            dataType: "Long"
            }, {
          parameter: "isReprint",
            value: false,
            dataType: "Boolean"
            }
            ],
            subject:this.subject,
            to: this.emails.length == 1 ? this.emails[0] : null,
            toAll : this.emails.length > 1 ? this.emails : null,
            ccAll: this.ccEmails,
            templateName:this.templateName,
            messageStr:this.message,
            action: "yes",
        }

      }else{
      reqPayload = {
        sampledtlsId: this.sampleId,
        toAll: this.emails.length>0 ? this.emails : null,
        ccAll: this.ccEmails.length>0 ? this.ccEmails : null,
        subject: this.subject,
        templateName: "labReport",
        messageStr: this.message,
        action: "yes",
      };
    }
      this.dialogRef.close(reqPayload);
    } else {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.warning"),
        "Please add Recipients",
        {
          duration: 5000,
        }
      );
    }
  }

  attachFile() {
    // Handle file attachment logic here
  }

  /**
   *
   * @param value
   */
  buttonClickNo(value) {
    let data = { remarks: "", reason: "", action: value };
    this.dialogRef.close(data);
  }

  /**
   *
   * @param event
   */
  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    // if (value) {
    //   this.emails.push(value);
    // }
    if (value) {
      if (this.emailFormControl.valid) {
        this.emails.push(value);
        this.emailFormControl.reset();
      }
      else{
        this.snackBar.open(
          this.langService.get("CommonAppoinment.warning"),
          "Please add Valid Email",
          {
            duration: 5000,
          }
        );
      }
    }
    event.chipInput!.clear();
  }
  /**
   *
   * @param email
   */
  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  /**
   *
   * @param email
   * @param event
   * @returns
   */
  edit(email, event: MatChipInputEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(email);
      return;
    }
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index] = value;
    }
  }

  /**
   *
   * @param event
   */
  addCC(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    // if (value) {
    //   this.ccEmails.push(value);
    // }
    if (value) {
      if (this.emailccFormControl.valid) {
        this.ccEmails.push(value);
        this.emailccFormControl.reset();
      }
      else{
        this.snackBar.open(
          this.langService.get("CommonAppoinment.warning"),
          "Please add Valid Email",
          {
            duration: 5000,
          }
        );
      }
    }
    event.chipInput!.clear();
  }
  /**
   *
   * @param email
   * @param event
   * @returns
   */
  editCC(email, event: MatChipInputEvent) {
    const value = event.value.trim();
    if (!value) {
      this.removeCC(email);
      return;
    }
    const index = this.ccEmails.indexOf(email);
    if (index >= 0) {
      this.ccEmails[index] = value;
    }
  }
  /**
   *
   * @param email
   */
  removeCC(email: string): void {
    const index = this.ccEmails.indexOf(email);

    if (index >= 0) {
      this.ccEmails.splice(index, 1);
    }
  }
}