import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {  MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-cancel-dialog",
  templateUrl: "./cancel-dialog.component.html",
  styleUrls: ["./cancel-dialog.component.scss"]
})
export class CancelDialogComponent implements OnInit {

  public remarkValue: any = "";
  public reasons = [
    { id: "1", desc: "Incorrect Amount Entry" },
    { id: "2", desc: "Incorrect Discount Entry" },
    { id: "3", desc: "Invalid Entry Data" },
    { id: "4", desc: "other" }
  ];
  public reasonId: any;
  public name: any;
  comments: String = "";
  isOther: Boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CancelDialogComponent>
  ) {}

  ngOnInit() {}

  getRemark() {
    this.isOther = (this.remarkValue == "other")? true : false;
  }

  SaveRemarks(value) {
    let data: any;
    if(this.remarkValue == "") {
      this.snackBar.open("Warning", "Kindly enter remarks to proceed", {
        duration: 3000
      });
      return;
    } else if (this.remarkValue != "other") {
      data = { confirm: value, remark: this.remarkValue };
    } else {
      data = { confirm: value, remark: this.comments };
    }
    this.dialogRef.close(data);
  }
  onNoClick() {
    let data = { confirm: "No", remark: "" };
    this.dialogRef.close(data);
  }

  buttonClick() {
    let data = { confirm: "No", remark: "" };
    this.dialogRef.close(data);
  }
}
