<div class="modal-header modalHeaderBlack">
    <h6 class="modal-title">{{ "AddInternalNotes.internalNotes" | translate }}</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()" data-automation-attribute="span-close-dialog">
      <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
    </span>
  </div>
  
  <div>
    <div style="margin: 7px;">
      <mat-grid-list cols="8" rowHeight="60px" style="margin-top: 10px;">
        <mat-grid-tile [colspan]='7' [rowspan]='1'>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ "AddInternalNotes.addNotes" | translate }}</mat-label>
            <input matInput type="text" name="notes" [(ngModel)]="notesRemark" pattern="[a-zA-Z0-9]+" [placeholder]="'AddInternalNotes.addNotes' | translate">
          </mat-form-field>
        </mat-grid-tile>
  
        <mat-grid-tile [colspan]='1' [rowspan]='1'>
          <button mat-mini-fab class="addButton-Margin" (click)="onAddRemarkClick()">
            <mat-icon matTooltip="{{ 'AddInternalNotes.addRemark' | translate }}">add</mat-icon>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
  
      <div class="divTableSearchResult  tableHeight  mat-elevation-z8">
        <table mat-table [dataSource]="remarkSource" matSort>
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>{{ "AddInternalNotes.notes" | translate }}</th>
            <td mat-cell *matCellDef="let element" matTooltip="{{element.remarks}}">{{element.note}} </td>
          </ng-container>
          <ng-container matColumnDef="addedBy">
            <th mat-header-cell *matHeaderCellDef>{{ "AddInternalNotes.addedBy" | translate }}</th>
            <td mat-cell *matCellDef="let element">{{element.addedBy }} </td>
          </ng-container>
        
          <ng-container matColumnDef="noteDate">
            <th mat-header-cell *matHeaderCellDef>{{ "AddInternalNotes.addedOn" | translate }}</th>
            <td mat-cell *matCellDef="let element">{{element.addedOn | date :'dd/MM/yyyy hh:mm a'}} </td>
          </ng-container>
          <ng-container matColumnDef="addedFrom">
            <th mat-header-cell *matHeaderCellDef>{{ "AddInternalNotes.addedFrom" | translate }}</th>
            <td mat-cell *matCellDef="let element">{{element.addedFrom}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="remarkColumns;sticky:true" class="modalHeaderGreen"></tr>
          <tr mat-row *matRowDef="let row; columns: remarkColumns;"></tr>
        </table>
      </div>
    </div>
  </div>