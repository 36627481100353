import { Component, HostListener, Input, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
})
export class CounterComponent implements OnInit {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  columnDefs: any[];
  dataSource: any;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let counter = document.getElementById(
      "counter" + this.data.id
    ) as HTMLElement;
    counter.style.height = card.offsetHeight - 32 + "px";
    counter.style.width = card.offsetWidth + "px";
    let mainDiv: any = document.getElementsByClassName("count-div");
    if (mainDiv && mainDiv.length) {
      for (let i = 0; i < mainDiv.length; i++) {
        mainDiv[i].style.width = (card.offsetWidth - 40) / 2 + "px";
      }
    }
  }
  constructor(private dataService: DataService) {}
  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            if (obj.data && obj.data.length) {
              obj.data.map((element) => {
                arr.push({
                  type: element[this.data.dashboardEntity],
                  count: element.count_data,
                });
              });
            }
            this.dataSource = arr;
          }

          let card = document.getElementById(this.data.id);
          let counter = document.getElementById("counter") as HTMLElement;
          counter.id = "counter" + this.data.id;
          counter.style.height = card.offsetHeight - 32 + "px";
          counter.style.width = card.offsetWidth + "px";
        }
      })
    );
  }

  ngAfterViewInit() {
    let card = document.getElementById(this.data.id);
    let mainDiv: any = document.getElementsByClassName("count-div");
    if (mainDiv && mainDiv.length) {
      for (let i = 0; i < mainDiv.length; i++) {
        mainDiv[i].style.width = (card.offsetWidth - 40) / 2 + "px";
      }
    }
  }
}
