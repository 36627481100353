export const CLINICARE_SEARCH_CONST  = {
    patientSearch: [
        {
      controls: [
        {
          type: "text",
          placeholder:"Patient MRN",
          column: 6,
          name: "MRN",
         
        },
        {
          type: "text",
          placeholder:"Patient Name",
          column: 6,
          name: "PatientName"
        },
       
      ],
    },
    {
        controls: [
            {
                type: "text",
                placeholder: "Identification Id",
                column: 6,
                name: "identificationNumber",
              },
              {
                type: "number",
                placeholder: "Mobile",
                column: 6,
                name: "MobileNo",
                selectType: "",
              },
         
        ],
      },
]
}
export const CLICARE_TABLE_PROPS = {
  searchDisplayColumns: [
    "selectRadio",
    "photo",
    "MRN",
    "firstName",
    "gender",
    "phoneNumber",
    "dob",
    "identification",
    "docID",
  ],
}
