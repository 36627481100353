<div class="cancel">
  <div>
    <div class="modal-header modalHeaderDefault">
      <h6 class="modal-title">{{ "adt.doYou" | translate }}</h6>
      <span aria-label="Close" class="close" (click)="onNoClick()">
        <mat-icon matTooltip="{{'AddInternalNotes.close' | translate}}">clear</mat-icon>
      </span>
    </div>
    <br />

    <div class="modal-body bodyCreatePasscode">
      <mat-grid-list cols="12" rowHeight="80px">
        <mat-grid-tile [colspan]="2">{{ 'inventory.remarks' | translate }}:</mat-grid-tile>
        <mat-grid-tile [colspan]="10">
          <mat-form-field class="full-width" appearance="outline">
            <textarea matInput [(ngModel)]="remarkValue"></textarea>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="modal-footer modalFooterRegistrationConfirm">
      <div>
        <button
          mat-flat-button
          (click)="SaveRemarks('yes')"
          class="btnYes btnGreen"
          data-automation-attribute="button-yes"
          matTooltip="{{ 'inventory.save' | translate }}"
        >
        {{ 'inventory.save' | translate }}
        </button>
        &nbsp;
        <button
          mat-flat-button
          (click)="onNoClick()"
          class="btnNo btnRed"
          data-automation-attribute="button-no"
          matTooltip="{{ 'inventory.cancel' | translate }}"
        >
        {{ 'inventory.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
