<div class="modal-header modalHeaderBlack">
  <h6 class="modal-title">{{
    "update.addsponsor" | translate
  }}</h6>
  <span aria-label="Close" class="close" (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </span>
</div>

<div class="update-sponsor-dialog">
  <form [formGroup]="formPayer" (ngSubmit)="addSponsor()" novalidate>
    <mat-grid-list cols="12" rowHeight="60px">
      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "update.payertype" | translate
          }}</mat-label>
          <mat-select
          formControlName="payerType"
          (selectionChange)="getPayerListData($event)"
        >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="payerTypeSearch"
            [placeholderLabel]="'RegistrationPage.search' | translate"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option>{{
          "RegistrationPage.none" | translate
        }}</mat-option>
            <mat-option
              *ngFor="let option of payerTypeList"
              [disabled]="option.isSelf"
              [value]="option"
              [required]="true"
            >
              {{ option.desc }} ({{ option.code }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "update.payer" | translate
          }}</mat-label>
          <mat-select
            formControlName="payer"
            ngDefaultControl
            [required]="true"
          >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="payerSearch"
              [placeholderLabel]="'RegistrationPage.search' | translate"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option>{{
            "RegistrationPage.none" | translate
          }}</mat-option>
            <mat-option *ngFor="let option of payerList" [value]="option">
              {{ option.desc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="60px">
      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "EncounterPage.tariff" | translate }}</mat-label>
          <mat-select
            formControlName="tariff"
            [required]="true"
          >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="tariffSearch"
              [placeholderLabel]="'RegistrationPage.search' | translate"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option>{{
            "RegistrationPage.none" | translate
          }}</mat-option>
            <mat-option *ngFor="let tariff of tariffList" [value]="tariff">
              {{ tariff.desc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "EncounterPage.contract" | translate }}</mat-label>
          <mat-select
          formControlName="contract"
          >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="contractCodeSearch"
              [placeholderLabel]="'RegistrationPage.search' | translate"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option>{{
            "RegistrationPage.none" | translate
          }}</mat-option>
          
            <mat-option
              *ngFor="let option of contractList"
              [value]="option"
              >{{ option.desc }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="60px">
      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "billing.associatedCompany" | translate
          }}</mat-label>
          <mat-select
            formControlName="AssociateCompany"
          >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="associatedCompanySearch"
              [placeholderLabel]="'RegistrationPage.search' | translate"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option>{{
            "RegistrationPage.none" | translate
          }}</mat-option>
            <mat-option
              *ngFor="let associateCompany of associatedCompanyList"
              [value]="associateCompany"
            >
              {{ associateCompany.desc }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "adt.glType" | translate
          }}</mat-label>
          <input
            matInput
            [disabled]="true"
            formControlName="GLType"
          />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="60px">
      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "EncounterPage.policyNumber" | translate
          }} </mat-label>
          <input
            id="adPolicyNuumber"
            matInput
            formControlName="policyNo"
            [(ngModel)]="policyNo"
            placeholder="Policy Number"
            pattern="[a-zA-Z0-9]+"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{
            "update.effectivedate" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="GLEffectiveDate"
            [min]="minGeExp"
            [required]="true"
            [matDatepicker]="picker1"
            placeholder="dd/mm/yyyy"
            attr.data-automation-attribute="dateDOB"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <div class="right mt-10">
      <button
        type="submit"
        class="btnOrange"
        mat-mini-fab
        data-automation-attribute="button-add-payment"
      >
        <mat-icon matTooltip="Add Sponsor">add</mat-icon>
      </button>
    </div>
  </form>
</div>
