<div
  class="modal-header modalHeaderGradient height45"
  data-automation-attribute="div-modal-header"
>
  <mat-grid-list cols="10" class="width100 mg-0" rowHeight="45px">
    <mat-grid-tile [colspan]="5" [rowspan]="1" > 
        <div class="left-align">
    <mat-label>
        {{orderName}}
    </mat-label>
        </div>
     </mat-grid-tile> 
   
    <mat-grid-tile [colspan]="5" [rowspan]="1" class="grid-test-1">
      <div class="master-search">
        <mat-icon matTooltip="{{ 'RegistrationPage.searchBar' | translate }}"
          >search</mat-icon
        >
        <input
          type="text"
          (keyup.enter)="searchByMRN($event)"
          [placeholder]="'RegistrationPage.searchBar' | translate"
        />
        <mat-icon
          matTooltip="{{ 'payerInfo.expand' | translate }}"
          [matMenuTriggerFor]="masterSearch"
          class="search-btn"
          >arrow_drop_down</mat-icon
        >
      </div>
      <mat-menu
        class="menu-master"
        [overlapTrigger]="false"
        #masterSearch="matMenu"
        disabled
      >
        <div>
          <form
            #searchForm="ngForm"
            class="master-search-form"
            (ngSubmit)="setSearch(searchForm.value)"
            data-automation-attribute="form-search"
          >
            <div
              (click)="$event.stopPropagation()"
              *ngFor="let search of searchList; let p_index = index"
              class="search-content-row"
            >
              <div
                *ngFor="let control of search.controls; let c_index = index"
                class="search-col search-col-{{ control.column }}"
              >
                <mat-form-field
                  *ngIf="control.type == 'text'"
                  appearance="outline"
                >
                  <mat-label>{{ control.placeholder }}</mat-label>

                  <input
                    matInput
                    placeholder="{{ control.placeholder }}"
                    [(ngModel)]="this[control.name]"
                    name="{{ control.name }}"
                    value="{{ control.value }}"
                    pattern="[a-zA-Z0-9]+"
                  />
                </mat-form-field>

                <mat-form-field
                  *ngIf="control.type == 'number'"
                  appearance="outline"
                >
                  <mat-label>{{ control.placeholder }}</mat-label>

                  <input
                    type="text"
                    matInput
                    placeholder="{{ control.placeholder }}"
                    [(ngModel)]="this[control.name]"
                    name="{{ control.name }}"
                    value="{{ control.value }}"
                    pattern="[a-zA-Z0-9]+"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="search-content-row">
              <div class="search-col search-col-12 right">
                <button
                  matTooltip="{{ 'RegistrationPage.searchBar' | translate }}"
                  type="submit"
                  class="btn-master-search"
                  data-automation-attribute="btn-search"
                  mat-button
                >
                  <mat-icon>search</mat-icon>
                </button>
                &nbsp;
                <button
                  matTooltip="{{ 'payerInfo.reset' | translate }}"
                  type="button"
                  class="btn-master-cancel"
                  mat-button
                  (click)="clearSearch()"
                >
                  <mat-icon>refresh</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-menu>
      <div style="margin-left: 35px; margin-top: 5px">
        <span
          aria-label="Close"
          (click)="onNoClick()"
          data-automation-attribute="span-close-dialog"
        >
          <mat-icon matTooltip="Close">clear</mat-icon>
        </span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<diV>
  <div
    class="modal-body"
    data-automation-attribute="div-modal-body"
  >
    <div class="patient-detail">
      <div class="flex">
        <div class="col-8">
          <div *ngIf="patientInfoDisplay">
            <img
              [style.width]="'30px'"
              [src]="patientImage ? patientImage : '../assets/images/user.png'"
            />
            &nbsp;&nbsp;
            <label class="patient-name-label"> {{ patientInfoDisplay }}</label>
            <img
              class="user-img"
              [src]="isVip ? '../assets/images/email-icons/vip-icon.png' : null"
              *ngIf="isVip"
              style="height: 25px; object-position: left; margin-left: 10px"
            />
          </div>
          <div *ngIf="!patientInfoDisplay">
            <label class="patient-name-label">{{
              "RegistrationPage.noPatient" | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-patient-search
    class="mb-10"
    [search]="searchCriteria"
    [list]="patientList"
    [refreshTime]="refreshTime"
    [hideAfter]="hidePatientSearch"
    (onActionClick)="patientOnSelect($event)"
  ></app-patient-search>

  <div class="example-container patientListOnPopup" *ngIf="resultFound">
    <table
      mat-table
      #tabl
      class="width100"
      [dataSource]="dataSource"
      *ngIf="dataSource.data.length > 0"
    >
      <!-- Code Column -->
      <ng-container matColumnDef="selectRadio">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="headerPhoto selectRadio"
        ></th>
        <td mat-cell *matCellDef="let element" class="headerPhoto">
          <div class="center">
            <mat-radio-button (click)="patientOnSelect(element)">
            </mat-radio-button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="photo">
        <th mat-header-cell *matHeaderCellDef class="headerPhoto">
          {{ "SearchHeader.photo" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="headerPhoto searchPatientPhoto center"
        >
          <img
            src="{{
              element.image ? element.image : 'assets/images/user-black.png'
            }}"
            class="height30 rounded-circle"
            alt="user"
          />
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="MRN">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="headerRegisteredNo headerLeft"
        >
          {{ "billing.mRN" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerRegisteredNo">
          {{ element.mrn }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef class="headerName headerLeft">
          {{ "SearchHeader.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerName">
          {{ element.patientName }}
        </td>
      </ng-container>

      <!-- Code Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef class="headerUser headerLeft">
          {{ "SearchHeader.gender" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerUser">
          {{ element.gender }}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef class="headerContact headerLeft">
          {{ "SearchHeader.phone" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerContact">
          {{ element.mobileNo }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef class="headerDOB headerLeft">
          {{ "SearchHeader.dob" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerDOB">
          {{ element.dob == 0 ? "" : (element.dob | date) }}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="identification">
        <th mat-header-cell *matHeaderCellDef class="headerIDType headerLeft">
          {{ "SearchHeader.idType" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="headerIDType">
          {{ element.identification ? element.identification : "" }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="docID">
        <th mat-header-cell *matHeaderCellDef class="headerLeft">
          {{ "SearchHeader.docNo" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.identificationNo ? element.identificationNo : "" }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="tableHeader"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="tableRow"
      ></tr>
    </table>
    <div class="search-result" *ngIf="recordsLength == '0'">
      Record Found ({{ recordsLength }})
    </div>
  </div>
  
</diV>
<div class="flex">
    <div class="opd-form-lr">
      <div class="form-section">
        <div class="mt-10" data-automation-attribute="div-encounter-details">
          <form #searchForm="ngForm" data-automation-attribute="form-add-service">
            <div style="height: 5vh">
              <div style="width: 75%; float: left">
                <mat-grid-list cols="8" rowHeight="60px" >
                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{ "billing.visitNo" | translate }}</mat-label>
                      <mat-select placeholder="Select Visit" 
                        (selectionChange)="validateChangeEncounter($event.value)" 
                        [(ngModel)]="visitNoDetails" name="visitNo" required
                        data-automation-attribute="text-encounter-no">
                        <mat-select-trigger>{{ visitNoDetails?.visitNo }}</mat-select-trigger>
                        <mat-option>
                          <ngx-mat-select-search [formControl]="visitNoFilterCtrl" class="full-width" ngDefaultControl
                            [placeholderLabel]="'Search'">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let visit of filteredVisitNo | async" [value]="visit">
                          {{visit.visitTypeDetails.code}} | {{ visit.visitNo }} | {{ visit.visitDate | date : "dd/MM/yyyy" }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile [colspan]="2">
                    <mat-form-field class="full-width" appearance="outline">
                      <mat-label>{{ "billing.visitDate" | translate }}</mat-label>
                      <input matInput [(ngModel)]="encounterDate" name="encounterDate" placeholder="Visit Date" 
                        data-automation-attribute="text-encounter-date" disabled />
                    </mat-form-field>
                  </mat-grid-tile>
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>{{ "billing.specialityDepartment" | translate }}</mat-label>
                          <input matInput [(ngModel)]="speciality" name="speciality" placeholder="Specialty / Department" 
                            data-automation-attribute="text-encounter-date" disabled />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>{{ "RegistrationPage.doctor" | translate }}</mat-label>
                          <input matInput [(ngModel)]="doctorName" name="doctorName" placeholder="Doctor" 
                            data-automation-attribute="text-encounter-date" disabled />
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 60vh;"> 
    <iframe [src]="urlSafe" width="100%" height="100%" frameborder="0" ></iframe> 
  </div>
  
