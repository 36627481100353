<div id="donut">
  <ejs-accumulationchart
    [id]="idName"
    #pie
    style="display: block; width: 92%; height: inherit; width: inherit"
    (pointRender)="pointRender($event)"
    [legendSettings]="legendSettings"
    (load)="load($event)"
    [tooltip]="tooltip"
    [enableBorderOnMouseMove]="false"
  >
    <e-accumulation-series-collection>
      <e-accumulation-series
        name="Project"
        [dataSource]="dataSource"
        xName="type"
        yName="count"
        [border]="border"
        [startAngle]="startAngle"
        innerRadius="65%"
        [radius]="radius"
        [dataLabel]="dataLabel"
      >
      </e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
</div>
