import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvoirnmentService} from "../../../../services/envoirnment.service";
import {AuthService} from "../../../../services/auth.service";
import {SocketService} from "../../../../services/socket.service";
import {AllPushNotificationsService} from "../../service/all-push-notifications.service";
import {PushNotificationService} from "../../service/push-notification.service";
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

/**
 *
 */
@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit, OnDestroy {

  /**
   *
   */
  public allNotificationList: any[] = [];

  /**
   *
   */
  public pushNotificationLevelClass: any = {
    error: 'divNotificationRed',
    success: 'divNotificationGrey'
  };

  /**
   *
   */
  public pushNotificationDefaultLevelClass: string = 'divNotificationBlack';

  /**
   *
   */
  private userInfo: any = null;


  /**
   *
   */
  private unitInfo: any = null;


  /**
   *
   */
  private roleInfo: any = null;

  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  notificationResponse: notificationResponse;

  /**
   *
   * @param socketService
   * @param pushNotificationService
   * @param authService
   * @param allPushNotificationsService
   * @param env
   */
  constructor(
    private readonly socketService: SocketService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly authService: AuthService,
    private readonly allPushNotificationsService: AllPushNotificationsService,
    private readonly env: EnvoirnmentService
  ) {
  }

  /**
   *
   */
  ngOnInit() {
    if(this.env.showPushNotification) {
      Promise.all([this.authService.getUser(), this.authService.getUnit()])
        .then((values) => {
          this.userInfo = values[0];
          this.unitInfo = values[1];
          this.roleInfo = this.unitInfo?.userRole;

          if(this.userInfo) this.connect();
          else this.disconnect();
        });
    }
  }

  /**
   *
   *
   * @memberof PushNotificationComponent
   */
  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  /**
   *
   *
   * @memberof PushNotificationComponent
   */
  connect(roleId?: string) {
    this.socketService
      ._connect(roleId ? roleId : this.roleInfo.roleId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        let pushNotification = JSON.parse(res.body);
        if (this.userInfo === null) {
          this.pushNotificationResponse(pushNotification);
        } else {
          this.pushNotificationResponse(pushNotification);
        }
      });
  }

  /**
   *
   *
   * @memberof PushNotificationComponent
   */
  disconnect() {
    this.socketService._disconnect();
  }

  /**
   *
   *
   * @param {notificationResponse} pushNotification
   * @memberof PushNotificationComponent
   */
  pushNotificationResponse(pushNotification: notificationResponse) {
    this.notificationResponse = {
      id: pushNotification.id,
      notificationMessage: pushNotification.notificationMessage,
      isButton: pushNotification.isButton,
      buttonLabel: pushNotification.buttonLabel,
      buttonUrl: pushNotification.buttonUrl,
      createdDate: pushNotification.createdDate,
      isRead: pushNotification.isRead,
    }

    //this.showPushNotification(this.notificationResponse);
    this.allNotificationList.push(this.notificationResponse);
    this.allPushNotificationsService.increasePushNotification(this.notificationResponse);
    this.pushNotificationService.increaseUnreadPushNotificationCount();
  }

  /**
   *
   * @param notification
   */
  public getStatusClass(notification) {
    let statusClass = this.pushNotificationLevelClass[notification.body.level];
    return statusClass === undefined ? this.pushNotificationDefaultLevelClass : statusClass;
  }

  /**
   *
   * @param pushNotification
   */
  public showPushNotification(pushNotification) {
    if (pushNotification.notificationType === 'showNotification') {
      this.showSinglepushNotification(pushNotification)
    } else if (pushNotification.notificationType === 'departmentPushNotification') {
      if (pushNotification.userId !== this.userInfo.userId) {
        this.allPushNotificationsService
          .decreasePushNotification(pushNotification.notificationId);
        this.allNotificationList = this.allNotificationList.filter(res => {
          return res.notificationId !== pushNotification.notificationId
        });
      }
    } else if (pushNotification.notificationType === 'showNotificationList') {
      pushNotification.notifications.forEach(res => {
        this.showSinglepushNotification(res);
      });
    }
  }

  /**
   *
   * @param $event
   */
  public closeNotification($event) {
    this.allNotificationList = this.allNotificationList.filter(res => {
      return res.notificationId != $event.notificationId;
    });
  }
  
  /**
   *
   * @param pushNotification
   */
  public showSinglepushNotification(pushNotification) {
    if(pushNotification.receiver.organizationCode === this.userInfo.orgCode &&
      pushNotification.receiver.unitCode === this.unitInfo.unitCode ) {
      if (pushNotification.sendTo === 'userBased' &&
        pushNotification.receiver.userId === this.userInfo.userId) {
        this.allNotificationList.push(pushNotification);
        this.allPushNotificationsService.increasePushNotification(pushNotification);
        this.pushNotificationService.increaseUnreadPushNotificationCount();
      } else if (pushNotification.sendTo === 'departmentBased' &&
        this.userInfo.userStationMap.DEPARTMENT !== undefined
      ) {
        this.userInfo.userStationMap.DEPARTMENT.forEach(res => {
          if (pushNotification.receiver.departmentCode === res.stationCode ) {
            this.allNotificationList.push(pushNotification);
            this.allPushNotificationsService.increasePushNotification(pushNotification);
            this.pushNotificationService.increaseUnreadPushNotificationCount();
          }
        });
      } else if (pushNotification.sendTo === 'roleBased' &&
        this.userInfo.userUnitRoleList !== undefined) {
        this.userInfo.userUnitRoleList.forEach(res => {
          if (pushNotification.receiver.roleId === res.role.id && res.unitCode === pushNotification.receiver.unitCode) {
            this.allNotificationList.push(pushNotification);
            this.allPushNotificationsService.increasePushNotification(pushNotification);
            this.pushNotificationService.increaseUnreadPushNotificationCount();
          }
        });
      } else if (pushNotification.sendTo === 'mapRoleBase' && this.userInfo.roleNotificationMappers !== undefined) {
        this.userInfo.roleNotificationMappers.forEach(res => {
          if (pushNotification.notificationTypeCode === res.notificationCode &&
            pushNotification.receiver.unitCode === res.unitCode
          ) {
            this.allNotificationList.push(pushNotification);
            this.allPushNotificationsService.increasePushNotification(pushNotification);
            this.pushNotificationService.increaseUnreadPushNotificationCount();
          }
        });
      }
    }
  }
}

/**
 *
 *
 * @export
 * @interface notificationResponse
 */
export interface notificationResponse {
  id: number,
  notificationMessage: string,
  isButton?: boolean,
  buttonLabel?: string,
  buttonUrl?: string,
  createdDate: string,
  isRead: boolean,
}
