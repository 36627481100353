import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ChartTheme, ILoadedEventArgs } from "@syncfusion/ej2-angular-charts";
import { Browser, getElement } from "@syncfusion/ej2-base";
import { Subject, Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { max } from "lodash";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class BarChartComponent implements OnInit, OnDestroy {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  @Input() isMaximize;
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    labelIntersectAction: Browser.isDevice ? "None" : "Rotate45",
    labelRotation: Browser.isDevice ? -45 : 0,
    edgeLabelPlacement: "Shift",
    valueType: "Category",
    interval: 1,
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: "Patient Count",
    maximum: 100,
    interval: 20,
    majorTickLines: { width: 0 },
    lineStyle: { width: 0 },
  };
  public marker: Object = {
    dataLabel: {
      visible: false,
      position: "Top",
      font: { fontWeight: "600", color: "#ffffff" },
    },
  };
  // public title: string = "Visit Types";
  public tooltip: Object = {
    enable: true,
    header: "<b>${point.tooltip}</b>",
    shared: true,
  };
  public legend: Object = {
    visible: true,
    enableHighlight: true,
  };
  dataSource: any[];
  idName: any = "123";
  tooltipFormat: string;
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
        /-dark/i,
        "Dark"
      )
    );
    if (selectedTheme === "highcontrast") {
      args.chart.series[0].marker.dataLabel.font.color = "#000000";
      args.chart.series[1].marker.dataLabel.font.color = "#000000";
      args.chart.series[2].marker.dataLabel.font.color = "#000000";
    }
  }
  // custom code end
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public width: string = Browser.isDevice ? "100%" : "75%";
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let barChart = document.getElementById(
      "barChart" + this.data.id
    ) as HTMLElement;
    barChart.style.height = card.offsetHeight - 28 + "px";
    barChart.style.width = card.offsetWidth + "px";
  }
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            this.idName = this.data.id;
            if (obj.data && obj.data.length) {
              obj.data.map((element) => {
                arr.push({
                  type: element[this.data.dashboardEntity],
                  count: element.count_data,
                  tooltip:
                    element[this.data.dashboardEntity] +
                    " : " +
                    element.count_data,
                });
              });
            }
            this.dataSource = arr;
            this.tooltip = { enable: true, format: "${point.tooltip}" };
            this.tooltipFormat = "${point.tooltip}";
            this.primaryYAxis["title"] = this.data.yCoordinate;
          }
        }
      })
    );
    if (this.dataSource.length) {
      let total = 0;
      this.dataSource.map((data) => {
        total += data.count;
      });
      if (total < 10) {
        this.primaryYAxis["maximum"] = total;
        this.primaryYAxis["interval"] = Math.round(total / 10);
      } else {
        let maxValue =
          Math.round(max(this.dataSource, "count").count / 10) * 10;
        this.primaryYAxis["maximum"] = maxValue;
        this.primaryYAxis["interval"] = Math.round(maxValue / 10);
      }
    }
    let card = document.getElementById(this.data.id);
    let barChart = document.getElementById("barChart") as HTMLElement;
    barChart.id = "barChart" + this.data.id;
    barChart.style.height = card.offsetHeight - 28 + "px";
    barChart.style.width = card.offsetWidth + "px";
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
