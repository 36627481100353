<div>
  <mat-form-field class="full-width" appearance="outline" [ngClass]="[!isValid? 'mat-form-field-invalid' : '']">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="selectedItem" (selectionChange)="validateField()"
      [placeholder]="'SearchHeader.placeholder' | translate" [required]="isRequired" [disabled]="isDisabled">
      <mat-option>
        <ngx-mat-select-search [formControl]="filterCtrl" class="full-width"
          ngDefaultControl [placeholderLabel]="'Search'"></ngx-mat-select-search>
      </mat-option>
      <mat-option>None</mat-option>
      <mat-option *ngFor="let option of filteredList | async" [value]="option">
        {{ option.desc }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>