import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./../../services/auth.service";
import localForage from "localforage";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { AppBaseService } from "../../services/http.service";
import { MICROSERVICES } from "../../constants/web-services";
import {
  UserLoginModel,
  UserUnitList,
} from "../../authentication/signin/signin.model";
import { APPLICATIONSERVICE } from "../../constants/application-service";
import { ConfirmDynamicDialogComponent } from "../../core/confirm-dynamic-dialog/confirm-dynamic-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { MultiLanguageService } from "../../core/multi-language/multi-language.service";
import { ScreenLockEnum } from "../../constants/configuration-modules.constants";
import { SearchService } from "../../services/search.service";
//import { atob } from "buffer";

@Injectable()
export class AuthGuardGuard implements CanActivate {
  menuId: any;
  passcode: any;
  isOpen: boolean = false;
  currentUrl: string = '';
  constructor(
    private router: Router,
    public authService: AuthService,
    private baseservice: AppBaseService,
    private cookieService: CookieService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private langService: MultiLanguageService,
    private searchService: SearchService

  ) {}

  /**
   * To validate user access rights to a page
   * @param route
   * @param state
   * @returns promise
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((res) => {
      const previousUrl = this.router.url 
      let st = previousUrl.lastIndexOf("/");
      let previousPath = previousUrl.slice(st + 1);
      if (
        !localStorage.getItem("isLoggedin") &&
        localStorage.getItem("isLoggedin") != "true" &&
        this.cookieService.get("IsLoggedin") != "true"
      ) {
        this.router.navigate(["/"]);
        res(false);
      } else {
        if(this.currentUrl !== state.url){
          this.isOpen = false;
        }
        if (this.cookieService.get("isClinical")) {
          localForage.getItem("unit").then((data: any) => {
            if (data && data.userRole) {
             if(previousPath != ScreenLockEnum.addGrn){
                this.searchService.setSearch(this.searchService.search);
              }
              let st = state.url.lastIndexOf("/");
              let finalRoute = state.url.slice(st + 1);
              let paramSt = finalRoute.indexOf("?");
              if (paramSt >= 0) {
                finalRoute = finalRoute.slice(0, paramSt);
              }
              let isAvailable = data.userRole.userMenuList.filter((item) => {
                return item.location == finalRoute;
              });
              if (isAvailable.length > 0) {
                if(previousPath == ScreenLockEnum.opbill || previousPath == ScreenLockEnum.ipbill){
                  if(!this.isOpen){
                    if(this.getScreenLock(state.url, ScreenLockEnum.billscreenTypeCode)){
                      res(true);
                    }
                  } else{
                    res(true);
                  }
                }
                else if(previousPath == ScreenLockEnum.addGrn){
                  if(!this.isOpen){
                    if(this.getGrnLock(state.url, ScreenLockEnum.grnScreenTypeCode)){
                      res(true);
                    }
                  } else{
                    res(true);
                  }
                }
                else if(previousPath == ScreenLockEnum.medicationOrder){
                  if(!this.isOpen){
                    if(this.getMedicationOrderLock(state.url, ScreenLockEnum.medicationOrderScreenTypeCode)){
                      res(true);
                    }
                  } else{
                    res(true);
                  }
                }
                 else{
                  res(true);
                }
              }
              else {
                this.router.navigate(["Dashboard/dash"]);
                res(false);
              }
            }
          });
        } else {
          if (!this.cookieService.get("isClinical")) {
         
            const authorization = this.cookieService.get("accessToken");
            const email = this.cookieService.get("USER_NAME");
            const valueEmail =global.atob(email);
            var valueauth=global.atob(authorization);
            if (authorization) {
              let authValue = valueauth.replace("Bearer", "").trim();
              localStorage.setItem("authorization", valueauth);
              this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
              this.baseservice
                .loginResource("user/getUserDetailByEmailId", {
                  email: valueEmail,
                  token: authValue,
                })
                .subscribe({
                  next: (result: any) => {
                    let data: UserLoginModel = result.body;
                    if (data) {
                      localStorage.setItem("userId", btoa(data.userId));
                      localStorage.setItem("userName", btoa(data.userName));
                      localStorage.setItem("userEmail", btoa(data.userEmailId));
                      localStorage.setItem(
                        "authorization",
                        result.headers.get("Authorization")
                      );
                      let tokenValue = result.headers.get("Authorization");
                      let unit: UserUnitList = data.userUnitList.find(
                        (i) => i.isDefault == true
                      );

                      if (unit) {
                        localStorage.setItem("unitId", btoa(unit.unitId));
                        localStorage.setItem("unitCode", btoa(unit.unitCode));
                        localStorage.setItem("orgCode", btoa(unit.orgCode));
                        localStorage.setItem("orgId", btoa(unit.orgId));
                        this.getRoleInfo(data, unit, tokenValue);
                      } else {
                      }
                      if (data && unit.userRole) {
                        let st = state.url.lastIndexOf("/");
                        let finalRoute = state.url.slice(st + 1);
                        let paramSt = finalRoute.indexOf("?");
                        if (paramSt >= 0) {
                          finalRoute = finalRoute.slice(0, paramSt);
                        }
                        let isAvailable = unit.userRole.userMenuList.filter(
                          (item) => {
                            return item.location == finalRoute;
                          }
                        );
                        if (isAvailable.length > 0) {
                          res(true);
                        } else {
                          res(false);
                        }
                        this.cookieService.deleteAll();
                        this.cookieService.set("isClinical", "true", 1, "/");
                      }
                    }
                  },
                  error: () => {
                    this.router.navigate(["/"]);
                  },
                });

            } else {
              this.router.navigate(["/"]);
            }
          }
        }
      }
    });
  }
  getRoleInfo(data, unit, tokenData) {
    data["userUnitList"] = data.userUnitList;
    data["roleNotificationMappers"] = null;
    data["userUnitRole"] = null;
    data["userUnit"] = null;
    data["authorization"] = tokenData ? tokenData : "";
    data["orgCode"] = unit.orgCode;
    localForage.setItem("unit", unit);
    localForage.setItem("role", {
      id: unit.userRole.roleId,
      roleName: unit.userRole.roleDescription,
      roleCode: unit.userRole.roleCode,
    });
    localStorage.setItem("roleName", btoa(unit.userRole.roleDescription));

    // this.langService.getLanguageList();
    localForage.setItem("auth-data", data, (err) => {
      localStorage.setItem("lan", "en");
      localStorage.setItem("passcode", "true");
      localStorage.setItem("isLoggedin", "true");
    });
  }
/**
 * 
 * @param state 
 * @param screenTypeCode 
 */
  getScreenLock(state, screenTypeCode):any{
    const previousUrl = this.router.url  

      this.baseservice.setResourceURL(MICROSERVICES.BILLING_SERVICE);
      this.baseservice
      .getResource(APPLICATIONSERVICE.SCREEN_LOCK_GET + "?screenTypeCode=" + screenTypeCode)
      .subscribe((res) => {
          if(res?.isAllow == true){
            const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
              width: "30%",
              data: { displayText: "You have placed a hold on the selected patient account. Would you like to release the patient's account?" }
            });
      
              dialogRef.afterClosed().subscribe((result) => {
                this.isOpen = false;
                if (
                  result != undefined &&
                  result.confirm &&
                  result.confirm == "yes"
                ) {
                  // release patient with update api call
                  let payload = {
                    screenTypeCode: ScreenLockEnum.billscreenTypeCode,
                    statusCode: ScreenLockEnum.statusCode
                  }
                  this.baseservice.setResourceURL(MICROSERVICES.BILLING_SERVICE);
                  this.baseservice
                    .postResource(APPLICATIONSERVICE.SCREEN_LOCK_UPDATE,payload)
                    .subscribe((res) => {
                      this.snackbar.open(
                        this.langService.get("CommonAppoinment.success"),
                        this.langService.get(res.message),
                        { duration: 3000 }
                      );
                      this.router.navigateByUrl(state);
                      this.isOpen = true;
                      this.currentUrl = state;
                      return true;
                    })
                   
                } else {
                    // patient will remain as hold & it will stay on same route
                    this.router.navigateByUrl(previousUrl);
                    this.searchService.setSearch(this.searchService.search);
                    this.isOpen = false;
                    return true;
                }
              })
          }
          else{
            this.router.navigateByUrl(state);
            this.isOpen = true;
            this.currentUrl = state;
            return true;
          }
      })       
   
  }

/**
 * 
 * @param state 
 * @param screenTypeCode 
 */
  getGrnLock(state, screenTypeCode):any{
    const previousUrl = this.router.url  

    this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
    this.baseservice
    .postResource(APPLICATIONSERVICE.SCREEN_LOCK_GET, {"screenTypeCode": screenTypeCode})
    .subscribe((res) => {
        if(res?.isAllow == true){
          const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
            width: "30%",
            data: { displayText: "You have placed a hold on the selected purchase order to perform the Goods Receipts. Would you like to proceed with releasing it?" }
          });
    
            dialogRef.afterClosed().subscribe((result) => {
              this.isOpen = false;
              if (
                result != undefined &&
                result.confirm &&
                result.confirm == "yes"
              ) {
                // release patient with update api call
                let payload = {
                  screenTypeCode: ScreenLockEnum.grnScreenTypeCode,
                  statusCode: ScreenLockEnum.statusCode
                }
                this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
                this.baseservice
                  .postResource(APPLICATIONSERVICE.SCREEN_LOCK_UPDATE,payload)
                  .subscribe((res) => {
                    this.snackbar.open(
                      this.langService.get("CommonAppoinment.success"),
                      this.langService.get(res.message),
                      { duration: 3000 }
                    );
                    this.router.navigateByUrl(state);
                    this.isOpen = true;
                    this.currentUrl = state;
                    return true;
                  })
                 
              } else {
                  // patient will remain as hold & it will stay on same route
                  this.router.navigateByUrl(previousUrl);
                  // this.searchService.setSearch(this.searchService.search);
                  this.isOpen = false;
                  return true;
              }
            })
        }
        else{
          this.router.navigateByUrl(state);
          this.isOpen = true;
          this.currentUrl = state;
          return true;
        }
    }) 
  }

/**
 * 
 * @param state 
 * @param screenTypeCode 
 */
getMedicationOrderLock(state, screenTypeCode):any{
  const previousUrl = this.router.url  

  this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
  this.baseservice
  .postResource(APPLICATIONSERVICE.SCREEN_LOCK_GET, {"screenTypeCode": screenTypeCode})
  .subscribe((res) => {
      if(res?.isAllow == true){
        const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
          width: "30%",
          data: { displayText: "You have placed a hold on the selected patient prescription. Would you like to proceed with releasing it?" }
        });
  
          dialogRef.afterClosed().subscribe((result) => {
            this.isOpen = false;
            if (
              result != undefined &&
              result.confirm &&
              result.confirm == "yes"
            ) {
              // release patient with update api call
              let payload = {
                screenTypeCode: ScreenLockEnum.medicationOrderScreenTypeCode,
                statusCode: ScreenLockEnum.statusCode
              }
              this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
              this.baseservice
                .postResource(APPLICATIONSERVICE.SCREEN_LOCK_UPDATE,payload)
                .subscribe((res) => {
                  this.snackbar.open(
                    this.langService.get("CommonAppoinment.success"),
                    this.langService.get(res.message),
                    { duration: 3000 }
                  );
                  this.router.navigateByUrl(state);
                  this.isOpen = true;
                  this.currentUrl = state;
                  return true;
                })
               
            } else {
                // patient will remain as hold & it will stay on same route
                this.router.navigateByUrl(previousUrl);
                this.searchService.setSearch(this.searchService.search);
                this.isOpen = false;
                return true;
            }
          })
      }
      else{
        this.router.navigateByUrl(state);
        this.isOpen = true;
        this.currentUrl = state;
        return true;
      }
  }) 
}
}
