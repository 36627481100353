<div class="flex" (click)="clickPushNotification()">
  <!-- <img src="/src/assets/images/notification-lab.png" class="pr-2"> -->
  <mat-icon *ngIf="notification.isRead" class="pr-2"> email </mat-icon>
  <mat-icon *ngIf="!notification.isRead" class="pr-2"> mail_outline </mat-icon>
  &nbsp;
  <div class="content-notifcation width70">
    <span
      *ngIf="notification.isRead"
      class="spanNotificationMsg"
      [innerHTML]='notification.notificationMessage'
    >
    </span>
    
    <b
      *ngIf="!notification.isRead"
      class="spanNotificationMsg"
      [innerHTML]='notification.notificationMessage'
    >
    </b>
  </div>
  <div *ngIf="notification.isRead" class="divTime width30 right">{{notification.createdDate | date:'MMM d, y, h:mm a'}}</div>
  <div *ngIf="!notification.isRead" class="divTime width30 right">
    <b> {{notification.createdDate | date:'MMM d, y, h:mm a'}} </b>
  </div>
</div>
<app-push-notification-button
  [notification]="notification"
></app-push-notification-button>
