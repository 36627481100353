import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MasterFormFieldComponent } from "./master-form-field/master-form-field.component";
import { MasterSelectFieldComponent } from "./master-select-field/master-select-field.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MasterProcurementComponent } from "./master-procurement/master-procurement.component";

@NgModule({
  declarations: [
    MasterFormFieldComponent, 
    MasterSelectFieldComponent,
    MasterProcurementComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    MasterFormFieldComponent,
    MasterSelectFieldComponent,
    MasterProcurementComponent
  ]
})
export class SharedModule {}
