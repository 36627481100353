<div [ngClass]="[isMaximize == true ? 'maximize-dimension':'normal-dimension']">
  <ejs-chart
    style="height: inherit !important"
    [chartArea]="chartArea"
    align="center"
    [id]="idValue"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    (load)="load($event)"
    (tooltipRender)="tooltipRender($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="data"
        type="StackingBar"
        xName="Height"
        yName="Female"
        name="Female"
        width="2"
        [marker]="marker"
        columnWidth="0.5"
      >
      </e-series>
      <e-series
        [dataSource]="data"
        type="StackingBar"
        xName="Height"
        yName="Male"
        name="Male"
        width="2"
        [marker]="marker1"
        columnWidth="0.5"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
