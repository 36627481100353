<div id="barChart">
  <ejs-chart
    style="display: block"
    [chartArea]="chartArea"
    style="height: inherit !important"
    align="center"
    [id]="idName"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]='tooltip'
    (load)="load($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="dataSource"
        type="Column"
        xName="type"
        yName="count"
        width="2"
        [marker]="marker"
        columnSpacing="0.1"
        [tooltipFormat]="tooltipFormat"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
