export class SponsorModel {
  tariffDesc: string = "";
  tariffCode: string = "";
  payersCode: string = "";
  payerName: string = "";
  AssociateCompany: string = "";
  Priority: number = 0;
  GLType: string = "";
  policyNo: string = "";
  GLLimit: number;
  GLEffectiveDate: Date = new Date();
  GLExpiryDate: Date = new Date();
  GLReferenceNo: string = "";
}

export class AccountPayerModel {
  glAttachementId: string = "";
  id: string = "";
  tariffname: string = "";
  tariffcode: string = "";
  payerTypeCode: string = "";
  payerTypeName: string = "";
  payerTypeId: string = "";
  payercode: string = "";
  payersname: string = "";
  GLEffectiveDate: Date = new Date();
  GLExpiryDate: Date = new Date();
  GLReferenceNo: string = "";
  billedStatus: boolean = false;
  payment: string = "";
  policyNo: string = "";
  GLBalance: number = 0;
  insurancenumber: string = "";
  insurancecompany: string = "";
  insuranceamount: number = 0;
  glLimitAmount: number = 0;
  insuranceEffectiveDate: Date = new Date();
  priority: number = 0;
  oldPriority: number = 0;
  insuranceamountExpiryDate: Date = new Date();
  effectiveDate: string = "";
  expiryDate: Date = new Date();
  glType: string = "";
  glTypeCode: string = "";
  glTypeName: string = "";
  coverageStatus: string = "";
  payerId: string = "";
  glReferenceNo: string = "";
  associatedCompanyId: string = "";
  AssociateCompandCode: string = "";
  associatedCompanyName: string = "";
  coverageStatusCd: string = "";
  status: string = "Active";
  contractId: string = "";
  contractCode: string = "";
  contractName: string = "";
}
