import { Component, OnInit, Inject, Injector } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";
import { AppBaseService, AuthService } from "medcare-core-ui";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
export interface PatientDialogData {
  listNotes: any;
  name: string;
  encounterId: any;
  mrnNo: any;
  prodiaOrderId: any;
  isPrint?:boolean;
}
@Component({
  selector: "app-patient-notes-dialog",
  templateUrl: "./patient-notes-dialog.component.html",
  styleUrls: ["./patient-notes-dialog.component.scss"],
})
export class PatientNotesDialogComponent implements OnInit {
  remarkColumns: string[]= ["notes", "addedBy", "addedFrom"];
  remarkSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  notesRemark: any;

  minDate: any = new Date();
  pageName: string;
  username: string;
  previousNotesList: any[] = [];
  isAdmission:boolean=true;
  isPrint: boolean;
  constructor(
    public dialogRef: MatDialogRef<PatientNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientDialogData,
    public snackBar: MatSnackBar,
    private appBaseService: AppBaseService,
    private langService: MultiLanguageService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.isPrint=this.data?.isPrint?this.data.isPrint:false;
    if (this.isPrint) {
      this.remarkColumns= ["notes", "addedBy", "noteDate","addedFrom"];
    }
    if(this.data.listNotes){
      this.previousNotesList = this.data.listNotes;
      this.previousNotesList.sort((a, b) => b.addedOn - a.addedOn);
    }
    const auth = this.injector.get(AuthService);
    let _this = this;
    auth.getUserInfo().then(function (value: any) {
      _this.username = value.userName;
    });
    this.pageName = this.data.name;

    if (this.previousNotesList && this.previousNotesList.length > 0) {
      this.remarkSource = new MatTableDataSource<any>([]);
      this.previousNotesList.forEach((element) => {
        let dateValue =
          (element.addedOn instanceof Date)
            ? element.addedOn
            : this.epochToDateConverter(element.addedOn * 1000);
        this.remarkSource.data.push({
          note: element.note ? element.note : element.note,
          addedOn: dateValue,
          addedFrom: element.addedFrom,
          addedBy: element.addedBy ? element.addedBy : "",
          status: true,
        });
      });
      this.remarkSource = new MatTableDataSource<any>(this.remarkSource.data);
    } else {
      this.remarkSource = new MatTableDataSource<any>([]);
    }
  }
  onNoClick() {
    let data = this.previousNotesList;
    this.dialogRef.close(data);
  }
  onAddRemarkClick() {
    if (!this.notesRemark) {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.pleaseaddnotes"),
        this.langService.get("CommonAppoinment.warning"),
        {
          duration: 2000,
        }
      );
    } 
    else {
      if (this.pageName == "Encounter" || this.pageName == "Emergency") {
        this.remarkSource.data.push({
          note: this.notesRemark,
          addedFrom: this.pageName,
          addedBy: this.username,
          status: true,
        });
        this.previousNotesList.push({
          status: true,
          addedFrom: this.pageName,
          addedBy: this.username,
          note: this.notesRemark,
        });

        this.remarkSource = new MatTableDataSource<any>(this.remarkSource.data);
        this.notesRemark = undefined;
        this.snackBar.open(
          this.langService.get("CommonAppoinment.NotesSavedSuccessfully"),
          this.langService.get("CommonAppoinment.success"),
          {
            duration: 2000,
          }
        );
      } else {
        this.remarkSource.data.push({
          note: this.notesRemark,
          addedFrom: this.pageName,
          addedBy: this.username,
          addedOn: this.epochToDateConverter(new Date().getTime()),
          status: true,
        });
        if(this.previousNotesList.length>0){
        this.previousNotesList.push({
          status: true,
          addedFrom: this.pageName,
          addedBy: this.username,
          addedOn: this.epochToDateConverter(new Date().getTime()),
          note: this.notesRemark,
        });
      }

        let requestobject = [
          {
            status: true,
            addedFrom: this.pageName,
            note: this.notesRemark,
          },
        ];

        this.appBaseService.setResourceURL("/opd-service/");
        this.appBaseService
          .putResource(
            "extendedEncounter/updateEncounterInternalNote/" +
              this.data.encounterId,
            requestobject
          )
          .subscribe((response) => {
            if (response) {
              this.snackBar.open(
                this.langService.get("CommonAppoinment.NotesSavedSuccessfully"),
                this.langService.get("CommonAppoinment.success"),
                {
                  duration: 1000,
                }
              );
              this.remarkSource.data.sort((a, b) => b.addedOn - a.addedOn);
              this.remarkSource = new MatTableDataSource<any>(
                this.remarkSource.data
              );
              this.notesRemark = undefined;
            }
          });
      }
    }
  }

  dateToEpochConverter(date: Date): number {
    try {
      return moment(date).unix();
    } catch (ex) {
      return moment(new Date()).unix();
    }
  }
  epochToDateConverter(epoch: number): Date {
    try {
      epoch = epoch / 1000;
      var d = new Date(0);
      d.setUTCSeconds(epoch);
      return d;
    } catch (ex) {
      return new Date();
    }
  }
}