import { Injectable } from "@angular/core";
import { RegisterModel } from "./RegistationModel";
import * as temp from 'moment';
const moment = temp["default"];

@Injectable({
  providedIn: "root"
})
export class PatientService {
  constructor() {}

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getPatientModel(patient: any): RegisterModel {
    try {
      let dateOfBirth: Date;
      if (patient.birthDate) {
        dateOfBirth = new Date(patient.birthDate);
      }
      let expiryDate = new Date();
      if (
        patient.identifier &&
        patient.identifier[1] &&
        patient.identifier.period &&
        patient.identifier.end
      ) {
        expiryDate = new Date(patient.identifier[1].period.end);
      } else {
        expiryDate = new Date();
      }
      let secondaryId = "";
      let identification = "";
      if (
        patient.identifier[2] &&
        patient.identifier[2].type &&
        patient.identifier[2].type.text
      ) {
        secondaryId = patient.identifier[2].type.text;
      }
      if (
        patient.identifier[1] &&
        patient.identifier[1].type &&
        patient.identifier[1].type.text
      ) {
        identification = patient.identifier[1].type.text;
      } else {
        identification = "NA";
      }

      let primaryValue = "",
        primaryId = "",
        primaryCode = "";
      if (patient.identifier[1] && patient.identifier[1].value) {
        primaryValue = patient.identifier[1].value;
      }
      if (
        patient.identifier[1] &&
        patient.identifier[1].type &&
        patient.identifier[1].type.coding &&
        patient.identifier[1].type.coding[0]
      ) {
        primaryId = patient.identifier[1].type.coding[0].code;
        primaryCode = patient.identifier[1].type.text;
      }

      let emailId = "",
        homecontact = "",
        tempcontact = "";
      if (patient.telecom) {
        let telecom = patient.telecom.find(i => i.system == "email");
        if (telecom) {
          emailId = telecom.value;
        }
        telecom = patient.telecom.find(
          i => i.system == "phone" && i.use == "home"
        );
        if (telecom) {
          homecontact = telecom.value;
        }
        telecom = patient.telecom.find(
          i => i.system == "phone" && i.use == "temp"
        );
        if (telecom) {
          tempcontact = telecom.value;
        }
      }

      let displayAge = "";
      if (patient.age) {
        let splice = patient.age.split("Y", 1);
        displayAge = splice + " Y";
      }

      let status = "";
      if (patient.isBlocked) {
        status = "Blocked";
      }
      if (!patient.isBlocked || patient.isBlocked == null) {
        status = "Not Blocked";
      }
      if (patient.isCTOS) {
        status = "CTOS Blocked";
      }

      let reason = "",
        remarks = "",
        blockingBy = "",
        blockingDate: Date;
      if (patient.patientBlock) {
        reason = patient.patientBlock[patient.patientBlock.length - 1].reason;
        remarks = patient.patientBlock[patient.patientBlock.length - 1].remarks;
        blockingBy = patient.patientBlock[patient.patientBlock.length - 1].user;
        let time =
          patient.patientBlock[patient.patientBlock.length - 1].datetime * 1000;
        blockingDate = new Date(time);
      }

      let contactRelation = "SELF";
      if (
        patient.contact &&
        patient.contact[0] &&
        patient.contact[0].relationship &&
        patient.contact[0].relationship[0].text
      ) {
        contactRelation = patient.contact[0].relationship[0].text;
      }
      let patientName: string = patient.name[0].text;
      const patientModel: RegisterModel = {
        MongoId: patient.id,
        primaryIDdesc: primaryValue,
        primaryId: primaryId,
        secondaryId: secondaryId,
        primaryCode: primaryCode,
        firstName: patientName,
        middleName: "NA",
        lastName: "NA",
        otherName: patient.name && patient.name[1] ? patient.name[1].text : "",
        dob: dateOfBirth,
        phoneNumber: patient.telecom && patient.telecom[0].value,
        photo:
          patient.photo && patient.photo[0] && patient.photo[0].url
            ? patient.photo[0].url
            : "",
        MRN: patient.mrnno,
        emailId: emailId,
        docID: patient.identifier[2] ? patient.identifier[2].value : "",
        prefix:
          patient.name &&
          patient.name[0] &&
          patient.name[0].prefix &&
          patient.name[0].prefix[0]
            ? patient.name[0].prefix[0]
            : "",
        // gender: this.capitalizeFirstLetter(patient.gender),
        //please don't chnage it ,FHIr says its should be small
        gender: patient.gender,
        race: patient.race,
        nation: patient.nationality,
        religion: patient.religion,
        pcat: patient.patientCategory,
        identification: identification,
        martial:
          patient.maritalStatus &&
          patient.maritalStatus.coding &&
          patient.maritalStatus.coding[0]
            ? patient.maritalStatus.coding[0].display
            : "",
        phonenumberL: tempcontact,
        expirDate: expiryDate,
        occupation: patient.occupation,
        address:
          patient.address &&
          patient.address[0] &&
          patient.address[0].line &&
          patient.address[0].line[0]
            ? patient.address[0].line[0]
            : "",
        country:
          patient.address && patient.address[0] && patient.address[0].country
            ? patient.address[0].country
            : "",
        state:
          patient.address && patient.address[0] && patient.address[0].state
            ? patient.address[0].state
            : "",
        district:
          patient.address && patient.address[0] && patient.address[0].city
            ? patient.address[0].city
            : "",
        pincode:
          patient.address && patient.address[0] && patient.address[0].postalCode
            ? patient.address[0].postalCode
            : "",
        addressP:
          patient.address &&
          patient.address[1] &&
          patient.address[1].line &&
          patient.address[1].line[0]
            ? patient.address[1].line[0]
            : "",
        countryP:
          patient.address && patient.address[1] && patient.address[1].country
            ? patient.address[1].country
            : "",
        stateP:
          patient.address && patient.address[1] && patient.address[1].state
            ? patient.address[1].state
            : "",
        cityP:
          patient.address && patient.address[1] && patient.address[1].city
            ? patient.address[1].city
            : "",
        pinccodeP:
          patient.address && patient.address[1] && patient.address[1].postalCode
            ? patient.address[1].postalCode
            : "",
        age: patient.age,
        phonenumberP: homecontact,
        admissionInfo: patient.admissionInfo ? patient.admissionInfo : {},
        contactRelation: contactRelation,
        displayAge: displayAge,
        blockStatus: status,
        isBlocked: patient.isBlocked,
        isCTOS: patient.isCTOS,
        reason: reason,
        remarks: remarks,
        blockedBy: blockingBy,
        blockedDate: blockingDate,
        patientBlock: patient.patientBlock
      };
      return patientModel;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  epochToDateConverter(epoch: number): Date {
    try {
      epoch = epoch / 1000;
      var d = new Date(0);
      d.setUTCSeconds(epoch);
      return d;
    } catch (ex) {
      return new Date();
    }
  }

  dateToEpochConverter(date: Date): number {
    try {
      return moment(date).unix();
    } catch (ex) {
      return moment(new Date()).unix();
    }
  }

  public calculateAge(
    dateOfBirth: Date
  ): {
    years: string;
    months: string;
    days: string;
  } {
    if (dateOfBirth) {
      let date_1 = new Date(dateOfBirth);
      let date_2 = new Date();
      let date2_UTC = new Date(
        Date.UTC(
          date_2.getUTCFullYear(),
          date_2.getUTCMonth(),
          date_2.getUTCDate()
        )
      );
      var date1_UTC = new Date(
        Date.UTC(
          date_1.getUTCFullYear(),
          date_1.getUTCMonth(),
          date_1.getUTCDate()
        )
      );
      let days = date2_UTC.getDate() - date1_UTC.getDate();
      if (days < 0) {
        date2_UTC.setMonth(date2_UTC.getMonth() - 1);
        days += DaysInMonth(date2_UTC);
      }
      let months = date2_UTC.getMonth() - date1_UTC.getMonth();
      if (months < 0) {
        date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
        months += 12;
      }
      let years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
      if (days == 0) days = 1;
      return {
        years: years + "Y",
        months: months + "M",
        days: days  + "D"
      };
    } else {
      return { years: "0Y", months: "0M", days: "0D" };
    }

    function DaysInMonth(date2_UTC) {
      var monthStart: any = new Date(
        date2_UTC.getFullYear(),
        date2_UTC.getMonth(),
        1
      );
      var monthEnd: any = new Date(
        date2_UTC.getFullYear(),
        date2_UTC.getMonth() + 1,
        1
      );
      var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
      return monthLength;
    }
  }
}
