import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from './constants';

@Pipe({
  name: 'gmailformat'
})
export class GmailformatPipe extends DatePipe implements PipeTransform {

  datePipe = new DatePipe('en-US');
  todaydate = new Date();

  transformedTodaydate =  this.datePipe.transform(this.todaydate, 'yyyy,M,dd');
  currentYear = new Date();
  transformedCurrentYear =  this.datePipe.transform(this.currentYear, 'yyyy,1,01');

  /**
   * 
   * @param value 
   * @returns gmail format date
   */
  transform(value: any): any {
    const data = value;
    const datePipe = new DatePipe('en-US');
    let newData: any;
    newData = datePipe.transform(data, 'yyyy,M,dd');

    if ( newData > this.transformedCurrentYear && newData < this.transformedTodaydate) {
      return super.transform(value, Constants.MONTH_DAY);
    } else if ( newData < this.transformedCurrentYear && this.transformedTodaydate) {
      return super.transform(value, Constants.DAY_MONTH_YEAR);
    } else {
      return newData;
    }
  }
}
