import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppBaseService, MICROSERVICES } from "medcare-core-ui";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { OPD_API_URL } from "../../regproperties/opd-url-properties";
@Component({
  selector: "app-confirm-remarks",
  templateUrl: "./confirm-remarks.component.html",
  styleUrls: ["./confirm-remarks.component.scss"],
})
export class ConfirmRemarksComponent implements OnInit, OnDestroy {
  pageName: any;
  reasonList: any[]=[];
  reasonSearch: FormControl = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<ConfirmRemarksComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private appBaseService: AppBaseService,
    public snackBar: MatSnackBar,
    private langService: MultiLanguageService,
    public translate: TranslateService,
  ) {
    this.pageName = data.pageName;
  }
  reasons: any[] = [];
  reason: any = "";
  remarks: any = "";
  private subscriptionList = new Subscription();

  /**
   *on destory method
   *
   * @memberof ConfirmRemarksComponent
   */
  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }

  /**
   *on load method
   *
   * @memberof ConfirmRemarksComponent
   */
  ngOnInit() {
    this.getReasonList();
    this.onChanges();
  }
  onChanges() {
    this.subscriptionList.add(
      this.reasonSearch.valueChanges.subscribe((value) => {
        this.reasons = this.reasonList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
  }
  getReasonList() {
    if (this.pageName == "PatientApointmentList") {
      this.appBaseService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
      this.appBaseService
        .getResource(OPD_API_URL.M_REASON_MASTER_QUERY_NEW + "APCN")
        .subscribe((res) => {
          res.forEach((element) => {
            this.reasons.push(element);
            this.reasonList.push(element);
          });
        });
    } else if (this.pageName == "PatientRescheduleList") {
      this.appBaseService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
      this.appBaseService
        .getResource(OPD_API_URL.M_REASON_MASTER_QUERY_NEW + "APRS")
        .subscribe((res) => {
          res.forEach((element) => {
            this.reasons.push(element);
            this.reasonList.push(element);
          });
        });
    } else if (this.pageName == "PatientEncounterList") {
      this.appBaseService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
      this.appBaseService
        .getResource(OPD_API_URL.M_REASON_MASTER_QUERY_NEW + "ENCN")
        .subscribe((res) => {
          res.forEach((element) => {
            this.reasons.push(element);
            this.reasonList.push(element);
          });
        });
    } else {
      this.appBaseService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
      this.appBaseService
        .getResource(OPD_API_URL.M_REASON_MASTER_QUERY)
        .subscribe((res) => {
          res.forEach((element) => {
            this.reasons.push(element);
            this.reasonList.push(element);
          });
        });
    }
  }

  /**
   *save click method
   *
   * @param {*} value
   * @memberof ConfirmRemarksComponent
   */
  buttonClick(value) {
    if (this.reason != "") {
      let reasonValue = this.reasons.filter((s) => s.code == this.reason);
      let data = { remarks: this.remarks, reason: reasonValue, action: value };
      this.dialogRef.close(data);
    } else if (this.reason == "") {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.warning"),
        this.langService.get("AppointmentListPage.giveReason"),
        {
          duration: 5000,
        }
      );
    }
  }

  /**
   *on no lcik method
   *
   * @param {*} value
   * @memberof ConfirmRemarksComponent
   */
  buttonClickNo(value) {
    let data = { remarks: "", reason: "", action: value };
    this.dialogRef.close(data);
  }
}
