import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NameFormat'
})
export class NameFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const maxLength = 50;
    let formattedName = '';
    let currentLength = 0;

    value.split(' ').forEach(word => {
      if (currentLength + word.length + 1 > maxLength) {
        formattedName += '\n'+' ' + word;
        currentLength = word.length;
      } else {
        formattedName += (formattedName ? ' ' : '') + word;
        currentLength += word.length + 1;
      }
    });

    return formattedName;
  }
}
