import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { EnvoirnmentService } from "../../services/envoirnment.service";

@Component({
  selector: "app-uploadfile",
  templateUrl: "./uploadfile.component.html",
  styleUrls: ["./uploadfile.component.scss"],
})
export class UploadfileComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  file: any;
  formData: FormData = new FormData();
  mrn: any;
  encounter: any;
  fileUploadList: {
    files: File;
    type: string;
    name: string;
    filetypevalue: string;
  }[] = [];
  constructor(
    public env: EnvoirnmentService,
    public dialogRef: MatDialogRef<UploadfileComponent>,
    private langService: MultiLanguageService,
    @Inject(MAT_DIALOG_DATA) public data,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.mrn = this.data.mrn;
    this.encounter = this.data.encounter;
  }
  /**
   *destroy method
   *
   * @memberof UploadfileComponent
   */
  ngOnDestroy() {}

  /**
   *on close method
   *
   * @memberof UploadfileComponent
   */
  onNoClick(): void {
    // let data = this.fileUploadList;
    this.dialogRef.close();
  }

  /**
   *get file method
   *
   * @param {*} e
   * @memberof UploadfileComponent
   */
   getFile(e) {
    let list = [];
    for (var i = 0; i < e.target.files.length; i++) {
      let totalFileSize = this.env.uploadLimit * 1048576;
      if (e.target.files[i].size <= totalFileSize ) {
        list.push({
          files: e.target.files[i],
          type: e.target.files[i].type,
          name: e.target.files[i].name,
          filetypevalue: "",
        });
      } else {
        let name = e.target.files[i].name;
        this.snackBar.open(
          // "Please Choose " + name + "Upto " + this.env.uploadLimit + "MB file",
          "File size is more than " + this.env.uploadLimit + "MB",
          "Validation",
          {
            duration: 2000,
          }
        );
        this.fileUpload.nativeElement.value = '';
      }
      this.fileUploadList = list;
    }
  }

  uploadFile() {
    if (this.fileUploadList.length == 0) {
      this.snackBar.open(
        //"Please Choose File", "Error",
        this.langService.get("CommonAppoinment.choosefile"),
        this.langService.get("CommonAppoinment.error"),
        {
          duration: 2000,
        }
      );
      return;
    }
    this.dialogRef.close(this.fileUploadList);
  }
}
