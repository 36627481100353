<div class="normal-dimension" id="progressBar">
  <div *ngFor="let data of dataSource">
    <span class="label">{{ data.type }}</span>
    <mat-progress-bar
      mode="determinate" 
      [style.width]="data.width"
      [matTooltip]="data.count"
      class="maintenance-progressbar"
      [value]="data.percent"
    ></mat-progress-bar>
  </div>
</div>
