import { filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import { SearchService } from "../../services/search.service";
import { QuickLinkService } from "./../../services/quick-link.service";
import { ComponentFactoryResolver, ViewContainerRef } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
  opened?: boolean;
}

@Component({
  selector: "app-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild("container", { read: ViewContainerRef })
  container: ViewContainerRef;
  @ViewChild("drawer") drawer: MatSidenav;
  showQuickLinks: boolean = false;
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );

  routeOptions: Options;
  options = {
    lang: "fr",
    theme: "light",
    settings: false,
    docked: true,
    boxed: false,
    opened: true,
    mode: "dock"
  };

  _mode = this.options.mode;
  currentLang = "fr";
  showQuickLink: boolean = false;
  toggleState: any = "close";
  opened: boolean = false;

  constructor(
    private _element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private modalService: NgbModal,
    private titleService: Title,
    private searchService: SearchService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private quickLink: QuickLinkService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  onRouteChange() {
    let searchService = this.searchService;
    setTimeout(function() {
      let filter = document.getElementsByClassName("divfilter").length;
      if (filter == 0) {
        searchService.setSearch([]);
      }
    }, 1000);
  }

  ngOnInit(): void {
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
        document.querySelector(".main-content").scrollTop = 0;
        if (this.isOver()) {
          this._mode = "over";
          this.options.opened = false;
        }
        this.runOnRouteChange();
    });
    this.runOnRouteChange();
    setTimeout(_ => {
      let element = document.getElementById("divQuickLinks");
      if (element) this.dragElement(element);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(_ => this.runOnRouteChange());
  }

  openFrequentlyAccessed() {
    this.quickLink.setLink("FrequentlyAccessed");
    this.drawer.toggle();
  }

  openQuickAppointments() {
    this.quickLink.setLink("QuickAppointments");
    this.drawer.toggle();
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  getLinkClick() {
    this.opened = !this.opened;
  }
  
  runOnRouteChange(): void {
    this.showQuickLink = false;
    if (this.toggleState == "close") {
    } else if (this.toggleState == "open") {
      this.drawer.toggle();
    }

    if (this.isOver() || this.router.url === "/maps/fullscreen") {
      this.options.opened = false;
    }

    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot.data;
    });

    if (this.routeOptions) {
      if (this.routeOptions.hasOwnProperty("heading")) {
        this.titleService.setTitle("Medcare");
      }
    }
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  toogleSidebar(): void {
    this._mode = this._mode == "dock" ? "push" : "dock";
  }

  receiveMessage($event) {
    this.options = $event;
  }

  openSearch(search) {
    this.modalService.open(search, { windowClass: "search", backdrop: false });
  }

  toggleFullscreen(): void {
    const elem = this._element.nativeElement.querySelector(".main-content");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullScreen) {
      elem.msRequestFullScreen();
    }
  }

  dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        //elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        elmnt.style.right = 0;
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }
}
