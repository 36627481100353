import { ErrorHandler, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EnvoirnmentService } from "./envoirnment.service";

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private toastr: ToastrService,
    private envoirnment: EnvoirnmentService
  ) {}

  handleError(error: Error) {
    if (this.toastr && this.envoirnment.logMessages) {
      this.toastr.warning(error.message, "Error");
    }
  }
}
