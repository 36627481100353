export enum MasterMenus {
  OPD = "Patient_Management",
  Billing = "Billing",
  Lab = "Lab",
  APP = "APP",
  PROCUREMENT = "Procurement",
  MRD="MRD_Department",
  PHARMACY="Pharmacy"
}

export enum MasterSubMenus {
  glapproval = "glapproval",
  Bill = "Bill",
  Registration = "Registration",
  Phlebotomy = "Phlebotomy",
  Encounter = "Encounter",
  Charges = "Charges",
  ERRegistration="ER Registration",
  SampleReg="Sample Registration"
}

export enum MasterChildSubMenus {
  CentralReceiving = "Central Receiving"
}

export enum MasterKeys {
  VisitDate = "VISIT_DATE_VALIDITY",
  performaRequired = "Is_performa_required",
  tariffConfig = "Default_Tariff",
  depositAlias = "D_Payment_Alias_Name",
  tariffDiscountType = "TARIFF_DISCOUNT_TYPE",
  contractDiscountType = "CONTRACT_DISCOUNT_TYPE",
  selfSettlementFlag = "SELF_SETTLEMENT_MANDATORY",
  defaultSelfContract = "Default_Self_Contract",
  StaffPatientCategory = "StaffPatientCategory",
  StudentPatientCategory = "StudentPatientCategory",
  NextOfKinRequired="Kin Details Required",
  staffActiveFlag="Is_Staff_Active_Requierd",
  servicePurchaseRequired = "Service Purchase Required",
  isRaceDependentRequired ="IsRaceDependentRequired",
  updateAuthRequired="Update Authentication Required",
  patientCategoryTariffRequired="PatientCategoryTariffMapperRequired",
  mrdReport="MRD_Report",
  firstPayerPriorityRequired="Payer_DefaultFirstPriorityRequired",
  patientCardRequired="Patient Card Required",
  defaultNationality="Default Nationality",
  defaultCountry="Default Country",
  emergencyDoctor="EmergencyDoctor",
  isDefaultDepositRequired ="IsDefaultDepositRequired",
  NewBorndefaultspecialty ="New Born default specialty",
  DefaultCountryCode = "Default Country Code",
  isAdmissionDateEditable="Admission Date Editable"
}
export enum ScreenLockEnum {
  billscreenTypeCode= "BILL",
  statusCode= "REL",
  opbill = "opbill",
  ipbill = "ipbill",
  addGrn = "addnewGrn",
  grnScreenTypeCode = "AGAINSTPO",
  medicationOrder = "medicationorder-dashboard",
  medicationOrderScreenTypeCode = "MEDICATIONORDER"
}
export enum ManditoryKayEnum{
  clinic="clinic"
}
