/*eslint-disable*/
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent, TableService, FileManagerService } from '@syncfusion/ej2-angular-richtexteditor';
import { DomSanitizer } from '@angular/platform-browser';
import { AudioRecordingService } from '../../../services/audio-recording.service';


@Component({
  selector: 'app-text-editor-template',
  templateUrl: './text-editor-template.component.html',
  styleUrls: ['./text-editor-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, FileManagerService,AudioRecordingService]
})
export class TextEditorTemplateComponent implements OnInit, OnChanges, OnDestroy{

  enableEditor: boolean = true;

  @Input() inputText: string = "<p>Edited message</p>";
  @Output() changedText = new EventEmitter<string>();

  editedText: string = "<p></p>";

  internalSpeech: string = "";
  internalTextBox: boolean = false;

  autoSaveTempId: string = "autoSaveTemp";

  @ViewChild('autosaveRTE') public textEdited: RichTextEditorComponent;

  public tools: object = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
    'FontName', 'FontSize',
    'LowerCase', 'UpperCase','SuperScript', 'SubScript', '|',
    'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
    'Outdent', 'Indent', '|',
    'CreateTable', 'CreateLink', 'Image', 'FileManager', '|', 'ClearFormat', 'Print',
    'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
  };
  public iframe: object = { enable: true };
  //public height: number = 350;
  public height: string = '100%';

  //audion changes to be removed
  isRecording = false;
  recordedTime: any;
  blobUrl: any;

  constructor(
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer
  ){
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });
  
    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });
  
    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.audioRecordingService.generateText(data.blob).then(text => {
        if(text) {
          let htmlString = '<p>' + text + '</p>'
          this.editedText = htmlString + (this.textEdited.value? this.textEdited.value: "");
          this.textEdited.value = this.editedText;
          this.onChange();
        }
      })
    });
  }

  ngOnDestroy(): void {
    this.blobUrl = null;
    this.abortRecording();
  }

  ngOnInit(): void {
    this.editedText = this.inputText? this.inputText:  "";
  }

  ngOnChanges(changes: SimpleChanges) {  
    if (changes.inputText) {
      this.editedText = changes.inputText.currentValue;
    }
  }

  public onRTECreate(e: any): void { 
    if(this.textEdited) {
      this.textEdited.refreshUI(); 
    }
  } 

  //Handler for changed event trace
  onChange(): void {
    this.changedText.emit(this.textEdited.value);
  }

  addTextToTemplate() {
    this.editedText = this.editedText + '<p>' +this.internalSpeech + '</p>';
    this.internalTextBox = false;
    this.changedText.emit(this.textEdited.value);
  }

  startRecording() {
    this.clearRecordedData();
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }
  
  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }
  
  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
      this.clearRecordedData();
    }
  }
  
  clearRecordedData() {
    this.blobUrl = null;
  }
}
