import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {AppBaseService} from "../../../../services/http.service";
import {PushNotificationService} from "../../service/push-notification.service";

/**
 *
 */
@Component({
  selector: 'app-single-notification-item',
  templateUrl: './single-notification-item.component.html',
  styleUrls: ['./single-notification-item.component.scss']
})
export class SingleNotificationItemComponent implements OnInit {

  /**
   *
   */
  public pushNotificationDefaultLevelClass: string = 'divNotificationBlack';

  /**
   *
   */
  public pushNotificationLevelClass: any = {
    error: 'divNotificationRed',
    success: 'divNotificationGrey'
  };

  /**
   *
   */
  @Input() notification: any;

  /**
   * Creates an instance of SingleNotificationItemComponent.
   * @param {AppBaseService} appBaseService
   * @param {Router} route
   * @param {PushNotificationService} pushNotificationService
   * @param {AuthService} authService
   * @memberof SingleNotificationItemComponent
   */
  constructor(
    private readonly appBaseService: AppBaseService,
    private readonly route: Router,
    private readonly pushNotificationService: PushNotificationService,
    private authService: AuthService,
  ) { }

/**
 *
 *
 * @memberof SingleNotificationItemComponent
 */
  ngOnInit() {
  }

  /**
   *
   *
   * @param {*} notification
   * @return {*} 
   * @memberof SingleNotificationItemComponent
   */
  public createdAtTime(notification) {
    return new Date(notification).valueOf();
  }

  /**
   *
   *
   * @param {*} notification
   * @return {*} 
   * @memberof SingleNotificationItemComponent
   */
  public getStatusClass(notification) {
    let statusClass = this.pushNotificationLevelClass[notification.body.level];
    return  statusClass === undefined ? this.pushNotificationDefaultLevelClass : statusClass;
  }

  /**
   *
   *
   * @memberof SingleNotificationItemComponent
   */
  public clickPushNotification() {
    if (this.notification.type === 'actionAble') {
      if (this.notification.body.actionUrl !== '') {
        this.notificationClick(this.notification.body);
      }
    }
    if (this.notification.status === 'unread') {
      this.notification.status = 'read';
      this.authService.getUser().then((res: any) => {
        this.pushNotificationService.markPushNotificationRead(
          this.notification.notificationId
          //,res.userId
        );
      });
    }
  }

  /**
   *
   *
   * @param {*} buttonAction
   * @memberof SingleNotificationItemComponent
   */
  public notificationClick(buttonAction) {
    switch (buttonAction.action) {
      case 'get':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService
          .getResource(buttonAction.actionUrl+"?"+this.serialize(buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'post':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.postResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'patch':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.patchResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'put':
        this.appBaseService.setResourceURL(buttonAction.serviceName);
        this.appBaseService.putResource(buttonAction.actionUrl, (buttonAction.param))
          .subscribe(res => {
          });
        break;
      case 'redirect':
        this.route.navigate(
          [buttonAction.actionUrl],
          {
            queryParams:buttonAction.param
          }
        );
        break;
    }
  }

  /**
   *
   *
   * @param {*} param
   * @return {*} 
   * @memberof SingleNotificationItemComponent
   */
  public serialize(param) {
    var str = [];
    for (var p in param)
      if (param.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(param[p]));
      }
    return str.join("&");
  }
}
