import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppointmentModel } from "../regmodels/MappointmentModel";
import { OPD_CONST } from "../regproperties/opd-static-properties";

@Injectable()
export class AppointmentService {
  private values = new BehaviorSubject<any>({ selectedDate: "" });
  value = this.values.asObservable();

  setAppointmentData(value) {
    this.values.next(value);
  }
  setURlslotData(_this, startDate, endDate) {
    return `/slot/${_this.appointmentType}/Appointment/List?departmentCode=${_this.department}&startTime=${startDate}&endTime=${endDate}`;
  }
  setURLmultipleDoctor(_this, startDate, endDate) {
    return `/slot/${_this.appointmentType}/Appointment/List?entities=${_this.doctor.code}&departmentCode=${_this.department}&startTime=${startDate}&endTime=${endDate}`;
  }
  appoinmentSearchList(_this) {
    let searchList: any[] = [
      {
        controls: [
          // {
          //   type: "text",
          //   placeholder: _this.langService.get("SearchHeader.patientName"),
          //   value: "",
          //   column: 6,
          //   name: "PatientName",
          //   url: "",
          //   selectType: "",
          // },
          {
            "type": "text",
            "placeholder":_this.langService.get("SearchHeader.patientName"),
            "column": 6,
            "name": "PatientName"
  
          },
          {
            type: "text",
            placeholder: _this.langService.get("AppointmentListPage.mrn"),
            value: "",
            column: 6,
            name: "MRN",
            url: "",
            selectType: "",
          },
        ],
      },
      {
        controls: [
          {
            type: "select",
            placeholder: _this.langService.get("SearchHeader.idType"),
            value: "",
            column: 6,
            name: "identificationType",
            url: "masters-service/Masters/IdentificationTypeMaster",
            selectType: "",
          },
          {
            type: "text",
            placeholder: _this.langService.get("SearchHeader.docNo"),
            value: "",
            column: 6,
            name: "identificationNumber",
            url: "",
            selectType: "",
          },
        ],
      },
      {
        controls: [
          {
            type: "number",
            placeholder: _this.langService.get("RegistrationPage.handphoneNo"),
            value: "",
            column: 6,
            name: "MobileNo",
            url: "",
            selectType: "",
          },
          {
            type: "date",
            placeholder: _this.langService.get("AppointmentPage.dob"),
            column: 6,
            name: "dob",
          },
        ],
      },
    ];
    return searchList;
  }
  getappointmentSearchList(_this) {
    let searchList: any[] = [
      {
        controls: [
          {
            type: "select",
            placeholder: _this.langService.get(
              "AppointmentPage.appointmenttype"
            ),
            url: "masters-service/Masters/AppointmentTypeMaster",
            column: 6,
            name: "AppointmentType",
          },
          {
            type: "select",
            placeholder: _this.langService.get("AppointmentPage.department"),
            url: "masters-service/Masters/DepartmentMaster",
            column: 6,
            name: "Department",
          },
        ],
      },
      {
        controls: [
          {
            type: "select",
            placeholder: _this.langService.get("AppointmentListPage.doctor"),
            url: "masters-service/Masters/EmployeeMasterDetails",
            column: 6,
            name: "Doctor",
          },
          {
            type: "text",
            placeholder: _this.langService.get("AppointmentListPage.mrn"),
            column: 6,
            name: "MRN",
          },
        ],
      },

      {
        controls: [
          {
            type: "text",
            placeholder: _this.langService.get("SearchHeader.patientName"),
            column: 6,
            name: "PatientName",
          },
        ],
      },
      {
        controls: [
          {
            type: "dateRange",
            placeholder: _this.langService.get(
              "AppointmentListPage.appointmentFromTo"
            ),
            column: 12,
            name: "dateRange",
          },
        ],
      },
    ];
    return searchList;
  }
  getstatusReqPayload(_this, startDate, endDate, isCount) {
    if (isCount)
      return `appointment/filter?fromDate=${startDate}&toDate=${endDate}&appointmentType=${_this.searchAppt.apptype}&speciality=${_this.searchAppt.dept}&doctor=${_this.searchAppt.doctor}&mrn=${_this.searchAppt.mrn}&patientName=${_this.searchAppt.pname}&groupedCount=true`;
    else
      return `appointment/filter?fromDate=${startDate}&toDate=${endDate}&appointmentType=${_this.searchAppt.apptype}&speciality=${_this.searchAppt.dept}&doctor=${_this.searchAppt.doctor}&mrn=${_this.searchAppt.mrn}
       &offset=${_this.offsetData}
       &size=${_this.pageSize}
       &patientName=${_this.searchAppt.pname}`;
  }
  getrescheuleReqPayload(_this, startDate, endDate)
  {
    return`appointment/filter?fromDate=${startDate}&toDate=${endDate}&appointmentType=${_this.searchAppt.apptype}&speciality=${_this.searchAppt.dept}&doctor=${_this.searchAppt.doctor}&mrn=${_this.searchAppt.mrn}
    &offset=${_this.offsetData}
    &size=${_this.pageSize}
    &patientName=${_this.searchAppt.pname}&businessStatus=reschedule`;
  }
  getSearchPatient(partientSearch)
  {
    return `extendedPatient/filter?mrn=${partientSearch.mrn}&name=${
      partientSearch.PatientName
          }&identificationType=${
            partientSearch.identificationType.code
              ? partientSearch.identificationType.code
              : " "
          }&identificationId=${
            partientSearch.identificationNumber
          }&operation=search&mobile=${partientSearch.MobileNo}&birthDate=${
            partientSearch.birthDate
          }`
  }
  getappoitmentList(res, _this): AppointmentModel {
    let today = new Date().setHours(0, 0, 0, 0);
    _this.totalDataLength = res.totalRecords;
    res.response.forEach((element) => {
      let checkIn = false;
      let mrn;
      let name;
      let mobileNO;
      let speciality = "";
      let packagelist ="";
      let patientType = element.patientType.code;
      let gender = "";
      let dob: any;
      let patientTypemulti;
      let statusMultiLang;
      let patientId;
      let isSelcted;
      let doctorId = "";
      let doctorName = "";
      let start = new Date(element.start).setHours(0, 0, 0, 0);
      let vipFlag = element.patient?element.patient.vip:false;     

     
      if (patientType == OPD_CONST.appoinmentRef.temp) {
        mrn = element.mrn;
        name = element.participant[0].actor.display;
        dob = _this.patientService.calculateAge(
          element.temporaryPatient ? element.temporaryPatient.birthDate : ""
        );
        (gender = element.temporaryPatient
          ? element.temporaryPatient.gender
            ? element.temporaryPatient.gender.display
            : ""
          : ""),
          (patientTypemulti = _this.langService.get("CommonAppoinment.TEMP"));
          mobileNO= element.temporaryPatient? element.temporaryPatient.mobileNumber:"";
      } else {
        patientId = element.patient.id;
        mrn = element.mrn;
        dob = _this.patientService.calculateAge(
          element.patient ? element.patient.birthDate : ""
        );
        name = element.participant[0].actor.display;
        (gender = element.patient ? ( element.patient.gender?element.patient.gender.display:"") : ""),
          (patientTypemulti = _this.langService.get("CommonAppoinment.REG"));
          mobileNO=element.patient?element.patient.telecom[1]?element.patient.telecom[1].value:"":"";
      }
      if (element.status == OPD_CONST.apppointmentListDisplay.booked && today === start) {
        checkIn = true;
      }
      if (element.businessStatus == OPD_CONST.apppointmentListDisplay.booked) {
        statusMultiLang = _this.langService.get("AppointmentListPage.booked");
        isSelcted = "booked";
      } else if (element.businessStatus == OPD_CONST.apppointmentListDisplay.arrived) {
        statusMultiLang = _this.langService.get("AppointmentListPage.arrived");
      } else if (element.businessStatus ==  OPD_CONST.apppointmentListDisplay.cancelled) {
        statusMultiLang = _this.langService.get(
          "AppointmentListPage.cancelled"
        );
      } else if (element.businessStatus == "NOSHOW") {
        statusMultiLang = "No Show"
      } else if (element.businessStatus == OPD_CONST.apppointmentListDisplay.rescheduled) {
        statusMultiLang = "Rescheduled"
      }
      else {
        statusMultiLang = _this.langService.get(
          "AppointmentListPage.cancelled"
        );
      }
      if (element.serviceCategory && element.serviceCategory.code == "1") {
        doctorId = element.participant[0].actor.reference;
        doctorName = element.participant[0].actor.display;
      }
      if (element.specialRequirements) {
        element.specialRequirements.forEach((res) => {
          speciality += res.display + " , ";
        });
      }
      if (element.packageList) {
        element.packageList.forEach((res) => {
          packagelist += res.display + " , ";
        });
      }

      const appointment:AppointmentModel = {
        patientId: patientId,
        doctorId: doctorId,
        doctorName: element.doctor ? element.doctor.display : "",
        specialty: element.specialty
          ? element.specialty[0].coding[0].display
          : "",
        mrnNumber: mrn,
        patientName: name,
        PatientGender:
          gender + " / " + dob.years + " " + dob.months + " " + dob.days,
        patientCode: element.participant[0].type[0].coding[0].code,
        createdDate: element.created,
        appointmentDate: element.start,
        endDate:element.epochEndDateTime,
        status: statusMultiLang,
        comment: element.remarks,
        app: element,
        patientType: patientTypemulti,
        patientMrn: mrn,
        checkInTime: element.checkInTime,
        checkIn: checkIn,
        bookingType: element.serviceCategory
          ? element.serviceCategory.display
          : "",
        bookingTypeCode: element.serviceCategory
          ? element.serviceCategory.code
          : "",
        isAppoinmentConfirmed: element.isAppoinmentConfirmed
          ? element.isAppoinmentConfirmed
          : false,

        company: element.company ? element.company.display : "",
        appNo: element.identifier[0].value,
        apptSelection: false,
        id: element.id,
        previousRemarks: element.internalRemark ? element.internalRemark : [],
        rescheduleRemarks: element.rescheduleRemarks
          ? element.rescheduleRemarks
          : "",
        cancelRemarks:element.remarks?element.remarks:"",
        rescheduleReason: element.reason ? element.reason[0].display : "",
        specialReq: speciality ? speciality.slice(0, -2) : "",
        specialRequirements: element.specialRequirements,
        package:packagelist?packagelist.slice(0,-2):"",
        mobileNo:mobileNO,
        selected: isSelcted,
        isVip:vipFlag,
      };

      _this.statusList["all"].push(appointment);
      if (element.status) {
        _this.statusList[element.status].push(appointment);
      }
    });
    return _this.statusList.all;
  }
}
