import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  public form: FormGroup;
  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.form = this.fb.group ({
      uname: [null , Validators.compose ( [ Validators.required ] )]
    });
  }

  /**
   * route to pre-login page
   */
  onSubmit() {
    this.router.navigate ( [ '/' ] );
  }
}
