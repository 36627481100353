import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-regsuccess-dialog",
  templateUrl: "./regsuccess-dialog.component.html",
  styleUrls: ["./regsuccess-dialog.component.scss"],
})
export class RegsuccessDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RegsuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   *close methos
   *
   * @param {*} confirm
   * @memberof RegsuccessDialogComponent
   */
  onNoClick(confirm): void {
    const _dataToMainComp = {
      workflow: confirm,
      data: "",
    };
    this.dialogRef.close(_dataToMainComp);
  }

  /**
   *encounter method
   *
   * @param {*} workflow
   * @param {*} data
   * @memberof RegsuccessDialogComponent
   */
  goForEncounter(workflow, data): void {
    const _dataToMainComp = {
      workflow: workflow,
      data: data,
    };
    this.dialogRef.close(_dataToMainComp);
  }
}
