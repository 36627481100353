import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { EnvoirnmentService } from "./../../services/envoirnment.service";
import { AppBaseService } from "../../services/http.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { authProperties } from "../../authentication/authentication-properties";
import localForage from "localforage";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { UserLoginModel, UserUnitList } from '../signin/signin.model';
import { MultiLanguageService } from '../../core/multi-language/multi-language.service';
import { MICROSERVICES } from "./../../constants/web-services";

@Component({
  selector: 'app-signin-uum',
  templateUrl: './signin-uum.component.html',
  styleUrls: ['./signin-uum.component.scss']
})
export class SigninUumComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  LoginBackgroundImage: string;
  hideRequiredControl = new FormControl(false);
  backgroundImage: string;
  public form: FormGroup;
  public password: string;
  public createOrforget: boolean;
  public sentornotfound: boolean;
  orgId: string = "";
  passcode: string;
  username: string = "";
  enterusername: string = "";
  firsttimepasscode: string = "";
  enterpassword: string = "";
  enterpasscode: string = "";
  emailid: string = "";
  enteremailID: string = "";
  emailnotfound: string = "";
  verificationemail: string = "";
  usernameToken: string = "";
  passwordToken: string = "";

  constructor(
    private env: EnvoirnmentService,
    private fb: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private baseservice: AppBaseService,
    private langService: MultiLanguageService
    ) { }

  ngOnInit() {
    // mapping place holder to ts
    this.username = authProperties.username;
    this.passcode = authProperties.passcode;
    this.enterusername = authProperties.enterusername;
    this.firsttimepasscode = authProperties.firsttimepasscode;
    this.enterpassword = authProperties.enterpassword;
    this.enterpasscode = authProperties.enterpasscode;
    this.emailid = authProperties.emailid;
    this.enteremailID = authProperties.enteremailID;
    this.emailnotfound = authProperties.emailnotfound;
    this.verificationemail = authProperties.verificationemail;
    this.backgroundImage = authProperties.backgroundImage;
    this.LoginBackgroundImage = this.env.loginImage;

    let isLoggedin = localStorage.getItem("isLoggedin");
    if (isLoggedin != null && isLoggedin == "true") {
      localStorage.removeItem("passcode");
      this.router.navigate(["Dashboard/dash"]);
    } else {
      localForage.config({
        driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: "localforage",
        version: 1.0,
        size: 9999999999, // Size of database, in bytes. WebSQL-only for now.
        storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
        description: "Medcare WebSQL Database"
      });
      localForage.clear();
      this.form = this.fb.group({
        uname: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])]
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
  
  /**
   * To vallidate user
   */
  loginMethodUserName() {
    try {
      if (this.usernameToken && this.passwordToken) {
        let loginData = {
          username: this.usernameToken ? this.usernameToken : "",
          password: this.passwordToken ? this.passwordToken : ""
        }
        this.baseservice.setResourceURL(MICROSERVICES.ID_SERVICE);
        this.baseservice
          .postResource("users/validateAdUser", loginData)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (res) => {
              if(res) {
                this.getLoginData();
              } else {
                this.snackBar.open("Warning", "User Name and Password do not Match", { duration: 3000 });
              }
            },
            error : (err) => {
              this.invalidLogin();
            }
          });
      }
    } catch (ex) {
      this.invalidLogin();
    }
  }

  /**
   * To call loign information
   */
  getLoginData() {
    this.baseservice.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.baseservice
      .postResource("users/adUser", {username: this.usernameToken})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next : (data: UserLoginModel) => {
          if (data) {
            localStorage.setItem("userId", btoa(data.userId));
            localStorage.setItem("userName", btoa(data.userName));
            localStorage.setItem("orgId", btoa(data.orgId));

            let unit: UserUnitList = data.userUnitList.find(
              i => i.isDefault == true
            );
            if (unit) {
              localStorage.setItem("unitId", btoa(unit.unitId));
              localStorage.setItem("unitCode", btoa(unit.unitCode));
              localStorage.setItem("orgCode", btoa(unit.orgCode));

              this.getRoleInfo(data, unit);
            } else {
              this.snackBar.open(
                "Validation",
                "User does not have any default Unit",
                {
                  duration: 3000
                }
              );
            }
          }
        },
        error: (err) => {
          this.invalidLogin();
        }
      });
  }

  /**
   * 
   * @param data 
   * @param unit 
   */
  getRoleInfo(data, unit){
    data["userUnitList"] = data.userUnitList;
    data["roleNotificationMappers"] = null;
    data["userUnitRole"] = null;
    data["userUnit"] = null;
    data["orgCode"] = unit.orgCode;
    localForage.setItem("unit", unit);
    localForage.setItem("role", {
      id: unit.userRole.roleId,
      roleName: unit.userRole.roleDescription,
      roleCode: unit.userRole.roleCode
    });
    localStorage.setItem("roleName", btoa(unit.userRole.roleDescription));
    localForage.setItem("auth-data", data, (err) => {
      localStorage.setItem("lan", "en");
      localStorage.setItem("passcode", "true");
      localStorage.setItem("isLoggedin", "true");
      this.router.navigate(["Dashboard/dash"]);
    });
  }
  
  invalidLogin() {
    this.resetPin();
  }

  resetPin() {
    this.usernameToken = "";
    this.passwordToken = "";
  }
}
