import { depositSaveSchema } from "./deposit-bject-schema";

export class DepositService {
  constructor() {}

  depsoitSaveObject: depositSaveSchema;
  genericReturn: {
    depositAmount: number;
    refundAmount: number;
    consumeAmount: number;
    balanceAmount: number;
    depositList: any[];
  };

  getDepositGeneric(res): any {
    var depositAmount = 0;
    var refundAmount = 0;
    var consumeAmount = 0;
    var balanceAmount = 0;
    var depositList: any[] = [];

    if (res.length > 0) {
      res.forEach(element => {
        depositList.push({
          id: element.id,
          depositno: element.depositNo,
          depositdate: element.date,
          patientName: element.patientName,
          patientId: element.patient.MongoId,
          against: element.against,
          encountertype: element.visitType,
          encounterno: element.againstAccountNo,
          paymentmode: element.paymentMode,
          depositamt: element.depositAmt,
          consumeamt: element.consumedDeposit,
          refundamt: element.refundAmount,
          balance: element.availableDeposit,
          collectedby: element.createdBy,
          payments: element.listDepositDetails,
          checked: false,
          consumedAmount: 0,
          data: element
        });
        depositAmount += element.depositAmt;
        refundAmount += element.refundAmount;
        consumeAmount += element.consumedDeposit;
        balanceAmount += element.availableDeposit;
      });

      return (this.genericReturn = {
        depositAmount: depositAmount,
        refundAmount: refundAmount,
        consumeAmount: consumeAmount,
        balanceAmount: balanceAmount,
        depositList: depositList
      });
    }
  }

  getDepostGenericSchema(
    DepostCase: string,
    depositTypeCode?: any,
    payerobject?: any,
    accountObject?: any,
    amount?: string,
    remarks?: string,
    payments?: any[],
    patient?: any
  ) {

    var DepostCase: string = DepostCase;
    switch (DepostCase) {
      case "COMPANY": {
        this.depositServiceLogic(
          "COMPANY",
          depositTypeCode,
          payerobject,
          "",
          amount,
          payments,
          patient
        );
        break;
      }
      case "PATIENT": {
        this.depositServiceLogic(
          "PATIENT",
          depositTypeCode,
          payerobject,
          "",
          amount,
          payments,
          patient
        );
        break;
      }
      case "ACCOUNT": {
        this.depositServiceLogic(
          DepostCase,
          depositTypeCode,
          payerobject,
          accountObject,
          amount,
          payments,
          patient
        );
        break;
      }
      case "RESERVATION": {
        this.depositServiceLogic(
          DepostCase,
          depositTypeCode,
          payerobject,
          accountObject,
          amount,
          payments,
          patient
        );
        break;
      }
      case "ADMISSION": {
        this.depositServiceLogic(
          DepostCase,
          depositTypeCode,
          payerobject,
          accountObject,
          amount,
          payments,
          patient
        );
        break;
      }
      default: {
        console.log("Invalid choice");
        break;
      }
    }

    return this.depsoitSaveObject;
  }

  depositServiceLogic(
    against,
    depositTypeCode,
    payerObj,
    accountObject,
    amount,
    result,
    patient
  ) {
    var listDepositDetails: any[] = [];

    result.payments.forEach(element => {
      const payment = {
        paymentModeId: element.paymentmode.id,
        amount: element.amount,
        cardTypeId: element.cardtype.id,
        cardNo: "",
        cardHolderName: "",
        expireDate: "",
        chequeNo: "",
        bankId: element.bank.id,
        chequeDate: "",
        transferTypeId: "",
        availableDeposit: amount,
        consumeDeposit: "0",
        refundDeposite: "0",
        indentificationNo: result.indentificationNumber,
        currencyId: element.currency.id,
        paymentdetails: "",
        createdBy: "this.userName",
        bankname: element.bank.desc,
        cardtype: element.cardtype ? element.cardtype.desc : "",
        currency: element.currency ? element.currency.desc : "",
        number: element.number ? element.number : "",
        paymentmode: element.paymentmode.desc,
        totalamount: element.amount,
        transactiontype: element.transactiontype
          ? element.transactiontype.desc
          : ""
      };
      payment.paymentdetails = JSON.stringify({
        paymentmode: element.paymentmode.desc,
        bankname: element.bank.desc,
        number: element.number ? element.number : "",
        totalamount: element.amount,
        cardtype: element.cardtype ? element.cardtype.desc : "",
        transactiontype: element.transactiontype
          ? element.transactiontype.desc
          : "",
        currency: element.currency ? element.currency.desc : "",
        date: new Date(element.date)
      });
      listDepositDetails.push(payment);
    });

    this.depsoitSaveObject = {
      payerCode: payerObj.code,
      payerDesc: payerObj.desc,
      against: against,
      depositAmt: amount,
      depositTypeCode: depositTypeCode ? depositTypeCode : "",
      accountTypeCode: accountObject ? accountObject.accountTypeCode : null,
      patientId: patient.patientId ? patient.patientId : patient.MongoId,
      mrn: patient.mrn ? patient.mrn : patient.MRN,
      accountType: accountObject.accountType,
      againstAccountId: accountObject.againstAccountId,
      againstAccountNo: accountObject.againstAccountNo,
      listDepositDetails: listDepositDetails
    };
  }
}
