<div class="centerBtn">
    <button
      mat-button
      class="btnBlue"
      [matMenuTriggerFor]="menu"
      data-automation-attribute="btn-save"
      style="margin-right: 10px"
    >
      <i class="material-icons"> print </i>
      Export
    </button>
  
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        style="line-height: 30px !important; height: 30px !important"
        *ngFor="let type of exportTypes"
        (click)="exportfile(type)"
      >
        {{ type.desc }}
      </button>
    </mat-menu>
  </div>
  <div class="center" id="pdfContainer" style="height: 75vh"></div>
  