import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-quick-links",
  templateUrl: "./quick-links.component.html",
  styleUrls: ["./quick-links.component.scss"]
})
export class QuickLinksComponent implements OnInit {
  quickLinkList: any[] = [
    {
      title: "Registration",
      icon: "supervised_user_circle"
    },
    {
      title: "Encounter",
      icon: "event"
    },
    {
      title: "Patient Blocking",
      icon: "monetization_on"
    }
  ];

  quickLinkList2: any[] = [
    {
      title: "Bill List",
      icon: "alarm"
    },
    {
      title: "Deposit",
      icon: "assessment"
    },
    {
      title: "OP Billing",
      icon: "card_travel"
    }
  ];

  quickLinkList3: any[] = [
    {
      title: "Bill Settlement",
      icon: "credit_card"
    }
  ];

  constructor() {}

  ngOnInit() {}
}
