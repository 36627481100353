import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  AccumulationTheme,
  IAccLoadedEventArgs,
} from "@syncfusion/ej2-angular-charts";
import { Browser } from "@syncfusion/ej2-base";
import { Subject, Subscription } from "rxjs";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PieChartComponent implements OnInit, OnDestroy {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  @Input() isMaximize;
  //Initializing Legend
  public legendSettings: Object = {
    visible: false,
  };
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: true,
    position: "Outside",
    name: "type",
    font: {
      fontWeight: "600",
    },
    connectorStyle: { length: "20px", type: "Curve" },
  };
  idName: any;
  dataSource: any[];

  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, "Dark")
        .replace(/contrast/i, "Contrast")
    );
  }
  // custom code end
  public startAngle: number = Browser.isDevice ? 55 : 35;
  public radius: string = Browser.isDevice ? "40%" : "70%";
  public explode: boolean = true;
  public enableAnimation: boolean = true;
  public tooltip: Object = {
    enable: true,
    format: "<b>${point.type}</b><br>Count: <b>${point.count}</b>",
    header: "",
  };

  public title: string = "Specaiality";
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let pieChart = document.getElementById(
      "pieChart" + this.data.id
    ) as HTMLElement;
    pieChart.style.height = card.offsetHeight - 28 + "px";
    pieChart.style.width = card.offsetWidth + "px";
  }
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            this.idName = this.data.id;
            if (obj.data && obj.data.length) {
              obj.data.map((element) => {
                arr.push({
                  type: element[this.data.dashboardEntity],
                  count: element.count_data,
                });
              });
            }
            this.dataSource = arr;
          }
        }
      })
    );
    let card = document.getElementById(this.data.id);
    let pieChart = document.getElementById("pieChart") as HTMLElement;
    pieChart.id = "pieChart" + this.data.id;
    pieChart.style.height = card.offsetHeight - 28 + "px";
    pieChart.style.width = card.offsetWidth + "px";
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
