<div class="modal-header modalHeaderBlack">
    <h6 class="modal-title">Export</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()" data-automation-attribute="span-close-dialog">
      <mat-icon matTooltip="Close">clear</mat-icon>
    </span>
</div>
  <div >
    <mat-form-field
      class="fieldBox"
      style="padding-bottom: 250px; padding-left: 15px; padding: 20px;"
      appearance="outline"
    >
      <mat-label>Format</mat-label>
      <mat-select placeholder="Format"   (selectionChange)="onexport($event.value)">
        <mat-option
        *ngFor="let exportType of exportTypes"
        [value]="exportType.id"
      >
        {{ exportType.desc }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-icon  matTooltip="Download" (click)="exportfile()"
      >file_download</mat-icon
    >&nbsp;&nbsp;<mat-icon (click)="onNoClick()" matTooltip="Close">clear</mat-icon>
  </div>
