<div
  class="example-container heightVh80" style="background-color: white; margin: 20px;"
  data-automation-attribute="div-worklist"
>
  <mat-table
    #table
    [dataSource]="dataSource"
    matSort
    class="heightVh75 overflowAuto"
    data-automation-attribute="table-worklist"
  >
    <ng-container matColumnDef="patientDetails">
      <mat-header-cell
        id="t1"
        value="Download dinamically generated text file"
        *matHeaderCellDef
        mat-sort-header
        class="headerMrn"
      >
        &nbsp;&nbsp; MRN
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="headerMrn"
        >&nbsp;

        {{ element.mrn }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="mrn">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="headerPatientName"
      >
        Patient
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="headerPatientName">
        {{ element.patientName }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="gender">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerAge">
        Age/Gender
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="headerAge"
        >{{ element.age }}/ {{ element.gender }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="identificationType">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerIdtype">
        Identification Type</mat-header-cell
      >
      <mat-cell *matCellDef="let element" [attr.colspan]="2" class="headerIdno">
        {{ element.identificationType }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="IdentificationNo.">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerIdtype">
        Identification No</mat-header-cell
      >
      <mat-cell *matCellDef="let element" [attr.colspan]="2" class="headerIdno">
        {{ element.identificationNo }}
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="Location">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerIdtype">
        Location</mat-header-cell
      >
      <mat-cell *matCellDef="let element" [attr.colspan]="2" class="headerIdno">
        {{ element.location }}
      </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerPriority">
        Priority</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        [attr.colspan]="2"
        class="headerPriority"
      >
        <span
          class="span-dot"
          style="background-color: rgb(36, 36, 177)"
          matTooltip="Routine"
          *ngIf="element.priority.routine > 0"
          >{{ element.priority.routine }}</span
        >
        <span
          class="span-dot"
          style="background-color: rgb(255, 165, 0)"
          matTooltip="Urgent"
          *ngIf="element.priority.urgent > 0"
          >{{ element.priority.urgent }}</span
        >
        <span
          class="span-dot"
          style="background-color: rgb(214, 14, 0)"
          matTooltip="Stat"
          *ngIf="element.priority.stat > 0"
          >{{ element.priority.stat }}</span
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="headerStatus">
        Status</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        [attr.colspan]="2"
        class="headerStatus"
      >
        <span
          class="span-dot"
          [style.backgroundColor]="element.collectedC.color"
          *ngIf="element.collectedC.count > 0"
          matTooltip="Collected"
          >{{ element.collectedC.count }}</span
        >
        <span
          class="span-dot"
          [style.backgroundColor]="element.rejectedC.color"
          *ngIf="element.rejectedC.count > 0"
          matTooltip="Rejected"
          >{{ element.rejectedC.count }}</span
        >
        <span
          class="span-dot"
          [style.backgroundColor]="element.holdC.color"
          *ngIf="element.holdC.count > 0"
          matTooltip="Hold"
          >{{ element.holdC.count }}</span
        >
        <span
          class="span-dot"
          [style.backgroundColor]="element.otherStatus.color"
          *ngIf="element.otherStatus.count > 0"
          matTooltip="Recollect"
          >{{ element.otherStatus.count }}</span
        >
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      class="tableHeader"
      data-automation-attribute="header-worklist"
    ></mat-header-row>

    <mat-row
      *matRowDef="let row; let i = index; columns: displayedColumns"
      matRipple
      class="element-row tableRow"
      [cdkDetailRow]="row"
      [cdkDetailRowTpl]="tpl"
      data-automation-attribute="row-worklist"
    >
    </mat-row>
  </mat-table>
  <div
    class="center no-records-message"
    *ngIf="dataSource.data.length == 0 && isDisplay == true"
  >
    No records found
  </div>
  <div style="background-color: white;display: flex; justify-content: flex-end;margin-top: 5px; cursor: pointer">
  <img title="Print and Submit" alt="" src="./assets/images/lab-icons/accept.png"  (click)="save()">
</div>
  <!-- <div style="display: flex; justify-content: flex-end;margin-top: 5px;">
    
    <div class="example-button-container">
    <img title="Print and Submit" alt="" src="./assets/images/lab-icons/accept.png"  (click)="save()">
    </div>
 
  </div> -->
</div>
<ng-template #tpl let-element>
    <table id="customers" data-automation-attribute="table-test-details">
      <tr style="font-family: 'Roboto', sans-serif">
        <th class="headContainer col-width-md" style="width: 3%"></th>
        <th class="headTestCode col-width-lg" style="width: 10%">
          Test &nbsp; &nbsp;
        </th>
        <th class="headSample col-width-lg" style="width: 6%">Sample</th>
        <th class="headVolume col-width-lg" style="width: 5%">Volume</th>
        <th class="headContainer col-width-md" style="width: 5%">Container</th>
        <th class="headSample col-width-sm" style="width: 8%">Panel</th>
        <th class="headOrderedBy col-width-md" style="width: 8%">Doctor</th>
        <th class="headOrderedDate col-width-md" style="width: 8%">
          Schedule Date
        </th>
        <th class="headOrderedDate col-width-md" style="width: 8%">
          Ordered Date
        </th>
        <th class="headPriority col-width-md" style="width: 5%">Location</th>
        <th class="headinfo text-center col-width-sm" style="width: 3%">Info</th>
        <th class="headPriority col-width-md" style="width: 6%">Priority</th>
        <th
          class="headStatus col-width-sm"
          style="width: 11%"
          *ngIf="activeTabIndex == 1"
        >
          Convert To
        </th>
        <th
          class="headStatus col-width-sm"
          style="width: 7%"
          *ngIf="activeTabIndex == 0"
        >
          Status
        </th>
      </tr>
    </table>
  
    <mat-accordion
      data-automation-attribute="accordion-test-details"
      class="accordion-test-details"
      *ngFor="let sample of element.listSampleDetailsGroup"
    >
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header
          class="headerGrey"
          [collapsedHeight]="'34px'"
          [expandedHeight]="'34px'"
        >
          <mat-panel-title>
            <mat-checkbox
              style="margin-left: 5px"
              [(ngModel)]="sample[0].departmentflag"
              [checked]="sample[0].departmentflag"
              (change)="
                selectdepart(
                  sample[0].subDepartmentMaster,
                  element.mrn,
                  $event,
                  sample[0].businessStatus.desc,
                  sample[0].billingStatus.desc
                )
              "
              (click)="$event.stopPropagation()"
              class="chkData"
            ></mat-checkbox>
            &nbsp;<b style="margin-left: 5px">{{
              sample[0].subDepartmentMaster
            }}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <div
          class="mat-row detail-row mat-expanded-row"
          [@detailExpand]
          style="overflow: hidden"
          data-automation-attribute="div-expanded-test"
        >
          <table
            id="patientDetailsExp"
            data-automation-attribute="table-test-details"
          >
            <tr *ngFor="let data of sample">
              <td class="expSample align-middle col-width-sm" style="width: 3%">
                <mat-checkbox
                  [(ngModel)]="data.isPselect"
                  [checked]="data.isPselect"
                  (click)="$event.stopPropagation()"
                  (change)="
                    selectprocedureRequest(
                      sample[0].subDepartmentMaster,
                      data.id,
                      element.mrn,
                      $event,
                      data.businessStatus.desc,
                      data.visitId,
                      data.serviceId,
                      data.billingStatus.desc
                    )
                  "
                  class="chkData"
                  data-automation-attribute="chk-testcode"
                ></mat-checkbox>
              </td>
              <td
                *ngIf="activeTabIndex == 1"
                class="expSample align-middle col-width-sm"
                style="width: 12%"
              >
                {{ data.visitType }}
              </td>
              <td
                class="expTestCode align-middle col-width-lg"
                style="width: 10%; overflow: hidden"
                matTooltip="{{ data.testDesc }}"
              >
                {{ data.testDesc }}
              </td>
              <td class="expTestCode align-middle col-width-lg" style="width: 6%">
                {{ data.sample }}
              </td>
              <td class="expTestCode align-middle col-width-lg" style="width: 5%">
                {{ data.volume ? data.volume + " ml" : "" }}
              </td>
              <td
                class="expContainer col-width-md cont-width"
                style="width: 5%"
                [ngStyle]="{ 'background-color': data.containerColorCode }"
              >
                {{ data.containerCode }}
              </td>
              <td class="expSample align-middle col-width-sm" style="width: 8%">
                {{ data.profile ? data.profile : "NA" }}
              </td>
              <td
                class="expOrderedDate align-middle col-width-md"
                style="width: 8%"
              >
                {{ data.doctor }}
              </td>
              <td class="expOrderedDate col-width-md" style="width: 8%">
                &nbsp;&nbsp;
                {{
                  data.scheduleDate === 0 || data.scheduleDate === null
                    ? ""
                    : (this.patientService.epochToDateConverter(
                        data.scheduleDate * 1000
                      ) | date : "dd/MM/yyyy h:mm a")
                }}
              </td>
  
              <td class="expOrderedDate col-width-md" style="width: 8%">
                &nbsp;&nbsp; {{ data.createdDate | date : "dd/MM/yyyy h:mm a" }}
              </td>
              <td class="expSample align-middle col-width-sm" style="width: 5%">
                {{ data.location ? data.location : "NA" }}
              </td>
              <td class="info center col-width-sm" style="width: 3%">
                <i
                  class="material-icons md-18 lightblue align-bottom"
                  [matTooltip]="data.info"
                >
                  info
                </i>
              </td>
  
              <td
                class="expPriority align-middle col-width-md"
                style="width: 6%; color: white"
              >
                <div
                  class="chip-worklist {{ data.priority }}"
                  *ngIf="data.priority == 'ROUTINE' || data.priority == 'Routine'"
                >
                  <span
                    class="span-dot-lab"
                    style="background-color: rgb(36, 36, 177)"
                    >R</span
                  >
                </div>
                <div
                  class="chip-worklist {{ data.priority }}"
                  *ngIf="data.priority == 'URGENT' || data.priority == 'Urgent'"
                >
                  <span
                    class="span-dot-lab"
                    style="background-color: rgb(36, 177, 36)"
                    >U</span
                  >
                </div>
                <div
                  class="chip-worklist {{ data.priority }}"
                  *ngIf="data.priority == 'STAT' || data.priority == 'Stat'"
                >
                  <span
                    class="span-dot-lab"
                    style="background-color: rgb(229, 20, 20)"
                    >S</span
                  >
                </div>
              </td>
            
              <td
                class="expOrderedDate align-middle col-width-sm"
                style="width: 7%; margin-left: 10px"
                *ngIf="activeTabIndex == 0"
              >
                <span
                  style="
                    display: inline-block;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 4%;
                  "
                  [style.backgroundColor]="data.businessStatus.colourCode"
                >
                  <b>{{ data.businessStatus.desc }}</b>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-template>
