<nav class="navbar custom-navbar main-brand">
  <div class="image-align">
    <img  *ngIf="userType=='HIS'"
      src="assets/images/Medcare-Logo.png"
      class="navbar-brand-logo lg"
      alt="Medcare Logo"
    />
    <img  *ngIf="userType=='LITE'"
    src="assets/images/mykarelites.png"
    class="navbar-brand-logo lg"
    alt="Medcare Logo"
  />
    <img *ngIf="userType=='CL'"
    src="assets/images/myklinik.png"
    class="navbar-brand-logo lg"
    alt="Medcare Logo"
  />
    <img
      src="assets/images/Medicare-Logo-Sm.png"
      class="navbar-brand-logo-sm sm"
      alt="Medcare Logo"
    />
  </div>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleSidebar.emit()">
        <i class="hamburger-icon v2" *ngIf="mode === 'over'">
          <span></span>
        </i>
      </a>
    </li>
  </ul>
</nav>

<app-menu></app-menu>
