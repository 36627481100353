import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Configuration} from "../../../../../shared/Configuration";
import {AuthService} from  "../../../../../services/auth.service";
import {AllPushNotificationsService} from "../../../service/all-push-notifications.service";

/**
 *
 */
@Component({
  selector: 'app-show-all-push-notification',
  templateUrl: './show-all-push-notification.component.html',
  styleUrls: ['./show-all-push-notification.component.scss']
})
export class ShowAllPushNotificationComponent implements OnInit {

  /**
   *
   */
  public allNotificationList: any[] = [];

  /**
   *
   */
  public pagination:any = {
    total: 0,
    limit: 10,
    page: "1",
    pages: 1
  };

  /**
   * Paginator Setting
   */
  public paginatorOptions:Array<number>;

  /**
   *
   */
  public userInfo: any = null;

  /**
   *
   */
  public unitInfo: any = null;

  /**
   *
   * @param dialogRef
   * @param allPushNotificationsService
   * @param pushNotificationService
   * @param authService
   */
  constructor(
    public dialogRef: MatDialogRef<ShowAllPushNotificationComponent>,
    private allPushNotificationsService: AllPushNotificationsService,
    private authService: AuthService,
  ) {
    this.paginatorOptions = Configuration.PAGINATOR_OPTIONS;
  }

  /**
   *
   */
  ngOnInit() {
    Promise.all([this.authService.getUser(), this.authService.getUnit()])
      .then((values: any) => {
      this.userInfo = values[0];
      this.unitInfo = values[1];
      this.loadPushNotification();
    });
  }

  /**
   *
   */
  private loadPushNotification() {
    // this.allPushNotificationsService
    //   .getAllNotification(
    //     this.pagination.page,
    //     this.pagination.limit,
    //     this.userInfo.userId,
    //     this.userInfo.orgCode,
    //     this.unitInfo.unitCode,
    //   )
    //   .subscribe(res => {
    //     if (res.statusCode === 200) {
    //       this.allNotificationList = res.data.docs;
    //       this.pagination.total = res.data.total;
    //     } else {
    //       this.allNotificationList = [];
    //     }
    //   });
  }

  /**
   *
   * @param notification
   */
  public rowClicked(notification) {
    // if (notification.status === 'unread') {
    //   notification.status = 'read';
    //   this.pushNotificationService.markPushNotificationRead(
    //     notification.notificationId,
    //     this.userInfo.userId
    //   );
    // }
  }

  /**
   *
   */
  onNoClick() {
    this.dialogRef.close({
      triageOperation: false
    });
  }

  /**
   *
   * @param notification
   */
  public createdAtTime(notification) {
    return new Date(notification.createdAt).valueOf() ;
  }

  /**
   *
   * @param event
   */
  public nextPage(event) {
    this.pagination.limit = event.pageSize;
    this.pagination.page = event.pageIndex + 1;

    this.loadPushNotification();
  }
}
