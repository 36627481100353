<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>

<div class="d-flex align-items-stretch min-height-100 h-100 d-none bg-cover bg-1" [ngStyle]="{
    background: 'url(./assets/images/' + LoginBackgroundImage + ')'
  }" data-automation-attribute="div-hospital-backImg">
  <div class="col-lg-9 col-md-7 col-sm-12 pt-3">
    <img src="assets/images/medcare-logo-white.png" class="logo-login medcare-logo" alt="Medcare Logo" />
  </div>
  <div class="col-lg-3 col-md-5 col-sm-12 pt-3 divBakcgroundGradient">
    <img src="assets/images/sdglobal-logo.png" class="logo-login pull-right" alt="SD Global Logo" />
  </div>
</div>

<!-- if no background image is present-->
<!-- <div class="d-flex align-items-stretch min-height-100 h-100 d-none bg-cover bg-none" *ngIf="!backgroundImage"
  data-automation-attribute="div-default-backImg">
  <div class="col-lg-9 col-md-7 col-sm-12 pt-3">
    <img src="assets/images/Medcare-Logo.png" class="logo-login medcare-logo" alt="Medcare Logo" />
  </div>
  <div class="col-lg-3 col-md-5 col-sm-12 pt-3">
    <img src="assets/images/sdglobal-logo.png" class="logo-login pull-right" alt="SD Global Logo" />
  </div>
</div> -->

<div class="signForm" data-automation-attribute="div-signin-form">
  <div class="col-lg-12 col-md-12 m-0 divMainSection">
    <div class="divContainerRight" data-automation-attribute="div-passcode" style="margin-left: 10%;">
      <div class="divSection">
        <span class="headingSignin">Sign In</span>
        <div class="my-5">
          <mat-grid-list cols="12" rowHeight="60px">
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="legacy" floatLabel="never"
                [hideRequiredMarker]="hideRequiredControl.value">
                <!-- <mat-label>Username</mat-label> -->
                <input matInput [(ngModel)]="usernameToken" type="text" placeholder="Username" />
                <mat-icon matPrefix>person</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="12" rowHeight="60px">
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="legacy" floatLabel="never"
                [hideRequiredMarker]="hideRequiredControl.value">
                <!-- <mat-label>Password</mat-label> -->
                <input matInput [(ngModel)]="passwordToken" (keyup.enter)="loginMethodUserName()" type="password" placeholder="Password" />
                <mat-icon matPrefix>lock</mat-icon>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="12" rowHeight="40px">
            <mat-grid-tile [colspan]="12" class="gridRadioRememberMe">
              <mat-radio-button>Remember Me</mat-radio-button>
            </mat-grid-tile>
          </mat-grid-list>

        </div>
        <table class="tablePasscodeActions" data-automation-attribute="table-passcode-actions">
          <tr>
            <td class="btnCreatePasscode">
              <button (click)="loginMethodUserName()" mat-flat-button data-automation-attribute="button-create-passcode">
                Login
              </button>
            </td>
            <td class="blankTD">&nbsp;</td>
            <td class="btnForgetPasscode">
              <button mat-flat-button data-automation-attribute="button-forget-passcode">
                Forgot Passcode?
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="footerDiv">
  <div class="col-lg-12 col-md-12 m-0">
    <span class="footer">Copyright &copy;2020 sdglobaltech.com | All Rights Reserved</span>
  </div>
</div>