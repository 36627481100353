<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">Confirm</h6>
      <span aria-label="Close" class="close" (click)="buttonClick()">
        <mat-icon>clear</mat-icon>
      </span>
    </div>
    <div class="modal-body bodyCreatePasscode">
      <p>Do you want to cancel charges?</p>
      <div style="padding-top: 10px;">
          <mat-select placeholder="Select Reason:" [(ngModel)]="remarkValue" 
          name="reason" (selectionChange)="getRemark()" required>
              <mat-option *ngFor="let reason of reasons" [value]="reason.desc">
                {{reason.desc}}
              </mat-option>
          </mat-select>
      </div>
      <div class="buttonalignment" *ngIf="isOther" style="width: 118%;">
        <mat-form-field class="example-full-width">
            <textarea [(ngModel)]="comments" [ngModelOptions]="{standalone: true}" matInput placeholder="Leave a comment"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button mat-mini-fab class="btnCyan" type="submit" data-automation-attribute="button-save-dialog">
          <mat-icon matTooltip="Yes" (click)="SaveRemarks('yes')">done</mat-icon>
        </button>
        <button mat-mini-fab class="btnRed" type="submit" data-automation-attribute="button-cancel-dialog">
          <mat-icon matTooltip="No" (click)="onNoClick()">close</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>