<div
  class="divNotification {{getStatusClass()}} px-3 py-2 my-1"
>
  <div class="flex"   (click)="clickPushNotification()">
    <img src="assets/images/{{notification.body.icon}}" class="pr-2">
    <p class="content-notifcation width100">
      <b>{{notification.body.title}}</b><br>
      <span
        class="spanNotificationMsg"
        [innerHTML]='notification.body.message'
      >
      </span>
    </p>
  </div>
  <app-push-notification-button
    [notification]="notification"
  ></app-push-notification-button>
</div>
