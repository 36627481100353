export const opdEnvironment = {
  idExistWarning: "This ID already exist in system",
  primaryID: "Identification Type",
  primaryDocNo: "Doc No.",
  expiryDate: "Expiry Date",
  secondaryId: "Secondary ID Type",
  secondaryDocNo: "Secondary Doc No.",
  prefix: "Prefix",
  name: "Full name",
  dobForm: "Birth Date",
  age: "Age",
  phone: "Phone Number",
  gender: "Gender",
  race: "Race",
  nationality: "Nationality",
  maritalStatus: "Marital Status",
  occupation: "Occupation",
  patientCategory: "Patient Category",
  religion: "Religion",
  city: "City",
  country: "Country",
  address: "Address",
  state: "State",
  zipcode: "Pin code",
  localAddress: "Local Address",
  permanentAddress: "Permanent Address",
  saveButton: "Save Changes",
  nextButton: "Next",
  FutureAgeMessage: "Future year is not allowed as Date of Birth",
  searchBar: "Search",
  patientSearchError: "Something went Wrong..Try a different Search",
  mrn: "MRN",
  patientSearchName: "Name",
  identificationId: "Identification Id",
  dateTimeFormat: "dd-MMM-yyyy hh:mm a",
  dateFormat: "dd-MMM-yyyy",
  timeFormat: "HH:mm",
  successHeader: "Success",
  successMessage: "Record Saved Successfully",
  confirmTariff: "Do you want to save the Encounter for Patient with ",
  serviceURL: {
    appointment: "appointment",
    registration: "",
    encounter: ""
  },
  placeholder: {
    fromDate: "Select From Date",
    toDate: "Select To Date",
    billFromTo: "Bill:From-To",
    doctorName: "Enter Doctor Name",
    patientMRN: "Enter Patient MRN",
    mrn: "Patient MRN",
    employeeName: "Employee Name",
    dateOfBirth: "Date of Birth",
    gender: "Gender",
    mobileNumber: "Mobile",
    identificationType: "Identification Type",
    identificationID: "Identification Id",
    unit: "Unit",
    country: "Country",
    state: "State",
    city: "City",
    area: "Area",
    pincode: "Pin code",
    registrationFormDate: "Registration From",
    registrationToDate: "Registration To",
    employeeid: "Employee Id",
    employeeId: "Employee Id",
    patientName: "Patient Name",
    doctor: "Doctor",
    doctorIdL: "Doctor Id",
    encounterNo: "Encounter No",
    department: "Department",
    speciality: "Speciality",
    primaryDocNo: "Document Number",
    status: "Status",
    nursingStation: "Nursing Station",
    registrationFromTo: "From Date - To Date",
    Speciality: "Speciality",
    Doctor: "Doctor",
    BedCategory: "Bed Category",
    admissionDate: "Admission From - To",
    dischargeDate: "Discharge From - To",
    Ward: "Ward",
    admissionNumber: "Admission Number",
    Status: "Status",
    dischargeFrDate:"Discharge From",
    dischargeToDate:"DIscharge To",

  }
};

export const payerTypes = [
  "Self",
  "Insurance",
  "Doctors"
]
export const ER_REPORT_TYPE = {
  pdf: "pdf",
  csv: "csv",
  xlsx: "xlsx",
};