<div class="divActions flex width100" *ngIf="notification.isButton">  
  <!-- *ngFor="let buttonAction of notification.buttonAction;" -->
  <!-- class="{{buttonColor(buttonAction)}} mx-1 mat-flat-button" -->
  &nbsp; &nbsp; &nbsp;
  <button
    mat-flat-button
    class="btnCyan mx-1 mat-flat-button"
    (click)="buttonClick(notification.buttonUrl)"
  >
    {{notification.buttonLabel}}
  </button>
</div>
