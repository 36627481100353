import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  @ViewChild(NgxScannerQrcodeComponent) scanner: NgxScannerQrcodeComponent;

  public mrn: string = "";
  private type: string = "";
  public qrOutput: string = "";

  constructor(
    public dialogRef: MatDialogRef<BarcodeScannerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: BarcodeScannerComponent,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if(this.dialogData.type && this.dialogData.type == "SEARCH") {
    } else {
      this.mrn = this.dialogData.mrn ? this.dialogData.mrn : "";
    }
  }

  ngAfterViewInit(): void {
    this.scanner.start();
  }

  ngOnDestroy(): void {
    if(this.scanner) this.scanner.stop();
  }

  onClose(close?: boolean): void {
    if(this.scanner) this.scanner.stop();

    if(close) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.qrOutput);
    }
  }

  mrnValid(): boolean {
    let isValid: boolean = false;
    if(this.qrOutput == this.mrn) isValid = true;
    else {
      this.snackBar.open("Warning", "QR Does not match Patient to be " + this.type, {duration: 3000});
    }

    return isValid;
  }

  onError(error: any) {
    console.error(error);
  }

  onCameraStart(action) {
    if(!this.scanner) this.scanner = action;
    action.start();
  }

  onBarcodeScanned(action, $event): void {
    this.qrOutput = $event? $event[0].value : null;
    this.onClose();
  }
}

