export class RegisterModel {
  placeholder?: string;
  MongoId?: string;
  MRN?: string;
  NRIC?: string;
  primaryId?: string;
  primaryCode?: string;
  secondaryId?: string;
  seondaryCode?: string;
  primaryIDdesc?: string;
  passport?: string;
  expirDate?: any;
  firstName?: string;
  nickName?: string;
  middleName?: string;
  lastName?: string;
  otherName?: string;
  dob?: any;
  phoneNumber?: string;
  emailId?: string;
  docID?: string;
  prefix?: string;
  gender?: string;
  race?: string;
  nation?: string;
  pcat?: string;
  identification?: string;
  martial?: string;
  occupation?: string;
  photo?: string;
  relegion?: string;
  address?: string;
  country?: string;
  state?: string;
  district?: string;
  pincode?: string;
  phonenumberL?: string;
  faculty?: string;
  addressP?: string;
  countryP?: string;
  stateP?: string;
  cityP?: string;
  test?: any;
  pinccodeP?: string;
  phonenumberP?: string;
  age?: any;
  regType?: any;
  regTypeId?: any;
  religion?: any;
  admissionInfo?: any;
  contactRelation?: string;
  displayAge?: string;
  blockStatus?: any;
  isBlocked?: any;
  isCTOS?: any;
  reason?: string;
  remarks?: string;
  blockedBy?: string;
  blockedDate?: any;
  patientBlock?: any[];
}

export class PatientRegistration {
  id: string = "";
  mrn: string = "";
  identificationTypePrimary: codable;
  identificationTypeSecondary: codable;
  issuedCountryPrimary: codable;
  faulty: codable;
  prefixTitle: codable;
  gender: codable;
  preferredLanguage: codable;
  nationality: codable;
  race: codable;
  bloodGroup: codable;
  martialStatus: codable;
  occupation: codable;
  patientCategory: codable;
  religion: codable;
  countryPrimary: codable;
  countrySecondary: codable;
  statePrimary: codable;
  subDistrictPrimary:codable;
  stateSecondary: codable;
  cityPrimary: codable;
  citySecondary: codable;
  subDistrictSecondary:codable;
  relation: codable;
  identificationNumberPrimary: string = "";
  identificationNumberSecondary: string = "";
  expiryDate: Date;
  fullName: string = "";
  otherName: string = "";
  dateOfBirth: Date;
  age: string = "";
  addressPrimary: string = "";
  addressSecondary: string = "";
  postcodePrimary: string = "";
  postcodeSecondary: string = "";
  email: string = "";
  mobile: string = "";
  homeContact: string = "";
  personalFax:string="";
  workFax:string="";
  motherName:string="";
  education:codable;
  ethnic:codable;
  workContact: string = "";
  isVip: boolean = false;
  isDeceased:boolean =false;
  realdob:boolean=false;
  birthplace: string = "";
  isCtos:boolean=false;
  isBlock:boolean=false;
  sourceType:codable;
  patientNotes:string="";
  birthTime:string="";
  isNewborn:boolean=false;
  countryCodemobile:codable;
  seondaryExpiryDate: Date;
  employmentStatus:codable;
  employerName:codable;
  employerNameOthers: string;
  employmentNo: string;
  workingFrom: Date;
  employmentContactNo: string;
  employmentAddress: string;
  employmentPostCode: string;
  employmentCountry: codable;
  employmentState: codable;
  employmentCity: codable;
  active:boolean;
}

export class PatientEncounter {
  encounterType: codable;
  department: codable;
  doctor: codable;
  clinic: codable;
  cashCounter: codable;
  remark: string = "";
  visittype:codable;
  referralType: codable;
  referral: codable;
  patientCategory: codable;
  practiceNotes:string="";
  reportRemarks:string="";
  referralRemark: string = "";
  reportType:codable;
  reportReason:codable;
  reportRemarksMrd:string="";
  defaultDeposit?: string = ""

}

export class PatientNextOfKin {
  id?:string
  prefix: codable;
  identificationType: codable;
  relation: codable;
  country: codable;
  state: codable;
  city: codable;
  name: string = "";
  idNumber: string = "";
  phoneNumber: string = "";
  address: string = "";
  postcode: string = "";
  isGuarantor: boolean = false;
  checked: boolean = false;
  active?: boolean = false;
}

export class PatientEncounterPayer {
  id: string = "";
  isSelf: boolean = false;
  refereeCompany:string="";
  payerTypeCode:string="";
  payerCode: string = "";
  contractCode: string = "";
  gradeCode: string = "";
  tariffCode: string = "";
  associatedCompanyCode: string = "";
  glTypeCode: string = "PENDING GL";
  payerDesc: string = "";
  contractDesc: string = "";
  gradeDesc: string = "";
  tariffDesc: string = "";
  associatedCompanyDesc: string = "";
  glTypeDesc: string = "";
  policyNumber: string = "";
  glReferenceNo?:string="";
  insuranceNumber:string="";
  glAmount: number = 0;
  effectiveDate: Date = new Date();
  expiryDate: Date = null;
  isexpirypayerDate:boolean=false;
  priority: number = 1;
  contractList: any[] = [];
  contractCodeList: any[] = [];
  gradeList: any[] = [];
  status: string = "ACTIVE";
  glAttachmentId: string = "";
  memberShipId:string="";
  payerList: obj[] = [];
  payerFilteredList : obj[] = [];
  referralNoList?:[]=[];
  tariffList :obj[] = [];
}
export interface obj {
  code: string ;
  desc: string ;
  id:number;
}

export class codable {
  code: string = "";
  desc: string = "";
}
