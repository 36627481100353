<!-- Enter Email Dialog -->
<div *ngIf="resetState === resetPasswordEnum.ENTER_EMAIL" class="div-forget-password">
    <div class="modal-header" style="background-color: #23244A;">
        <mat-icon style="color: white !important;"> cached </mat-icon>
        <h6 class="modal-title" style="color: white !important;">&nbsp; Forgot Password</h6>
        <span aria-label="Close" class="close" matTooltip="Close" (click)="onNoClick()">
            <mat-icon>clear</mat-icon>
        </span>
    </div>

    <div class="modal-body bodyCreateNewPasscode">
        <div class="displayText">
            <p> {{ resetPasswordText }} &nbsp; </p>
        </div>

        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline" class="width70 formfield-emailid" >
                <mat-icon matPrefix> email </mat-icon>
                <input 
                    matInput
                    [placeholder]="enteremailIDText"
                    [formControl]="useremailValue"
                    [type]="'text'" 
                    style="color: black !important; padding-left: 2px;"
                />
            </mat-form-field>
        </div>
    </div>

    <div class="modal-footer-center right-btn">
        <button (click)="validateEmail()" matTooltip="Send Verification Email" mat-flat-button data-automation-attribute="button-login">
            Next
        </button>
    </div>
</div>

<!-- Enter OTP Dialog -->
<div *ngIf="resetState === resetPasswordEnum.ENTER_OTP" class="div-forget-password">
    <div class="modal-header" style="background-color: #23244A;">
        <mat-icon style="color: white !important;"> cached </mat-icon>
        <h6 class="modal-title" style="color: white !important;">&nbsp; Code Verification</h6>
        <span aria-label="Close" class="close" matTooltip="Close" (click)="onNoClick()">
            <mat-icon>clear</mat-icon>
        </span>
    </div>

    <div class="modal-body bodyEnterOTP">
        <div class="displayText">
            <p> {{ verificationCodeText }} </p>
            <b> {{ useremailValue.value }} </b>
        </div>

        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline" class="width70" >
                <ng-container *ngFor="let input of otpInputArray">
                    <input
                        autofocus
                        id="otpTab{{ input }}"
                        type="password"
                        class="input-pin"
                        maxlength="1"
                        (keyup.backspace)="onBackspace('otpTab', input)"
                        (input)="onInputEntry('otpTab', input)"
                        (focus)="$event.target.select()"
                        (keyup)="validateOTP()"
                        #myInput
                    />
                </ng-container>
            </mat-form-field>
        </div>
    </div>

    <div class="modal-footer-center right-btn">
        <button (click)="validateOTP()" matTooltip="Verify OTP" mat-flat-button data-automation-attribute="button-login">
            Verify Code
        </button>
    </div>
</div>

<!-- Reset Password Dialog -->
<div *ngIf="resetState === resetPasswordEnum.RESET_PASSWORD" class="div-forget-password">
    <div class="modal-header" style="background-color: #23244A;">
        <mat-icon style="color: white !important;"> cached </mat-icon>
        <h6 class="modal-title" style="color: white !important;">&nbsp; Reset Password</h6>
        <span aria-label="Close" class="close" matTooltip="Close" (click)="onNoClick()">
            <mat-icon>clear</mat-icon>
        </span>
    </div>

    <div class="modal-body bodyCreateNewPasscode">
        <div class="displayText">
            <p> {{ updatePasswordText }} </p>
        </div>

        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline" class="width70 formfield-emailid" >
                <mat-icon matPrefix class="iconColorFade"> lock </mat-icon>
                <input 
                    matInput
                    [placeholder]="newPasswordText"
                    [formControl]="newPassword"
                    [type]="nPasswordBtn ? 'text' : 'password'"
                    style="color: black !important; padding-left: 2px;"
                />
                <mat-icon matSuffix *ngIf="!nPasswordBtn" (click)="toggleNButton()" matTooltip="Show Password" class="iconColorFade"> visibility </mat-icon>
                <mat-icon matSuffix *ngIf="nPasswordBtn" (click)="toggleNButton()" matTooltip="Hide Password" class="iconColorFade"> visibility_off </mat-icon>
            </mat-form-field>
        </div>
        
        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline" class="width70 formfield-emailid" >
                <mat-icon matPrefix class="iconColorFade"> lock </mat-icon>
                <input 
                    matInput
                    [placeholder]="confirmPasswordText"
                    [formControl]="confirmPassword"
                    [type]="cPasswordBtn ? 'text' : 'password'"
                    style="color: black !important; padding-left: 2px;"
                    (focusout)="validateNewPassword()"
                />
                <mat-icon matSuffix *ngIf="!cPasswordBtn" (click)="toggleCButton()" matTooltip="Show Password" class="iconColorFade"> visibility </mat-icon>
                <mat-icon matSuffix *ngIf="cPasswordBtn" (click)="toggleCButton()" matTooltip="Hide Password" class="iconColorFade"> visibility_off </mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="modal-footer-center right-btn">
        <button (click)="updatePassword()" matTooltip="Update Password" mat-flat-button data-automation-attribute="button-login">
            Update
        </button>
    </div>
</div>

<!-- Enter Passcode Dialog  -->
<div *ngIf="resetState === resetPasswordEnum.RESET_PASSCODE" class="div-forget-password">
    <div class="modal-header" style="background-color: #23244A;">
        <mat-icon style="color: white !important;"> cached </mat-icon>
        <h6 class="modal-title" style="color: white !important;">&nbsp; Reset Passcode</h6>
        <span aria-label="Close" class="close" matTooltip="Close" (click)="onNoClick()">
            <mat-icon>clear</mat-icon>
        </span>
    </div>

    <div class="modal-body bodyEnterPasscode">
        <div class="displayText">
            <p> {{ updatePasscodeText }} </p>
        </div>

        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline">
              <input
                matInput
                placeholder="{{ this.autoGeneratedPin }}"
                [type]="'number'"
                disabled
              />
            </mat-form-field>
            
            <mat-form-field floatLabel="never" appearance="outline">
              <input
                matInput
                [placeholder]="enterPasscodeText"
                [formControl]="newPin"
                [type]="nPasswordBtn ? 'number' : 'password'"
                maxlength="4"
              />
              <mat-icon matSuffix *ngIf="!nPasswordBtn" (click)="toggleNButton()" matTooltip="Show Passcode" class="iconColorFade"> visibility </mat-icon>
              <mat-icon matSuffix *ngIf="nPasswordBtn" (click)="toggleNButton()" matTooltip="Hide Passcode" class="iconColorFade"> visibility_off </mat-icon>
            </mat-form-field>
        </div>

        <div style="display:inline-flex;">
            <mat-form-field floatLabel="never" appearance="outline">
              <input
                matInput
                placeholder="{{ this.autoGeneratedPin }}"
                [type]="'number'"
                disabled
              />
            </mat-form-field>

            <mat-form-field floatLabel="never" appearance="outline">
              <input
                matInput
                [placeholder]="confirmPasscodeText"
                [formControl]="confirmPin"
                [type]="cPasswordBtn ? 'number' : 'password'"
                (focusout)="validateNewPasscode()"
                maxlength="4"
              />
              <mat-icon matSuffix *ngIf="!cPasswordBtn" (click)="toggleCButton()" matTooltip="Show Passcode" class="iconColorFade"> visibility </mat-icon>
              <mat-icon matSuffix *ngIf="cPasswordBtn" (click)="toggleCButton()" matTooltip="Hide Passcode" class="iconColorFade"> visibility_off </mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="modal-footer-center right-btn">
        <button (click)="updatePasscode()" matTooltip="Verify OTP" mat-flat-button data-automation-attribute="button-login">
            Update
        </button>
    </div>
</div>
