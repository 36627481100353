import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MultiLanguageService } from "../multi-language/multi-language.service";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-extend-dialog",
  templateUrl: "./extend-dialog.component.html",
  styleUrls: ["./extend-dialog.component.scss"]
})
export class ExtendDialogComponent implements OnInit {
  stayLength: any = "";
  blockingForm: FormGroup;
  minReleaseDate: Date;
  minReleaseTime: string;
  isPastTimeDisabled: boolean = false;
  currentDate = new Date();
  selectedDate: any;
  selectedTime:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExtendDialogComponent>,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private langService: MultiLanguageService
  ) {}

  ngOnInit() {
    this.minReleaseDate = this.data.expectedReleasedDate;
    this.blockingForm = this.formBuilder.group({
      releaseDate : [ new Date(), [Validators.required]],
      releaseTime : ['', [Validators.required]]
    });
  }

  buttonClick(value) {
    const selectedDate = this.blockingForm.get("releaseDate").value;
    const selectedTime = this.blockingForm.get("releaseTime").value;
    const currentDateTime = new Date();
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(parseInt(selectedTime.split(":")[0]));
    selectedDateTime.setMinutes(parseInt(selectedTime.split(":")[1]));
      if (selectedDateTime.toDateString() === currentDateTime.toDateString()) {
      if (selectedDateTime <= currentDateTime) {
        this.snackbar.open(
          this.langService.get('CommonAppoinment.warning'),
          this.langService.get('Select Valid Time'),
          { duration: 2000 }
        );
        return;
      }
    }
  
    else {
      if (selectedDateTime < currentDateTime) {
        this.snackbar.open(
          this.langService.get('CommonAppoinment.warning'),
          this.langService.get('Select Valid Time'),
          { duration: 2000 }
        );
        return;
      }
    }
    let object = { data: this.blockingForm.value, confirmationValue: value };
    this.dialogRef.close(object);
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
