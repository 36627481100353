import { Component, OnDestroy, OnInit } from '@angular/core';
import { MICROSERVICES } from '../../constants/web-services';
import { APPLICATIONSERVICE } from '../../constants/application-service';
import { AppBaseService } from '../../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
declare const PDFObject: any;

@Component({
  selector: 'app-pdf-viewer2',
  templateUrl: './pdf-viewer2.component.html',
  styleUrls: ['./pdf-viewer2.component.scss']
})
export class PdfViewer2Component implements OnInit, OnDestroy {
  protected _onDestroy = new Subject<void>();
  reportPayload: any;
  componentLoaded: boolean = false;
  exportTypes = [
    { id: '1', active: true, code: 'xlsx', desc: 'Excel' },
    { id: '2', active: true, code: 'doc', desc: 'DOC' },
    { id: '3', active: true, code: 'csv', desc: 'CSV' }
  ];
  reportSecondPayload: any;

  constructor(
    private httpService: AppBaseService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.componentLoaded = true;
    this.reportPrint();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    localStorage.removeItem("patientLablePayload");
    localStorage.removeItem("billPayload");
  }

  reportPrint() {
    this.reportPayload = JSON.parse(localStorage.getItem("patientLablePayload"));
    this.reportSecondPayload = JSON.parse(localStorage.getItem("billPayload"));
    
    if (!this.reportPayload && !this.reportSecondPayload) {
      console.error("No report payload found in local storage.");
      return;
    }
    else if(this.reportSecondPayload && this.reportSecondPayload.isBilling) {
      if(this.reportSecondPayload) {
        this.multipleBillingReportsAPI(this.reportSecondPayload)
        localStorage.removeItem("billPayload");
        this.reportSecondPayload=null;
      } 
    }
    else{
      this.multipleReportsAPI(this.reportPayload);
    }
  }

  reportPrintAPI(resourceUrl: string, reportGeneratePath: string, reqPayload: any) {
    this.httpService.setResourceURL(resourceUrl);
    this.httpService
      .postResource(reportGeneratePath, reqPayload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        PDFObject.embed(result.payload, "#pdfContainer");
        if (!this.componentLoaded) {
          window.location.reload();
        }
      });
  }

  multipleReportsAPI(payLoad: any) {
    const reqPayload = this.formatGenericReportPayload(payLoad);
    this.reportPrintAPI(
      MICROSERVICES.OPD_SERVICE,
      APPLICATIONSERVICE.REPORT_GENERATE_VIEW,
      reqPayload
    );
  }

  formatGenericReportPayload(report: any): any {
    return {
      transactionId: report.transactionId,
      type: report.type,
      reportName: report.reportName
    };
  }

  multipleBillingReportsAPI = (payLoad: any) => {
    let payloadres="";
    let requestPayload;
    payloadres=payLoad;
    return new Promise<void>((resolve) => {
        requestPayload = this.formatBillingReportPayload(payloadres);

      this.reportPrintAPI(
        MICROSERVICES.BILLING_SERVICE,
        APPLICATIONSERVICE.REPORT_GET_COMMON_REPORT,
        requestPayload,
      );
      resolve();
    });
  };  

   /**
   *
   *
   * @return {*}  {*}
   * @memberof PdfViewerComponent
   */
   formatBillingReportPayload(reportPayload): any {
    const requestPayload = {
      type: reportPayload.type,
      reportName: reportPayload.reportName.toUpperCase(),
      parameterList: [
        {
          parameter: "id",
          value: reportPayload.transactionId,
          dataType:"Long"
        },
        {
          parameter: "isReprint",
          value: reportPayload.reprint,
          dataType:"Boolean"
        }
      ]
    }

    if(reportPayload.params && reportPayload.params.length > 0) {
      reportPayload.params.forEach(param => {
        requestPayload.parameterList.push({
          parameter: param.name.toLowerCase(),
          value: param.value,
          dataType: param.name
        });
      });
    }

    return requestPayload;
  }
}
