import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { max, Observable, Subject } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";
//import { ImageCroppedEvent } from "ngx-image-cropper";
import { HttpClient } from "@angular/common/http";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { start } from 'repl';
import { CdkTextColumn } from '@angular/cdk/table';

export class datared {
  id :number=0;
types:string="";
direction:string="";
}

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss'],

  animations: [],
})

export class CameraDialogComponent implements OnInit,OnDestroy {

  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {};
  public errors: WebcamInitError[] = [];

  public webcamImage: WebcamImage = null;
  showWebcam = false;
  
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  fileToUpload: any = [];
  base64textString: string = "";
  imageUser: string = "";
  imageChangedEvent: any = "";
  imageChanged: any = "";
  croppedImage: string = "";
  UndoData:Array<datared>=[];
  RedoData:Array<datared>=[];
  ids:number=0;
  saveImageEdit: boolean = false;
  backButton= false;
  imageshow : boolean = false;
 
  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,private httpClient: HttpClient,
  ) {}
  
  ngOnDestroy(): void {}

  ngOnInit(): void {
    if(this.data.name != null && this.data.name != ""){
      this.imageUser = this.data.name;
      this.imageshow = true;
      this.saveImageEdit = true;
      this.backButton = true;   
    }else{
      this.imageshow = false;
      this.saveImageEdit = false;
      this.backButton = false;
    }
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.imageUser = event.base64;
  // }
  

  onNoClick(result): void {
    const data = { picdata: this.webcamImage ? this.webcamImage.imageAsDataUrl : this.imageUser, res: result };
    this.dialogRef.close(data);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.onNoClick("yes");
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info("received webcam image", webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

selectFile() {
  document.getElementById("fileAttachment").click();
  this.ids = 0;
  this.imageshow=true;
    this.UndoData = [];
    this.RedoData = [];
  }

handleFileInput(files: FileList) {
  this.fileToUpload = files;
}

handleFileSelect(evt) {
  var files = evt.target.files;
  var file = files[0];

  if (file.size > 200000) {
    this.snackBar.open("Info:", "image size is bigger than 200 KB", {
      duration: 3000,
    });
    return null;
  }

  if (files && file) {
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(file);
  }
}

_handleReaderLoaded(readerEvt) {
  this.imageUser = "";
  var binaryString = readerEvt.target.result;
  if (binaryString) {
    this.base64textString = btoa(binaryString);
    this.imageUser = "data:image/jpg;base64," + this.base64textString;
  }
}

onFileChange(event: any): void {
    this.imageChangedEvent = event;
  }

  // cropImg(e: ImageCroppedEvent) {
  //   this.imageUser = "cropImg" + this.base64textString;
  //   this.imageUser = e.base64;
  // }
  imgLoad() {
    // display cropper tool
  }
  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  onCropClick() {
    this.imageshow = true;
    this.imageChangedEvent = this.imageChanged;
    this.imageChanged = this.imageUser;
  }

  change(){
    this.saveImageEdit= !this.saveImageEdit;
    this.imageshow=false;
    this.imageChangedEvent = this.data.name;
  }

  remove(){
    this.imageUser = null;
    this.dialogRef.close("imageUser");
  }

  back(){
    this.saveImageEdit= !this.saveImageEdit;
  }

  rotateImageright(){    
    let img = new Image();
        //img.src = document.getElementById('myimage').src;
        img.src=(document.getElementById('image') as HTMLImageElement).src     
       
        // Create a canvas object.
        let canvas = document.createElement("canvas");
        
        // Wait till the image is loaded.
        img.onload = function(){          
            rotateImage();            
            saveImage(img.src.replace(/^.*[\\\/]/, ''));            
        }

        let rotateImage = () => {
          // Create canvas context.
          let ctx = canvas.getContext("2d");	

          // Assign width and height.
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.translate(canvas.width / 2,canvas.height / 2);

          // Rotate the image and draw it on the canvas. 
              // (I am not showing the canvas on the webpage.
          ctx.rotate(1.57);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);          
      }

      let saveImage = (img_name) => {        
        let a = document.createElement('a');        
        a.href = canvas.toDataURL("image/png");
        document.body.appendChild(a);
        this.imageUser=a.href;                     
    }

    this.ids=this.ids+1;
     this.UndoData.push({
       id:this.ids,
       types:'ctx.rotate',
      direction:'rotateImageright',   
      }); 
  }

  rotateImageleft(){    
    let img = new Image();
        //img.src = document.getElementById('myimage').src;
        img.src=(document.getElementById('image') as HTMLImageElement).src     
       
        // Create a canvas object.
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        // Wait till the image is loaded.
        img.onload = function(){          
            rotateImage();            
            saveImage(img.src.replace(/^.*[\\\/]/, ''));            
        }

         let rotateImage = () => {
          
          // Create canvas context.
          	

          // Assign width and height.
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.translate(canvas.width / 2,canvas.height / 2);

          // Rotate the image and draw it on the canvas. 
              // (I am not showing the canvas on the webpage.
          ctx.rotate(-1.57);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);        
     }

      let saveImage = (img_name) => {        
        let a = document.createElement('a');        
        a.href = canvas.toDataURL("image/png");      
        document.body.appendChild(a);
        this.imageUser=a.href;      
               
    }
    this.ids=this.ids+1;
    this.UndoData.push({
      id:this.ids,
      types:'ctx.rotate',
     direction:'rotateImageleft',
    });
    
  }

FlipHorizontal(){  
let img = new Image();
img.src=(document.getElementById('image') as HTMLImageElement).src;

let canvas=document.createElement("canvas");

img.onload= function(){
  flipHorizontally();
  saveImage(img.src.replace(/^.*[\\\/]/, ''))
}

let flipHorizontally = () => {
  let ctx=canvas.getContext("2d");
  canvas.width = img.width;
  canvas.height = img.height;

    // move to x + img's width
    ctx.translate(canvas.width / 2, canvas.height / 2);

    // scaleX by -1; this "trick" flips horizontally
    ctx.scale(-1,1);
    
    // draw the img
    // no need for x,y since we've already translated
    ctx.drawImage(img, -img.width / 2, -img.height / 2);
}

let saveImage = (img_name) => {        
  let a = document.createElement('a');        
  a.href = canvas.toDataURL("image/png");      
  document.body.appendChild(a);
  this.imageUser=a.href;             
}

this.ids=this.ids+1;
this.UndoData.push({
  id:this.ids,
  types:'ctx.scale',
 direction:'FlipHorizontal',
});
}

FlipVertical(){  
  let img = new Image();
  img.src=(document.getElementById('image') as HTMLImageElement).src;
  let canvas=document.createElement("canvas");
  
  img.onload= function(){
    flipVertically();
    saveImage(img.src.replace(/^.*[\\\/]/, ''))
  }
  
  let flipVertically = () => {
    let ctx=canvas.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
  
      // move to x + img's width
      ctx.translate(canvas.width / 2, canvas.height / 2);
  
      // scaleX by -1; this "trick" flips vertically
      ctx.scale(1,-1);
      
      // draw the img
      // no need for x,y since we've already translated
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
  }
  
  let saveImage = (img_name) => {        
    let a = document.createElement('a');        
    a.href = canvas.toDataURL("image/png"); 
    document.body.appendChild(a);
    this.imageUser=a.href;     
           
  }
  this.ids=this.ids+1;
  this.UndoData.push({
    id:this.ids,
    types:'ctx.scale',
   direction:'FlipVertical',
  });
}

undo() {
  const dt=this.UndoData.filter(x=>x.id==this.ids)
  const UpdatedUndo=this.UndoData.filter(x=>x.id!=dt[0].id)
 this.UndoData=UpdatedUndo;

let img = new Image();
img.src=(document.getElementById('image') as HTMLImageElement).src     
let canvas = document.createElement("canvas");

let ctx = canvas.getContext("2d");	
       canvas.width = img.width;
       canvas.height = img.height;
      ctx.translate(canvas.width / 2,canvas.height / 2);

if(dt[0].types=='ctx.rotate'){

if(dt[0].direction =='rotateImageleft'){
img.onload = function(){          
rotateImage();            
saveImage(img.src.replace(/^.*[\\\/]/, ''));            
}

     let rotateImage = () =>{
       ctx.rotate(1.57);
       ctx.drawImage(img, -img.width / 2, -img.height / 2);          
   }
   this.ids=this.ids-1;
 }
 else
 if(dt[0].direction =='rotateImageright'){

     img.onload = function(){          
         rotateImage();            
         saveImage(img.src.replace(/^.*[\\\/]/, ''));            
     }

     let rotateImage = () => {
       ctx.rotate(-1.57);
       ctx.drawImage(img, -img.width / 2, -img.height / 2);          
   }

   this.ids=this.ids-1;
 }
 let saveImage = (img_name) => {        
   let a = document.createElement('a');        
   a.href = canvas.toDataURL("image/png");
   document.body.appendChild(a);
   this.imageUser=a.href;      
 }
}

if(dt[0].types=='ctx.scale'){
   if(dt[0].direction=='FlipVertical'){
     img.onload= function(){
       flipVertically();
       saveImage(img.src.replace(/^.*[\\\/]/, ''))
     }
     
     let flipVertically = () => {
       // scaleX by -1; this "trick" flips vertically
         ctx.scale(1,-1);
         // draw the img
         // no need for x,y since we've already translated
         ctx.drawImage(img, -img.width / 2, -img.height / 2);
     }
     this.ids=this.ids-1;
   }
   else if(dt[0].direction=='FlipHorizontal'){
     img.onload= function(){
       flipHorizontally();
       saveImage(img.src.replace(/^.*[\\\/]/, ''))
     }
     
     let flipHorizontally = () => {
         // scaleX by -1; this "trick" flips horizontally
         ctx.scale(-1,1);
         // draw the img
         // no need for x,y since we've already translated
         ctx.drawImage(img, -img.width / 2, -img.height / 2);
     }
     this.ids=this.ids-1;
     }

     let saveImage = (img_name) => {        
       let a = document.createElement('a');        
       a.href = canvas.toDataURL("image/png");      
       document.body.appendChild(a);
       this.imageUser=a.href;             
     }
}
this.RedoData.push({
 id :dt[0].id,
 types:dt[0].types,
 direction:dt[0].direction
});
}

redo() {
 const dt=this.RedoData.filter(x=>x.id==Math.max(x.id))
 const UpdatedRedo=this.RedoData.filter(x=>x.id!=dt[0].id)
 this.RedoData=UpdatedRedo;

 let img = new Image();
 img.src=(document.getElementById('image') as HTMLImageElement).src     
 let canvas = document.createElement("canvas");

 let ctx = canvas.getContext("2d");	
         canvas.width = img.width;
         canvas.height = img.height;
         ctx.translate(canvas.width / 2,canvas.height / 2);

if(dt[0].types=='ctx.rotate'){

 if(dt[0].direction =='rotateImageleft'){
img.onload = function(){          
 rotateImage();            
 saveImage(img.src.replace(/^.*[\\\/]/, ''));            
}

       let rotateImage = () =>{
         ctx.rotate(-1.57);
         ctx.drawImage(img, -img.width / 2, -img.height / 2);          
     }
     this.ids=this.ids+1;
   }
   else
   if(dt[0].direction =='rotateImageright'){

       img.onload = function(){          
           rotateImage();            
           saveImage(img.src.replace(/^.*[\\\/]/, ''));            
       }

       let rotateImage = () => {
         ctx.rotate(1.57);
         ctx.drawImage(img, -img.width / 2, -img.height / 2);          
     }

     this.ids=this.ids+1;
   }
   let saveImage = (img_name) => {        
     let a = document.createElement('a');        
     a.href = canvas.toDataURL("image/png");
     document.body.appendChild(a);
     this.imageUser=a.href;
   }
 }

 if(dt[0].types=='ctx.scale'){
     if(dt[0].direction=='FlipVertical'){
       img.onload= function(){
         flipVertically();
         saveImage(img.src.replace(/^.*[\\\/]/, ''))
       }
       
       let flipVertically = () => {
         // scaleX by -1; this "trick" flips vertically
           ctx.scale(1,-1);
           // draw the img
           // no need for x,y since we've already translated
           ctx.drawImage(img, -img.width / 2, -img.height / 2);
       }
       this.ids=this.ids+1;
     }
     else if(dt[0].direction=='FlipHorizontal'){
       img.onload= function(){
         flipHorizontally();
         saveImage(img.src.replace(/^.*[\\\/]/, ''))
       }
       
       let flipHorizontally = () => {
           // scaleX by -1; this "trick" flips horizontally
           ctx.scale(-1,1);
           // draw the img
           // no need for x,y since we've already translated
           ctx.drawImage(img, -img.width / 2, -img.height / 2);
       }
       this.ids=this.ids+1;
       }

       let saveImage = (img_name) => {        
         let a = document.createElement('a');        
         a.href = canvas.toDataURL("image/png");      
         document.body.appendChild(a);
         this.imageUser=a.href;             
       }
 }
 this.UndoData.push({
  id :dt[0].id,
  types:dt[0].types,
  direction:dt[0].direction
 });
}
}