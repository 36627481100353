<div *ngIf="data.createOrforget" class="div-create-passcode">
    <div class="modal-header modalHeaderCyan">
      <h6 class="modal-title">Create Password</h6>
      <span aria-label="Close" class="close" (click)="onNoClick()">
        <mat-icon>clear</mat-icon>
      </span>
    </div>
  
    <div class="modal-body bodyCreatePasscode">
      <div style="display:inline-flex;">
        <span class="spanLabel">{{ username }}</span>
        <mat-form-field floatLabel="never">
          <input
            matInput
            [placeholder]="enterusername"
            [(ngModel)]="usernameValue"
            [type]="'text'"
          />
        </mat-form-field>
      </div>
  
      <div style="display:inline-flex;">
        <span class="spanLabel">{{ firsttimepasscode }}</span>
        <mat-form-field floatLabel="never">
          <input
            matInput
            [placeholder]="enterpassword"
            [(ngModel)]="passwordValue"
            [type]="'text'"
            (blur)="focusOut()"
          />
        </mat-form-field>
      </div>
  
      <div style="display:inline-flex;">
        <span class="spanLabel" style="width: 116px;"> {{ passcode }}</span>
        <mat-form-field floatLabel="never" class="text-passcode">
          <input
            matInput
            placeholder="{{ this.pinValue }}"
            [type]="'number'"
            [(ngModel)]="passcodeValue"
            disabled
          />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="text-enterpasscode">
          <input
            matInput
            [placeholder]="enterpasscode"
            [(ngModel)]="newpasswordValue"
            [type]="'number'"
          />
        </mat-form-field>
      </div>
    </div>
  
    <div class="modal-footer float-right">
      <div>
        <button matTooltip="Save" mat-mini-fab (click)="confirmSave()" class="btnCyan btnSave">
          <mat-icon>sim_card</mat-icon>
        </button>&nbsp;
        <button mat-mini-fab class="btnRed btnCancel">
          <mat-icon matTooltip="Reset" (click)="resetData()">undo</mat-icon>
        </button>
      </div>
    </div>
  </div>
  