<div id="range">
  <ejs-chart
    id="chartcontainer"
    [chartArea]="chartArea"
    [width]="width"
    align="center"
    style="display: block" 
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    (load)="load($event)"
  >
    <e-series-collection>
      <e-series
        [dataSource]="dataSource"
        type="RangeColumn"
        xName="Days"
        high="IND_HighTemp"
        low="IND_LowTemp"
        columnSpacing="0.1"
        [marker]="marker"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
