export const OPD_REPORT_NAME = {
  patient_Card: "patientCard",
  encounter_Slip: "encounterSlip",
  patient_Label: "patientLabel",
  pdpaForm_new: "pdpaFormnew",
  concsent_Form: "concsentForm",
  appointment_Slip: "appointmentSlip",
  default_deposit_slip:"encounterDefaultDepositSlip"
};
export const OPD_REPORT_TYPE = {
  pdf: "pdf",
  csv: "csv",
  xlsx: "xlsx",
};
