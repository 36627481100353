import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import { MatIconModule} from "@angular/material/icon";
import { MatPaginatorModule} from "@angular/material/paginator";
import {ApplicationPipeModule} from "../../application-pipes/applicationpipes.module";
import {PushNotificationComponent} from "./controllers/push-notification/push-notification.component";
import { ShowPushNotificationComponent } from './shared/show-push-notification/show-push-notification.component';
import { PushNotificationListComponent } from './controllers/push-notification-list/push-notification-list.component';
import { ShowAllPushNotificationComponent } from './shared/dialog/show-all-push-notification/show-all-push-notification.component';
import { PushNotificationDirective } from './directive/push-notification.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PushNotificationButtonComponent } from './shared/push-notification-button/push-notification-button.component';
import { SinglePushNotificationComponent } from './shared/single-push-notification/single-push-notification.component';
import { SingleNotificationItemComponent } from './shared/single-notification-item/single-notification-item.component';

/**
 *
 */
@NgModule({
  declarations: [
    PushNotificationComponent,
    ShowPushNotificationComponent,
    PushNotificationListComponent,
    ShowAllPushNotificationComponent,
    PushNotificationDirective,
    PushNotificationButtonComponent,
    SinglePushNotificationComponent,
    SingleNotificationItemComponent,
  ],
  exports: [
    PushNotificationComponent,
    PushNotificationListComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    ApplicationPipeModule,
    BrowserAnimationsModule,
  ],
  entryComponents: [
    ShowAllPushNotificationComponent
  ]
})
export class PushNotificationModule { }
