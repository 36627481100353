import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppBaseService } from 'medcare-core-ui';
import { Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OPD_API_URL } from '../../regproperties/opd-url-properties';

declare const PDFObject: any;
@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent implements OnInit,OnDestroy {
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  url: string='';
  imageSrc: SafeUrl;
  isImage:boolean;

  constructor(
    public dialogRef: MatDialogRef<ViewDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appBaseService:AppBaseService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .getMIMETypeResource(OPD_API_URL.OPD_VIEW_FILE + this.data.value)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res:Blob) => {
        let value=res;
        var fileURL = URL.createObjectURL(value);
        if(value.type=="image/jpeg" || value.type=="image/png"){
          if(value.size<130000){
            const pdfObject = PDFObject.embed(fileURL, "#pdfContainer");
            this.isImage=false;
          }else{
            const safeImageUrl: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(fileURL);
            this.imageSrc = safeImageUrl;
            this.isImage=true;
          }
        }else{
          const pdfObject = PDFObject.embed(fileURL, "#pdfContainer");
          this.isImage=false;
        }
      })
   
  }
  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
  closeView(){
    this.dialogRef.close();
  }
}
