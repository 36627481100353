import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DataService {
  private messageSource = new BehaviorSubject({});
  private dashboarData = new BehaviorSubject<any>({});
  currentMessage = this.messageSource.asObservable();

  constructor() {}

  setData(data: any) {
    this.messageSource.next(data);
  }

  updateDashboardData(data) {
    this.dashboarData.next(data);
  }

  getDashboardData() {
    return this.dashboarData.asObservable();
  }
}
