/*eslint-disable*/
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppBaseService } from "medcare-core-ui";
import { catchError, Observable, of } from "rxjs";
import { BILL_LANG } from "../properties/billing-lang-properties";

@Injectable()
export class GeneralUrlService {
    selectedLang: string;
    multLangDilaogs: any[] = [];

    constructor(
        private baseservice: AppBaseService,
        public translate: TranslateService
      ) {
        this.selectedLang = localStorage.getItem(BILL_LANG.langCode);
        translate.addLangs(BILL_LANG.langList);
        this.translate.use(this.selectedLang);
        this.translate.get("dialogs").subscribe((data: any) => {
          this.multLangDilaogs = data;
        });
    }

    /**
     *
     *
     * @param {string} serviceUrl
     * @param {string} urlType
     * @return {*}  {Observable<any[]>}
     * @memberof GeneralUrlService
     */
    getTypeApi(serviceUrl: string, urlType: string): Observable<any[]> {
        this.baseservice.setResourceURL(serviceUrl);
    
        return this.baseservice
          .getResource(urlType)
          .pipe(catchError(this.handleError<any[]>()));
    }

    /**
     *
     *
     * @param {string} serviceUrl
     * @param {string} urlType
     * @param {*} payload
     * @return {*}  {Observable<any[]>}
     * @memberof GeneralUrlService
     */
    postTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
        this.baseservice.setResourceURL(serviceUrl);
    
        return this.baseservice
          .postResource(urlType, payload)
          .pipe(catchError(this.handleError<any[]>()));
    }

    /**
     *
     *
     * @param {string} serviceUrl
     * @param {string} urlType
     * @param {*} payload
     * @return {*}  {Observable<any[]>}
     * @memberof GeneralUrlService
     */
    putTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
        this.baseservice.setResourceURL(serviceUrl);
        
        return this.baseservice
          .putResource(urlType, payload)
          .pipe(catchError(this.handleError<any[]>()));
    }

    /**
     *
     *
     * @param {string} serviceUrl
     * @param {string} urlType
     * @param {*} payload
     * @return {*}  {Observable<any[]>}
     * @memberof GeneralUrlService
     */
    patchTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(serviceUrl);
    
    return this.baseservice
        .patchResource(urlType, payload)
        .pipe(catchError(this.handleError<any[]>()));
    }

    /**
     *
     *
     * @private
     * @template T
     * @return {*} 
     * @memberof GeneralUrlService
     */
    private handleError<T>() {
        return (error: any): Observable<T> => {
          console.log(`failed: ${error.message}`);
    
          return of(error);
        };
    }

}