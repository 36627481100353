<div>
  <form [formGroup]="blockingForm" (ngSubmit)="blockingForm.valid">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">Extension Length</h6>
      <span aria-label="Close" class="close" (click)="closeDialog()">
        <mat-icon>clear</mat-icon>
      </span>
    </div>
    <div class="modal-body bodyCreatePasscode">
      <mat-grid-list cols="2" rowHeight="65px">
        <mat-grid-tile [colspan]="2">
          <mat-form-field class="full-width" appearance="outline">
            <input matInput [matDatepicker]="picker5" [placeholder]="'adt.releasedate' | translate"
              formControlName="releaseDate" [required]="true"  [min]="minReleaseDate" />
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5 disabled="false"></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2">
          <mat-form-field class="time-picker" appearance="outline">
            <input matInput type="time" [required]="true"
            [placeholder]="'adt.releaseTime' | translate" formControlName="releaseTime"    [min]="minReleaseTime"
            [disabled]="isPastTimeDisabled"
            [(ngModel)]="selectedTime" />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button mat-mini-fab class="btnCyan" type="submit" data-automation-attribute="button-save-dialog">
          <mat-icon matTooltip="Yes" (click)="buttonClick('yes')">done</mat-icon>
        </button>&nbsp;
        <button mat-mini-fab class="btnRed"  data-automation-attribute="button-cancel-dialog">
          <mat-icon matTooltip="No" (click)="buttonClick('no')">close</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>
