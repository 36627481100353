<div id="pieRadius">
  <ejs-accumulationchart
    [id]="idName"
    #pie
    style="width: 92%;height: inherit !important;"
    [tooltip]="tooltip"
    [enableSmartLabels]="enableSmartLabels"
    [enableAnimation]="enableAnimation"
    (load)="load($event)"
    [enableBorderOnMouseMove]="false"
  >
    <e-accumulation-series-collection>
      <e-accumulation-series
        [dataSource]="dataSource"
        xName="type"
        yName="count"
        innerRadius="5%"
        [dataLabel]="dataLabel"
        radius="radius"
        tooltipMappingName="radius"
      >
      </e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
</div>
<style>  

  .e-chart {  
  
          width: inherit !important;  
  
          height: inherit !important;  
  
      }  
  
     
  
    .e-accumulationchart {  
  
          width: inherit !important;  
  
          height: inherit !important;  
  
      }  
  
  </style>  