import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppBaseService } from "medcare-core-ui";
import { of,Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { OPD_LANG } from "../regproperties/opd-lang-properties";


@Injectable()
export class GeneralUrlService {
  selectedLang: string;
  multLangDilaogs: any[] = [];

  constructor(
    private baseservice: AppBaseService,
    public translate: TranslateService
  ) {
    this.selectedLang = localStorage.getItem(OPD_LANG.langCode);
    translate.addLangs(OPD_LANG.langList);
    this.translate.use(this.selectedLang);
    this.translate.get("dialogs").subscribe((data: any) => {
      this.multLangDilaogs = data;
    });
  }

 
  getTypeApi(serviceUrl: string, urlType: string): Observable<any[]> {
    this.baseservice.setResourceURL(serviceUrl);

    return this.baseservice
      .getResource(urlType)
      .pipe(catchError(this.handleError<any[]>()));
  }

  /**
   *
   *
   * @param {string} urlType
   * @param {*} payload
   * @return {*}  {Observable<any[]>}
   * @memberof MasterUrlService
   */
 
  postTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(serviceUrl);

    return this.baseservice
      .postResource(urlType, payload)
      .pipe(catchError(this.handleError<any[]>()));
  }

  /**
   *
   *
   * @param {string} urlType
   * @param {*} payload
   * @return {*}  {Observable<any[]>}
   * @memberof MasterUrlService
   */
   
  putTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(serviceUrl);
    
    return this.baseservice
      .putResource(urlType, payload)
      .pipe(catchError(this.handleError<any[]>()));
  }
  patchTypeApi(serviceUrl: string, urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(serviceUrl);
    
    return this.baseservice
      .patchResource(urlType, payload)
      .pipe(catchError(this.handleError<any[]>()));
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {
      console.log(`failed: ${error.message}`);

      return of(error);
    };
  }
}
