import { Injectable } from '@angular/core';
import {ReplaySubject, Subject, takeUntil} from "rxjs";
import {AppBaseService} from "../../../services/http.service";
import {PushNotificationService} from "./push-notification.service";

/**
 *
 *
 * @export
 * @class AllPushNotificationsService
 */
@Injectable({
  providedIn: 'root'
})
export class AllPushNotificationsService {

  /**
   *
   */
  private allNotificationList: any[];

  /**
   *
   */
  public allNotificationList$: Subject<any[]> = new Subject();

  /**
   *
   *
   * @private
   * @type {number}
   * @memberof AllPushNotificationsService
   */
  private offset: number = 0;
  
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   *
   * @param appBaseService
   * @param userDataService
   * @param pushNotificationService
   */
  constructor(
    private appBaseService: AppBaseService,
    private pushNotificationService: PushNotificationService
  ) { }

  /**
   *
   * @param userId
   * @param orgCode
   * @param unitCode
   */
  public getMenuPushNotification(userId, roleId) {
    this.offset = 0;
    this.getAllNotification(userId, roleId)
    .pipe(takeUntil(this.unsubscribe)) 
      .subscribe(res => {
        if (res && res.notificationResponseList.length > 0) {
          this.pushNotificationService.getUnreadPushNotificationCount(res.totalUnreadMessage);
          this.allNotificationList = res.notificationResponseList;
        } else {
          this.allNotificationList = [];
        }
        this.allNotificationList$.next(this.allNotificationList);
        this.offset++;
      });
  }

  /**
   *
   * @param notificationData
   */
  public increasePushNotification(notificationData: any) {
    let updateNotificationList: any[] = [];
    for(let i = 0; i<5; i++) {
      if (i === 0 ) {
        updateNotificationList.push(notificationData);
      } else {
        if ((this.allNotificationList[(i-1)]) !== undefined) {
          updateNotificationList.push((this.allNotificationList[(i-1)]));
        }
      }
    }
    this.offset = 0;
    this.allNotificationList = updateNotificationList;
    this.allNotificationList$.next(this.allNotificationList);
  }

  /**
   *
   * @param notificationId
   */
  public decreasePushNotification(notificationId: string) {
    const pushNotification = [];
    this.allNotificationList.forEach(res => {
      if (res.notificationId !== notificationId) {
        pushNotification.push(res);
        this.pushNotificationService.decreaseUnreadPushNotificationCount();
      }
    });
    this.allNotificationList = pushNotification;
    this.allNotificationList$.next(this.allNotificationList);
  }

  /**
   *
   * @param pageNumber
   * @param recordPerPage
   * @param userId
   * @param orgCode
   * @param unitCode
   */
  public getAllNotification(userId:string, roleId: string) {    
    this.appBaseService.setResourceURL("/notification-service/");
    return this.appBaseService.postResource(
      "notification/getAllNotification",
      {
        "userId": userId,
        "roleId": roleId,
        "offset": this.offset,
        "limit": 5
      }
    );
  }

  /**
   *
   *
   * @param {string} userId
   * @param {string} roleId
   * @memberof AllPushNotificationsService
   */
  getMoreNotifications(userId:string, roleId: string) {
    this.appBaseService.setResourceURL("/notification-service/");
    this.appBaseService.postResource(
      "notification/getAllNotification",
      {
        "userId": userId,
        "roleId": roleId,
        "offset": this.offset,
        "limit": 5
      }
    )
    .subscribe(res => {
      if (res && res.notificationResponseList.length > 0) {
        res.notificationResponseList.forEach( notification => {
          this.allNotificationList.push(notification);
        });
        
        this.pushNotificationService.getUnreadPushNotificationCount(res.totalUnreadMessage);
        this.allNotificationList$.next(this.allNotificationList);
        this.offset++;
      }
    });
  }

  markAllRead(userId:string, roleId: string) {
    this.appBaseService.setResourceURL("/notification-service/");
    this.appBaseService.postResource(
      "notification/readAllNotification?read=true&userId=" + userId + "&roleId=" + roleId,
      {}
    )
    .subscribe(res => {
      if (res) {
        this.allNotificationList.forEach( notification => {
          notification.isRead = true;
        });
        this.pushNotificationService.getUnreadPushNotificationCount(0);
        this.allNotificationList$.next(this.allNotificationList);
      }
    });
  }
}

