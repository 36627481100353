<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">{{ data.display }}</h6>
      <span aria-label="Close" class="close" (click)="buttonClick('no')">
        <mat-icon>clear</mat-icon>
      </span>
    </div>
    <div class="modal-body bodyCreatePasscode">
      <p [innerHTML]="data.info"></p>
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button mat-mini-fab class="btnCyan" type="submit" data-automation-attribute="button-save-dialog">
          <mat-icon matTooltip="{{ 'payerInfo.ok' | translate }}" (click)="buttonClick('yes')">done</mat-icon>
        </button>
        <!-- <button mat-mini-fab class="btnRed" type="submit" data-automation-attribute="button-cancel-dialog">
          <mat-icon matTooltip="No" (click)="buttonClick('no')">close</mat-icon>
        </button> -->
      </div>
    </div>
  </form>
</div>