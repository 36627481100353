import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  AccumulationTheme,
  IAccLoadedEventArgs,
  IPointRenderEventArgs,
} from "@syncfusion/ej2-angular-charts";
import { Browser } from "@syncfusion/ej2-base";
import { Subject, Subscription } from "rxjs";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-donut",
  templateUrl: "./donut.component.html",
  styleUrls: ["./donut.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DonutComponent implements OnInit, OnDestroy {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  @Input() isMaximize;
  idName: any;
  dataSource: any[];
  public pointRender(args: IPointRenderEventArgs): void {
    this.SetTheme(args);
  }
  public SetTheme(args: IPointRenderEventArgs): void {
    let selectedTheme: string = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    let seriesColor = [
      "#FFE066",
      "#FAB666",
      "#F68F6A",
      "#F3646A",
      "#CC555A",
      "#9C4649",
    ];
    if (selectedTheme === "fluent" || selectedTheme === "bootstrap5") {
      args.fill = seriesColor[args.point.index % 10];
    }
    if (selectedTheme.indexOf("dark") > -1) {
      if (selectedTheme.indexOf("material") > -1) {
        args.border.color = "#303030";
      } else if (selectedTheme.indexOf("bootstrap5") > -1) {
        args.border.color = "#212529";
      } else if (selectedTheme.indexOf("bootstrap") > -1) {
        args.border.color = "#1A1A1A";
      } else if (selectedTheme.indexOf("tailwind") > -1) {
        args.border.color = "#1F2937";
      } else if (selectedTheme.indexOf("fluent") > -1) {
        args.border.color = "#252423";
      } else if (selectedTheme.indexOf("fabric") > -1) {
        args.border.color = "#201f1f";
      } else {
        args.border.color = "#222222";
      }
    } else if (selectedTheme.indexOf("highcontrast") > -1) {
      args.border.color = "#000000";
    } else {
      args.border.color = "#FFFFFF";
    }
  }
  //Initializing Legend
  public legendSettings: Object = {
    visible: false,
  };
  //Initializing DataLabel
  public dataLabel: Object = {
    visible: true,
    name: "dataLabelMappingName",
    position: "Outside",
    font: {
      fontWeight: "600",
    },
    connectorStyle: {
      length: "20px",
      type: "Curve",
    },
  };
  public tooltip: Object = {
    enable: true,
    format: "<b>${point.type}</b><br>Count: <b>${point.count_data}</b>",
    header: "",
  };
  public border: object = {
    width: 1,
  };
  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, "Dark")
        .replace(/contrast/i, "Contrast")
    );
  }
  // custom code end
  public radius: string = Browser.isDevice ? "40%" : "70%";
  public startAngle: number = Browser.isDevice ? 30 : 62;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let donut = document.getElementById("donut" + this.data.id) as HTMLElement;
    donut.style.height = card.offsetHeight - 32 + "px";
    donut.style.width = card.offsetWidth + "px";
  }
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            this.idName = this.data.id;
            if (obj.data && obj.data.length) {
              obj.data.map((element) => {
                arr.push({
                  type: element[this.data.dashboardEntity],
                  count: element.count_data,
                  dataLabelMappingName:
                    this.data.dashboardEntity +
                    "<br>" +
                    element[this.data.dashboardEntity],
                });
              });
            } 
            this.dataSource = arr;
          }
        }
      })
    );
    // setTimeout(() => {
      let card = document.getElementById(this.data.id);
      let donut = document.getElementById("donut") as HTMLElement; 
      donut.id = "donut" + this.data.id;
      donut.style.height = card.offsetHeight - 28 + "px";
      donut.style.width = card.offsetWidth + "px";
    // }, 100);
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
