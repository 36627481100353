export interface sampleDisplayListObj {
    mrn: string;
    patientName: string;
    identificationType:string;
    identificationNo: string;
    gender: string;
    age: string;
    priority: {
        routine: number;
        urgent: number;
        stat: number;
    },
    acceptedC?: {
        count: number;
        color: string;
    };
    rejectedC: {
        count: number;
        color: string;
    };
    orderedC?: {
        count: number;
        color: string;
    };
    collectedC?: {
        count: number;
        color: string;
    };
    cancelledC?: {
        count: number;
        color: string;
    };
    holdC: {
        count: number;
        color: string;
    };
    receivedC?: {
        count: number;
        color: string;
    };
    otherStatus: {
        count: number;
        color: string;
    };
    checked: boolean;
    listSampleDetails: sampleDetails[];
    listSampleDetailsGroup: sampleDetails[];
    labNo?: string;
    location?:string;
    isSelected?:boolean
    serviceDetails?: {
        id?: number,
        code?: string,
        desc?: string
    },
    doctorDetails?: {
        id?: number,
        code?: string,
        desc?: string
    },
    clinicalPriorityDetails?: {
        id?: number,
        code?: string,
        desc?: string
    },
    visitId?: number,
    visitNo?:string,
    patientId?: number,
    id?: number,
    clinicareOrderId?: string,
    isPselect?:boolean
}
export interface sampleDetails {
    doctorName: string;
    sampleDetailsId:number;
    id: number;
    visitNo: string;
    labNo: string;
    resultId?:number;
    location?:string;
    print?:boolean;
    test: string;
    container: string;
    containerColorCode: string;
    subDepartmentMaster: string;
    subDeptmentRefId?:number;
    priority: string;
    doctor: string;
    orderOn: Date;
    screenStatus: baseObject;
    businessStatus: baseObject;
    billStatus?:baseObject;
    sample: string;
    sampleVolume: number;
    profile: string;
    profileDesc?:string;
    info: string;
    isPselect: boolean;
    departmentflag: boolean;
    occurrenceDateTime?: Date;
    performer?: any;
    createdDate?: Date;
    sampleDate?:Date;
    sampleNumber?: string;
    testDetails?: testDetailsObj;
    testDetailsId?:number;
    visitType?: baseObject;
    testDesc?: string;
    statColor?: string;
    dueDate?: string;
    alertColor?:string;
    refSampleDtlsId:any;
    testResultTypeStatus?: {
        id: string;
        desc: string;
        colorCode: string;
    }
    mrn: string;
    patientName: string;
    identificationNo: string;
    gender: string;
    age: string;
    isAddendum:boolean;
    dob:string;
    serviceId?:number;
    visitId?:number
    labResultDetailsResponseList:any;
    sampleNumberDtlsId:number

};
export interface baseObject {
    id: number;
    code: string;
    desc: string;
    isAlert?:boolean;
    isAllowMultipleQuantity?: boolean;
    isDoctorRequired?: boolean;
    colourCode?: string;
    isArrowUp?: boolean;
    isBold?: boolean;
    templateData?: string;
    savedId?: number;
    reasonId?: number;
  };
  export interface searcPayloadOrders {
    offset: number,
    limit: number,
    fromDate?: number,
    toDate?: number,
    screenStatus?: string,
    patientName?: string,
    mrn?: string,
    identificationNo?:string,
    visitNo?: string,
    testId?:number,
    priorityId?:number,
    statusId?:number,
    serviceId?:number,
    visitTypeId?:number,
    groupCode?:string,
    typeCode?:string,
    categoryId?:number,
    doctorId?:number
    visitTypeCode?:string,
  
}
export interface searchGlobalPayload {
    mrn?: string;
    patientName?: string;
    identificationNo?: string;
    categoryId?: number;
    testId?: number;
    sampleTypeId?:number
    priorityId?: number;
    serviceId?:number;
    screenStatus?: string;
    offset: number;
    limit: number;
    fromDate?: number;
    toDate?: number;
    testResultTypeStatus?: string;
    sampleNumber?: string;
    department?: string;
    labNo?: string;
    sampleId?: string;
    cultureResultId?:number
    statusId?:number
    processStatusId?:number
    identificationId?:string
    visitNo?:string
    doctorId?:number
    wardId?:number
};
export interface sampleMasterResponseDTOList {
    id: number;
    mrn: string;
    patientName: string;
    identificationType:string;
    identificationNo: string;
    gender: string;
    priority: string;
    active: boolean;
    dob: Date;
    age: string;
    labNo?: string;
    listSampleDetailsResponseDTO: sampleDetails[];
    sampleDetailsResultResponseList?:sampleDetails[];
    doctorOrderDetailsResponseList?:sampleDetails[];

    totalPages: number;
    totalElements: number;
    location:string;
    patientId:number
};
export interface sampleDetails {
    doctorName: string;
    sampleDetailsId:number;
    id: number;
    visitNo: string;
    labNo: string;
    resultId?:number;
    location?:string;
    print?:boolean;
    test: string;
    container: string;
    containerColorCode: string;
    subDepartmentMaster: string;
    subDeptmentRefId?:number;
    priority: string;
    doctor: string;
    orderOn: Date;
    screenStatus: baseObject;
    businessStatus: baseObject;
    billStatus?:baseObject;
    sample: string;
    sampleVolume: number;
    profile: string;
    profileDesc?:string;
    info: string;
    isPselect: boolean;
    departmentflag: boolean;
    occurrenceDateTime?: Date;
    performer?: any;
    createdDate?: Date;
    sampleDate?:Date;
    sampleNumber?: string;
    testDetails?: testDetailsObj;
    testDetailsId?:number;
    visitType?: baseObject;
    testDesc?: string;
    statColor?: string;
    dueDate?: string;
    alertColor?:string;
    refSampleDtlsId:any;
    testResultTypeStatus?: {
        id: string;
        desc: string;
        colorCode: string;
    }
    mrn: string;
    patientName: string;
    identificationNo: string;
    gender: string;
    age: string;
    isAddendum:boolean;
    dob:string;
    serviceId?:number;
    visitId?:number
    labResultDetailsResponseList:any;
    sampleNumberDtlsId:number

};
export interface testDetailsObj {
    id: number;
    code: string;
    desc: string;
    printName: string;
    categoryName: string;
    testParameterDetails: testParameterList[];
    testTemplateDetails: templateDetail[];
    resultType: baseObject;
    serviceVolume: number;
    sampleType: baseObject;
    specimenType: string;
    containerType: baseObject;
    lioncCode: string;
    methodMaster: baseObject;
    printSequence: string;
    processTime: number;
    ststProcessTime: string;
    printInstructions: boolean;
    printFootNote: boolean;
    confidential: boolean;
    printOnNextPage: boolean;
    remarks: string;
    instructions: string;
    footNote: string;
}

export interface templateDetail {
    templateMaster: templateData;
}
export interface templateData {
    code: string;
    desc: string;
    id: number;
    templateData: string;
}

export interface testParameterList {
    headerRequired: boolean;
    headerMaster: baseObject;
    sequence: number;
    parameterMaster: paramerterMaster;
}

export interface paramerterMaster {
    id: number;
    code: string;
    desc: string;
    labUnitMaster: baseObject;
    formulaDetail: string;
    printName: string;
    defaultValues: string;
    defaultComments: string;
    precision: string;
    deltaValue: string;
    deltaDuration: string;
    min: number;
    max: number;
    cMin: number;
    cMax: number;
    mMin: number;
    mMax: number;
    fieldColor: string;
    arrowUp: boolean;
    arrowDown: boolean;
    parameterReferenceRangeType: baseObject;
    parameterReference: parameterDetails[];
    parameterHelpValue: baseObject[];
    firstResult: string;
    firstResultEnteredBy: string;
    firstResultEnteredOn: Date;
    secondResult: string;
    secondResultEnteredBy: string;
    secondResultEnteredOn: Date;
    finalResult: string;
    finalResultEnteredBy: string;
    finalResultEnteredOn: Date;
    formula: boolean;
    optional: boolean;
    commentRequired: boolean;
    deltaRequired: boolean;
    //user defined
    testValue?: string;
    resultType?: string;
    userComments?: string;
    totalDeltaList?: any[];
    additionalParamRef?: any;
    isRetest?:boolean;
    operator?:string;
    criOperator?:string;
}
export interface parameterDetails {
    id: number;
    methodMaster: baseObject;
    instrumentMaster: string;
    parameterReferenceDetail: {
        normalRange: string;
        criticalRange: string;
        maxRange?: string;
        remarks: string;
    },
}
export interface groupBioSampleList {
    biochemistry: sampleDetails[];
    hematology: sampleDetails[];
    microbiology: sampleDetails[];
    histopathology: sampleDetails[];
    histology: sampleDetails[];
    cytology: sampleDetails[];
    virology: sampleDetails[];
    serology: sampleDetails[];
    otherGroup: sampleDetails[];
}

export enum SubDepartmentEnum {
    biochemistry = "BIOCHEMISTRY",
    haematoloty = "HAEMATOLOGY",
    microbiology = "MICROBIOLOGY",
    histopathology = "HISTOPATHOLOGY",
    histology = "HISTOLOGY",
    cytology = "CYTOLOGY",
    virology = "VIROLOGY",
    serology = "SEROLOGY"
};
export const LAB_TABLE_PROPS = {
    phlebotomyColumnName: [
        "patientDetails",
        "mrn",
        "gender",
        "identificationType",
        "IdentificationNo.",
        "priority",
        "status",
      ],
}

  
