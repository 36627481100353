<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">
        {{ "AppointmentListPage.confirm" | translate }}
      </h6>
      <span aria-label="Close" class="close" (click)="buttonClick('no')">
        <mat-icon matTooltip="{{ 'common.close' | translate }}">clear</mat-icon>
      </span>
    </div>
    <div class="modal-body bodyCreatePasscode">
      <p>
        {{ translatedText }}
      </p>
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button
          mat-mini-fab
          class="btnCyan"
          type="submit"
          data-automation-attribute="button-save-dialog"
        >
          <mat-icon
            matTooltip="{{ 'payerInfo.yes' | translate }}"
            (click)="buttonClick('yes')"
            >done</mat-icon
          >
        </button>
        &nbsp;
        <button
          mat-mini-fab
          class="btnRed"
          type="submit"
          data-automation-attribute="button-cancel-dialog"
        >
          <mat-icon
            matTooltip="{{ 'payerInfo.no' | translate }}"
            (click)="buttonClick('no')"
            >close</mat-icon
          >
        </button>
      </div>
    </div>
  </form>
</div>
