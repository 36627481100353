import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'camelCase'
})
export class CamelCasePipe extends TranslatePipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @param args 
   * @returns camelCase
   */
  transform(value: any, args?: any): any {
    const data = value;
    return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
  }
}
