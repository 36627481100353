import { Injectable } from '@angular/core';
import { OPD_REPORT_TYPE } from '../regproperties/opd-report-properties';
import { OPD_CONST } from '../regproperties/opd-static-properties';
import { opdNavigation } from '../regproperties/opd-navigation-properties';

@Injectable({
  providedIn: 'root'
})
export class PdpaFormService {

  constructor() { }
  generateReports(encounterId: string, reportname: string) {
    let reqPayload = {
      transactionId: encounterId,
      type: OPD_REPORT_TYPE.pdf,
      reportName: reportname,
      isReports: false,
    };
    localStorage.setItem("pdpaformPayload", JSON.stringify(reqPayload));
    window.open(opdNavigation.REPORT_NAVIGATE+"3");
  }
}
