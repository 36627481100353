import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppBaseService } from "../../services/http.service";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientService } from "./../../services/patient.service";
import { DatePipe } from "@angular/common";
import { MICROSERVICES } from "../../constants/web-services";
import { codable, RegisterModel } from "../../services/RegistationModel";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { dataTool } from "echarts";
import { I } from "@angular/cdk/keycodes";
import { MASTERSSERVICES } from "../../constants/master-service";

@Component({
  selector: "app-patient-search",
  templateUrl: "./patient-search.component.html",
  styleUrls: ["./patient-search.component.scss"],
  providers: [DatePipe],
})
export class PatientSearchComponent implements OnChanges, OnDestroy {
  firstChange: boolean;
  hasSearched: boolean = false;
  @Input() search: {
    isDocService: boolean;
    startDate: string;
    endDate: string;
    identificationID: string;
    patientName: string;
    MRN: string;
    gender: string;
    identificationType: string;
    unit: string;
    mobileNo: string;
    dob: string;
    country: string;
    state: string;
    offset?: number;
    limit?: number;
    city: string;
    pincode: string;
    isExternalPatient: boolean;
    isAncillaryService: boolean;
    isAncillaryServiceDoc: boolean;
    isStockService: boolean;
    visitType: string;
    isStockServiceCounselling: boolean;
    userType?: string;
    employeeNo?: string;
    empoyeeName?: string;
  } = {
    startDate: "",
    endDate: "",
    identificationID: "",
    patientName: "",
    MRN: "",
    gender: "",
    identificationType: "",
    unit: "",
    mobileNo: "",
    dob: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    isExternalPatient: false,
    isAncillaryService: false,
    isStockService: false,
    visitType: "",
    isStockServiceCounselling: false,
    isAncillaryServiceDoc: false,
    userType: "",
    employeeNo: "",
    empoyeeName: "",
    isDocService:false
  };
  @Input() list: any;
  @Input() refreshTime: string;
  @Input() hideAfter: boolean = false;
  @Output() onActionClick: EventEmitter<PatientDTOObject> =
    new EventEmitter<PatientDTOObject>();
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  advancedSearch: any;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = [
    "selectRadio",
    "photo",
    "MRN",
    "firstName",
    "gender",
    "phoneNumber",
    "dob",
    "identification",
    "docID",
  ];
  patientList: any[]=[];
  searchCount: number = null;
  name: string;
  dateOfBirth: string;
  birthOfDate:string;
  age: string;
  ages?:string;
  gender: string;
  mrnNumber: string;
  photo:any;
  patientId: string;
  showTable: boolean = true;
  loadingData = false;
  payloadData: any = {};
  totalElements: number;
  heightClass: string = 'height-min';
  enableScroll: boolean = false;
  constructor(
    private baseservice: AppBaseService,
    private loadingBar: LoadingBarService,
    public snackBar: MatSnackBar,
    private patientService: PatientService,
    private datepipe: DatePipe,
    private http: HttpClient
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.refreshTime) {
      this.firstChange = changes.refreshTime.firstChange;
    } else {
      this.firstChange = true;
    }
    if (!this.firstChange) {
      if (!this.list && this.search) {
        if (this.search.isAncillaryService) {
          this.getAncillaryPatientData(MICROSERVICES.ANCILLARY_SERVICE);
        } else if (this.search.isStockService) {
          this.getStockPayloadtData(MICROSERVICES.STOCK_SERVICE);
        } else if (this.search.isStockServiceCounselling) {
          this.getCounsellingStockPatientData(MICROSERVICES.STOCK_SERVICE);
        } else if(this.search.isDocService){
          this.getDocServicePatientData(MICROSERVICES.DOC_SERVICE);
        } else {
          if (
            this.search.userType &&
            (this.search.userType.toLocaleUpperCase() == "STAFF" ||
              this.search.userType.toLocaleUpperCase() == "STUDENT" ||
              this.search.userType.toLocaleUpperCase() == "DOCTOR")
          ) {
            this.getDataList(this.search.userType.toLocaleUpperCase());
          } else this.getPatientList(false);
        }
      } else {
        let patientList: RegisterModel[] = [];
        this.list.forEach((patient) => {
          let patientModel = this.patientService.getPatientModel(patient);
          if (patientModel) patientList.push(patientModel);
        });
        this.displayElasticList(patientList);

        if (!this.search) this.showTable = true;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    if (this.advancedSearch && this.advancedSearch.unsubscribe())
      this.advancedSearch.unsubscribe();
  }

  getPatientList(isScroll) {
    this.patientSearchFieldFormatting();

    const startDate = this.search.startDate
      ? this.patientService.dateToEpochConverter(
          new Date(this.search.startDate)
        )
      : "";
    const endDate = this.search.endDate
      ? this.patientService.dateToEpochConverter(new Date(this.search.endDate))
      : "";

    const birthDate = this.search.dob
      ? this.datepipe.transform(this.search.dob, "yyyy-MM-dd")
      : "";
    let queryParams =
      "operation=search" +
      "&identificationId=" +
      (this.search.identificationID ? this.search.identificationID : "") +
      "&name=" +
      (this.search.patientName
        ? encodeURIComponent(this.search.patientName)
        : "") +
      "&mrn=" +
      (this.search.MRN ? this.search.MRN : "") +
      "&gender=" +
      (this.search.gender ? this.search.gender : "") +
      "&unitCode=" +
      (this.search.unit ? this.search.unit : "") +
      "&city=" +
      (this.search.city ? this.search.city : "") +
      "&identificationType=" +
      (this.search.identificationType ? this.search.identificationType : "") +
      "&frDate=" +
      startDate +
      "&toDate=" +
      endDate +
      "&id=" +
      "&mobile=" +
      (this.search.mobileNo ? this.search.mobileNo : "") +
      "&birthDate=" +
      birthDate +
      "&offset=" +this.search.offset+
      "&size=" +10+
      "&pincode=" +
      (this.search.pincode ? this.search.pincode : "") +
      "&country=" +
      (this.search.country ? this.search.country : "") +
      "&state=" +
      (this.search.state ? this.search.state : "");

    this.baseservice.setResourceURL(MICROSERVICES.OPD_SERVICE);
    this.advancedSearch = this.baseservice
      .getResource("extendedPatient/filter?" + queryParams)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any[]) => {
        this.hasSearched = true;
        this.searchCount = res.length;
        this.loadingBar.complete();
        this.displayList(res,isScroll);
        this.loadingData = false;
      });
  }

  patientSearchFieldFormatting() {
    if(this.search.identificationID && this.search.identificationID.includes("-")) {
      this.search.identificationID = this.search.identificationID.split("-").join("");
    }

    if(this.search.mobileNo && this.search.mobileNo.includes("-")) {
      this.search.mobileNo = this.search.mobileNo.replace("-","");
    }
  }

  getDataList(type) {
    let value = type == "STAFF" ? "STAFF_DOCTOR" : "STUDENT_MASTER";

    let payload = {
      constEnum: value,
      employeeNo: this.search.employeeNo ? this.search.employeeNo : "",
      doctorName: this.search.patientName ? this.search.patientName : "",
      gender: this.search.gender ? this.search.gender : "",
      dob: this.search.dob
        ? this.patientService.dateToEpochConverter(new Date(this.search.dob))
        : null,
      identificationType: this.search.identificationType
        ? this.search.identificationType
        : "",
      identificationNo: this.search.identificationID
        ? this.search.identificationID
        : "",
      pinCode: this.search.pincode ? this.search.pincode : "",
      unitCode : this.search.unit ? this.search.unit :"",
      mobileNo: this.search.mobileNo ? this.search.mobileNo : "",
      countryCode: this.search.country ? this.search.country : "",
      stateCode: this.search.state ? this.search.state : "",
      cityCode: this.search.city ? this.search.city : "",
      limit: 10,
      offset: 0,
    };
    this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.advancedSearch = this.baseservice
      .postResource(MASTERSSERVICES.EMPLOYEE_OTHER_LIST, payload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any[]) => {
        this.hasSearched = true;
        this.searchCount = res.length;
        this.displayList(res,false);
      });
  }
  getAncillaryPatientData(serviceName: string) {
    const startDate = this.search.startDate
      ? this.patientService.dateToEpochConverter(
          new Date(this.search.startDate)
        )
      : "";
    const endDate = this.search.endDate
      ? this.patientService.dateToEpochConverter(new Date(this.search.endDate))
      : "";

    const birthDate = this.search.dob
      ? this.datepipe.transform(this.search.dob, "yyyy-MM-dd")
      : "";

    const date = this.search.dob
      ? this.patientService.dateToEpochConverter(new Date(this.search.dob))
      : "";
    let body = this.search.isAncillaryServiceDoc
      ? {
          mrn: this.search.MRN ? this.search.MRN : null,
          patientName: this.search.patientName ? this.search.patientName : null,
          identificationNo: this.search.identificationID
            ? this.search.identificationID
            : null,
          mobileNo: this.search.mobileNo ? this.search.mobileNo : null,
          identificationTypeId: this.search.identificationType
            ? Number(this.search.identificationType)
            : null,
          dateOfBirth: date ? Number(date) : null,
          gender: this.search.gender ? Number(this.search.gender) : null,
          offset: this.search.offset ? this.search.offset : null,
          limit: this.search.limit ? this.search.limit : 0,
        }
      : {
          mrn: this.search.MRN ? this.search.MRN : "",
          patientName: this.search.patientName ? this.search.patientName : "",
          identificationNo: this.search.identificationID
            ? this.search.identificationID
            : "",
          mobileNo: this.search.mobileNo ? this.search.mobileNo : "",
          countryId: this.search.country ? this.search.country : "",
          stateId: this.search.state ? this.search.state : "",
          identificationTypeId: this.search.identificationType
            ? this.search.identificationType
            : "",
          dob: birthDate,
          gender: this.search.gender ? this.search.gender : "",
          visitFromDate: startDate ? startDate : 0,
          visitToDate: endDate ? endDate : 0,
          visitRequired: true,
          glRequired: true,
          visitType: this.search.visitType
            ? this.search.visitType == "all"
              ? ""
              : this.search.visitType
            : "OP",
        };

    this.baseservice.setResourceURL(serviceName);
    this.advancedSearch = this.baseservice
      .postResource(
        this.search.isAncillaryServiceDoc
          ? "patientSearch/getPatient"
          : "patientSearch/get",
        body
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any[]) => {
        this.hasSearched = true;
        this.searchCount = res.length;
        this.loadingBar.complete();
        this.search.isAncillaryServiceDoc
          ? this.displayAncillaryService(res)
          : this.patientList=[];this.displayStockService(res);
      });
  }

  getStockPayloadtData(serviceName: string) {
    this.patientList =[];
    const startDate = this.search.startDate
      ? this.patientService.dateToEpochConverter(
          new Date(this.search.startDate)
        )
      : "";
    const endDate = this.search.endDate
      ? this.patientService.dateToEpochConverter(new Date(this.search.endDate))
      : "";

    const birthDate = this.search.dob
      ? this.datepipe.transform(this.search.dob, "yyyy-MM-dd")
      : 0;

    let body = {
      mrn: this.search.MRN ? this.search.MRN : "",
      patientName: this.search.patientName ? this.search.patientName : "",
      identificationNo: this.search.identificationID
        ? this.search.identificationID
        : "",
      mobileNo: this.search.mobileNo ? this.search.mobileNo : "",
      countryId: this.search.country ? this.search.country : "",
      stateId: this.search.state ? this.search.state : "",
      identificationTypeId: this.search.identificationType
        ? this.search.identificationType
        : "",
      dob: birthDate,
      gender: this.search.gender ? this.search.gender : "",
      limit: 10,
      offset: 0,
    };
    this.payloadData = body;
    this.getStockPatientData(this.payloadData, serviceName);
  }

  getStockPatientData(payloadData: any, serviceName: string) {
    this.baseservice.setResourceURL(serviceName);
    this.advancedSearch = this.baseservice
      .postResource("patientSearch/getPatient", payloadData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: { totalElements: number; patientResponseList: any[] }) => {
        if (res.totalElements != 0) {
        this.totalElements = res.totalElements; 
        this.hasSearched = true;
        this.searchCount = res.patientResponseList.length;
        this.loadingBar.complete();
        this.displayStockService(res.patientResponseList);
        this.loadingData = false;
        this.hideAfter = false;
        }
        else{
          this.snackBar.open("Info", "No patients found matching this search", {
            duration: 2000,
          });
          this.hideAfter = true;
        }
      });
  }


  displayAncillaryService(patients) {
    this.patientList = [];
    patients.patientResponseList.forEach((patient) => {
      try {
        let patientAge: any = this.patientService.calculateAge(patient.dob);
        let age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        if (patient.patientName) {
          let data = new PatientDTOObject();
          data.id = patient.id;
          data.photo = patient.image;
          data.dateOfBirth = patient.dob;
          data.fullName = patient.patientName;
          data.mrn = patient.mrn;
          data.gender = patient.gender;
          data.title = patient.prefix;
          data.identifierType = patient.identification;
          data.idNo = patient.identificationNo;
          data.emailId = patient.email;
          data.mobileNumber = patient.mobileNo;
          data.visitList = patient.visitList ? patient.visitList : null;
          data.extendedAddress = patient.primaryAddress;
          data.age = age;

          this.patientList.push(data);
        }
      } catch (ex) {
        console.log(ex);
      }
    });

    if (this.patientList.length > 0) {
      this.updatePatientList(this.patientList);
      this.dataSource = new MatTableDataSource<any>(this.patientList);
      this.showTable = true;
      if (this.patientList.length == 1) {
        this.patientOnSelect(this.patientList[0]);
      }
    } else {
      this.showTable = false;
    }
  }

  displayStockService(patients: any[]) {
    patients.forEach((patient) => {
      try {
        let patientAge: any = this.patientService.calculateAge(patient.dob);
        let age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        if (patient.patientName) {
          let data = new PatientDTOObject();
          data.id = patient.id;
          data.photo = patient.image;
          data.dateOfBirth = patient.dob;
          data.fullName = patient.patientName;
          data.mrn = patient.mrn;
          data.gender = patient.gender?patient.gender:(patient.genderDetails?patient.genderDetails.desc:"");
          data.title = patient.prefix?patient.prefix:(patient.prefixDetails?patient.prefixDetails.desc:"");
          data.identifierType = patient.identification
            ? patient.identification
            : (patient.identificationDetails?patient.identificationDetails.desc:"");
          data.idNo = patient.identificationNo;
          data.emailId = patient.email;
          data.mobileNumber = patient.mobileNo;
          data.visitList = patient.visitList ? patient.visitList : null;
          data.extendedAddress = patient.primaryAddress;
          data.age = age;
          this.patientList.push(data);
        }
      } catch (ex) {
        console.log(ex);
      }
    });
    if (this.patientList.length > 0) {
      this.updatePatientList(this.patientList);
      this.dataSource = new MatTableDataSource<any>(this.patientList);
      this.showTable = true;
      if (this.patientList.length == 1) {
        this.patientOnSelect(this.patientList[0]);
      }
    } else {
      this.showTable = false;
    }
  }


  displayList(patients: any[],isScroll:boolean) {
    if(!isScroll){
    this.patientList = [];
    }
    patients.forEach((patient) => {
      try {
        if (patient.id && patient.name) {
          let data = new PatientDTOObject();

          //Basic Information
          data.id = patient.id;
          data.mrn = patient.mrn;
          data.active=patient.active;
          data.isStaffActive=patient.isStaffActive;
          data.identifierType = patient.primaryIdentifier
            ? patient.primaryIdentifier.display
            : "";
          data.idNo = patient.primaryIdentifier
            ? patient.primaryIdentifier.value
            : "";
          data.expiryDate = patient.primaryIdentifier
            ? patient.primaryIdentifier.expiryDate
            : "";
          data.secondaryIdType = patient.secondaryIdentifier
            ? patient.secondaryIdentifier.code
            : "";
          data.secondaryNo = patient.secondaryIdentifier
            ? patient.secondaryIdentifier.value
            : "";
          data.idIssueCountry = patient.primaryIdentifier
            ? patient.primaryIdentifier.issuingCountryList
              ? patient.primaryIdentifier.issuingCountryList[0].code
              : ""
            : "";
          data.faculty = patient.faculty ? patient.faculty.code : "";
          data.newBorn = patient.isNewBornBaby ? patient.isNewBornBaby : false;
          data.birthTime = patient.birthTime ? patient.birthTime : "";
          data.vip = patient.vip ? patient.vip : false;
          data.countryCode = patient.mobileNumber
            ? patient.mobileNumber.countryCode
            : "";
          data.seondaryExpiryDate = patient.secondaryIdentifier
            ? patient.secondaryIdentifier.expiryDate
            : "";
            data.photo = patient.photo && patient.photo[0] ? (patient.photo[0].url || patient.photo[0].aws3FileUrl || null) : null;
          //ends here

          //Personal Information
          data.title = patient.title ? patient.title.code : "";
          data.fullName = patient.patientName ? patient.patientName : "";
          data.gender = patient.gender ? patient.gender.display : "";
          data.martialStatus = patient.maritalStatus
            ? patient.maritalStatus.code
            : "";
          data.religion = patient.religion ? patient.religion.code : "";
          data.dateOfBirth = patient.birthDate ? patient.birthDate : "";
          data.real = patient.isReal ? patient.isReal : false;
          data.patientCategory = patient.patientCategory
            ? patient.patientCategory.code
            : "";
          data.preferedLanguage = patient.preferedLanguage
            ? patient.preferedLanguage.code
            : "";
          data.nationality = patient.nationality
            ? patient.nationality.code
            : "";
          data.motherName = patient.motherName ? patient.motherName : "";
          data.education = patient.education ? patient.education.code : "";
          data.occupation = patient.occupation ? patient.occupation.code : "";
          data.race = patient.race ? patient.race.code : "";
          data.birthlocation = patient.birthLocation
            ? patient.birthLocation
            : "";
          data.ethnic = patient.ethnic ? patient.ethnic.code : "";
          data.bloodGroup = patient.bloodGroup ? patient.bloodGroup.code : "";
          data.source = patient.source ? patient.source.code : "";
          data.deceased = patient.deceasedPatient
            ? patient.deceasedPatient.deceased
            : false;
          data.blocked = patient.isBlocked ? patient.isBlocked : false;
          data.ctos = patient.isCTOS ? patient.isCTOS : false;
          data.patientNotes = patient.patientNotes
            ? patient.patientNotes.note
            : "";
          data.merged = patient.merged ? patient.merged.status : false;
          //ends here

          //Address Details
          data.extendedAddress = new ExtendedAddress();
          data.correspondingAddress = new ExtendedAddress();
          data.extendedAddress.addressLine = patient.extendedAddress
            ? patient.extendedAddress.addressLine
            : "";
          (data.extendedAddress.country =
            patient.extendedAddress && patient.extendedAddress.country
              ? patient.extendedAddress.country.code
              : ""),
            (data.extendedAddress.state =
              patient.extendedAddress && patient.extendedAddress.state
                ? patient.extendedAddress.state.code
                : "");
          data.extendedAddress.city =
            patient.extendedAddress && patient.extendedAddress.city
              ? patient.extendedAddress.city.code
              : "";
          data.extendedAddress.area =
            patient.extendedAddress && patient.extendedAddress.area
              ? patient.extendedAddress.area.code
              : "";
          data.extendedAddress.postCode = patient.extendedAddress
            ? patient.extendedAddress.postCode
            : "";
          data.correspondingAddress.addressLine = patient.correspondingAddress
            ? patient.correspondingAddress.addressLine
            : "";
          data.correspondingAddress.country =
            patient.correspondingAddress && patient.correspondingAddress.country
              ? patient.correspondingAddress.country.code
              : "";
          data.correspondingAddress.state =
            patient.correspondingAddress && patient.correspondingAddress.state
              ? patient.correspondingAddress.state.code
              : "";
          data.correspondingAddress.city =
            patient.correspondingAddress && patient.correspondingAddress.city
              ? patient.correspondingAddress.city.code
              : "";
          data.correspondingAddress.area =
            patient.correspondingAddress && patient.correspondingAddress.area
              ? patient.correspondingAddress.area.code
              : "";
          data.correspondingAddress.postCode = patient.correspondingAddress
            ? patient.correspondingAddress.postCode
            : "";
          //ends here

          //Contact Details
          data.mobileNumber = patient.mobileNumber
            ? patient.mobileNumber.mobileNumber
            : "";
          data.relation = patient.mobileNumber
            ? patient.mobileNumber.relationList
              ? patient.mobileNumber.relationList[0].code
              : ""
            : "";
          data.emailId = patient.emailId ? patient.emailId : "";
          data.homeContactNo = patient.homeContactNo
            ? patient.homeContactNo
            : "";
          data.workContactNo = patient.workContactNo
            ? patient.workContactNo
            : "";
          data.personalFax = patient.personalFax ? patient.personalFax : "";
          data.workFax = patient.workFax ? patient.workFax : "";
          //ends here

          //employment details
          let employentDetails = patient.employeeDetails;
          let employementAddress =
            patient.employeeDetails && patient.employeeDetails.employeeAdress
              ? patient.employeeDetails.employeeAdress
              : null;

          data.employmentStatus =
            employentDetails && employentDetails.employmentStatus
              ? employentDetails.employmentStatus[0].code
              : "";
          data.employerName =
            employentDetails && employentDetails.employer
              ? employentDetails.employer[0].code
              : null;
          (data.employerNameOthers =
            employentDetails && employentDetails.other
              ? employentDetails.other
              : ""),
            (data.employmentNo =
              employentDetails && employentDetails.employmentNo
                ? employentDetails.employmentNo
                : ""),
            (data.employmentContactNo =
              employentDetails && employentDetails.contactNo
                ? employentDetails.contactNo
                : ""),
            (data.workingFrom =
              employentDetails && employentDetails.workingFrom
                ? new Date(employentDetails.workingFrom)
                : null);

          if (employementAddress) {
            data.employmentCountry =
              employementAddress && employementAddress.country
                ? employementAddress.country.code
                : "";
            data.employmentState =
              employementAddress && employementAddress.state
                ? employementAddress.state.code
                : "";
            data.employmentCity =
              employementAddress && employementAddress.city
                ? employementAddress.city.code
                : "";
            data.employmentAddress =
              employementAddress && employementAddress.addressLine
                ? employementAddress.addressLine
                : "";
            data.employmentPostCode =
              employementAddress && employementAddress.postCode
                ? employementAddress.postCode
                : "";
          }
          //ends here

          this.patientList.push(data);
        }
      } catch (ex) {}
    });
    if (this.patientList.length > 0) {
      this.updatePatientList(this.patientList);
      this.dataSource = new MatTableDataSource<any>(this.patientList);
      this.showTable = true;
      if (this.patientList.length == 1) {
        this.onActionClick.emit(this.patientList[0]);
        this.showTable = false;
      }
    } else {
      this.showTable = false;
    }
    // if (this.patientList.length > 0) {
    //   this.dataSource = new MatTableDataSource<any>(this.patientList);
    //   this.showTable = true;
    //   if (this.patientList.length == 1) {
    //     this.patientOnSelect(this.patientList[0]);
    //   }
    // } else {
    //   this.showTable = false;
    // }
  }

  displayElasticList(patients: RegisterModel[]) {
    this.patientList = [];
    patients.forEach((patient) => {
      try {
        if (patient.MongoId && patient.firstName) {
          let data = new PatientSearchModel();
          data.id = patient.MongoId;
          data.image = patient.photo;
          data.birthDate = patient.dob;
          data.name = patient.firstName;
          data.mrn = patient.MRN;
          data.gender = patient.gender;
          data.primaryIdentifierType = patient.primaryIDdesc;
          data.primaryIdentifierValue = patient.primaryId;
          data.isVip = "No";
          data.email = patient.emailId;
          data.mobilePhone = patient.phoneNumber;

          this.patientList.push(data);
        }
      } catch (ex) {}
    });

    if (this.patientList.length > 0) {
      this.updatePatientList(this.patientList);
      this.dataSource = new MatTableDataSource<any>(this.patientList);
      this.showTable = true;
      if (this.patientList.length == 1) {
        this.patientOnSelect(this.patientList[0]);
      }
    } else {
      this.showTable = false;
      this.name = undefined;
    }
  }

  patientOnSelect(selectedPatient) {
    if (!this.search.isExternalPatient) {
      // if (this.search.isAncillaryService || this.search.isStockService) {
      //   this.name = selectedPatient.fullName;
      //   this.dateOfBirth = selectedPatient.dateOfBirth;
      //   let patientAge: any = this.patientService.calculateAge(
      //     selectedPatient.dateOfBirth
      //   );
      //   this.age =
      //     patientAge.years + " " + patientAge.months + " " + patientAge.days;
      //   this.ages =
      //   patientAge.years +"ears";
      //   this.gender = selectedPatient.gender;
      //   this.mrnNumber = selectedPatient.mrn;
      // }
      if (this.search.isAncillaryService) {
        this.name = selectedPatient.fullName;
        this.dateOfBirth = selectedPatient.dateOfBirth;
        this.birthOfDate = selectedPatient.dateOfBirth ? this.datepipe.transform(selectedPatient.dateOfBirth, "dd/MM/yyyy"): "";
        let patientAge: any = this.patientService.calculateAge(
          selectedPatient.dateOfBirth
        );
        this.ages =
        patientAge.years +"ears";
        this.age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        this.gender = selectedPatient.gender;
        this.mrnNumber = selectedPatient.mrn;
        this.photo = selectedPatient.photo;
      }else if(this.search.isStockService){
        this.name = selectedPatient.fullName;
        let dateBirth = this.patientService.epochToDateConverter(selectedPatient.dateOfBirth);
        this.dateOfBirth = selectedPatient.dateOfBirth;
        this.birthOfDate = dateBirth ? this.datepipe.transform(dateBirth, "dd/MM/yyyy"): "";
        let patientAge: any = this.patientService.calculateAge(
          selectedPatient.dateOfBirth
        );
        this.ages =
        patientAge.years +"ears";
        this.age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        this.gender = selectedPatient.gender;
        this.mrnNumber = selectedPatient.mrn;
        this.photo = selectedPatient.photo;
      }if (this.search.isDocService) {
        this.name = selectedPatient.fullName;
        this.dateOfBirth = selectedPatient.dateOfBirth;
        this.birthOfDate = selectedPatient.dateOfBirth ? this.datepipe.transform(selectedPatient.dateOfBirth, "dd/MM/yyyy"): "";
        let patientAge: any = this.patientService.calculateAge(
          selectedPatient.dateOfBirth
        );
        this.ages =
        patientAge.years +"ears";
        this.age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        this.gender = selectedPatient.gender;
        this.mrnNumber = selectedPatient.mrn;
        this.photo = selectedPatient.photo;
      }
      this.onActionClick.emit(selectedPatient);
      this.showTable = false;
    } else {
      this.name = selectedPatient.name;
      this.dateOfBirth = selectedPatient.birthDate;
      let patientAge: any = this.patientService.calculateAge(
        selectedPatient.birthDate
      );
      this.age =
        patientAge.years + " " + patientAge.months + " " + patientAge.days;
        this.ages =
        patientAge.years +"ears";
      this.gender = selectedPatient.gender;
      this.mrnNumber = selectedPatient.mrn;
      this.patientId = selectedPatient.id;
      this.showTable = false;
      this.baseservice.setResourceURL(MICROSERVICES.OPD_SERVICE);
      this.baseservice
        .getResource("extendedPatient/get/" + this.patientId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((patient: PatientDTOObject) => {
          patient.id = this.patientId;
          patient.age = this.age;
          if (patient.photo && patient.photo.url) {
            patient.photo.url = patient.photo.url;
          } else {
            patient.photo = {
              contentType: "",
              url: "../assets/images/user.png",
            };
          }
          this.onActionClick.emit(patient);
        });
    }
  }

  returnFormattedDate(dateObj) {
    let codDate = "";
    if (dateObj) {
      codDate =
        new Date(dateObj).getFullYear() +
        "-" +
        ("0" + (new Date(dateObj).getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date(dateObj).getDate()).slice(-2) +
        "T00:00:00.000Z";
    }
    return codDate;
  }
  getCounsellingStockPatientData(serviceName: string) {
    let body = {
      offset: 0,
      limit: 10,
      mrn: this.search.MRN ? this.search.MRN : "",
      patientName: this.search.patientName ? this.search.patientName : "",
      identificationNo: this.search.identificationID
        ? this.search.identificationID
        : "",
      mobileNo: this.search.mobileNo ? this.search.mobileNo : "",
      countryId: this.search.country ? this.search.country : "",
      stateId: this.search.state ? this.search.state : "",
      identificationTypeId: this.search.identificationType
        ? this.search.identificationType
        : "",
      dob: 0,
    };
    this.baseservice.setResourceURL(serviceName);
    this.advancedSearch = this.baseservice
      .postResource("patientSearch/getPatient", body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any[]) => {
        this.hasSearched = true;
        this.searchCount = res.length;
        this.loadingBar.complete();
        this.displayListCounsellingStockService(res);
      });
  }
  displayListCounsellingStockService(patients) {
    this.patientList = [];
    patients.patientResponseList.forEach((patient) => {
      try {
        let patientAge: any = this.patientService.calculateAge(patient.dob);
        let age =
          patientAge.years + " " + patientAge.months + " " + patientAge.days;
        if (patient.patientName) {
          let data = new PatientDTOObject();
          data.id = patient.id;
          data.photo = patient.image;
          data.dateOfBirth = patient.dob;
          data.fullName = patient.patientName;
          data.mrn = patient.mrn;
          data.gender = patient.gender;
          data.title = patient.prefix;
          data.identifierType = patient.identification;
          data.idNo = patient.identificationNo;
          data.emailId = patient.email;
          data.mobileNumber = patient.mobileNo;
          data.extendedAddress = patient.primaryAddress;
          data.age = age;
          this.patientList.push(data);
        }
      } catch (ex) {
        console.log(ex);
      }
    });

    if (this.patientList.length > 0) {
      this.updatePatientList(this.patientList);
      this.dataSource = new MatTableDataSource<any>(this.patientList);
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  onTableScroll(e) {
    if (this.search.isStockService && this.patientList.length < this.totalElements) {
      const tableViewHeight = e.target.offsetHeight; // viewport
      const tableScrollHeight = e.target.scrollHeight; // length of all table
      const scrollLocation = e.target.scrollTop; // how far user scrolled

      // If the user has scrolled within 200px of the bottom and not already loading data, add more data
      const buffer = 500;
      const limit = tableScrollHeight - tableViewHeight - buffer;

      if (scrollLocation > limit && !this.loadingData) {
        this.loadingData = true; // Set flag to indicate that data is being loaded
        this.payloadData.offset += 1;
        this.getStockPatientData(this.payloadData, MICROSERVICES.STOCK_SERVICE);
      }
    }else if(this.hasSearched ){
      const tableViewHeight = e.target.offsetHeight; // viewport
      const tableScrollHeight = e.target.scrollHeight; // length of all table
      const scrollLocation = e.target.scrollTop; // how far user scrolled

      // If the user has scrolled within 200px of the bottom and not already loading data, add more data
      const buffer = 500;
      const limit = tableScrollHeight - tableViewHeight - buffer;

      if (scrollLocation > limit && !this.loadingData) {
        this.loadingData = true; // Set flag to indicate that data is being loaded
        this.search.offset += 1;
        this.getPatientList(true);
      }
    }
  }
  updatePatientList(newPatientList: any[]) {
    this.patientList = newPatientList;
    this.setTableHeight();
  }
   setTableHeight() {
    const recordCount = this.patientList.length;
    if (recordCount > 10) {
      this.heightClass = 'height-max';
      this.enableScroll = true;
    } else if (recordCount > 5) {
      this.heightClass = `height-medium`;
      this.enableScroll = false;
    } else {
      this.heightClass = 'height-min';
      this.enableScroll = false;
    }

    }
    getDocServicePatientData(serviceName: string) {
      const startDate = this.search.startDate
        ? this.patientService.dateToEpochConverter(
            new Date(this.search.startDate)
          )
        : "";
      const endDate = this.search.endDate
        ? this.patientService.dateToEpochConverter(new Date(this.search.endDate))
        : "";
  
      const birthDate = this.search.dob
        ? this.datepipe.transform(this.search.dob, "yyyy-MM-dd")
        : "";
  
      const date = this.search.dob
        ? this.patientService.dateToEpochConverter(new Date(this.search.dob))
        : "";
      let body = {
            mrn: this.search.MRN ? this.search.MRN : null,
            patientName: this.search.patientName ? this.search.patientName : null,
            identificationNo: this.search.identificationID
              ? this.search.identificationID
              : null,
            mobileNo: this.search.mobileNo ? this.search.mobileNo : null,
            identificationTypeId: this.search.identificationType
              ? Number(this.search.identificationType)
              : null,
            dateOfBirth: date ? Number(date) : null,
            gender: this.search.gender ? Number(this.search.gender) : null,
            offset: this.search.offset ? this.search.offset : null,
            limit: this.search.limit ? this.search.limit : 0,
          }
  
      this.baseservice.setResourceURL(serviceName);
      this.advancedSearch = this.baseservice
        .postResource("patientSearch/getPatient",body
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: any) => {
          this.hasSearched = true;
          this.searchCount = res.length;
          this.loadingBar.complete();
          this.displayAncillaryService(res)
        });
    }
}

export class PatientSearchModel {
  id: string = "";
  image: string = "";
  birthDate: string = "";
  name: string = "";
  mrn: string = "";
  gender: string = "";
  primaryIdentifierType: string = "";
  primaryIdentifierValue: string = "";
  isVip: string = "";
  email: string = "";
  mobilePhone: string = "";
  homePhone: string = "";
}

export class PatientDTOObject {
  id: string = "";
  age: string = "";
  identifierType: Codable;
  secondaryIdType: Codable;
  faculty: Codable;
  title: Codable;
  fullName: string = "";
  otherName: string = "";
  religion: Codable;
  nationality: Codable;
  race: Codable;
  martialStatus: Codable;
  occupation: Codable;
  patientCategory: Codable;
  gender: Codable;
  dateOfBirth: number;
  extendedAddress: ExtendedAddress;
  correspondingAddress: ExtendedAddress;
  homeContactNo: string = "";
  workContactNo: string = "";
  emailId: string = "";
  mobileNumber: string = "";
  relation: Codable;
  photo: Photo;
  mergedList?:any;
  birthTime: string = "";
  preferedLanguage: Codable;
  bloodGroup: Codable;
  expiryDate: number;
  idNo: string = "";
  secondaryNo: any;
  vip: boolean = false;
  idIssueCountry: Codable;
  secondaryIssueCountry: Codable;
  mrn: string = "";
  active:boolean;
  isStaffActive:boolean;
  temporaryPatientReference: any;
  admReqId: string = "";
  admReqNumber: string = "";
  admissionInfo: any;
  newBorn: boolean = false;
  deceased: boolean = false;
  blocked: boolean = false;
  ctos: boolean = false;
  real: boolean = false;
  personalFax: string = "";
  workFax: string = "";
  motherName: string = "";
  education: Codable;
  ethnic: Codable;
  birthlocation: Codable;
  source: codable;
  isExternal: boolean = false;
  patientNotes: string = "";
  merged: boolean = false;
  seondaryExpiryDate: number;
  countryCode: string = "";
  visitList: any;
  employmentStatus: Codable;
  employerName: Codable;
  employerNameOthers: string;
  employmentNo: string;
  workingFrom: Date;
  employmentContactNo: string;
  employmentAddress: string;
  employmentPostCode: string;
  employmentCountry: Codable;
  employmentState: Codable;
  employmentCity: Codable;
  birthDate: number;
  patientId?: any;
  healthTouristDetailsDTO?: any;
  employeeDetailsDTO?:any;
}

export class ExtendedAddress {
  addressLine: string = "";
  country: Codable;
  city: Codable;
  state: Codable;
  area: Codable;
  postCode: string = "";
}

export class Codable {
  system: string = "";
  id: string = "";
  code: string = "";
  display: string = "";
}

export class Photo {
  contentType: string;
  url: string;
}
