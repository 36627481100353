import { Component, OnInit, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { SearchService } from "../services/search.service";
import { AppBaseService } from "../services/http.service";
import { MICROSERVICES } from "../constants/web-services";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  /**
   * total number of encounter
   */
  totalCountOfEncounters: number = 0;
  /**
   * gender array
   */
  genderArray: any = [];
  /**
   * doctor array
   */
  doctorArray: any = [];
  viewArray: any = new Array(10);

  /**
   * chart for encounters By Department
   */
  chartOption: any;
  /**
   * chart for encounters By Patient Category
   */
  chartOptionGreen: any;
  /**
   * chart for encounters By Type
   */
  chartOptionVisitType: any;

  isFullScreen1: boolean = false;
  fullScreenChart1() {
    this.isFullScreen1 = !this.isFullScreen1;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  isFullScreen2: boolean = false;
  fullScreenChart2() {
    this.isFullScreen2 = !this.isFullScreen2;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  isFullScreen3: boolean = false;
  fullScreenChart3() {
    this.isFullScreen3 = !this.isFullScreen3;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  isFullScreen4: boolean = false;
  fullScreenChart4() {
    this.isFullScreen4 = !this.isFullScreen4;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  isFullScreen5: boolean = false;
  fullScreenChart5() {
    this.isFullScreen5 = !this.isFullScreen5;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  isFullScreen6: boolean = false;
  fullScreenChart6() {
    this.isFullScreen6 = !this.isFullScreen6;
    setTimeout(() => {
      this.chartOption = this.chartOption;
    }, 100);
  }

  chartOptionBillStatus: any;

  /**
   * chart for Encounter by Patient gender
   */
  chartOptionPie: any;

  constructor(
    public searchService: SearchService,
    public appBaseService: AppBaseService
  ) {}

  ngOnInit() {
    let fromDate = new Date(new Date().setHours(0, 0, 0, 0));
    let frmDt = (fromDate.getTime() / 1000).toFixed(0);
    let toDate = new Date(new Date().setHours(23, 59, 0, 0));
    let tDt = toDate.getTime() / 1000 + 86399;

    const searchList: any[] = [{
        controls: [
          {
            type: "text",
            placeholder: "MRN",
            column: 6,
            name: "mrn"
          },
          {
            type: "text",
            placeholder: "Patient Name",
            column: 6,
            name: "patientName"
          }
        ]
      },
      {
        controls: [
          {
            type: "date",
            placeholder: "From Date",
            column: 6,
            name: "fromDate"
          },
          {
            type: "date",
            placeholder: "To Date",
            column: 6,
            name: "toDate"
          }
        ]
      }
    ];
    this.searchService.setSearch(searchList);
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  /**
   * Method to render bill chart
   *
   * @param response
   */
  //FIXME: make generic method for parsing the response and create xaxis and series data
  renderBillingChart(response: any) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      let xAxis = [];
      let seriesList = [];
      response.listOfResources.forEach(element => {
        xAxis.push(Object.keys(element)[0]);
        seriesList.push(element[Object.keys(element)[0]]);
      });

      this.chartOptionBillStatus = {
        xAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          show: false
        },
        yAxis: {
          type: "category",
          data: xAxis,
          splitLine: {
            show: false
          }
        },
        grid: {
          left: "100px",
          top: "20px",
          bottom: "30px"
        },
        series: [
          {
            data: seriesList,
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "right"
              }
            }
          }
        ],
        color: ["#e67e22"]
      };
    }
  }
  renderDoctorChart(response: any) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      response.listOfResources.forEach(element => {
        let value = element[Object.keys(element)[0]];
        if (!isNaN(value)) {
          let percent = Math.ceil((value / this.totalCountOfEncounters) * 100);
          this.doctorArray.push({
            doctor: Object.keys(element)[0],
            count: element[Object.keys(element)[0]],
            percent: percent
          });
        }
      });
    }
  }
  /**
   * Method to render gender chart
   *
   * @param response
   */
  //FIXME: make generic method for parsing the response and create xaxis and series data
  renderGenderChart(response: any) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      let pieData = [];
      response.listOfResources.forEach(element => {
        let rowData = {};
        let percent = Math.ceil(
          (element[Object.keys(element)[0]] / this.totalCountOfEncounters) * 100
        );
        rowData["value"] = element[Object.keys(element)[0]];
        rowData["name"] =
          Object.keys(element)[0].toLocaleUpperCase() + "   " + percent + "%";
        pieData.push(rowData);
        this.genderArray.push({
          gender: Object.keys(element)[0].toLocaleUpperCase(),
          count: element[Object.keys(element)[0]]
        });
      });
      this.chartOptionPie = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "Gender-wise Count",
            type: "pie",
            radius: ["50%", "70%"],
            data: pieData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ],
        color: ["#7c21e2", "#fa429b"]
      };
    }
  }

  /**
   * Method to render category chart
   *
   * @param response
   */
  //FIXME: make generic method for parsing the response and create xaxis and series data
  renderCategoryChart(response) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      var xAxis = [];
      var seriesList = [];
      response.listOfResources.forEach(element => {
        xAxis.push(Object.keys(element)[0]);
        seriesList.push(element[Object.keys(element)[0]]);
      });
      this.chartOptionGreen = {
        xAxis: {
          type: "category",
          data: xAxis,
          axisLabel: {
            inside: true,
            rotate: 90,
            color: "#000",
            fontFamily: "'Roboto', sans-serif"
          },
          axisLine: {
            show: true
          },
          z: 10
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          show: false
        },
        series: [
          {
            data: seriesList,
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "bottom"
              }
            }
          }
        ],
        grid: {
          top: "20px",
          bottom: "40px",
          left: "10px",
          right: "10px"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        color: ["#a1dd49"]
      };
    }
  }
  
  /**
   * Method to render department chart
   *
   * @param response
   */
  //FIXME: make generic method for parsing the response and create xaxis and series data
  renderDepartmentChart(response) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      var xAxis = [];
      var seriesList = [];
      response.listOfResources.forEach(element => {
        xAxis.push(Object.keys(element)[0]);
        seriesList.push(element[Object.keys(element)[0]]);
      });
      this.chartOption = {
        xAxis: {
          type: "category",
          data: xAxis,
          axisLabel: {
            inside: true,
            rotate: 90,
            color: "#000",
            position: "top",
            fontFamily: "'Roboto', sans-serif"
          },
          axisLine: {
            show: true
          },
          z: 10
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          show: false
        },
        series: [
          {
            //label: this.labelOption,
            data: seriesList, //[120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "bottom"
              }
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        color: ["#44afe3"],
        grid: {
          top: "30px",
          bottom: "40px",
          left: "10px",
          right: "10px"
        },
        backgroundColor: "#fff"
      };
    }
  }

  /**
   * Method to render type chart
   *
   * @param response
   */
  //FIXME: make generic method for parsing the response and create xaxis and series data
  renderTypeChart(response) {
    if (
      response &&
      response.listOfResources &&
      response.listOfResources.length > 0
    ) {
      let yAxis = [];
      let series = [];

      response.listOfResources.forEach(element => {
        yAxis.push(Object.keys(element)[0]);
        series.push(element[Object.keys(element)[0]]);
      });
      this.chartOptionVisitType = {
        xAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          show: false
        },
        yAxis: {
          type: "category",
          data: yAxis,
          axisLabel: {
            inside: true,
            rotate: 0,
            color: "#000",
            position: "right",
            fontFamily: "'Roboto', sans-serif"
          }
        },
        series: [
          {
            data: series,
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "left"
              }
            }
          }
        ],
        grid: {
          left: "50px",
          top: "10px",
          bottom: "20px",
          label: {
            normal: {
              fontFamily: "'Roboto', sans-serif"
            }
          }
        },
        color: ["#a52bf0", "#ff6c86"]
      };
    }
  }
}
