<ul class="navigation" appAccordion>
  <li *ngIf="parentMenuIcon" class="header">
    <a href="javascript:;" title="{{parentMenuName}}" class="navigation-link parent header-menu">
      <span class="parent-circle">
        <img alt="" src="../../../assets/images/master-icons/{{ parentMenuIcon }}" />
      </span>
      &nbsp;
      <span>{{ parentMenuName }}</span>
      <span class="mr-auto"></span>
    </a>
  </li>
  <div *ngIf="menuList.length == 0" class="menu-loader-contain">
    <div class="menu-loader"></div>
  </div>

  <li appAccordionLink  *ngFor="let menu of menuList" group="{{ menu.parent }}" class="navigation-item"
    routerLinkActive="open">
    <a title="{{ menu.displayText | translate }}" *ngIf="menu.children && menu.children.length > 0; else menu1" class="navigation-link parent" appAccordionToggle
      data-id="{{ menu.id }}" [attr.data-sequence]="menu.sequence">
      <img src="../../../assets/images/sidebar-icons/{{ menu.icon }}.png" class="iconStyling" alt="" />
      <span style="font-size:14px">{{ menu.displayText | translate }}</span>
      <span class="mr-auto"></span>
      <i class="menu-caret icon icon-arrows-right"></i>
    </a>

    <ng-template #menu1>
      <a  title="{{ menu.displayText | translate }}" [routerLink]="[menu.location]" class="navigation-link not-parent" *ngIf="!menu.tab" appAccordionToggle
        data-id="{{ menu.id }}" [attr.data-sequence]="menu.sequence">
        <img src="../../../assets/images/sidebar-icons/{{ menu.icon }}.png" class="iconStyling" alt="" />
        <span style="font-size:14px">{{ menu.displayText | translate }}</span>
        <span class="mr-auto"></span>
      </a>
      <a [routerLink]="[menu.location]" title="{{ menu.displayText | translate }}" class="navigation-link not-parent" target="_blank" *ngIf="menu.tab"
        appAccordionToggle data-id="{{ menu.id }}" [attr.data-sequence]="menu.sequence">
        <img src="../../../assets/images/sidebar-icons/{{ menu.icon }}.png" class="iconStyling" alt=""/>
        <span style="font-size:14px">{{ menu.displayText | translate }}</span>
        <span class="mr-auto"></span>
      </a>
    </ng-template>

    <ul *ngIf="menu.children && menu.children.length > 0" class="navigation-submenu" appAccordion>
      <li>
        <ul style="max-height: 500px; overflow-y: auto;overflow-x: hidden;">
      <li title="{{ child.displayText | translate }}" *ngFor="let child of menu.children" class="navigation-item" appAccordionLink routerLinkActive="open">
        <a class="navigation-link second-level" *ngIf="child.children && child.children.length > 0; else menu2"
          appAccordionToggle data-id="{{ child.id }}" [attr.data-sequence]="child.sequence" href="javascript:;">
          <img src="../../../assets/images/sidebar-icons/{{ child.icon }}.png" class="iconStyling"  alt=""/>
          <span style="font-size:14px">{{ child.displayText | translate }}</span>
          <span class="mr-auto"></span>
          <i class="menu-caret icon icon-arrows-right"></i>
        </a>

        <ng-template #menu2>
          <a [routerLink]="[menu.location, child.location]"  title="{{ child.displayText | translate }}" class="navigation-link relative" data-id="{{ child.id }}"
            [attr.data-sequence]="child.sequence" appAccordionToggle>
            <img src="../../../assets/images/sidebar-icons/{{ child.icon }}.png" class="iconStyling" alt=""/>
            <span style="font-size:14px; text-wrap: pretty;">{{ child.displayText | translate }}</span>
            <span class="mr-auto"></span>
          </a>
        </ng-template>
      </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>