<div style="padding: 5px;overflow: auto;" id="table">
<table>
  <tr>
    <th *ngFor="let element of columnDefs">{{ element.label }}</th>
  </tr>
  <tr *ngFor="let data of dataSource">
    <td *ngFor="let element of columnDefs">{{ data[element.entity] }}</td>
  </tr>
</table> 
</div>
