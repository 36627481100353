import { Component, OnInit } from "@angular/core";
import { Browser } from "@syncfusion/ej2-base";
import { AppBaseService } from "../services/http.service";
import { MICROSERVICES } from "../constants/web-services";
import { Subject, takeUntil } from "rxjs";
import { APPLICATIONSERVICE } from "../constants/application-service";
import { groupBy } from "lodash";
import { DataService } from "../services/data.service";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-main-dashboard",
  templateUrl: "./main-dashboard.component.html",
  styleUrls: ["./main-dashboard.component.scss"],
  providers: [DatePipe],
})
export class MainDashboardComponent implements OnInit {
  public columns: number = Browser.isDevice ? 2 : 8;
  public columnSizeXX: number = Browser.isDevice ? 1 : 2;
  public columnSizeYY: number = Browser.isDevice ? 1 : 1.38;
  public columnSizeX: number = Browser.isDevice ? 1 : 2;
  public columnSizeY: number = Browser.isDevice ? 1 : 1.2;
  public columnSizeX2: number = Browser.isDevice ? 1 : 2;
  public columnSizeY2: number = Browser.isDevice ? 1 : 1.2;
  public cellSpacing: number[] = [15, 150];
  public cellAspectRatio: number = Browser.isDevice ? 1 : 1;
  protected _onDestroy = new Subject<void>();
  dashboardData: any;
  isMaximize: boolean = false;
  configList: any = [];
  counterList: any = [];
  copyConfigList: any = {};
  constructor(
    private httpService: AppBaseService,
    private dataService: DataService,
    public datepipe: DatePipe,
  ) {
    // this.configList = {
    //   dashboardName: "Encounter Dashboard",
    //   configuration: [
    //     {
    //       id: "visitDashboard",
    //       header: "Visit Types",
    //       widget: "bar",
    //       row: 0,
    //       col: 2,
    //       dashboardEntity: "visitType",
    //     },
    //     {
    //       id: "cityDashboard",
    //       header: "City",
    //       widget: "pieradius",
    //       row: 0,
    //       col: 2,
    //       dashboardEntity: "city",
    //     },
    //     {
    //       id: "ageDashboard",
    //       header: "Age Group",
    //       widget: "pie",
    //       row: 0,
    //       col: 2,
    //       dashboardEntity: "ageGroup",
    //     },
    //     {
    //       id: "encounterTypeDashboard",
    //       header: "Encounter Type",
    //       widget: "progress",
    //       row: 0,
    //       col: 2,
    //       dashboardEntity: "encounterType",
    //     },
    //     {
    //       id: "specialtyDashboard",
    //       header: "Specialty",
    //       widget: "donut",
    //       row: 1,
    //       col: 2,
    //       dashboardEntity: "specialty",
    //     },
    //     {
    //       id: "businessStatusDashboard",
    //       header: "Business Status",
    //       widget: "donut",
    //       row: 1,
    //       col: 4,
    //       dashboardEntity: "businessStatus",
    //     },
    //     {
    //       id: "genderDashboard",
    //       header: "Gender",
    //       widget: "pie",
    //       row: 1,
    //       col: 6,
    //       dashboardEntity: "gender",
    //     },
    //     {
    //       id: "doctorDashboard",
    //       header: "Doctor",
    //       widget: "progress",
    //       row: 1,
    //       col: 8,
    //       dashboardEntity: "doctor",
    //     },
    //   ],
    //   counters: [
    //     {
    //       label: "Total Visits",
    //       entity: "total",
    //       color: "purple",
    //     },
    //     {
    //       label: "Male Patients",
    //       entity: "gender",
    //       detailEntity: "MALE",
    //       color: "brown",
    //     },
    //     {
    //       label: "Female Patients",
    //       entity: "gender",
    //       detailEntity: "FEMALE",
    //       color: "pink",
    //     },
    //     {
    //       label: "IP Patients",
    //       entity: "visitType",
    //       detailEntity: "IP",
    //       color: "green",
    //     },
    //     {
    //       label: "OP Patients",
    //       entity: "visitType",
    //       detailEntity: "IP",
    //       color: "orange",
    //     },
    //     {
    //       label: "New Cases",
    //       entity: "encounterType",
    //       detailEntity: "New Case",
    //       color: "blue",
    //     },
    //   ],
    // };
    this.getDashboardData();
  }

  ngOnInit(): void {}

  getDashboardData() {
    let obj = {
      
      frDate: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
      toDate: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
      // frDate:"2023-06-22",
      // toDate: "2024-07-22",
      unitId: 0,
      userId: 0,
    };
    this.httpService.setResourceURL(MICROSERVICES.REPORT_SERVICE);
    this.httpService
      .postResource(APPLICATIONSERVICE.DASHBOARD_API, obj)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        this.dashboardData = result;
        this.setdataForDashboards();
      });
  }

  setdataForDashboards() {
    this.configList.configuration = [];
    this.dashboardData.map((data, index) => {
      let obj: any = {
        id: data.header + "_" + Math.floor(Math.random() * 100),
        header: data.header,
        widget: data.widget,
        row: 0,
        col: 0,
        dashboardEntity: data.entityKey,
        xCoordinate: data.xcoordinate,
        yCoordinate: data.ycoordinate,
      };
      obj["chartData"] = {};
      obj["chartData"]["entity"] = data.entityKey;
      obj["chartData"]["data"] = data.payloads;
      // if (index <= 4) {
      this.configList.configuration.push(obj);
      // }
    });
    let configArr = this.configList?.configuration;
    let confiurations = [];
    if (configArr && configArr.length > 4) {
      let newArr = this.getDimensionalArray(configArr, 4);
      newArr.map((data, index) => {
        if (data.length) {
          data.map((element, innerIndex) => {
            element.row = index;
            element.col =
              innerIndex > 1
                ? data[innerIndex - 1].col + 2
                : innerIndex == 0
                ? element.col
                : element.col + 2;
            confiurations.push(element);
          });
        }
      });
    } else if (configArr && configArr.length > 2 && configArr.length <= 4) {
      this.columnSizeXX = 4;
      let newArr = this.getDimensionalArray(configArr, 2);
      newArr.map((data, index) => {
        if (data.length) {
          data.map((element, innerIndex) => {
            element.row = index;
            element.col = innerIndex > 1 ? data.col + 2 : (data.col = 0);
            confiurations.push(element);
          });
        }
      });
    } else if (configArr && configArr.length == 2) {
      this.columnSizeXX = 4;
      this.columnSizeYY = 2;
      configArr.map((element, index) => {
        element.row = 0;
        element.col = index > 1 ? element.col + 2 : (element.col = 0);
        confiurations.push(element);
      });
    } else if (configArr && configArr.length == 1) {
      this.columnSizeXX = 8;
      this.columnSizeYY = 2;
      confiurations = configArr;
    }
    this.configList.configuration = confiurations;
    this.copyConfigList.configuration = confiurations;
    this.dataService.updateDashboardData(this.configList);
  }

  maximize(card, index) {
    card.isMaximize = !card.isMaximize;
    if (card.isMaximize) {
      this.columnSizeXX = 8;
      this.columnSizeYY = 2;
      this.configList.configuration = [];
      this.configList.configuration.push(card);
    } else {
      this.columnSizeXX = 2;
      this.columnSizeYY = 1.38;
      this.configList.configuration = this.copyConfigList.configuration;
    }
    // this.configList.configuration.map((data, i) => {
    //   if (i != index) {
    //     data.isMaximize = false;
    //     data.isShow = false;
    //   }
    // });
  }

  getDimensionalArray(configArr, length) {
    let newArr = [];
    for (let i = 0; i < configArr.length; i += 0) {
      var sliced = configArr.slice(i, length);
      newArr.push(sliced);
      configArr.splice(0, length);
    }
    return newArr;
  }
}
