import {Directive, ElementRef, HostListener, Input, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from "@angular/router";
import { interval } from 'rxjs';
import {timeInterval} from "rxjs/operators";
import {PushNotificationService} from "../service/push-notification.service";


/**
 *
 */
@Directive({
  selector: '[appPushNotification]'
})
export class PushNotificationDirective implements OnInit {

  /**
   *
   */
  @Input() notification: any;

  /**
   *
   * @param el
   * @param pushNotificationService
   * @param route
   */
  constructor(
    private readonly el:ElementRef,
    private readonly pushNotificationService: PushNotificationService,
    private readonly route: Router,
    ) {
      interval(10000).pipe(
        timeInterval()
      ).subscribe(res => {
        this.el.nativeElement.remove()
      });
  }

  /**
   *
   */
  ngOnInit() {
  }

  /**
   *
   * @param $event
   */
  @HostListener('click', ['$event']) onClick($event) {
    if (this.notification.type === 'actionAble') {
      if (this.notification.body.actionUrl !== '') {
        this.route.navigate([this.notification.body.actionUrl], {queryParams: this.notification.body.param});
      }
    }
    this.pushNotificationService.decreaseUnreadPushNotificationCount();
  }
}
