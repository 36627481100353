import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppBaseService } from "../../services/http.service";
import { Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { authProperties } from "../../authentication/authentication-properties";
import { EnvoirnmentService } from "./../../services/envoirnment.service";
import localForage from "localforage";
import { UserLoginModel, UserUnitList } from "../signin/signin.model";
import { Subscription, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmDynamicDialogComponent } from "./../../core/confirm-dynamic-dialog/confirm-dynamic-dialog.component";
import { MultiLanguageService } from "../../core/multi-language/multi-language.service";
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";
import { CookieService } from "ngx-cookie-service";
// import { PushNotificationComponent } from "../../modules/push-notification/controllers/push-notification/push-notification.component";
//import { PushNotificationComponent } from "../../modules/push-notification/controllers/push-notification/push-notification.component";

@Component({
  selector: "app-signin-modal",
  templateUrl: "./signin-modal.component.html",
  styleUrls: ["./signin-modal.component.scss"],
})
export class SigninModalComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  backgroundImage: string;
  public form: FormGroup;
  public password: string;
  public createOrforget: boolean;
  public sentornotfound: boolean;
  create = "C";
  forget = "F";
  sent = "S";
  notfound = "N";
  orgId: string = "";
  passcode: string;
  username: string = "";
  enterusername: string = "";
  firsttimepasscode: string = "";
  enterpassword: string = "";
  enterpasscode: string = "";
  emailid: string = "";
  enteremailID: string = "";
  emailnotfound: string = "";
  verificationemail: string = "";
  process: boolean = false;
  LoginBackgroundImage: string;
  hideRequiredControl = new FormControl(false);
  usernameToken: string = "";
  passwordToken: string = "";
  inputArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
  isPasscode: boolean = true;
  isActiveDirectory: boolean = true;
  isQuickLogin: boolean = true;
  active: boolean = true;
  passcodeD: boolean = false;
  quickLogin: boolean = true;
  usernameValue: string = "";
  newpasswordValue: string = "";
  updatePassword:boolean = false

  constructor(
    public dialogRef: MatDialogRef<SigninModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private baseservice: AppBaseService,
    private langService: MultiLanguageService,
    private env: EnvoirnmentService, //@Inject("env") private env
    private cookieService: CookieService
  ) // private pushNotification: PushNotificationComponent
  {
    this.updatePassword = data?.updatePassword;
  }

  ngOnInit() {
    // mapping place holder to ts
    this.username = authProperties.username;
    this.passcode = authProperties.passcode;
    this.enterusername = authProperties.enterusername;
    this.firsttimepasscode = authProperties.firsttimepasscode;
    this.enterpassword = authProperties.enterpassword;
    this.enterpasscode = authProperties.enterpasscode;
    this.emailid = authProperties.emailid;
    this.enteremailID = authProperties.enteremailID;
    this.emailnotfound = authProperties.emailnotfound;
    this.verificationemail = authProperties.verificationemail;
    this.backgroundImage = authProperties.backgroundImage;
    this.LoginBackgroundImage = this.env.loginImage;
    if(this.updatePassword){
      this.isPasscode= false;
      this.isQuickLogin = true;
    }
    this.process = false;
    let isLoggedin = localStorage.getItem("isLoggedin");
    if (isLoggedin != null && isLoggedin == "true") {
      // localStorage.removeItem("passcode");
      // this.router.navigate(["Dashboard/dash"]);
    } else {
      localForage.config({
        driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: "localforage",
        version: 1.0,
        size: 9999999999, // Size of database, in bytes. WebSQL-only for now.
        storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
        description: "Medcare WebSQL Database",
      });
      localForage.clear();
      this.form = this.fb.group({
        uname: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
  /**
   *
   * @param id
   * @param nextInputIndex
   */
  onInputEntry(id, nextInputIndex) {
    let nexInput = +nextInputIndex + 1;
    let newID = id + nexInput;
    let textbox = document.getElementById(newID);
    if (textbox) textbox.focus();
  }

  activeDirectory() {
    if (this.isActiveDirectory) {
      this.passcodeD = true;
      this.quickLogin = true;
      this.active = false;
      this.isPasscode = !this.isPasscode;
      this.isActiveDirectory = true;
      this.isPasscode = false;
      this.isQuickLogin = false;
    } else {
      this.passcodeD = true;
      this.quickLogin = true;
      this.isQuickLogin = !this.isQuickLogin;
      this.isPasscode = false;
      this.isQuickLogin = false;
      this.isActiveDirectory = true;
      this.active = false;
    }
  }

  passcodeData() {
    if (!this.isPasscode) {
      this.active = true;
      this.quickLogin = true;
      this.passcodeD = false;
      this.isPasscode = !this.isPasscode;
      this.isActiveDirectory = false;
      this.isPasscode = true;
      this.passcodeD = false;
    } else {
      this.active = true;
      this.quickLogin = true;
      this.isActiveDirectory = !this.isActiveDirectory;
      this.isQuickLogin = false;
      this.isActiveDirectory = false;
      this.isPasscode = true;
      this.passcodeD = false;
    }
  }
  quick() {
    if (this.isQuickLogin) {
      this.active = true;
      this.passcodeD = true;
      this.quickLogin = false;
      this.isPasscode = !this.isPasscode;
      this.isActiveDirectory = !this.isActiveDirectory;
      this.isActiveDirectory = false;
      this.isPasscode = false;
      this.isQuickLogin = true;
    } else {
      this.active = true;
      this.passcodeD = true;
      this.isPasscode = !this.isPasscode;
      this.isActiveDirectory = false;
      this.isPasscode = false;
      this.isQuickLogin = true;
      this.quickLogin = false;
    }
  }

  /**
   *
   * @param id
   * @param nextInputIndex
   */
  onBackspace(id, nextInputIndex) {
    let nexInput = nextInputIndex - 1;
    let newID = id + nexInput;
    let textbox: any = document.getElementById(newID);
    if (textbox) {
      textbox.value = "";
      textbox.focus();
    }
  }

  /**
   * login API which gets triggered after entering 8 key passcode
   */
  loginMethod() {
    let pin: string = "";
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox) pin = pin + textbox.value;
    }
    try {
      if (pin && pin.length == 8) {
        this.process = true;
        this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
        this.baseservice
          .loginResource("user/login", {
            passCode: btoa(pin),
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (res: any) => {
              let data: UserLoginModel = res.body;
              if (data) {
                this.dialogRef.close(true);
                // this.cookieService.set("isClinical", "true", 1, "/");

                // localStorage.setItem("userId", btoa(data.userId));
                // localStorage.setItem("userName", btoa(data.userName));
                // localStorage.setItem("userEmail", btoa(data.userEmailId));
                // localStorage.setItem(
                //   "authorization",
                //   res.headers.get("Authorization")
                // );
                // let tokenValue = res.headers.get("Authorization");
                // let unit: UserUnitList = data.userUnitList.find(
                //   (i) => i.isDefault == true
                // );

                // if (unit) {
                //   localStorage.setItem("unitId", btoa(unit.unitId));
                //   localStorage.setItem("unitCode", btoa(unit.unitCode));
                //   localStorage.setItem("orgCode", btoa(unit.orgCode));
                //   localStorage.setItem("orgId", btoa(unit.orgId));
                //   this.getRoleInfo(data, unit, tokenValue);

                //   this.process = false;
                //   const roleId = unit.userRole.roleId;
                //   // this.pushNotification.connect(roleId);
                // } else {
                //   this.snackBar.open(
                //     "Validation",
                //     "User does not have any default Unit",
                //     {
                //       duration: 3000,
                //     }
                //   );
                // }
              }
            },
            error: (err) => {
              this.process = false;
              this.invalidLogin();
            },
          });
      }
    } catch (ex) {
      this.process = false;
      this.invalidLogin();
    }
  }

  /**
   * To fetch role information
   * @param data
   * @param unit
   */
  getRoleInfo(data, unit, tokenData) {
    data["userUnitList"] = data.userUnitList;
    data["roleNotificationMappers"] = null;
    data["userUnitRole"] = null;
    data["userUnit"] = null;
    data["authorization"] = tokenData ? tokenData : "";
    data["orgCode"] = unit.orgCode;
    localForage.setItem("unit", unit);
    localForage.setItem("role", {
      id: unit.userRole ? unit.userRole.roleId : "",
      roleName: unit.userRole ? unit.userRole.roleDescription : "",
      roleCode: unit.userRole ? unit.userRole.roleCode : "",
    });
    if (unit.userRole)
      localStorage.setItem("roleName", btoa(unit.userRole.roleDescription));

    // this.langService.getLanguageList();
    localForage.setItem("auth-data", data, (err) => {
      localStorage.setItem("lan", "en");
      localStorage.setItem("passcode", "true");
      localStorage.setItem("isLoggedin", "true");
      this.router.navigate(["Dashboard/dash"]);
    });
  }

  /**
   * To reset pin number
   */
  resetPin() {
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox) textbox.value = "";
    }
    document.getElementById("tab1").focus();
  }

  invalidLogin() {
    this.resetPin();
  }

  resetPinMethod() {
    this.usernameToken = "";
    this.passwordToken = "";
  }

  /**
   * Forgot passcode snackbar
   */
  forgotPasscode() {
    this.snackBar.open("Message", "Please Contact Admin to Reset Passcode", {
      duration: 10000,
    });
  }

  /**
   * Virtual keyboard numbers
   * @param number
   */
  numberClick(number) {
    let valueSet = false;
    for (let i = 1; i <= 8; i++) {
      let textbox: any = document.getElementById("tab" + i);
      if (textbox && textbox.value == "" && !valueSet) {
        textbox.value = number;
        valueSet = true;
        if (i == 8) {
          this.loginMethod();
        }
      }
    }
  }

  clearNumber() {
    this.resetPin();
  }

  loginMethodSubmit(directoryName) {
    try {
      if (this.usernameToken && this.passwordToken) {
        this.process = true;
        let loginData = {
          userName: this.usernameToken ? this.usernameToken : "",
          password: this.passwordToken ? this.passwordToken : "",
          login: directoryName ? directoryName : null,
        };

        this.baseservice.setResourceURL(MICROSERVICES.IDENTITY_SERVICE);
        this.baseservice
          .loginResource("user/login", loginData)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (res: any) => {
              let data: UserLoginModel = res.body;
              if (data) {
                this.dialogRef.close(true);
                //   this.cookieService.set("isClinical", "true", 1, "/");

                //   localStorage.setItem("userId", btoa(data.userId));
                //   localStorage.setItem("userName", btoa(data.userName));
                //   localStorage.setItem("userEmail", btoa(data.userEmailId));
                //   localStorage.setItem(
                //     "authorization",
                //     res.headers.get("Authorization")
                //   );
                //   let tokenValue = res.headers.get("Authorization");
                //   let unit: UserUnitList = data.userUnitList.find(
                //     (i) => i.isDefault == true
                //   );

                //   if (unit) {
                //     localStorage.setItem("unitId", btoa(unit.unitId));
                //     localStorage.setItem("unitCode", btoa(unit.unitCode));
                //     localStorage.setItem("orgCode", btoa(unit.orgCode));
                //     localStorage.setItem("orgId", btoa(unit.orgId));
                //     this.getRoleInfo(data, unit, tokenValue);

                //     this.process = false;
                //   } else {
                //     this.snackBar.open(
                //       "Validation",
                //       "User does not have any default Unit",
                //       {
                //         duration: 3000,
                //       }
                //     );
                //   }
              }
            },
            error: (err) => {
              this.process = false;
              this.invalidLogin();
            },
          });
      }
    } catch (ex) {
      this.process = false;
      this.invalidLogin();
    }
  }

  /**
   * To retrieve login information
   */
  getLoginData() {
    this.baseservice.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.baseservice
      .postResource("users/adUser", { username: this.usernameToken })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: UserLoginModel) => {
          if (data) {
            localStorage.setItem("userId", btoa(data.userId));
            localStorage.setItem("userName", btoa(data.userName));
            localStorage.setItem("orgId", btoa(data.orgId));

            let unit: UserUnitList = data.userUnitList.find(
              (i) => i.isDefault == true
            );
            if (unit) {
              localStorage.setItem("unitId", btoa(unit.unitId));
              localStorage.setItem("unitCode", btoa(unit.unitCode));
              localStorage.setItem("unitPrefix", btoa(unit.unitPrefix));
              localStorage.setItem("orgCode", btoa(unit.orgCode));

              this.getRoleInfo(data, unit, "");
              this.process = false;
            } else {
              this.snackBar.open(
                "Validation",
                "User does not have any default Unit",
                {
                  duration: 3000,
                }
              );
            }
          }
        },
        error: (err) => {
          this.process = false;
          this.invalidLogin();
        },
      });
  }
}
