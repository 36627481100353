export const PATIENT_SEARCH = [
    {
      controls: [
        {
          type: "text",
          placeholder: "Patient MRN",
          column: 6,
          name: "mrn",
          url: ""
        },
        {
          type: "text",
          placeholder: "Patient Name",
          column: 6,
          name: "PatienName",
          url: ""
        }
      ]
    },
    {
      controls: [
        {
          type: "text",
          placeholder: "Identification ID",
          column: 6,
          dependency: "identificationType",
          name: "identificationID",
          url: ""
        },
        {
          type: "select",
          placeholder: "Identification Type",
          column: 6,
          url: "masters-service/Masters/IdentificationTypeMaster",
          name: "identificationType"
        }
      ]
    },
    {
      controls: [
        {
          type: "date",
          placeholder: "Date Of Birth",
          column: 6,
          name: "dob",
          url: ""
        },
        {
          type: "text",
          placeholder: "Mobile",
          column: 6,
          name: "mobileNumber",
          url: ""
        }
      ]
    }
  ]; 

  export const PATIENT_MYSEARCH = [
    {
      controls: [
        {
          type: "text",
          placeholder: "Pesakit MRN",
          column: 6,
          name: "mrn",
          url: ""
        },
        {
          type: "text",
          placeholder: "NAMA Pesakit",
          column: 6,
          name: "PatienName",
          url: ""
        }
      ]
    },
    {
      controls: [
        {
          type: "text",
          placeholder: "ID Pengenalan",
          column: 6,
          dependency: "identificationType",
          name: "identificationID",
          url: ""
        },
        {
          type: "select",
          placeholder: "Jenis Pengenalan",
          column: 6,
          url: "masters-service/Masters/IdentificationTypeMaster",
          name: "identificationType"
        }
      ]
    },
    {
      controls: [
        {
          type: "date",
          placeholder: "Tarikh Lahir",
          column: 6,
          name: "dob",
          url: ""
        },
        {
          type: "text",
          placeholder: "Nombor Telefon",
          column: 6,
          name: "mobileNumber",
          url: ""
        }
      ]
    }
  ]; 