import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import {  MatSnackBar } from "@angular/material/snack-bar";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  MasterService
} from "medcare-core-ui";
import {
  FormControl,
} from "@angular/forms";

@Component({
  selector: "app-master-select-field",
  templateUrl: "./master-select-field.component.html",
  styleUrls: ["./master-select-field.component.scss"]
})
export class MasterSelectFieldComponent implements OnInit, OnDestroy {
  
  list: any = [];
  isValid: boolean = false;
  selectedItem = new FormControl('');
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() label: string;
  @Input() masterName: string;
  @Input() defaultValue: any;
  @Input() params: object;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();

  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  public filterCtrl: FormControl = new FormControl();
  public filteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    public snackBar: MatSnackBar,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.validateField();

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.filterList();
      });

    if(this.params != undefined){
      this.getListWithParams();
    }else{
      this.getList();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.params && changes.params.currentValue != null && changes.params.currentValue != changes.params.previousValue)
      this.getListWithParams();

    if(changes.defaultValue && changes.defaultValue.currentValue != null && changes.defaultValue.currentValue != changes.defaultValue.previousValue){
      if(this.params != undefined){
        this.getListWithParams();
      }else{
        this.getList();
      }
    }
  }

  /**
   * 
   * @returns null if validation fails
   */
  protected filterList() {
    // to filters datsbase on key
    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredList.next(this.list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredList.next(
      this.list.filter(
        e => e.desc.toLowerCase().indexOf(search) > -1
      )
    );
  }

  /**
   * Form validation
   */
  validateField(){
    this.isValid = this.selectedItem.valid;
    if(this.isValid)
      this.notifySelection(this.selectedItem.value);
  }

  /**
   * To get generic master
   */
  getList() {
    this.masterService.getMaster(this.masterName).then(data => {
      this.list = data;
      this.filteredList.next(this.list.slice());
      if(this.defaultValue != null){
        let item = this.list.find(data => {
          return data.code.toLowerCase() == this.defaultValue.toLowerCase();
        });
        this.selectedItem.setValue(item);
      }
    });
  }

  /**
   * To get generic master with params
   */
   getListWithParams() {
    this.masterService.getMasterWithParams(this.masterName, this.params).then(data => {
      this.list = data;
      this.filteredList.next(this.list.slice());
      if(this.defaultValue != null){
        let item = this.list.find(data => {
          return data.code.toLowerCase() == this.defaultValue.toLowerCase();
        });
        this.selectedItem.setValue(item);
      }
    });
  }

  /**
   * To emit event when item is selected
   * @param obj 
   */
  notifySelection(obj){
    this.itemSelected.emit(obj);
  }
}
