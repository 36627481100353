import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppBaseService, MICROSERVICES } from "medcare-core-ui";
import { of, Subject,Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { OPD_LANG } from "../regproperties/opd-lang-properties";
import {  CacheBuster } from 'ts-cacheable';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class MasterUrlService {
  
  selectedLang: string;
  multLangDilaogs: any[] = [];

  constructor(
    private baseservice: AppBaseService,
    public translate: TranslateService
  ) {
    this.selectedLang = localStorage.getItem(OPD_LANG.langCode);
    translate.addLangs(OPD_LANG.langList);
    this.translate.use(this.selectedLang);
    this.translate.get("dialogs").subscribe((data: any) => {
      this.multLangDilaogs = data;
    });
  }

  getTypeMaster(urlType: string): Observable<any[]> {
    this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);

    return this.baseservice
      .getResource(urlType)
      .pipe(catchError(this.handleError<any[]>()));
  }

  /**
   *
   *
   * @param {string} urlType
   * @param {*} payload
   * @return {*}  {Observable<any[]>}
   * @memberof MasterUrlService
   */
  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  postTypeMaster(urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);

    return this.baseservice
      .postResource(urlType, payload)
      .pipe(catchError(this.handleError<any[]>()));
  }

  /**
   *
   *
   * @param {string} urlType
   * @param {*} payload
   * @return {*}  {Observable<any[]>}
   * @memberof MasterUrlService
   */
   @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  putTypeMaster(urlType: string, payload: any): Observable<any[]> {
    this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    
    return this.baseservice
      .putResource(urlType, payload)
      .pipe(catchError(this.handleError<any[]>()));
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {
      console.log(`failed: ${error.message}`);

      return of(error);
    };
  }
}
