
<div class="signForm" data-automation-attribute="div-signin-form">
  <mat-tab-group>
      <mat-tab *ngIf="isPasscode">
  <div
    class="divContainerRight"
    data-automation-attribute="div-passcode"
  >
    <div class="divSection">
      <span class="label-passcode"><b>Enter Passcode</b></span>
      <div *ngIf="process" class="center">
        <mat-spinner
          style="display: inline-block;"
          [diameter]="15"
        ></mat-spinner>
      </div>
      <div class="mt-10">
        <ng-container *ngFor="let input of inputArray">
          <input
            autofocus
            id="tab{{ input }}"
            type="password"
            class="input-pin"
            maxlength="1"
            (keyup.backspace)="onBackspace('tab', input)"
            (input)="onInputEntry('tab', input)"
            (focus)="$event.target.select()"
            (keyup)="loginMethod()"
            #myInput
          />
        </ng-container>
      </div>
      <table class="tablePasscode" data-automation-attribute="table-passcode">
        <tr>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-one"
              (click)="numberClick(1)"
            >
              1
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-two"
              (click)="numberClick(2)"
            >
              2
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-three"
              (click)="numberClick(3)"
            >
              3
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-four"
              (click)="numberClick(4)"
            >
              4
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-five"
              (click)="numberClick(5)"
            >
              5
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-six"
              (click)="numberClick(6)"
            >
              6
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-seven"
              (click)="numberClick(7)"
            >
              7
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-eight"
              (click)="numberClick(8)"
            >
              8
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-nine"
              (click)="numberClick(9)"
            >
              9
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-clear"
              (click)="clearNumber()"
            >
              <mat-icon matTooltip="Cancel">clear</mat-icon>
            </button>
          </td>
          <td>
            <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-zero"
              (click)="numberClick(0)"
            >
              0
            </button>
          </td>
          <td>
            <!-- <button
              mat-button
              class="btnLogin"
              data-automation-attribute="button-enter"
            >
              <mat-icon matTooltip="Enter">subdirectory_arrow_left</mat-icon>
            </button> -->
          </td>
        </tr>
      </table>

      <!-- <table
        class="tablePasscodeActions"
        data-automation-attribute="table-passcode-actions"
      >
        <tr>
          <td class="btnCreatePasscode">
            <button
              mat-flat-button
              (click)="dilogCreatePasscode()"
              data-automation-attribute="button-create-passcode"
            >
              Create
            </button>
          </td>
          <td class="blankTD">&nbsp;</td>
          <td class="btnForgetPasscode">
            <button
              mat-flat-button
              (click)="dilogForgetPasscode()"
              data-automation-attribute="button-forget-passcode"
            >
              Forgot
            </button>
          </td>
        </tr>
      </table> -->
    </div>
  </div>

  
</mat-tab>

<mat-tab *ngIf="isActiveDirectory">
    <div class="divContainerRight" data-automation-attribute="div-passcode" style="margin-left: -2%;">
      <div class="divSection">
        <div class="headingSignin"><b>Log In</b></div>
        
          <mat-grid-list cols="12" rowHeight="65px">
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="outline">
              <mat-label>User Name</mat-label>
                <input matInput placeholder="User Name" [(ngModel)]="usernameToken" type="text" class="btox"/>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
           

          <mat-grid-list cols="12" rowHeight="65px" >
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
                <input matInput placeholder="Password" [(ngModel)]="passwordToken" (keyup.enter)="loginMethodSubmit('KEYCLOAK')"  type="password"/>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="12" rowHeight="40px">
            <mat-grid-tile [colspan]="7">
              <!-- <a routerLink=" ">
                Forgot password?
              </a> -->
            </mat-grid-tile>
          </mat-grid-list>

          <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
            <div class="right">
              <button (click)="loginMethodSubmit('KEYCLOAK')" mat-flat-button data-automation-attribute="button-login">
                login
              </button>
            </div>
          </table>
        <!-- <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
          <tr>
            <td class="btnCreatePasscode">
              <button (click)="loginMethodUserName()" mat-flat-button data-automation-attribute="button-create-passcode">
               Create
              </button>
            </td>
            <td class="blankTD">&nbsp;</td>
            <td class="right">
              <button mat-flat-button (click)="loginMethodSubmit('KEYCLOAK')" mat-flat-button data-automation-attribute="button-login">
                Login
              </button>
            </td>
          </tr>
        </table> -->
      </div>
    </div>
</mat-tab>

<mat-tab *ngIf="isQuickLogin">

    <div class="divContainerRight" data-automation-attribute="div-passcode" style="margin-left: -2%;">
      <div class="divSection">
        <span class="headingSignin"><b>Log In</b></span>
          <mat-grid-list cols="12" rowHeight="65px">
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="outline">
              <mat-label>User Name</mat-label>
                <input matInput placeholder="User Name" [(ngModel)]="usernameToken" type="text"/>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="12" rowHeight="65px">
            <mat-grid-tile [colspan]="12">
              <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
                <input matInput placeholder="Password" [(ngModel)]="passwordToken" (keyup.enter)="loginMethodSubmit('AD')" type="password"/>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

          <!-- <mat-grid-list cols="12" rowHeight="40px">
            <mat-grid-tile [colspan]="7">
              <a routerLink=" ">
                Reset
              </a>
            </mat-grid-tile>
          </mat-grid-list> -->
          <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
            <div class="right">
              <button (click)="loginMethodSubmit('AD')" mat-flat-button data-automation-attribute="button-login">
                login
              </button>
            </div>
          </table>
        <!-- <table class="tableloginActions" data-automation-attribute="table-passcode-actions">
          <tr>
            <td class="btnCreatePasscode">
              <button (click)="loginMethodUserName()" mat-flat-button data-automation-attribute="button-create-passcode">
               Create
              </button>
            </td>
            <td class="blankTD">&nbsp;</td>
            <td class="btnForgotPasscode">
              <button mat-flat-button (click)="loginMethodUserName()" data-automation-attribute="button-forgot-passcode">
                Forgot
              </button>
            </td>
          </tr>
        </table> -->
      </div>
    </div>    
</mat-tab>
</mat-tab-group>

<mat-card class="homeIcon1" *ngIf="!updatePassword">
  <div *ngIf="active">
  <button
  mat-button
  class="passcodeButton"
  (click)="activeDirectory()"
  data-automation-attribute="button-one"
>
<mat-icon>vpn_key &nbsp;</mat-icon>
<label>&nbsp; Verify in with Password</label>
</button>
</div>
<div *ngIf="passcodeD">
<button
mat-button
class="passcodeButton"
(click)="passcodeData()"
data-automation-attribute="button-one"
>
<mat-icon color="blue">dialpad &nbsp;</mat-icon>
<label>&nbsp; Verify in with Passcode</label>
</button>
</div>


<!-- <div *ngIf="quickLogin"> -->
<!-- <button
  mat-button
  class="passcodeButton"
  (click)="quick()"
  data-automation-attribute="button-one"
>
<mat-icon>device_hub &nbsp;</mat-icon>
<label>&nbsp;Sign in with Active Directory</label>
</button> -->
<!-- </div> -->
</mat-card>
</div>
