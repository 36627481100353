import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

export interface ConfirmDynamicDialogData {
  confirm: string;
  displayText: string;
}

@Component({
  selector: "app-dynamic-confirm-dialog",
  templateUrl: "./confirm-dynamic-dialog.component.html",
  styleUrls: ["./confirm-dynamic-dialog.component.scss"]
})
export class ConfirmDynamicDialogComponent implements OnInit {
  translatedText: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDynamicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDynamicDialogData,
    public translate: TranslateService
  ) {
    translate.addLangs(["en", "id", "hn", "cn", "ms", "th"]);
    let language = localStorage.getItem("lan");
    if(language) {
      this.translate.use(localStorage.getItem("lan"));
    } else {
      this.translate.use("en");
    }
    this.translate.get("dialogs").subscribe((data: any) => {
      this.translatedText = data[dialogData.displayText];
    });
    if(!this.translatedText)
        this.translatedText= dialogData.displayText 
  }

  ngOnInit() {}

  buttonClick(value) {
    let data = { confirm: value };
    this.dialogRef.close(data);
  }
}
