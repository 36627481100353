<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">{{ "AppointmentListPage.confirm" | translate }}</h6>
      <span aria-label="Close" class="close" (click)="buttonClick('no')">
        <mat-icon  matTooltip="{{ 'common.close' | translate }}">clear</mat-icon>
      </span>
    </div>
    <div class="modal-body bodyCreatePasscode">
      <p>{{ "CommonAppoinment.doyouwanttoproceed" | translate }} </p>
      <!-- Do you want to proceed? -->
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button
          avoidDoubleClick
          (debounceClick)="buttonClick('yes')"
          mat-mini-fab
          class="btnCyan"
          type="submit"
          data-automation-attribute="button-save-dialog"
        >
          <mat-icon matTooltip="{{ 'payerInfo.yes' | translate }}">done</mat-icon>
        </button>
        &nbsp;
        <button
          mat-mini-fab
          class="btnRed"
          type="submit"
          data-automation-attribute="button-cancel-dialog"
          avoidDoubleClick
          (debounceClick)="buttonClick('no')"
        >
          <mat-icon matTooltip="{{ 'payerInfo.no' | translate }}">close</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>
