export class SearchModel {
    patientName?: string = "";
    MRN?: string = "";
    gender?: string = "";
    dob?: string = "";
    mobileNo?: string = "";
    identificationType?: string = "";
    identificationID?: string = "";
    offset?: string = "";
    limit?: string = "";
    unit?: string = "";
    country?: string = "";
    state?: string = "";
    city?: string = "";
    pincode?: string = "";
    startDate?: string = "";
    endDate?: string = "";
}