<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title"> QR Code Scanner </h6>
      <span aria-label="Close" class="close" (click)="onClose(true)">
        <mat-icon>clear</mat-icon>
      </span>
    </div>

    <div class="modal-body bodyCreatePasscode">
      <button mat-raised-button color="accent" (click)="onCameraStart(action)" *ngIf="!action.isStart">Open Camera</button>
      <button mat-raised-button color="warn" (click)="action.stop()" *ngIf="action.isStart">Stop Camera</button>
      <mat-card class="matcard-camera mat-elevation-z4">
        <ngx-scanner-qrcode
          #action="scanner"
          (event)="onBarcodeScanned(action, $event)"
          [isBeep]="false"
          [constraints]="{audio:false,video:true}"
          [canvasStyles]="[{ lineWidth: 1, strokeStyle: 'green', fillStyle: '#55f02880' },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }]"
        ></ngx-scanner-qrcode>
        <p *ngIf="action.isLoading"> ⌛ Loading... </p>
      </mat-card>
    </div>
  </form>
</div>
