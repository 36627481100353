<div class="content-quick-links">
  <div class="center">
    <label class="green">Today's Appointments</label>
  </div>
  <div class="mt-10">
    <mat-form-field class="full-width" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        maxlength="20"
        [placeholder]="'Doctor Name'"
        data-automation-attribute="txtDoctor"
        (keyup)="doctorOnKeyUp()"
        [(ngModel)]="doctorName"
      />
    </mat-form-field>
  </div>
  <div class="mt-10" *ngIf="filteredAppointmentList.length == 0">
    <div class="card-appointment">
      <div class="flex">
        <div class="col-12">No records found</div>
      </div>
    </div>
  </div>
  <div
    *ngFor="let app of filteredAppointmentList"
    (mouseover)="app.actions = true"
    (mouseout)="app.actions = false"
    class="card-appointment mt-10"
  >
    <div class="flex">
      <div class="col-4">
        <mat-icon class="icon-quick">calendar_today</mat-icon>
        <label class="quick-label">{{ app.appointmentNumber }}</label>
      </div>
      <div class="col-8">
        <mat-icon class="icon-quick">alarm_on</mat-icon>
        <label class="quick-label">{{
          app.appointmentDate | date: "hh:mm aa"
        }}</label>
        <button *ngIf="app.actions" mat-mini-fab class="btnCyan btnCheckIn">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex">
      <div class="col-12">
        <mat-icon class="icon-quick">perm_identity</mat-icon>
        <label class="quick-label">({{ app.trn }}) {{ app.patientName }}</label>
      </div>
    </div>
    <div class="flex">
      <div class="col-12">
        <mat-icon class="icon-quick">work_outline</mat-icon>
        <label class="quick-label">{{ app.departmentName }}</label>
      </div>
    </div>
    <div class="flex">
      <div class="col-12">
        <mat-icon class="icon-quick">supervised_user_circle</mat-icon>
        <label class="quick-label">{{ app.doctorName }}</label>
      </div>
    </div>
    <div *ngIf="false" class="quick-actions">
      <button mat-mini-fab class="btnCyan">
        <mat-icon>check_circle_outline</mat-icon>
      </button>
      &nbsp;
      <button mat-mini-fab class="btnOrange">
        <mat-icon>cancel</mat-icon>
      </button>
      &nbsp;
      <button mat-mini-fab class="btnRefresh">
        <mat-icon>history</mat-icon>
      </button>
    </div>
  </div>
</div>
