import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { APPLICATIONSERVICE } from "../../constants/application-service";
import { MICROSERVICES } from "../../constants/web-services";
import { AppBaseService } from "../../services/http.service";
import { MatDialog } from "@angular/material/dialog";
declare const PDFObject: any;
@Component({
  selector: 'app-pdf-viewer3',
  templateUrl: './pdf-viewer3.component.html',
  styleUrls: ['./pdf-viewer3.component.scss']
})
export class PdfViewer3Component implements OnInit, OnDestroy {
  protected _onDestroy = new Subject<void>();
  reportPayload: any;
  componentLoaded: boolean = false;
  exportTypes = [
    { id: '1', active: true, code: 'xlsx', desc: 'Excel' },
    { id: '2', active: true, code: 'doc', desc: 'DOC' },
    { id: '3', active: true, code: 'csv', desc: 'CSV' }
  ];

  constructor(
    private httpService: AppBaseService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.componentLoaded = true;
    this.reportPrint();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    localStorage.removeItem("pdpaformPayload");
  }

  reportPrint() {
    this.reportPayload = JSON.parse(localStorage.getItem("pdpaformPayload"));

    if (!this.reportPayload) {
      console.error("No report payload found in local storage.");
      return;
    }else{
      this.multipleReportsAPI(this.reportPayload);
    }
  }

  reportPrintAPI(resourceUrl: string, reportGeneratePath: string, reqPayload: any) {
    this.httpService.setResourceURL(resourceUrl);
    this.httpService
      .postResource(reportGeneratePath, reqPayload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        PDFObject.embed(result.payload, "#pdfContainer");
        if (!this.componentLoaded) {
          window.location.reload();
        }
      });
  }

  multipleReportsAPI(payLoad: any) {
    const reqPayload = this.formatGenericReportPayload(payLoad);
    this.reportPrintAPI(
      MICROSERVICES.OPD_SERVICE,
      APPLICATIONSERVICE.REPORT_GENERATE_VIEW,
      reqPayload
    );
  }

  formatGenericReportPayload(report: any): any {
    return {
      transactionId: report.transactionId,
      type: report.type,
      reportName: report.reportName
    };
  }
}
