import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "./../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AppBaseService } from "../../services/http.service";
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
  providers: [AuthService]
})
export class MenuComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  public menuList: any = [];
  menuId: any;
  nursing: string;
  parentMenuName: string = "";
  parentMenuIcon: string = "";

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public baseservice: AppBaseService
  ) {
    this.route.queryParams.subscribe(params => {
      this.menuId = params["menuId"];
      this.nursing = params["nursing"];
    });
  }

  ngOnInit() {
    let routingURL = this.router.url;
    if (routingURL) {
      let parentMenuUrl = routingURL.split("/")[1];
      if (parentMenuUrl) {
        this.loadAllMenu(parentMenuUrl);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }


  public loadAllMenu(parentMenuUrl) {
    this.authService.getData().then((user: any) => {
      if (user) {
        let units = user.userUnitList;
        this.authService.getUnit().then((unit: any) => {
          if (unit) {
            let defaultUnit = units.find(
              i => i.unitCode == unit.unitCode
            );
            if (defaultUnit.userRole && defaultUnit.userRole.userMenuList) {
              let transformedMenu = [];
              defaultUnit.userRole.userMenuList.forEach(element => {
                let newObj = this.transformMenuItem(element);
                transformedMenu.push(newObj);
              });
              this.getMenus(parentMenuUrl, transformedMenu);
            }
          }
        });
      }
    });
  }

  transformMenuItem(item) {
    let obj = {
      "id": "",
      "active": true,
      "createdDate": "",
      "menu": {
        "id": item["id"] ? item["id"] : "",
        "status": item["status"] ? item["status"] : "",
        "parentid": item["parentid"] ? item["parentid"] : "",
        "menuorder": item["menuorder"] ? item["menuorder"] : "",
        "location": item["location"] ? item["location"] : "",
        "sequenceOrder": item["sequenceOrder"] ? item["sequenceOrder"] : "",
        "displayText": item["header"] ? item["header"] : "",
        "module": item["module"] ? item["module"] : "",
        "url": item["location"] ? item["location"] : "",
        "variablename": "default",
        "tab": item.tab ? item.tab : false,
        "icon": item["icon"] ? item["icon"] : "",
      },
      "tab": item.tab ? item.tab : false,
      "menuId": item["id"] ? item["id"] : "",
      "createPermission": item["createPermission"] ? item["createPermission"] : "",
      "updatePermission": item["updatePermission"] ? item["updatePermission"] : "",
      "readPermission": item["readPermission"] ? item["readPermission"] : "",
      "deletePermission": item["deletePermission"] ? item["deletePermission"] : "",
      "createdByUserPermission": false,
      "readByUserPermission": false,
      "updateByUserPermisssion": false,
      "deleteByUserPermission": false,
      "viewReport": item["viewReport"] ? item["viewReport"] : "",
      "reportPrint": item["reportPrint"] ? item["reportPrint"] : "",
      "reportExport": item["reportExport"] ? item["reportExport"] : "",
      "reportMail": item["reportMail"] ? item["reportMail"] : "",
      "deActive": false
    };
    return obj;
  }

  getMenus(parentMenuUrl, data) {
    let displayList = [];
    if (data) {
      let menuList = data;
      if (menuList) {
        //Sort menu as per sequence
        menuList = menuList.sort(
          (a, b) =>
            parseFloat(a.menu.sequenceOrder.toString()) -
            parseFloat(b.menu.sequenceOrder.toString())
        );

        //Get the parent menu
        let menus = [];
        if (parentMenuUrl) {
          let parentMenu: any;
          let menuData = menuList.find(
            i => i.menu.location == parentMenuUrl && i.menu.parentid == "0"
          );
          parentMenu = menuData ? menuData.menu : null;
          if (parentMenu) {
            this.parentMenuName = parentMenu.displayText;
            this.parentMenuIcon = parentMenu.icon;
            menus = menuList.filter(i => i.menu.parentid == parentMenu.id);
          } else {
            this.parentMenuName = "Home";
            this.parentMenuIcon = "home.png";
            menus = [
              {
                id: "1",
                menu: {
                  id: "1",
                  status: "A",
                  parentid: "1",
                  menuorder: "1",
                  location: "dash",
                  sequenceOrder: 1,
                  displayText: "Dashboard",
                  module: "Core",
                  icon: "home"
                },
                menuId: "163"
              }
            ];
          }
        }
        if (menus.length > 0) {
          menus.forEach(child => {
            let children;
            let subChildren = [];
            let subChildrenList = menuList.filter(
              i => i.menu.parentid == child.menu.id
            );
            subChildrenList.forEach(subchild => {
              subChildren.push({
                id: subchild.menu.id,
                displayText: subchild.menu.displayText,
                icon: subchild.menu.icon,
                children: [],
                parent: subchild.menuId,
                location: subchild.menu.location,
                index: 0,
                tab: subchild?.menu?.tab ? subchild?.menu?.tab : '',
                sequence: subchild.menu.sequenceOrder
              });
            });
            children = {
              id: child.menu.id,
              displayText: child.menu.displayText,
              icon: child.menu.icon,
              children: subChildren,
              parent: child.menuId,
              location: child.menu.location,
              variable: child.menu.variablename,
              tab: child?.menu?.tab ? child?.menu?.tab : false,
              index: 0,
              sequence: child.menu.sequenceOrder
            };

            displayList.push(children);
          });
        } else {
          this.router.navigate(["/Dashboard/dash"]);
        }

        if (displayList.length > 0) {
          this.menuList = displayList;
        }
      }
    }
  }
}
