import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { PushNotificationModule } from "./modules/push-notification/push-notification.module";
import { MaterialModule } from "./modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SidebarModule } from "ng-sidebar";
import { AppComponent } from "./app.component";
import { AuthGuardGuard } from "./shared/authguard/auth-guard.guard";
import { SearchService } from "./services/search.service";
import { ApplicationConfigService } from "./services/application-config.service";
import { AuthService } from "./services/auth.service";
import { PatientService } from "./services/patient.service";
import { MasterService } from "./services/masters.service";
import { UtilsService } from "./services/utils.service";
import { SigninComponent } from "./authentication/signin/signin.component";
import { SigninUumComponent } from "./authentication/signin-uum/signin-uum.component";
import { ForgotComponent } from "./authentication/forgot/forgot.component";
import { LockscreenComponent } from "./authentication/lockscreen/lockscreen.component";
import { SignupComponent } from "./authentication/signup/signup.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NgxEchartsModule } from "ngx-echarts";
import { InfoDialogComponent } from "./core/info-dialog/info-dialog.component";
import { settingsDialog } from "./core/header/header.component";
import { profileDialog } from "./core/header/header.component";
import { unitDialog } from "./core/header/header.component";
import { PatientSearchModule } from "./core/patient-search/patient-search.module";
import { ApplicationPipeModule } from "./application-pipes/applicationpipes.module";
import { DialogOverviewExampleDialog, CreatePassword } from "./authentication/signin/signin.component";
import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./core";

import {
  HashLocationStrategy,
  LocationStrategy,
  CommonModule,
} from "@angular/common";

import { AppBaseService } from "./services/http.service";
import { ConfirmDialogComponent } from "./core/confirm-dialog/confirm-dialog.component";
import { ConfirmDynamicDialogComponent } from "./core/confirm-dynamic-dialog/confirm-dynamic-dialog.component";
import { ConfirmRemarkComponent } from "./core/remark-dialog/remark-dialog.component";
import { CancelDialogComponent } from "./core/cancel-dialog/cancel-dialog.component";
import { ExtendDialogComponent } from "./core/extend-dialog/extend-dialog.component";
import { DataService } from "./services/data.service";
import { NotFoundComponent } from "./core/not-found/not-found.component";
import { ElasticService } from "./services/elastic.service";
import { ToastrModule } from "ngx-toastr";
import { UiConfigService } from "../app/ui-configuration/ui-config.service";
import { EnvServiceProvider } from "./services/env.service.provider";
import { PaymentDialogComponent } from "./core/payment-dialog/payment-dialog.component";
import { SocketService } from "./services/socket.service";
import { InfoDynamicDialogComponent } from "./core/info-dynamic-dialog/info-dynamic-dialog.component";
import { OnlyNumberDirective } from "./directives/number.directive";
import { OnlyAmountDirective } from "./directives/amount.directive";
import { DoubleClickDirective } from "./directives/double-click.directive";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import {
  ProfileComponent,
  PasswordDialogComponent,
} from "./core/profile/profile.component";
import { QuickLinkService } from "./services/quick-link.service";
import { QuickLinksComponent } from "./core/admin-layout/dynamic-components/quick-links/quick-links.component";
import { QuickAppointmentsComponent } from "./core/admin-layout/dynamic-components/quick-appointments/quick-appointments.component";
import { SortByPipe } from "./core/pipes/sortByPipe.pipe";
import { MultiLanguageService } from "./core/multi-language/multi-language.service";
import { ReportService } from "./services/report.service";
import { PdfViewerComponent } from "./core/pdf-viewer/pdf-viewer.component";
import { ExportFileDialogComponent } from "./core/export-file-dialog/export-file-dialog.component";
import { BarChartComponent } from "./dashboard-components/bar-chart/bar-chart.component";
import {
  ChartAllModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule,
} from "@syncfusion/ej2-angular-charts"; 
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { PieChartComponent } from './dashboard-components/pie-chart/pie-chart.component';
import {
  DashboardLayoutComponent,
  PanelModel,
} from "@syncfusion/ej2-angular-layouts";
import { NegativeStackComponent } from './dashboard-components/negative-stack/negative-stack.component';
import { PieRadiusComponent } from './dashboard-components/pie-radius/pie-radius.component';
import { RangeColumnComponent } from './dashboard-components/range-column/range-column.component';
import { DonutComponent } from './dashboard-components/donut/donut.component';
import { ProgressBarComponent } from './dashboard-components/progress-bar/progress-bar.component';
import { FileUploadService } from "./services/file-upload.service";
import { SigninModalComponent } from './authentication/signin-modal/signin-modal.component';
import { TableComponent } from './dashboard-components/table/table.component';
import { CounterComponent } from './dashboard-components/counter/counter.component';
import { BarcodeScannerComponent } from "./core/barcode-scanner/barcode-scanner.component";
import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";
import { ComposeMailDialogComponent } from './core/compose-mail-dialog/compose-mail-dialog.component';
import { TextEditorTemplateComponent } from "./core/text-editor-template/text-editor-template/text-editor-template.component";
import { RichTextEditorModule } from "@syncfusion/ej2-angular-richtexteditor";
import { qmsSocketService } from "./services/qms.socket.service";
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import {CopDecimalCalculateService} from "./services/cop-decimal-calculate.service"
import { PatientRegistrationComponent } from "./patient-registration/patient-registration.component"; 
import { PatientNotesDialogComponent } from "./core/patient-notes-dialog/patient-notes-dialog.component";
import { GeneralUrlService } from "./regservices/SgeneralUrl.service";
import { MasterUrlService } from "./regservices/SmasterUrl.service";
import { patientGetDataservice } from "./regservices/SpatientGetData.service";
import { SearchHeaderService } from "./regservices/SsearchHeader.service";
import { CameraDialogComponent } from './regshared/camera-dialog/camera-dialog.component';
import { RegsuccessDialogComponent } from './regshared/regsuccess-dialog/regsuccess-dialog.component';
import { UploadfileComponent } from "./regshared/uploadfile/uploadfile.component";
import { AppointmentService } from "./regservices/Sappointment.service";
import { PatientAppointmentDialogComponent } from "./patient-registration/patient-appointment-dialog/patient-appointment-dialog.component";
import { UnsavedFormDilogComponent } from "./patient-registration/unsaved-form-dilog/unsaved-form-dilog.component";
import { SharedModule } from "./modules/shared/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ConfirmRemarksComponent } from "./update-sponsor/confirm-remarks/confirm-remarks.component";
import { GlAttachmentComponent } from "./update-sponsor/gl-attachment/gl-attachment.component";
import { AddSponsorDialogComponent } from "./update-sponsor/update-sponsor/add-sponsor.dialog";
import { UpdateSponsorComponent } from "./update-sponsor/update-sponsor/update-sponsor.component";
import { PdfViewer2Component } from './core/pdf-viewer2/pdf-viewer2.component';
import { PdfViewer3Component } from './core/pdf-viewer3/pdf-viewer3.component';
import { ViewDocumentComponent } from "./regshared/view-document/view-document.component";
import { ClinicareViewDialogComponent } from './core/clinicare-view-dialog/clinicare-view-dialog.component';
import { LabClinicalOrdersComponent } from './lab-clinical-orders/lab-clinical-orders.component';
import { CdkDetailRowDirective } from "./lab-clinical-orders/cdk-detail-row.directive";
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    settingsDialog,
    profileDialog,
    unitDialog,
    ConfirmDialogComponent,
    ConfirmDynamicDialogComponent,
    ConfirmRemarkComponent,
    CancelDialogComponent,
    ExtendDialogComponent,
    SigninComponent,
    SigninUumComponent,
    LockscreenComponent,
    SignupComponent,
    ForgotComponent,
    DashboardComponent,
    InfoDialogComponent,
    DialogOverviewExampleDialog,
    CreatePassword,
    NotFoundComponent,
    PaymentDialogComponent,
    InfoDynamicDialogComponent,
    OnlyNumberDirective,
    OnlyAmountDirective,
    ProfileComponent,
    PasswordDialogComponent,
    QuickLinksComponent,
    QuickAppointmentsComponent,
    SortByPipe,
    DoubleClickDirective,
    PdfViewerComponent,
    ExportFileDialogComponent,
    BarChartComponent,
    MainDashboardComponent,
    PieChartComponent,
    NegativeStackComponent,
    PieRadiusComponent,
    RangeColumnComponent,
    DonutComponent,
    ProgressBarComponent,
    SigninModalComponent,
    TableComponent,
    CounterComponent,
    BarcodeScannerComponent,
    ComposeMailDialogComponent,
    TextEditorTemplateComponent,
    ResetPasswordComponent,
    PatientRegistrationComponent,
    PatientNotesDialogComponent,
    CameraDialogComponent,
    RegsuccessDialogComponent,
    UploadfileComponent,
    PatientAppointmentDialogComponent,
    UnsavedFormDilogComponent,
    UpdateSponsorComponent,
    AddSponsorDialogComponent,
    GlAttachmentComponent,
    ConfirmRemarksComponent,
    PdfViewer2Component,
    PdfViewer3Component,
    ViewDocumentComponent,
    ClinicareViewDialogComponent,
    LabClinicalOrdersComponent,
    CdkDetailRowDirective
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    SidebarModule.forRoot(),
    NgxEchartsModule,
    CommonModule,
    ApplicationPipeModule,
    PatientSearchModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      closeButton: true,
      disableTimeOut: false,
      progressBar: true,
      progressAnimation: "increasing",
    }),
    PushNotificationModule,
    NgxDaterangepickerMd.forRoot(),
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    DashboardLayoutModule,
    NgxScannerQrcodeModule,
    RichTextEditorModule,
    SharedModule,
    NgxMatSelectSearchModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuardGuard,
    SearchService,
    AuthService,
    DataService,
    ElasticService,
    UiConfigService,
    PatientService,
    MasterService,
    UtilsService,
    EnvServiceProvider,
    ApplicationConfigService,
    SocketService,
    QuickLinkService,
    MultiLanguageService,
    ReportService,
    FileUploadService,
    qmsSocketService,
    CopDecimalCalculateService,
    GeneralUrlService,
    MasterUrlService,
    patientGetDataservice,
    SearchHeaderService,
    AppointmentService

  ],
  bootstrap: [AppComponent],
  entryComponents: [
    settingsDialog,
    profileDialog,
    unitDialog,
    ConfirmDialogComponent,
    ConfirmDynamicDialogComponent,
    ConfirmRemarkComponent,
    CancelDialogComponent,
    ExtendDialogComponent,
    InfoDialogComponent,
    DialogOverviewExampleDialog,
    CreatePassword,
    PaymentDialogComponent,
    InfoDynamicDialogComponent,
    PasswordDialogComponent,
    QuickLinksComponent,
    QuickAppointmentsComponent,
    BarcodeScannerComponent,
    PatientNotesDialogComponent,
    PatientAppointmentDialogComponent,
    UnsavedFormDilogComponent,
    AddSponsorDialogComponent,
    GlAttachmentComponent,
    ConfirmRemarksComponent,
    ClinicareViewDialogComponent,
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: AppModule,
      providers: [
        AppBaseService,
        {
          provide: "env", // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
