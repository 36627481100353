<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">Warning Unsaved Form Data ! </h6>
      
    </div>
    <div class="modal-body bodyCreatePasscode">
      <p>You have unsaved changes on this form .if you click 'yes' these changes will be discarded. Click 'cancel' to continue creating slots.</p>
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button mat-mini-fab class="btnCyan" type="submit" data-automation-attribute="button-save-dialog">
          <mat-icon matTooltip="Yes" (click)="onNoClick('yes')">done</mat-icon>
        </button>
        <button mat-mini-fab class="btnRed" type="submit" data-automation-attribute="button-cancel-dialog">
          <mat-icon matTooltip="No" (click)="onNoClick('no')">close</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>
