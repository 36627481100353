import { Observable } from "rxjs";
import { EnvoirnmentService } from "../services/envoirnment.service";
import { Injectable } from "@angular/core";

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Injectable()
export class SocketService {
    webSocketEndPoint: string = this.env.socketUrl;// "https://socket-outsourcedev.hatiintl.com/ws-notification";
    userTopic: string = "";
    roleTopic: string = "";
    stompClient: any;
    roleId: string;

    constructor(
        public env: EnvoirnmentService
    ){}
    
    /**
     *
     *
     * @return {*} 
     * @memberof SocketService
     */
    _connect(roleId?) {
        this.roleId = roleId;
        const userId = atob(localStorage.getItem("userId"));
        this.userTopic = "/topic/" + userId + "/push-notification";
        this.roleTopic = "/topicRole/" + roleId + "/push-notification";
        // let qmsTopic = "/topic/qm-notification";

        console.log("Initialize WebSocket Connection");
        let socket = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(socket);
        const _this = this;

        let observable = new Observable(observer => {
            _this.stompClient.connect({}, function (frame) {
                if (roleId) {
                    _this.stompClient.subscribe(_this.userTopic, function (sdkEvent) {
                        _this.onMessageReceived(sdkEvent);
                        observer.next(sdkEvent);
                    });
        
                    _this.stompClient.subscribe(_this.roleTopic, function (sdkEvent) {
                        _this.onMessageReceived(sdkEvent);
                        observer.next(sdkEvent);
                    });
                }
                //  else {
                //     _this.stompClient.subscribe(qmsTopic, function (sdkEvent) {
                //         _this.onMessageReceived(sdkEvent);
                //         observer.next(sdkEvent);
                //     });
                // }
            }, _this.errorCallBack);
        
            return () => {
                _this._disconnect();
            };
        });
        
        return observable;
    }

    /**
     *
     *
     * @memberof SocketService
     */
    _disconnect() {
        if (this.stompClient) {
            this.stompClient.disconnect();
            console.log("Disconnected");
        }
    }
    
    /**
     *
     *
     * @param {*} error
     * @memberof SocketService
     */
    errorCallBack(error) {
        console.log("errorCallBack -> " + error)
        setTimeout(() => {
          
           this._connect(this.roleId);
        }, 5000);
    }
    
    /**
     *
     *
     * @param {*} message
     * @memberof SocketService
     */
    onMessageReceived(message) {
        console.log("Message Recieved from Server :: " + message);
        console.log(JSON.stringify(message.body));
    }
}
