import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { APPLICATIONSERVICE } from "../../constants/application-service";
import { MICROSERVICES } from "../../constants/web-services";
import { AppBaseService } from "../../services/http.service";
import { ReportService } from "../../services/report.service";
import { ExportFileDialogComponent } from '../export-file-dialog/export-file-dialog.component';
import { MatDialog } from "@angular/material/dialog";
declare const PDFObject: any;
@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  constructor(
    private httpService: AppBaseService,
    public dialog: MatDialog,
    private reportService: ReportService
  ) {}

  protected _onDestroy = new Subject<void>();
  reportPayload:any;
  reportSecondPayload:any=null;
  reportThirdPayload:any=null;
  componentLoaded:boolean=false;
  exportTypes = [
    {
      "id": "1",
      "active": true,
      "code": "xlsx",
      "desc": "Excel"
    },
    {
      "id": "2",
      "active": true,
      "code": "doc",
      "desc": "DOC"
    },
    {
      "id": "3",
      "active": true,
      "code": "csv",
      "desc": "CSV"
    }
  ];

  /**
   *
   *
   * @memberof PdfViewerComponent
   */
  ngOnInit(): void {
    this.componentLoaded=true;
    this.reportPrint();
  }

  /**
   *Destory Mehthod
   *
   * @memberof PdfViewerComponent
   */
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    localStorage.removeItem("reportPayload");
    localStorage.removeItem("reportPayload2");
    localStorage.removeItem("reportPayload3");
  }

  /**
   *Report Print method
   *
   * @memberof PdfViewerComponent
   */
  reportPrint() {
    this.reportPayload = JSON.parse(localStorage.getItem("reportPayload"));
    this.reportSecondPayload = JSON.parse(localStorage.getItem("reportPayload2"));
    this.reportThirdPayload = JSON.parse(localStorage.getItem("reportPayload3"));
    
    if(this.reportPayload.isReports) {
      delete this.reportPayload['isReports'];
      this.misGenerateReports();
    }
    else if(this.reportPayload.isMrd) {
      this.reportPrintAPI(
        MICROSERVICES.ANCILLARY_SERVICE,
        APPLICATIONSERVICE.REPORT_GENERATE_VIEW,
        this.reportPayload,
        "reportPayload"
       );
    }
    else if(this.reportPayload.isDietKitchen) {
      this.reportPrintAPI(
        MICROSERVICES.STOCK_SERVICE,
        APPLICATIONSERVICE.REPORT_GET_COMMON_REPORT,
        this.reportPayload,
        "reportPayload"
       );
    }
    else if(this.reportPayload.isLab) { 
      let serviceName = "";
      if(this.reportPayload.isBarcode) serviceName = APPLICATIONSERVICE.REPORT_GET_COMMON_REPORT;
      else serviceName = APPLICATIONSERVICE.REPORT_GENERATE_VIEW;

      this.reportPrintAPI(
        MICROSERVICES.LAB_SERVICE,
        serviceName,
        this.reportPayload
      );
    }
    else if(this.reportPayload.isBilling) {
      if(this.reportSecondPayload) {
        this.multipleBillingReportsAPI(this.reportSecondPayload)
        //localStorage.removeItem("reportPayload2");
       // this.reportSecondPayload=null;
      } else {
        this.multipleBillingReportsAPI(this.reportPayload)
       // localStorage.removeItem("reportPayload");
       // this.reportPayload=null;
      }
    }
    else if(this.reportPayload.isStock) {
      let apiUrl = APPLICATIONSERVICE.REPORT_GENERATE_VIEW;
      let requestPayload;
      if(this.reportPayload.reportName=="STOCKTAKE") {
        requestPayload = {
          transactionId: this.reportPayload.transactionId,
          type: this.reportPayload.type,
          reportName: this.reportPayload.reportName,
          isQtyRequired:this.reportPayload.isQtyRequired
        }
      } else {
       requestPayload = this.formatStockReportPayload();
      }
      if(requestPayload.isforhistory) apiUrl = APPLICATIONSERVICE.REPORT_GENERATE_PHARMACY_VIEW;

      this.reportPrintAPI(
        MICROSERVICES.STOCK_SERVICE,
        apiUrl,
        requestPayload
      );
    }
    else if(this.reportPayload.isInternalNotes) {
      this.printInternalNotesAPI(this.reportPayload)
      this.reportPayload=null;
    }
    else if(this.reportThirdPayload) {
      this.multipleReportsAPI(this.reportThirdPayload);
      localStorage.removeItem("reportPayload3");
      this.reportThirdPayload=null;
    }
    else if(this.reportSecondPayload) {
      this.multipleReportsAPI(this.reportSecondPayload);
      localStorage.removeItem("reportPayload2");
      this.reportSecondPayload=null;
    }
    else {
      this.multipleReportsAPI(this.reportPayload);
      localStorage.removeItem("reportPayload");
      this.reportPayload=null;
    }
  }

  /**
   *
   *
   * @param {string} resourceUrl
   * @param {string} reportGeneratePath
   * @param {*} reqPayload
   * @param {string} [removeItem]
   * @memberof PdfViewerComponent
   */
  reportPrintAPI(resourceUrl: string, reportGeneratePath: string, reqPayload: any, removeItem?: string) {
    this.httpService.setResourceURL(resourceUrl);
    this.httpService
      .postResource(reportGeneratePath, reqPayload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        console.log(this.componentLoaded);
        const pdfObject = PDFObject.embed(result.payload, "#pdfContainer");
        if(!this.componentLoaded){
          window.location.reload();
          console.log(this.componentLoaded);
        }
      });
  }

  /**
   * 
   * @param payload 
   * @returns 
   */
  printInternalNotesAPI = (payload:any) => {
    return new Promise<void>((resolve) => {
      this.reportPrintAPI(
        MICROSERVICES.BILLING_SERVICE,
        APPLICATIONSERVICE.REPORT_GET_COMMON_REPORT,
        payload,
      );
      resolve();
    });
  }
  
  /**
   *
   *
   * @return {*}  {*}
   * @memberof PdfViewerComponent
   */
  formatBillingReportPayload(reportPayload): any {
    const requestPayload = {
      type: reportPayload.type,
      reportName: reportPayload.reportName.toUpperCase(),
      parameterList: [
        {
          parameter: "id",
          value: reportPayload.transactionId,
          dataType:"Long"
        },
        {
          parameter: "isReprint",
          value: reportPayload.reprint,
          dataType:"Boolean"
        }
      ]
    }

    if(reportPayload.params && reportPayload.params.length > 0) {
      reportPayload.params.forEach(param => {
        requestPayload.parameterList.push({
          parameter: param.name.toLowerCase(),
          value: param.value,
          dataType: param.name
        });
      });
    }

    return requestPayload;
  }

  /**
   *
   *
   * @param {*} reportPayload
   * @return {*}  {*}
   * @memberof PdfViewerComponent
   */
  formatReminderLetterBillingReportPayload(reportPayload): any {
    const requestPayload = {
      type: reportPayload.type,
      reportName: reportPayload.reportName.toUpperCase(),
      parameterList: []
    }

    if(reportPayload.params && reportPayload.params.length > 0) {
      reportPayload.params.forEach(param => {
        requestPayload.parameterList.push({
          parameter: param.parameter,
          value: param.value,
          dataType: param.dataType
        });
      });
    }
    return requestPayload;
  }

  /**
   *
   *
   * @param {*} report
   * @return {*}  {*}
   * @memberof PdfViewerComponent
   */
  formatGenericReportPayload(report: any): any {
    const requestPayload = {
      transactionId: report.transactionId,
      type: report.type,
      reportName: report.reportName,
    };

    return requestPayload;
  }

  /**
   *
   *
   * @return {*}  {*}
   * @memberof PdfViewerComponent
   */
  formatStockReportPayload(): any {
    let requestPayload : {
      transactionId: string,
      type: string,
      reportName: string,
      progressnoteid?: number,
      languageid?: number,
      isforhistory?: boolean,
      fromDate?:string,
      toDate?: string
      parameterList?: any,
    }

    // requestPayload = this.formatGenericReportPayload(this.reportPayload);
    
    if(this.reportPayload.isPharmacy) {          
      if(this.reportPayload.reportName == "MEDICATIONORDERPRESCRIPTION" || 
        this.reportPayload.reportName == "MEDICATIONORDERDRUGLABEL" ||
        this.reportPayload.reportName == "MEDICATIONORDERDISTRIBUTIONLIST" || 
        this.reportPayload.reportName == "DRUGLABELPICKER") {
        requestPayload = {
          transactionId: this.reportPayload.transactionId,
          type: this.reportPayload.type,
          reportName: this.reportPayload.reportName,
          progressnoteid: this.reportPayload.progressnoteid ? this.reportPayload.progressnoteid : null,
          languageid: this.reportPayload.languageid ? Number(this.reportPayload.languageid) : null,
          isforhistory: true,
          fromDate:"2023-01-01",
          toDate:"2023-01-01"
        }
      }
    }

    return this.reportPayload;
  }

  /**
   *
   *
   * @memberof PdfViewerComponent
   */
  misGenerateReports() {
    this.httpService.setResourceURL(MICROSERVICES.REPORT_SERVICE);
    this.httpService
      .postResource(APPLICATIONSERVICE.REPORT_GENERATE_MIS_VIEW, this.reportPayload )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((result: any) => {
        console.log(result.payload);
        const pdfObject = PDFObject.embed(result.payload, "#pdfContainer");
      });
  }

  /**
   *
   *
   * @memberof PdfViewerComponent
   */
  exportReport() {
    const dialogRef = this.dialog.open(ExportFileDialogComponent, {
      height: "170px",
      width: "300px",
    });
  }

  /**
   *
   *
   * @param {*} valuelist
   * @memberof PdfViewerComponent
   */
  exportfile(valuelist){
    this.reportPayload = JSON.parse(localStorage.getItem("reportPayload"));
    if (valuelist.id == 1) {
      this.exportfileType("xlsx");
    } else if(valuelist.id == 2) {
      this.exportfileType("docx");
    } else if(valuelist.id == 3) {
      this.exportfileType("csv");
    } else{
      this.reportService.printReport(
        this.reportPayload.transactionId,
        this.reportPayload.type,
        this.reportPayload.reportName
      );
    }
  }

  /**
   *
   *
   * @param {string} fileType
   * @memberof PdfViewerComponent
   */
  exportfileType(fileType: string) {
    if(this.reportPayload) {
      if(this.reportPayload.isReports) {
        this.reportPayload.type = fileType;
        this.reportService.printMISReport(
          this.reportPayload,
          fileType,
          this.reportPayload.reportName
        );
      } else if(this.reportPayload.isBilling) {
        let params = this.reportPayload.params ? this.reportPayload.params : [];
        this.reportService.printReportBillingExport(
          this.reportPayload.transactionId,
          fileType,
          this.reportPayload.reportName,
          this.reportPayload.reprint,
          params
        );
      } else if(this.reportPayload.isStock) {
        this.reportService.printReportStock(
          this.reportPayload.transactionId,
          fileType,
          this.reportPayload.reportName,
          false
        );
      } else if(this.reportPayload.isLab) {
        if(this.reportPayload.isBarcode) {
          this.reportService.printReportLabBarcode(
            this.reportPayload.parameterList,
            fileType,
            this.reportPayload.reportName
          );
        } else {
          this.reportService.printReportStock(
            this.reportPayload.transactionId,
            fileType,
            this.reportPayload.reportName,
            true
          );
        }
      } else {
        this.reportService.printReport(
          this.reportPayload.transactionId,
          fileType,
          this.reportPayload.reportName
        );
      }
    }
  }
  
  /**
   *
   *
   * @param {*} payLoad
   * @memberof PdfViewerComponent
   */
  multipleReportsAPI = (payLoad: any) => {
    let payloadres="";
    payloadres = payLoad;
    return new Promise<void>((resolve,reject) => {
      let reqPayload = this.formatGenericReportPayload(payloadres);
      this.reportPrintAPI(
        MICROSERVICES.OPD_SERVICE,
        APPLICATIONSERVICE.REPORT_GENERATE_VIEW,
        reqPayload,
      );
      console.log(JSON.stringify(reqPayload));
      setTimeout(() => {
        reject(new Error('Report generation failed.'));
      resolve();
    }, 2000);
    });
  };

  /**
   *
   *
   * @param {*} payLoad
   * @memberof PdfViewerComponent
   */
  multipleBillingReportsAPI = (payLoad: any) => {
    let payloadres="";
    let requestPayload;
    payloadres=payLoad;
    return new Promise<void>((resolve) => {
      if(payLoad.isReminder){
        requestPayload = this.formatReminderLetterBillingReportPayload(payloadres);
      }else{
        requestPayload = this.formatBillingReportPayload(payloadres);
      } 
      this.reportPrintAPI(
        MICROSERVICES.BILLING_SERVICE,
        APPLICATIONSERVICE.REPORT_GET_COMMON_REPORT,
        requestPayload,
      );
      resolve();
    });
  };
}
