import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import localForage from "localforage";


@Injectable()
export class AuthService {

  constructor(@Inject("env") private env) {}
  
  getDepartmentList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then( (data: any) => {
          return data.userDepartmentList;
        })
      );
    });
  }
  
  getData() {
    return localForage.getItem("auth-data");
  }

  getUserInfo() {
    return localForage.getItem("auth-data").then((data: any) => {
      return {
        userTypeName: (data.employeeType && data.employeeType.employeeTypeDescription)? data.employeeType.employeeTypeDescription : null,
        userName: data.userName,
        name: data.userName,
        email:data.userEmail,
        userId: data.userId,
        autho:data.authorization,
        userSpecialRightList:data.userSpecialRightsList?data.userSpecialRightsList:[],
        user_code: null,
        userTypeImage:data.userType?data.userType.userTypeCode:"",
        userRefernceId:data.employeeDetails?data.employeeDetails.employeeId:0
      };
    });
  }

  getGeneralSettingInfo() {
    return localForage.getItem("auth-data").then((data: any) => {
      return {
        slotTime: data.generalSetting?data.generalSetting.slotTime:"",
      };
    });
  }

  getUser() {
    return localForage.getItem("auth-data");
  }

  getUnit() {
    return localForage.getItem("unit");
  }

  getNotification() {
    return localForage.getItem("notification");
  }

  getRole() {
    return localForage.getItem("role");
  }
  getSchedule() {
    return localForage.getItem("schedule");
  }

  //get counter related information
  getCounterName() {
    return localForage.getItem("counterName");
  }
  getCounterCode() {
    return localForage.getItem("counterCode");
  }
  getShiftId() {
    return localForage.getItem("shiftId");
  }
  getShiftNo() {
    return localForage.getItem("shiftNo");
  }

  getUnitList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) =>{
          return data.userUnitList;
        })
      );
    });
  }

  getSubDepartList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          return data.userSubDepartmentList;
        })
      );
    });
  }

  getStore() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) =>{
          let stationId = atob(localStorage.getItem("storeId"));
          return data.userStoreList
            ? data.userStoreList.find(i => i.storeId == stationId)
            : null;
        })
      );
    });
  }

  getStoreList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          return data.userStoreList ? data.userStoreList
            : [];
        })
      );
    });
  }

  getRoleList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          return data.userUnitRoleList;
        })
      );
    });
  }

  getScheduleList() {
    let _this = this;
    return new Observable<boolean>(observer => {
      _this.getSchedule().then((data: any) => {
        return data;
      });
    });
  }

  getCounterList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          let unitCode = atob(localStorage.getItem("unitCode"));
          return data.userSpecialRightsList
            ? data.userSpecialRightsList
            : [];
        })
      );
    });
  }
}
