import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


const cacheBuster$ = new Subject<void>();

@Injectable()

/**
 *
 *
 * @export
 * @class mandatoryFieldsService
 */
export class mandatoryFieldsService {
    setMandatoryFormValues(_this,_mandatorykeys) {
    _this.forEach((res) => {
      for (let objKey in _mandatorykeys) {
        if (res.key == objKey) {
            _mandatorykeys[objKey]=(res.systemMandatory==true?true:false);
        }
      }
    });
  }
}
