export const QUEUE_STATUS = {
    NOSHOW: "NOSHOW",
    WAITING: "WAITING",
    SERVING: "SERVING",
    CLOSED: "CLOSED",
    HOLD: "HOLD",
    FORWARDED: "FORWARDED"
};


export const QUEUE_STATUS_CODE = {
    NOSHOW: "NS",
    WAITING: "WAT",
    SERVING: "SER",
    CLOSED: "CLS",
    HOLD: "HLD",
    FORWARDED: "FOW"
};
