<div data-automation-attribute="divProfileDialog">
  <div class="modal-body bodyCreatePasscode">
    <div class="fix-height" style="padding-left: 5px; padding-right: 5px;">
      <div class="internalMatGrid fix-height">

        <!-- User Profile Grid -->
        <mat-grid-list cols="1" rows="6" rowHeight="2.6rem">
          <mat-grid-tile [colspan]="1" [rowspan]="4">
            <!-- <img src="assets/images/noavatar.png" alt="User" title="User" class="img-profile"
              data-automation-attribute="imgDefaultUser" /> -->
            
            <img *ngIf="userImage != undefined" [src]="userImage" alt="User" title="User" class="img-profile"
              data-automation-attribute="imgDefaultUser" />

            <img *ngIf="userImage == undefined && empImage != undefined" [src]="userImage" alt="User" title="User" class="img-profile"
              data-automation-attribute="imgDefaultUser" />

            <img *ngIf="userImage == undefined && empImage == undefined" src="assets/images/noavatar.png" alt="User" title="User" class="img-profile"
              data-automation-attribute="imgDefaultUser" />

          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <div class="center">
              <b> {{ userName }} </b>
              <p> {{ userRole }} </p>
            </div>
          </mat-grid-tile>

        </mat-grid-list>

        <!-- Button Grid -->
        <mat-grid-list cols="1" rows="6" rowHeight="2.6rem" [gutterSize]="0">

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <button (click)="changeTab(0)" class="buttonSizeCyan"
              mat-raised-button>
              <mat-icon> info </mat-icon>
              <mat-label> &nbsp;&nbsp;Info </mat-label>
            </button>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <button (click)="changeTab(1)" class="buttonSizeCyan"
              mat-raised-button>
              <mat-icon> link </mat-icon>
              <mat-label> &nbsp;&nbsp;Quick Links </mat-label>
            </button>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <button (click)="changeTab(2)" class="buttonSizeCyan"
              mat-raised-button>
              <mat-icon> dashboard </mat-icon>
              <mat-label> &nbsp;&nbsp;Dashboards </mat-label>
            </button>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <button (click)="changeTab(3)" class="buttonSizeCyan"
              mat-raised-button>
              <mat-icon> desktop_mac </mat-icon>
              <mat-label> &nbsp;&nbsp;Frequently Accessed </mat-label>
            </button>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <button (click)="changeTab(4)" class="buttonSizeCyan"
              mat-raised-button>
              <mat-icon> notifications </mat-icon>
              <mat-label> &nbsp;&nbsp;Notification Types </mat-label>
            </button>
          </mat-grid-tile>

        </mat-grid-list>
      </div>

      <div class="tabContent fix-height">
        <mat-tab-group #tabGroup class="tabGroupPersonalDetails invisible-tabs fix-height">
          <mat-tab class="tabSubPersonalDetails">
            <ng-template mat-tab-label>
              Encounter
            </ng-template>
            <div class="section-grey">
              <mat-grid-list cols="3" rows="2" rowHeight="2.6rem" class="grid-backGround">
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                  <div class="contentLeftAlign">
                    <h6 class="section-head">Login</h6>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input matInput [(ngModel)]="userName" [disabled]="true" />
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Login ID</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="userLoginID" [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Passcode</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="passCode" [disabled]="true">
                    <mat-icon matSuffix (click)="openPasswordDialog()" class="icon-edit" matTooltip="Edit Passcode">edit
                    </mat-icon>
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="3" rows="2" rowHeight="2.6rem" class="grid-backGround">
                <mat-grid-tile [colspan]="3" [rowspan]="1">
                  <div class="contentLeftAlign">
                    <h6 class="section-head">Personal</h6>
                  </div>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Date of Birth</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.dob"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Age</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.age"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Nationality</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.nationality"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Faculty</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.faculty"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Department</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.department"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>

                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field class="full-width contentForm" appearance="outline">
                    <mat-label>Sub Department</mat-label>
                    <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.subdepartment"
                      [disabled]="true">
                  </mat-form-field>
                </mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="3" rows="1" rowHeight="12.5rem" class="grid-backGround">
                <mat-grid-tile [colspan]="2" [rowspan]="1">
                  <mat-grid-list cols="4" rows="1" rowHeight="2.6rem" class="sub-grid">
                    <mat-grid-tile [colspan]="4" [rowspan]="1">
                      <div class="contentLeftAlign">
                        <h6 class="section-head">Address</h6>
                      </div>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="4" [rowspan]="1">
                      <mat-form-field class="full-width contentFormAddress" appearance="outline">
                        <mat-label>Residential Address</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.address"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.city"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>State</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.state"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Postcode</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.postcode"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.country"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>


                    <mat-grid-tile [colspan]="4" [rowspan]="1">
                      <mat-form-field class="full-width contentFormAddress" appearance="outline">
                        <mat-label>Correspondance Address</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.corAddress"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.corCity"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>State</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.corState"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Postcode</mat-label>
                        <input matInput data-automation-attribute="txtUsername"
                          [(ngModel)]="employeeDetails.corPostcode" [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.corCountry"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>
                  </mat-grid-list>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-grid-list cols="1" rows="1" rowHeight="2.6rem" class="sub-grid">
                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <div class="contentLeftAlign">
                        <h6 class="section-head">Contact Information</h6>
                      </div>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Email ID</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.emailID"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.mobileNo"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Home Contact No.</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.homeNo"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <mat-form-field class="full-width contentForm" appearance="outline">
                        <mat-label>Work Contact No.</mat-label>
                        <input matInput data-automation-attribute="txtUsername" [(ngModel)]="employeeDetails.workNo"
                          [disabled]="true">
                      </mat-form-field>
                    </mat-grid-tile>
                  </mat-grid-list>
                </mat-grid-tile>
              </mat-grid-list>

              <mat-grid-list cols="1" rows="2" rowHeight="2.6rem" class="grid-backGround">
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <div class="contentLeftAlign">
                    <h6 class="section-head">Preference Settings</h6>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <!-- <section class="contentBoxAlign">
                    <mat-checkbox [(ngModel)]="checked">Menu Collapse</mat-checkbox>
                  </section> -->
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </mat-tab>

          <mat-tab class="tabSubPersonalDetails">
            <ng-template mat-tab-label>
              Quick Links
            </ng-template>

            <div class="heading-section">
              <h6 class="section-head">Selected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, true)" (drop)="onDrop($event, true, quickLinkList, quickLinkListInactive)">
              <div *ngFor="let item of quickLinkList" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>

            <div class="heading-section">
              <h6 class="section-head">Unselected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, false)" (drop)="onDrop($event, false, quickLinkListInactive, quickLinkList)">
              <div *ngFor="let item of quickLinkListInactive" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>
            <div class="modal-footer footerProfile">
              <div>
                <button (click)="saveChanges('userQuickLinks/updateUserQuickLink', quickLinkList, quickLinkListInactive, 'Quick Link ')" mat-mini-fab class="btnCyan btnSave">
                    <mat-icon matTooltip="Update Profile">sim_card</mat-icon>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="tabSubPersonalDetails">
            <ng-template mat-tab-label>
              Dashboard
            </ng-template>

            <div class="heading-section">
              <h6 class="section-head">Selected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, true)" (drop)="onDrop($event, true, dashboardList, dashboardListInactive)">
              <div *ngFor="let item of dashboardList" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>

            <div class="heading-section">
              <h6 class="section-head">Unselected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, false)" (drop)="onDrop($event, false, dashboardListInactive, dashboardList)">
              <div *ngFor="let item of dashboardListInactive" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>
            <div class="modal-footer footerProfile">
              <div>
                <button (click)="saveChanges('userDashboard/updateData', dashboardList, dashboardListInactive, 'Dashboard ')" mat-mini-fab class="btnCyan btnSave">
                    <mat-icon matTooltip="Update Profile">sim_card</mat-icon>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="tabSubPersonalDetails">
            <ng-template mat-tab-label>
              Frequently Accessed
            </ng-template>

            <div class="heading-section">
              <h6 class="section-head">Selected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, true)" (drop)="onDrop($event, true, frequentlyAccessedList, frequentlyAccessedListInactive)">
              <div *ngFor="let item of frequentlyAccessedList" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>

            <div class="heading-section">
              <h6 class="section-head">Unselected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, false)" (drop)="onDrop($event, false, frequentlyAccessedListInactive, frequentlyAccessedList)">
              <div *ngFor="let item of frequentlyAccessedListInactive" class="grid-item"  matTooltip="{{ item.menuMaster.displayText }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <img class="img" *ngIf="item.menuMaster.parentid == '0'" [src]="'../../../assets/images/master-icons/'+item.menuMaster.icon" />
                <img class="img" *ngIf="item.menuMaster.parentid != '0'" [src]="'../../../assets/images/sidebar-icons/'+item.menuMaster.icon+'.png'" />
              </div>
            </div>
            <div class="modal-footer footerProfile">
              <div>
                <button (click)="saveChanges('userFrequent/updateUserFreqData', frequentlyAccessedList, frequentlyAccessedListInactive, 'Frequently Accessed ')" mat-mini-fab class="btnCyan btnSave">
                    <mat-icon matTooltip="Update Profile">sim_card</mat-icon>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="tabSubPersonalDetails">
            <ng-template mat-tab-label>
              Notification Type
            </ng-template>

            <div class="heading-section">
              <h6 class="section-head">Selected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, true)" (drop)="onDrop($event, true, notificationList, notificationListInactive)">
              <div *ngFor="let item of notificationList" class="grid-notification"  matTooltip="{{ item.roleNotificationMapper.notificationCode }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <div class="notification-flex">
                  {{ item.roleNotificationMapper? item.roleNotificationMapper.notificationCode : '' }}
                </div>
              </div>
            </div>

            <div class="heading-section">
              <h6 class="section-head">Unselected Items</h6>
            </div>
            <div class="section-flex" (dragover)="allowDrop($event, false)" (drop)="onDrop($event, false, notificationListInactive, notificationList)">
              <div *ngFor="let item of notificationListInactive" class="grid-notification"  matTooltip="{{ item.roleNotificationMapper.notificationCode }}"
                draggable="true"
                (drag)="onDrag($event, item)">
                <div class="notification-flex">
                  {{ item.roleNotificationMapper? item.roleNotificationMapper.notificationCode : '' }}
                </div>
              </div>
            </div>
            <div class="modal-footer footerProfile">
              <div>
                <button (click)="saveChanges('userNotification/updateUserNotification', notificationList, notificationListInactive, 'Notification Type ')" mat-mini-fab class="btnCyan btnSave">
                    <mat-icon matTooltip="Update Profile">sim_card</mat-icon>
                </button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <!-- Bottom Buttons -->
  <!-- <div class="modal-footer footerProfile">
    <div>
      <button mat-mini-fab class="btnOrange btnSave">
          <mat-icon matTooltip="Return to Dashboard">undo</mat-icon>
      </button>
    </div>
    <div>
      <button *ngIf="showSave" mat-mini-fab class="btnCyan btnSave">
          <mat-icon matTooltip="Update Profile">sim_card</mat-icon>
      </button>
    </div>
  </div> -->
</div>