<div class="d-flex align-items-center justify-content-md-center min-height-100 text-center bg-indigo-A700 h-100">
  <div class="col col-lg-10 p-5">
    <img src="assets/images/avatar.jpg" class="avatar-md rounded-circle" alt="user" title="user"/>
    <div class="mb-3 mt-3">
      <p class="ff-headers text-uppercase mb-0">Betty Simmons</p>
      <small>Please enter your lock code</small>
    </div>
    <div class="center-block lockcode mt-1">
      <form role="form" (ngSubmit)="onSubmit()">
        <input type="text" class="form-control" placeholder="Enter passcode and press enter">
      </form>
    </div>
  </div>
</div>
