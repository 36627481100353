import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-push-notification',
  templateUrl: './show-push-notification.component.html',
  styleUrls: ['./show-push-notification.component.scss']
})
export class ShowPushNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
