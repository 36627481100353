  <div data-automation-attribute="div-success-encounter">

    <div class="modal-header modalHeaderBlack" data-automation-attribute="div-modal-header">
      <h6 class="modal-title">Notifications</h6>
      <span aria-label="Close" class="close" (click)="onNoClick()" data-automation-attribute="span-close-dialog">
        <mat-icon>clear</mat-icon>
      </span>
    </div>

    <div class="modal-body bodyCreatePasscode" data-automation-attribute="div-modal-body" style="overflow-y: auto; max-height: 400px">
      <div class="mat-menu-modal divNotification">
        <div class="divBody" *ngFor="let notification of allNotificationList;">
          <div
            class="divNotificationItem {{notification.status === 'read' ? 'divNotificationItemRead' : 'divNotificationItemUnread'}}"
            (click)="rowClicked(notification)"
          >
            <app-single-notification-item
              [notification]="notification"
            ></app-single-notification-item>
        </div>
      </div>
    </div>
  </div>

    <div class="modal-footer" data-automation-attribute="div-modal-footer">
      <mat-paginator
        [length]="pagination.total"
        [pageSize]="pagination.limit"
        [pageSizeOptions]="paginatorOptions"
        (page)="nextPage($event)"
        style="height: 60px;"
      >
      </mat-paginator>
    </div>

</div>

