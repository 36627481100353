export class ContractModel {
    conId = "";
    encounterId = "";
    payerId = "";
    contractId = "";
    contractCode = "";
    contractName = "";
    effectiveStartDate: Date = new Date();
    effectiveEndDate: Date = new Date();
    senderTypeId = "";
    companyDesc = "";
    fallBackTariffCode = "";
    fallBackTariffDesc = "";
    coSharePercentage = 0;
    selected?: boolean = false;
    status = "";
  }