<div id="pieChart">
  <ejs-accumulationchart
    [id]="idName"
    #pie
    style="display: block; width: 92%;height: inherit;width: inherit;"
    [legendSettings]="legendSettings"
    [tooltip]="tooltip" 
    (load)="load($event)"
    [enableAnimation]="enableAnimation"
    [enableBorderOnMouseMove]="false"
  >
    <e-accumulation-series-collection>
      <e-accumulation-series
        name="Browser"
        [dataSource]="dataSource"
        xName="type"
        yName="count"
        [startAngle]="startAngle"
        innerRadius="0%"
        [radius]="radius"
        [dataLabel]="dataLabel"
        [explode]="explode"
        explodeOffset="10%"
        [explodeIndex]="0"
      >
      </e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
</div>
