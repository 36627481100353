export class PatientSearchModel {  
    patientName?: string;
    MRN?: string;
    gender?: string;
    dob?: string;
    mobileNo?: string;
    identificationType?: string;
    identificationID?: string;
    offset?: number;
    limit?: string;
    unit?: string;
    country?: string;
    state?: string;
    city?: string;
    area?: string;
    pincode?: string;
    startDate?: string;
    endDate?: string;
    isExternalPatient?:boolean=false;
    toLowerCase: any;
    userType?:string;
    employeeNo?:string;
    empoyeeName?:string;
  }