<div class="div-create-passcode">
  <div class="modal-header modalHeaderCyan">
    <h6 class="modal-title">Edit Passcode</h6>
    <span aria-label="Close" class="close" (click)="onCloseClick()">
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <!-- TODO: Write CSS Class For Input Width Size (To Be Decreased) -->
  <div class="modal-body createPasscode">
    <form novalidate [formGroup]="passcodeForm" (ngSubmit)="passcodeForm.valid && changePasscode()" #changePasscodeForm="ngForm">
      <div class="form-container grid-border width100">
        <span class="spanLabel section-head"> Current Passcode: </span>
        <mat-form-field floatLabel="never" class="center" appearance="outline">
          <input
            matInput
            maxlength="8"
            minlength="8"
            formControlName="oldPasscode"
            [type]="inputType"
            required
            (keyup)="currentPasscodeKeyup()"
          />
          <mat-icon matSuffix *ngIf="!showPassword" (click)="$event.preventDefault(); showPasswordToggle()" class="icon-password" 
            matTooltip="Show Password"> visibility </mat-icon>
          <mat-icon matSuffix *ngIf="showPassword"  (click)="$event.preventDefault(); showPasswordToggle()" class="icon-password" 
            matTooltip="Hide Password"> visibility_off </mat-icon>
        </mat-form-field>
      </div>

      <div class="form-container grid-border width100">
        <span class="spanLabel section-head"> New Passcode: </span>
        <mat-form-field floatLabel="never" class="text-passcode center" appearance="outline">
          <input
            matInput
            formControlName="passcodePrefix"
            type="number"
            readonly
          />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="text-enterpasscode center" appearance="outline">
          <input
            matInput
            maxlength="4"
            minlength="4"
            formControlName="newPasscode"
            required
            type="text"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
      </div>

      <div class="form-container grid-border width100">
        <span class="spanLabel section-head"> Confirm Passcode: </span>
        <mat-form-field floatLabel="never" class="text-passcode center" appearance="outline">
          <input
            matInput
            formControlName="passcodePrefix"
            type="number"
            readonly
          />
        </mat-form-field>
        <mat-form-field floatLabel="never" class="text-enterpasscode center" appearance="outline">
          <input
            matInput
            maxlength="4"
            minlength="4"
            formControlName="confirmNewPasscode"
            required
            type="text"
            (keypress)="numberOnly($event)"
          />
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="modal-footer footerProfile">
    <div>
      <button mat-mini-fab (click)="onCloseClick()" class="btnRed btnCancel">
        <mat-icon matTooltip="Exit without Saving">undo</mat-icon>
      </button>
    </div>
    <div>
      <button mat-mini-fab class="btnCyan btnSave" (click)="markAsTouched(); changePasscodeForm.ngSubmit.emit()">
        <mat-icon matTooltip="Save Passcode">sim_card</mat-icon>
      </button>
    </div>
  </div>
</div>
