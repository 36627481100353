<div class="mat-menu-modal divNotification">
  <div class="divHeader" >
    <b class="left">Notifications</b>

    <mat-icon style="float: right; cursor: pointer;" matTooltip="Mark All as Read" (click)="markAllMessagesRead()">
      chrome_reader_mode
    </mat-icon>
  </div>
  <div class="divMainBody"> 
    <div class="divBody" *ngFor="let notification of allNotificationList; let i = index;">
      <div
        class="divNotificationItem {{notification.isRead ? 'divNotificationItemRead' : 'divNotificationItemUnread'}}"
        (click)="rowClicked(notification)"
      >
        <app-single-notification-item
          [notification]="notification"
        ></app-single-notification-item>
      </div>
    </div>
  </div>
  <div class="divFooter" (click)="showMorePushNotifications()">
    <b class="viewNotifications cursorPointer">View More</b>
  </div>
</div>
