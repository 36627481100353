import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

import {
  HttpClient,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { EnvoirnmentService } from "../services/envoirnment.service";
import { HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { APPLICATIONSERVICE } from "../constants/application-service";

@Injectable()
export class AppBaseService implements HttpInterceptor {
  protected resourceUrl: string;
  private baseUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      responseType: "json",
    }),
  };

  patchTempResource: any;
  putTempResource: any;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private router: Router,
    private env: EnvoirnmentService //@Inject("env") private env
  ) {
    this.baseUrl = env.apiUrl;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
    if (
      !request.url.includes("integration/token") &&
      !request.url.includes("user/login") && 
      !request.url.includes("relay") 
     )
    // !request.url.includes(APPLICATIONSERVICE.GET_MACHINE_IP
     {
      const unitId = localStorage.getItem("unitId");
      const orgId = localStorage.getItem("orgId");
      const orgCode = localStorage.getItem("orgCode");
      const unitCode = localStorage.getItem("unitCode");
      const userId = localStorage.getItem("userId");
      const userName = localStorage.getItem("userName");
      const counterName = localStorage.getItem("counterName");
      const counterId = localStorage.getItem("counterId");
      const activeShiftId = localStorage.getItem("activeShiftId");
      const languageCode = localStorage.getItem("lan");
      const authorization = localStorage.getItem("authorization")
        ? localStorage.getItem("authorization")
        : "";
      const emloyeeId = localStorage.getItem("employeeId");
     // const machineIP = localStorage.getItem("machineIP");
      request = request.clone({
        setHeaders: {
          "X-API-VERSION": "1",
          "X-ORGID": orgId ? atob(orgId) : "",
          "X-ORGCODE": orgCode ? atob(orgCode) : "",
          "X-UNITID": unitId ? atob(unitId) : "",
          "X-UNITCODE": unitCode ? atob(unitCode) : "",
          "X-USERID": userId ? atob(userId) : "",
          "x-EMPLOYEEID": emloyeeId ? emloyeeId : "",
          "X-USERNAME": userName ? atob(userName) : "",
          "X-COUNTERNAME": counterName ? counterName : "e",
          "X-ACTIVESHIFTID": activeShiftId ? activeShiftId : "open",
          "X-LANGUAGE": languageCode ? languageCode.toUpperCase() : "EN",
          Authorization: authorization,
          "X-SHIFTID": activeShiftId
            ? activeShiftId != "null"
              ? activeShiftId
              : "0"
            : "0",
          "X-COUNTERID": counterId
            ? counterId != "null"
              ? counterId
              : "0"
            : "0",
         // "X-MACHINE-IP": machineIP ? atob(machineIP) : ""
        },
      });
    }
    else {
      if(request.url.includes("relay")) {
        const authorization = localStorage.getItem("authorization")
        ? localStorage.getItem("authorization")
        : "";
        request = request.clone({
          setHeaders: {
            "X-API-VERSION": "1",
            Authorization: authorization,
            "access-token":this.env.clinicalAcessToken
          },
        });
      }
    }
    return next.handle(request).pipe(
      catchError((response) => {
        this.displayToastMessage(response, url);
        return throwError(response);
      })
    );
  }

  setAuditTrailAction(action) {
    const roleName = localStorage.getItem("roleName");
    this.httpOptions = {
      headers: new HttpHeaders({
        "X-ACTION": action,
        "X-ROLE": atob(roleName),
      }),
    };
  }

  setResourceURL(url) {
    this.resourceUrl = url;
  }

  getAutoSearch(url) {
    return this.http.get(this.baseUrl + url, this.httpOptions).pipe(
      tap((response: any[]) => {
        return response;
      })
    );
  }

  displayCMSToastMessage(message) {
    this.toastr.info(message);
  }

  getExternalResource(externalUrl: string) {
    return this.http.get(externalUrl).pipe(map((res: any) => res));
  }

  getResource(params) {
    const data = this.baseUrl + this.resourceUrl + params;
    return this.http.get(data, this.httpOptions).pipe(map((res: any) => res));
  }

  postResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  putResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .put(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .patch(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchServiceRequest(params) {
    const url = this.baseUrl + params;
    return this.http.patch(url, this.httpOptions).pipe(map((res: any) => res));
  }

  deleteResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http.delete(url, this.httpOptions).pipe(map((res: any) => res));
  }

  getBlobFileResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http.get(url, { responseType: "blob" as "json" }).pipe(
      map((res: any) => {
        return new Blob([res], { type: "application/pdf" });
      })
    );
  }

  getMIMETypeResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .get(url, { responseType: "blob" as "json", observe: "response" })
      .pipe(
        map((res: any) => {
          let myHeader = res.headers.get("Content-Type");
          return new Blob([res.body], { type: myHeader });
        })
      );
  }

  postFileResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "blob" as "json" })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: "application/pdf" });
        })
      );
  }
  postReportResource(params, payload, type) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "blob" as "json" })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: type });
        })
      );
  }

  //EMRLite API STARTS

  getLikeSearchDataUrl(url, payload) {
    return this.http.post(this.baseUrl + url, payload, this.httpOptions).pipe(
      tap((response: any[]) => {
        return response;
      })
    );
  }

  postCMSresource(url, payload) {
    return this.http
      .post(this.baseUrl + url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchCRMResource(url, payload) {
    return this.http
      .patch(this.baseUrl + url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  getCMSResource(url) {
    const data = this.baseUrl + url;
    return this.http.get(data, this.httpOptions).pipe(map((res: any) => res));
  }

  deleteCMSResource(url) {
    return this.http.delete(this.baseUrl + url).pipe(map((res: any) => res));
  }

  // EMRLite API ENDS

  getFileResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .get(url, { responseType: "json" })
      .pipe(map((res: any) => res));
  }

  uploadResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "json" })
      .pipe(map((res: any) => res));
  }

  setMultiPartHeader() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data",
      }),
    };
  }

  uploadMultiResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  displayToastMessage(response, url) {
    let statusText = response.statusText;
    let displayToast: boolean = true;
    if(response.url.includes(APPLICATIONSERVICE.USER_UPDATE_PASSWORD)) {
      displayToast = false;
    }

    if ((response.status == 0 || response.status >= 300) && displayToast) {
      let message = "Oops something went wrong. Contact Admin";
      if (response.status == 409) {
        statusText = "Validation";
        if (response.error && response.error.message) message = response.error.message;
        else if (response.error && response.error.statusMessage) message = response.error.statusMessage;
        else message = response.error;
        this.toastr.info(message, statusText);
      } else if (response.status == 401) {
        statusText = "Unauthorized Access";
        message = "Invalid User Credentials";
        this.router.navigate(["/"]);
        localStorage.setItem("isLoggedin", "false");
        this.toastr.info(message, statusText);
      } else if (response.status == 503) {
        statusText = "RULE ENGINE ERROR";
        message = "Rule Engine is not accessible. Please contact DevOps Admin";
        this.toastr.info(message, statusText + " (" + response.status + ")");
      } else if (response.status == 0 && statusText == "Unknown Error") {
        statusText = this.getMicroServiceName(url) + " ERROR";
        message = "Above microservice is down. Please contact DevOps Admin";
        this.toastr.error(message, statusText + " (404)");
      } else if (response.message && this.env.logMessages) {
        message = response.message;
        this.toastr.error(message, statusText + " (" + response.status + ")");
      }
    }
  }

  getMicroServiceName(url: string): string {
    if (url && url.split("/").length > 3) {
      return url.split("/")[3].toUpperCase();
    } else return "";
  }

  loginResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      observe: "response" as "response",
    };
    return this.http
      .post(url, payload, httpOptions)
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
