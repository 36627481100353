import { Injectable } from '@angular/core';
import { ObservableInput, takeUntil } from 'rxjs';
import { MultiLanguageService } from 'projects/medcare-core-ui/src/app/core/multi-language/multi-language.service';
import { AppBaseService } from 'medcare-core-ui';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  formData: FormData = new FormData();
  appointmentNo: any;
  _onDestroy: ObservableInput<any>;
  docTypeCode: any;
  folderCode: any;
  fileDescription: any;
  files: any;

  constructor(private langService: MultiLanguageService,
    public appBaseService: AppBaseService,
    public snackBar: MatSnackBar,) {
   }

   getFileUpload(list, mrn, visitNo, typeCode, folderCode,identificationNo?) {
    list.forEach(element => {
      this.formData= new FormData();
      this.formData.append("mrn", mrn ? mrn : "");
      this.formData.append("docTypeCode", element.doctype ? element.doctype : "");
      this.formData.append("fileDescription", element.fileDescription ? element.fileDescription : "");
      this.formData.append("folderCode", folderCode ? folderCode : "");
      this.formData.append("files", element.files);
      this.formData.append("visitNo", visitNo ? visitNo : "");
      this.formData.append("identificationNo",identificationNo?identificationNo:"");
      this.formData.append("documentStatusCode", element.docFileType ? element.docFileType : (element.fileType ? element.fileType : 'final'));
      this.formData.append("screenCode", element.screenCode? element.screenCode:''); 
      let apiurl = "file/uploadMultipleFiles"
      this.appBaseService.setResourceURL("/doc-service/");
      this.appBaseService
        .postResource(apiurl, this.formData)
        .subscribe(() => {
          this.snackBar.open(
            this.langService.get("EncounterPage.fileUploadSuccess"),
            this.langService.get("CommonAppoinment.success"),
            {
              duration: 1000,
            }
          );
  
        });
    });
   
  }

}
