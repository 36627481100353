import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AccumulationChart,
  AccumulationChartComponent,
  AccumulationTheme,
  IAccLoadedEventArgs,
} from "@syncfusion/ej2-angular-charts";
import { Browser } from "@syncfusion/ej2-base";
import { Subject, Subscription } from "rxjs";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-pie-radius",
  templateUrl: "./pie-radius.component.html",
  styleUrls: ["./pie-radius.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PieRadiusComponent implements OnInit, OnDestroy {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  @Input() isMaximize;
  @ViewChild("pie")
  public pie: AccumulationChartComponent | AccumulationChart;
  //Initializing Legend
  public legendSettings: Object = {
    visible: false,
    reverse: true,
  };
  //Initializing Datalabel
  public dataLabel: Object = {
    visible: false,
    position: Browser.isDevice ? "Inside" : "Outside",
    name: "text",
    connectorStyle: { length: "20px", type: "Curve" },
    font: {
      fontWeight: "600",
    },
    enableRotation: true,
  };
  dataSource: any[];
  idName: any;
  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, "Dark")
        .replace(/contrast/i, "Contrast")
    );
  }
  // custom code end
  public radius: string = "Radius";
  public enableAnimation: boolean = true;
  public enableSmartLabels: boolean = false;
  public tooltip: Object = {
    enable: true,
    header: "",
    format: "<b>${point.x}</b><br>Count: <b>${point.y}</b>",
    name: "Radius",
  };
  public title: string = "Pie with different Radius";
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            this.idName = this.data.id;
            for (let key in obj.data) {
              arr.push({
                type: key,
                count: obj.data[key].length,
                radius: Math.floor(Math.random() * 101) + "%", 
              });
            }
            this.dataSource = arr;
          }
        }
      })
    );
    let card = document.getElementById(this.data.id);
    let barChart = document.getElementById("pieRadius") as HTMLElement;
    barChart.id = "pieRadius" + this.data.id
    barChart.style.height = card.offsetHeight - 28 + "px";
    barChart.style.width = card.offsetWidth + "px";
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
