<div *ngIf="data.createOrforget" class="div-create-passcode">
  <div class="modal-header" style="background-color: #23244A;">
    <h6 class="modal-title" style="color: white">Create Passcode</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div class="modal-body bodyCreateNewPasscode">
    <div style="display:inline-flex;">
      <span class="spanLabel">{{ username }}</span>
      <mat-form-field floatLabel="never">
        <input
          matInput
          [placeholder]="enterusername"
          [(ngModel)]="usernameValue"
          [type]="'text'"
          style="color: black !important;"
        />
      </mat-form-field>
    </div>

    <div style="display:inline-flex;">
      <span class="spanLabel">{{ firsttimepasscode }}</span>
      <mat-form-field floatLabel="never">
        <input
          matInput
          [placeholder]="enterpassword"
          [(ngModel)]="passwordValue"
          [type]="'text'"
          (blur)="focusOut()"
          style="color: black !important;"
        />
      </mat-form-field>
    </div>

    <div style="display:inline-flex;">
      <span class="spanLabel"> {{ passcode }}</span>
      <mat-form-field floatLabel="never" class="text-passcode">
        <input
          matInput
          placeholder="{{ this.pinValue }}"
          [type]="'number'"
          disabled
          style="color: black !important;"
        />
      </mat-form-field>
      <mat-form-field floatLabel="never" class="text-enterpasscode">
        <input
          matInput
          [placeholder]="enterpasscode"
          [(ngModel)]="passcodeValue"
          [type]="'number'"
          style="color: black !important;"
        />
      </mat-form-field>
    </div>

    <div style="display:inline-flex;">
      <span class="spanLabel">{{ confirmpasscode }}</span>
      <mat-form-field floatLabel="never" class="text-passcode">
        <input
          matInput
          placeholder="{{ this.pinValue }}"
          [type]="'number'"
          disabled
          style="color: black !important;"
        />
      </mat-form-field>
      <mat-form-field floatLabel="never" class="text-enterpasscode">
        <input
          matInput
          [placeholder]="confirmpasscode"
          [(ngModel)]="passcodeConfirmValue"
          [type]="'number'"
          style="color: black !important;"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="modal-footer float-right">
    <div class="right-btn">
      <button (click)="resetData()" matTooltip="Reset" mat-flat-button data-automation-attribute="button-login">
        Cancel
      </button>     
      <button (click)="confirmSave()" matTooltip="Save" mat-flat-button data-automation-attribute="button-login">
        Save
      </button>
    </div>
  </div>
</div>

<div *ngIf="!data.createOrforget" class="div-forget-passcode">
  <div class="modal-header" style="background-color: #23244A;">
    <h6 class="modal-title"style="color: white !important;">Forgot Passcode</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div class="modal-body bodyCreateNewPasscode">
    <div style="display:inline-flex;">
      <span class="spanLabel">{{ emailid }}</span> 
      <mat-form-field floatLabel="never" class="width70 formfield-emailid" >
        <input matInput
         [placeholder]="enteremailID" 
         [(ngModel)]="useremailValue"
          ng [type]="text" 
          style="color: black !important;" />
      </mat-form-field>
    </div>
  </div>

  <div class="modal-footer float-right">
    <div class="right-btn">
      <button (click)="resetData()" matTooltip="Reset" mat-flat-button data-automation-attribute="button-login">
        Reset
      </button>     
      <button (click)="forgetpasscode()" mat-flat-button data-automation-attribute="button-login">
        Save
      </button>
    </div>
  </div>
</div>

<div *ngIf="data.sentornotfound">
  <div class="modal-header modalHeaderOrange">
    <h6 class="modal-title">Attention</h6>
    <span aria-label="Close" class="close" (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </span>
  </div>

  <div class="modal-body">
    <b>{{ verificationemail }}</b>
  </div>

  <div class="modal-footer">
    <div>
      <button mat-mini-fab [mat-dialog-close]="data.accept" class="btnOrange">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- <div *ngIf="!data.sentornotfound">
    <div class="modal-header modalHeaderOrange">
        <h6 class="modal-title">Attention</h6>
        <span aria-label="Close" class="close" (click)="onNoClick()">
            <mat-icon>clear</mat-icon>
        </span>
    </div>
    <div class="modal-body">
        <b>{{emailnotfound}}</b>
    </div>
    <div class="modal-footer">
        <div>
            <button mat-mini-fab [mat-dialog-close]="data.accept" class="btnOrange">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
        </div>
    </div>
</div> -->
