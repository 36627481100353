<span *ngIf="allNotificationList.length > 0">
  <div class="divNotificationContainer" >
      <!-- <app-single-push-notification
        *ngFor="let notification of allNotificationList;"
        [notification]="notification"
        (closeNotification)="closeNotification($event)"
      >
      </app-single-push-notification> -->
  </div>
</span>
