// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  Modules: {
    account_payable: false,
    account_recievable: false,
    adt: true,
    ambulance: true,
    billing: true,
    biomedic_waste: true,
    cash_book: false,
    chiropractic: false,
    cssd: false,
    daycare: true,
    dental: false,
    dietkitchen: true,
    emergency: false,
    fixed_asset: false,
    general_ledger: false,
    house_keeping: true,
    inventory: true,
    ipd: false,
    lab: true,
    linen_laundry: false,
    master: true,
    mis_reports: true,
    mrd: true,
    ncp: false,
    opd: true,
    operation_theatre: false,
    pharmacy: true,
    procurement: true,
    queue_management: false,
    rehab: false,
    user_management: true,
    welfare: false,
    general_maintenance: true,
    doctor_share: true,
    emrlite: false,
    ris: false,
    dpms: false,
    fis_integration: false,
    roster: false,
    generic_lab: false,
    sample_tracking: false,
    quality_control: false,
    quality_system: false,
    barcode: false,
    fixed_assets: false,
    CRM: true, 
    moh:true,
    mortuary:true,
    qms:true,
    counselling: true,
    radiology: true

  },
  client_name: "rsd",
  showPushNotification: false,
  isUserNameAndPasscodeLogin: false
};
