import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { DataService } from "../../services/data.service";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  @Input() isMaximize;
  idName: any;
  dataSource: any[];
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let progressBar = document.getElementById(
      "progressBar" + this.data.id
    ) as HTMLElement;
    progressBar.style.width = card.offsetWidth + "px";
    progressBar.style.height = card.offsetHeight - 40 + "px";
    let progressBarList: any = document.getElementsByClassName(
      "maintenance-progressbar"
    );
    if (progressBarList && progressBarList.length) {
      for (let i = 0; i < progressBarList.length; i++) {
        progressBarList[i].style.width = card.offsetWidth - 20 + "px";
      }
    }
  }
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    let card = document.getElementById(this.data.id);
    let progressBar = document.getElementById("progressBar");
    progressBar.id = "progressBar" + this.data.id;
    progressBar.style.width = card.offsetWidth + "px";
    progressBar.style.height = card.offsetHeight - 40 + "px";
    let progressBarList: any = document.getElementsByClassName(
      "maintenance-progressbar"
    );
    if (progressBarList && progressBarList.length) {
      for (let i = 0; i < progressBarList.length; i++) {
        progressBarList[i].style.width = card.offsetWidth - 20 + "px";
      }
    }
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let arr = [];
            let obj = this.data.chartData;
            this.idName = this.data.id;
            let columnTotal = this.calculateColumnTotal("count_data", obj.data);

            obj.data.map((data) => {
              arr.push({
                type: data[this.data.dashboardEntity],
                width: card.offsetWidth - 20 + "px",
                percent: (data.count_data / columnTotal) * 100,
                count:
                  this.data.xCoordinate == "isAmount"
                    ? data.count_data
                      ? data.count_data.toFixed(2)
                      : (0).toFixed(2)
                    : data.count_data,
              });
            });
            this.dataSource = arr;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  calculateColumnTotal(type: string, list): number {
    let sum: number = 0;
    if (list) {
      for (let row of list) {
        sum += Number(row[type] ? row[type] : 0);
      }
    }
    return sum;
  }
}
