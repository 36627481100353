import { Observable } from "rxjs";
import { EnvoirnmentService } from "../services/envoirnment.service";
import { Injectable } from "@angular/core";
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

@Injectable()
export class qmsSocketService {
    webSocketEndPoint: string = this.env.socketUrl;
    stompClient: any;

    constructor(
        public env: EnvoirnmentService
    ){}
    
    /**
     *
     *
     * @return {*} 
     * @memberof SocketService
     */
    _qmsconnect() {
      
        let qmsTopic = "/topic/qm-notification";
        console.log("Initialize WebSocket Connection");
        let socket = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(socket);
        const _this = this;

        let observable = new Observable(observer => {
            _this.stompClient.connect({}, function (frame) {
                    _this.stompClient.subscribe(qmsTopic, function (sdkEvent) {
                        _this.onMessageReceived(sdkEvent);
                        observer.next(sdkEvent);
                    });
            }, _this.errorCallBack);
        
            return () => {
                _this._disconnect();
            };
        });
        
        return observable;
    }

    /**
     *
     *
     * @memberof SocketService
     */
    _disconnect() {
        if (this.stompClient) {
            this.stompClient.disconnect();
            console.log("Disconnected");
        }
    }
    
    /**
     *
     *
     * @param {*} error
     * @memberof SocketService
     */
    errorCallBack(error) {
        console.log("errorCallBack -> " + error)
        setTimeout(() => {
          
           this._qmsconnect();
        }, 5000);
    }
    
    /**
     *
     *
     * @param {*} message
     * @memberof SocketService
     */
    onMessageReceived(message) {
        console.log("Message Recieved from Server :: " + message);
        console.log(JSON.stringify(message.body));
    }
}
