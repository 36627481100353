export const OPD_API_URL = {
  BILLING_SERVICE: "/sdgt-billing-service/",
  MASTER_SERVICE: "/masters-service/",
  FILE_SERVICE:"/file-service/",
  DOC_SERVICE:"/doc-service/",
  OPD_FILE_PATIENT_GET:"file/getMRNFileDetail/",
  M_BLOOD_GROUP: "Masters/BloodGroupMaster",
  M_LANGUAGE: "Masters/LanguageMaster",
  M_IDENTIFICATION: "Masters/IdentificationTypeMaster",
  M_UNITMASTER: "Masters/UnitMaster",
  M_RELIGION: "Masters/ReligionMaster",
  M_PATIENT_CATEGORY: "Masters/PatientCategoryMaster",
  M_GENDER: "Masters/GenderMaster",
  M_EDUCATION: "Masters/QualificationMaster",
  M_PLACEOFBIRTH: "Masters/PlaceOfBirthMaster",
  M_OCCUPATION: "Masters/OccupationMaster",
  M_MARITAL_STATUS: "Masters/MaritalStatusMaster",
  M_RACE: "Masters/RaceMaster",
  M_FACULTY: "Masters/FacultyMaster",
  M_NATIONALITY: "Masters/NationalityMaster",
  M_PREFIX: "Masters/PrefixMaster",
  M_COUNTRY: "Masters/CountryMaster",
  M_COUNTRYM:"countryMaster",
  M_REFFERALTYPE:"referralTypeMaster",
  M_COUNTRY_VALUE: "/query/?field=countryMaster&value=",
  M_CITY_VALUE:"cityMaster",
  M_STATE: "Masters/StateMaster",
  M_CITY: "Masters/CityMaster",
  M_AREA:"Masters/AreaMaster",
  M_AREA_VALUE:"/query/?field=cityMaster&value=",
  M_STATE_VALUE:"/query/?field=stateMaster&value=",
  M_STATEM:"stateMaster",
  M_ENCOUNTER_SEARCH:
    "Masters/UIConfig/query/?field=filterName&value=Patient",
  M_GLTYPE: "Masters/GLTypeMaster",
  M_COMPANY: "Masters/CompanyMaster",
  M_COMPANYTYPE:"Masters/CompanyTypeMaster",
  M_CONTRACT:"Masters/ContractMaster",
  M_ASSOCIATECOMPANY:"Masters/RefereeCompanyMaster",
  M_TARIFF: "Masters/TariffMaster",
  M_TARIFF_LIST:"Masters/TariffPatientCategoryMapper/query",
  M_RELATION: "Masters/RelationMaster",
  M_REFERAL: "Masters/ReferralMaster",
  M_REFERAL_VALUE:"/query?field=referralTypeMaster&value=",
  M_REFERALTYPE: "Masters/ReferralTypeMaster",
  M_PATIENT_SOURCE: "Masters/PatientSourceMaster",
  M_CABIN: "Masters/CabinMaster",
  M_ENCOUNTER_TYPE: "Masters/EncounterTypeMaster",
  M_APPOINTEMENT_TYPE: "Masters/AppointmentTypeMaster",
  M_CONTRACTS: "contracts/company/",
  M_GUARANTOR_SEARCH:
    "Masters/UIConfig/query/?field=filtername&value='Guarantor'&type='Guarantor'",
  M_SUBSPECIALITY: "Masters/SubSpecialityMaster",
  M_BOOKINGTYPE: "Masters/BookingTypeMaster",
  M_CASHCOUNTER: "Masters/CashCounterMaster",
  M_VIST_TYPE:"Masters/VisitReasonMaster", 
  M_REASON_MASTER:"Masters/ReasonMaster/query",
  M_REASON_MASTER_QUERY:"Masters/ReasonMaster/query?field=reasonTypeMaster&value=2",
  M_REPORT_TYPE:"Masters/ReportTypeMasterInV",
  M_REFEREECOMPANY:"Masters/RefereeCompanyMaster/all?includeInactive=true&offset=0&limit=10",
  M_COMPANYBYASSOCIATECODE:"Masters/CompanyMaster/byAssociateCompany/",
  M_DOC_TYPE:"Masters/DocumentTypeMaster", 
  M_ETHNIC:"Masters/EthnicityMaster",
  M_DEPT: "department/getDeptByUnit",
  M_EMPLIST: "employee/list/",
  EMPLOYEE_LIST:"employee/employeeList",
  M_SPECIALREQLIST:"Masters/SpecialRequirementsMaster",
  M_BULK_SEARCH: "Masters/UIConfig/query/?field=filtername&value='Patient'",
  
  M_PAT_REG_CONTRACT: "contracts/contractList?limit=0",
  M_PAT_REG_SEARCH: "UIConfig/query/?field=filterName&value=Patient",
  M_PAT_REG_TYPE_SEARCH: "UIConfig/query/?field=filterName&value=PatientRegistration",
  M_PAT_ENCOUNTER_SEARCH: "UIConfig/query/?field=filterName&value=Encounter",
  M_PAT_REG_SEARCH_VALUE: "Masters/UIConfig/query/?field=filtername&value=",
  M_PAT_REG_SEARCH_TYPE: "&type=",
  M_PAT_REG_SEARCH_KEY: "Patient",
  M_TELE_CONSULT_PATIENT: "Masters/TeleconsultationAppoinmentType",

  S_SCH_SERVICE: "/scheduler-service/",
  S_SCH_DOC: "schedule/doctors/",
  M_TELE_APPOINTMENT_TYPE:"Masters/TeleconsultationAppointmentType",
  O_EXTENDED_PAT_GET: "extendedPatient/get",
  S_PATIENT_REG:"extendedPatient/create",
  S_PATIENT_ENCOUNTER:"extendedEncounter/createEncounter",
  S_QUICK_PATIENT_ENCOUNTER:"encounterPatient/create",
  S_PATIENT_UPDATE:"extendedPatient/update/",
  OPD_SERVICE: "/opd-service/",
  OPD_DUPLICATE:"/extendedPatient/duplicatePatientCheck?code=",
  FILE_UPLOAD:"cloud/uploadMultipleFiles",
  OPD_DUPLICATE_ENCOUNTER:"/extendedEncounter/checkDuplicateEncounter?departmentCode=",
  OPD_ENCOUNTER_LIST: "extendedencounter/getEncounterWithFilter?mrn=",
  OPD_PATIENT_SEARCH:"extendedPatient/filter?operation=search&",
  OPD_PATIENT_SEARCH_NEW :"extendedPatient/getPatientPage?operation=search&",
  OPD_TEMP_PATIENT_SEARCH:"temporaryPatient?trn=",
  OPD_BLOCK_PATIENT:"/extendedPatient/blockUnblockPatient/",
  OPD_MERGE:"extendedPatient/merge",
  OPD_UNMERGE:"extendedPatient/unmerge",
  OPD_FILE_GET:"cloud/getFilesByMRNORTRN",
  OPD_PREVIOUS_ENCOUNTERS:"&encounterNumber=&frDate= +&toDate=&id=&encounterToken=&encounterDate=&offset=0&size=9999&doctorName=&departmentName=&name=&status=&includedPatientData=true&isExcludeCancel=true",
  OPD_APPOINTMENT_FILTER:"appointment/filter?includePatientData=true&id=",
  OPD_APPOINTMENT_SAVE:"appointment/new",
  OPD_APPOINTMENT_NAVIGATE: "/OPD/new-appointment/patient-appointment",
  OPD_PATIENT_NAVIGATE:"/OPD/patient/patient-encounter",
  OPD_APPOINTMENT_STATUS:"/appointment/updateAppointmentStatus",
  OPD_TEMP_SET_PATIENT:"temporaryPatient?trn=",
  OPD_REMARKS_UPDATE:  "appointment/appointmentRemarkUpdate",
  OPD_FILE_DOWNLOAD_ID:"file/downloadFileById/",
  OPD_ENCOUNTER_UPDATE: "extendedEncounter/updateEncounterStatus/",
  OPD_DELETE_SPONSER:"order/getOpenCharges",
  OPD_APPOINTMENT_PATIENt:"appointment/filter?includePatientData=true&id=",
  OPD_FILE_REMOVE: "cloud/file/remove/",
  OPD_FILE_GET_ENCOUNTER: "cloud/findByencounterNoAndMRN/",
  OPD_FILE_UPLOAD: "cloud/downloadFile/",
  OPD_PAYER_DETAILS: "extendedEncounter/payer-details?encounterId=",
  OPD_PAYER_ALL_DETAILS: "extendedEncounter/gl-payer-details?mrn=",
  OPD_PAYER_ALL_DETAILS_PAYERCODE: "extendedEncounter/gl-payer-by-payer-code?mrn=",
  M_MASTERS_SERVICE: "/masters-service/Masters/",
  M_COMPANY_MASTER: "CompanyMaster/",
  OPD_UPDATE_PAYER: "extendedEncounter/save-update-payer",
  OPD_Outstanding_Bill:"extendedEncounter/getOutstandingPatientList?mrn=",
  M_PACKAGE: "package/getPackageByUnit/hsc",
  M_PACKAGEVALIDATION:"package/findPackageByTariff",
  OPD_APPOINTMENT_HOLD:"slot/updateSlotStatusForHold",
  OPD_BLOCKING_HISTORY:"extendedPatient/getAuditTrailHistoryByPatientId?patientId=",
  PATIENT_STATUS_FILTER:"/extendedPatient/getValidationByPatientId?patientId=",
  AUDIT_TRAIL_HISTORY:"extendedEncounter/getAuditTrailHistoryByVisitId?",
  DELETE_HEALTH_TOURIST:"extendedPatient/deleteHealthTouristDetails?",
  GET_NOK_LIST:"extendedEncounter/getNextToKinDetails?",
  UPDATE_ENQ_STATUS:"extendedEncounter/updateEncounterStatusWithRemark/",
  DELETE_NOK:"extendedEncounter/getDeactiveNextOfKin?",
  PATIENT_INTEGRATION_SERVICE:"/patientintegration-service/qm/checkTokenNo?",
  DOCUMENTTYPEMASTER:"Masters/DocumentStatusMaster",
  GET_UPLOAD_FILE:"file/getVisitFileDetail/",
  GET_ADDRESS_BY_POSTCODE:"area/getAddressDtlByPostCode/",
  GET_ER_SUMMARY: "extendedEncounter/getAdmissionSummary?visitType=ER",
  OPEN_CHARGES:"order/getOpenCharges",
  ADMISSION_UPDATE_STATUS: "extendedEncounter/updateEncounterStatus/",
  UPDATE_DISCHARGE_DETAIL: "extendedEncounter/updateDischargeDetail/",
  CANCEL_DISCHARGE_DETAIL: "extendedEncounter/cancelDischargeDetail/",
  STATUS_UPDATE_DETAIL :"extendedEncounter/addBedTransfer",
  UPDATE_ADMISSION_STATUS: "extendedEncounter/updateAdmissionStatus/",
  GET_PATIENT_TIME_LINE:"extendedEncounter/getAuditTrailHistoryByVisitId?",
  REASON_MASTER: "Masters/ReasonMaster/query",
  STATUS_UPDATE:"BedStatus/getStatusByScreenId/2",
  GET_PHYSICAL_LOCATION_BY_ID: "extendedEncounter/getPhysicalLocationById/",
  GET_PAYER_DETAILS: "extendedEncounter/payer-details?encounterId=",
  S_EMERGENCY_REGISTRATION:"emergencyRegistration/create",
  PATIENT_CATEGORY_MASTER:"PatientCategory/getPatientCategoryTariffByPatientCategoryCode/",
  GET_KIN_DETAILS_LIST_BY_ID: "extendedEncounter/getNextToKinDetails?patientId=",
  GET_KIN_DETAILS_LIST_BY_ENCOUNTER_ID: "extendedEncounter/getNextToKinByEncounterId?encounterId=",
  CHECK_PATIENT_LAST_VISIT:"extendedEncounter/getEncounterDetails/",
  CHECK_EMPLOYEE_VALID:"employee/findByTypeAndEmployeeNo?",
  M_REASON_MASTER_QUERY_NEW:"Masters/ReasonMaster/query?field=reasonTypeMaster.code&value=",
  OPD_DOWNLOAD_FILE: "file/downloadFile/",
  LAB_PENDING_ORDERS:"doctorOrder/getPendingOrders",
  LAB_SERVICE:"/lab-service/",
  GET_SPECIALITY_BY_DOCTOR:"employee/getDepartmentByDoctor?doctorCode=",
  M_EMPLOYER:"Masters/Employer",
 GET_COUNTRY_CODE:"country/getCodesByCountryId/",
 REMOVE_UPLODED_FILE:"file/deleteFileById/",
 //gl attachment url
 GET_GLVIEWDETAILS: "glAttachment/get/",
 GET_GLTYPE_MASTER: "Masters/GLTypeMaster",
 GET_DEPARTMENT: "department/getDeptByUnit",
 GET_RELATION_MASTER: "Masters/RelationMaster",
 GET_CUSTOMER_GROUP_MASTER: "Masters/CustomerGroupMaster",
 SAVE_GLATTACHMENT: "glAttachment/save",
 GET_DOCUMENT:"file/getMRNFileDetail/",
 GET_PATIENT_AUDIT:"extendedPatient/getAuditTrailHistoryByPatient/?patientId=",
 OPD_VIEW_FILE:"file/viewFile/",
 GET_CONTRACT_PAYER:"Masters/CompanyMaster/query/?field=companyTypeMaster&value=",
 GET_PAYERTYPE_TYPE:"Masters/getContractDetails?",
};
