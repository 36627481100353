import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  MatTabGroup } from '@angular/material/tabs';
import { AppBaseService } from '../../services/http.service';
import { AuthService } from '../../services/auth.service';
import { Validators, ValidationErrors } from "@angular/forms";
import { FormGroup, FormBuilder, AbstractControl } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";

export class EmployeeModel {
  dob?: any;
  age?: any;
  nationality?: any;
  faculty?: any;
  department?: any;
  subdepartment?: any;

  address?: any;
  city?: any;
  state?: any;
  postcode?: any;
  country?: any;
  
  corAddress?: any;
  corCity?: any;
  corState?: any;
  corPostcode?: any;
  corCountry?: any;

  emailID?: any;
  mobileNo?: any;
  homeNo?: any;
  workNo?: any;  
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  moduleList: any[] = [];
  menuList: any[] = [];
  userName: any;
  userLoginID: any;
  userRole: any;
  userEmployeeID: any;
  userId: any;
  passCode: string = "********"

  employeeDetails: any = {};
  userImage: any = undefined;
  empImage: any = undefined;
  
  frequentlyAccessedList : any[] = [];
  frequentlyAccessedListInactive : any[] = [];
  dashboardList : any[] = [];
  dashboardListInactive : any[] = [];
  quickLinkList : any[] = [];
  quickLinkListInactive : any[] = [];
  notificationList : any[] = [];
  notificationListInactive : any[] = [];
  draggingItem: any;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(
    public appBaseService: AppBaseService,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.authService.getUser().then((value: any) =>{
      this.userEmployeeID = value.user_emp_id;
      this.userName = value.userName;
      this.userLoginID = value.userEmail
      this.userId = value.userId;
      this.getEmployeeInfo();
    });

    this.authService.getRole().then((role: any) =>{
      if (role) {
        this.userRole = role.roleName;
        this.getAllMenu(role.id);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  getEmployeeInfo() {
    this.appBaseService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.appBaseService
      .getResource("employee/byCode/" + this.userEmployeeID)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if(res) {
          this.employeeDetails = this.setEmployeeData(res);
          if(res.empImage)
            this.empImage = 'data:image/jpg;base64,' +res.empImage;

        } else {
          this.snackBar.open( "ERROR:", "Employee Information Not Found, Contact Administrator", {
            duration: 3000
          });
        }
      });
  }

  setEmployeeData(res) : EmployeeModel {
    let dob = (res.dob)? new Date(res.dob) : " "
    const employeeModel: EmployeeModel = {
      dob: dob,
      age: res.age,
      nationality: res.nationalityMaster.desc,
      faculty: res.facultyMaster.desc,
      department: res.departmentMaster.desc,
      subdepartment: res.subDepartmentMaster.desc,
    
      address: (res.employeeContactDetails && res.employeeContactDetails.localAddress)? res.employeeContactDetails.localAddress : '',
      city: (res.employeeContactDetails && res.employeeContactDetails.localCityMaster)? res.employeeContactDetails.localCityMaster.desc : '',
      state: (res.employeeContactDetails && res.employeeContactDetails.localStateMaster)? res.employeeContactDetails.localStateMaster.desc : '',
      postcode: (res.employeeContactDetails && res.employeeContactDetails.localPincode)? res.employeeContactDetails.localPincode : '',
      country: (res.employeeContactDetails && res.employeeContactDetails.localCountryMaster)? res.employeeContactDetails.localCountryMaster.desc : '',
      
      corAddress: (res.employeeContactDetails && res.employeeContactDetails.permanentAddress)? res.employeeContactDetails.permanentAddress : '',
      corCity: (res.employeeContactDetails && res.employeeContactDetails.permanentCityMaster)? res.employeeContactDetails.permanentCityMaster.desc : '',
      corState: (res.employeeContactDetails && res.employeeContactDetails.permanentStateMaster)? res.employeeContactDetails.permanentStateMaster.desc : '',
      corPostcode: (res.employeeContactDetails && res.employeeContactDetails.permanentPincode)? res.employeeContactDetails.permanentPincode : '',
      corCountry: (res.employeeContactDetails && res.employeeContactDetails.permanentCountryMaster)? res.employeeContactDetails.permanentCountryMaster.desc : '',
    
      emailID: res.email,
      mobileNo: res.mobileNumber,
      homeNo: res.localContactNo,
      workNo: res.permanentContactNo
    }

    return employeeModel;
  }

  
  public getAllMenu(roleid: string) {
    const _this = this;
    this.appBaseService.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.appBaseService.getResource("roles/" + roleid)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(data => {
      if (data) {
        let menuList = data.roleMenuPermissions;
        let menus = [];
        if (menuList) {
          let parents = menuList.filter(i => i.menu.parentid == "0");
          if (parents) {
            parents.forEach(element => {
              let childrenList = menuList
                .filter(i => i.menu.parentid == element.menuId)
                .sort(
                  (a, b) =>
                    parseFloat(a.menu.sequenceOrder) -
                    parseFloat(b.menu.sequenceOrder)
                );

              let children = [];
              if (childrenList.length > 0) {

                let child = childrenList[0];
                let subChildren = [];
                let subChildrenList = menuList.filter(
                  i => i.menu.parentid == childrenList[0].menuId
                );

                if (subChildrenList.length > 0) {
                  let subchild = subChildrenList[0];

                  subChildren.push({
                    displayText: subchild.menu.displayText,
                    icon: subchild.menu.icon,
                    children: [],
                    parent: subchild.menuId,
                    location: subchild.menu.location,
                    index: 0
                  });
                }

                children.push({
                  displayText: child.menu.displayText,
                  icon: child.menu.icon,
                  children: subChildren,
                  parent: element.menuId,
                  location: child.menu.location,
                  index: 0
                });
              }

              menus.push({
                displayText: element.menu.displayText,
                children: children,
                location: element.menu.location,
                icon: element.menu.icon,
                index: element.menu.menuorder,
                menuid: element.menu.id,
                url: element.menu.url,
                module: element.menu.module
              });
            });

            _this.manageMenus(menus);
          }
        }
      }
    });
  }

  manageMenus(menus) {
    this.moduleList = [];
    let modules = menus
      .map(item => item.module)
      .filter((value, index, self) => self.indexOf(value) === index);
    modules.forEach(module => {
      if (module) {
        let i = 1;
        let list = [];
        this.menuList = [];
        let menuList = menus.filter(i => i.module == module);
        menuList.forEach((menu, index) => {
          if (menu) {
            list.push({
              name: menu.displayText,
              image: "../../../assets/images/master-icons/" + menu.icon,
              children: menu.children,
              parent: menu.location,
              index: index,
              menuid: menu.menuid,
              url: menu.url
            });
          }
          if (i == 4 || index == menuList.length - 1) {
            this.menuList.push({ moduleName: module, list: list });
            i = 1;
            list = [];
          } else {
            i++;
          }
        });

        this.moduleList.push({
          moduleName: module,
          menuList: this.menuList
        });
      }
    });
  }

  openPasswordDialog() {
    this.dialog.open( PasswordDialogComponent, {
      width: '30pc',
      data: { },
      disableClose: false
    });
  }

  changeTab(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  onDrop(event, flag, activeList, passiveList){
    event.stopPropagation();
    event.preventDefault();

    let transitData = this.draggingItem;
    transitData['active'] = flag;
    activeList.push(transitData);

    let index = passiveList.findIndex( data => {
      return data.id == this.draggingItem.id
    });

    passiveList.splice(index, 1);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  onDrag(event, item){
    this.draggingItem = item;
  }

  allowDrop(event, type){
    if(type != this.draggingItem.active)
      event.preventDefault();
  }

  saveChanges(link, selectedArray, unselectedArray, text){
    let selectedIds = '', unselectedIds = '';
    selectedArray.forEach((element, index) => {
      if(index == selectedArray.length - 1 ){
        selectedIds += element.id
      }else{
        selectedIds += element.id+','
      }
    });

    unselectedArray.forEach((element, index) => {
      if(index == unselectedArray.length - 1 ){
        unselectedIds += element.id
      }else{
        unselectedIds += element.id+','
      }
    });

    selectedIds = selectedIds+'&active=true'
    unselectedIds = unselectedIds+'&active=false'

    this.patchData(link, selectedIds, text);
    this.patchData(link, unselectedIds, text);
  }


  patchData(link, ids, text){
    this.appBaseService.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.appBaseService
      .patchResource(link+"?ids="+ids, {})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.snackBar.open("Success:", text+"status has been updated", {
          duration: 3000
        });
    });
  }
}


@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.html',
  styleUrls: ['./profile.component.scss']
})
export class PasswordDialogComponent implements OnInit {

  showPassword: boolean = false;
  inputType: string = "password"
  passcodeForm: FormGroup;
  userInformation: any;
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public appBaseService: AppBaseService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.passcodeForm = this.formBuilder.group({
      oldPasscode : ['', [Validators.required]],
      newPasscode: ['', [Validators.required]],
      confirmNewPasscode : ['', [Validators.required, PasswordDialogComponent.matchValues('password')]],
      passcodePrefix : ['']
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  getPasscodeDetails(str) {
    let obj = {
      passCode: str
    }

    this.appBaseService.setResourceURL(MICROSERVICES.ID_SERVICE);
    this.appBaseService
      .postResource("users/getByPassCode", obj)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res) => {
          if(res != null){
            this.userInformation = res;
          }else{
            this.userInformation = undefined;
            this.snackBar.open( "Error: ", "Invalid passcode, Please provide a valid passcode", {
              duration: 3000
            });
          }
        },
        error: (err) => {
          this.snackBar.open( "Error: ", "101", {
            duration: 1000
          });
        }
      });
  }

  currentPasscodeKeyup(){
    let str = this.passcodeForm.value.oldPasscode.toString();
    this.currentPasscode(str);
    if(str.length == 8)
      this.getPasscodeDetails(str);

  }

  currentPasscode(str) {
    if(str.length >= 4){
      this.passcodeForm.patchValue({
        passcodePrefix : str.slice(0, 4)
      })
    }else{
      this.passcodeForm.patchValue({
        passcodePrefix : ""
      })
    }
  }

  showPasswordToggle() {
    if(this.showPassword) {
      this.showPassword = false;
      this.inputType = "password";
    } else {
      this.showPassword = true;
      this.inputType = "text"
    }
  }

  markAsTouched(){
    this.passcodeForm.controls.newPasscode.markAsTouched({ onlySelf: true });
    this.passcodeForm.controls.confirmNewPasscode.markAsTouched({ onlySelf: true });
  }

  public static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls['newPasscode'].value
        ? null
        : { isMatching: false };
    };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changePasscode(){
    if(this.userInformation != undefined){
      let payload = {
        "password": (this.userInformation.password)? this.userInformation.password : "",
        "passCode": this.passcodeForm.value.newPasscode,
        "username": (this.userInformation.username)? this.userInformation.username : "",
      }

      this.appBaseService.setResourceURL(MICROSERVICES.ID_SERVICE);
      this.appBaseService
        .putResource("users/pin", payload)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.snackBar.open("success:", "Passcode has been updated", {
            duration: 3000
          });
          this.onCloseClick();
      });
    }else{
      this.snackBar.open( "Error: ", "Invalid passcode, Please provide a valid passcode", {
        duration: 3000
      });
    }
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
