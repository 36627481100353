import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  CLICARE_TABLE_PROPS,
  CLINICARE_SEARCH_CONST,
} from "./search-properties";
import moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import {
  AppBaseService,
  AuthService,
  ConfirmDynamicDialogComponent,
  EnvoirnmentService,
  MICROSERVICES,
  PatientService,
  UICONFIGSERVICE,
} from "medcare-core-ui";
import { DatePipe } from "@angular/common";
import { ReplaySubject, Subscription, takeUntil } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultiLanguageService } from "../multi-language/multi-language.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { visitDetails } from "./MpatientClinicSearch";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MasterMenus } from "../../enums/configuration-modules.enum";

@Component({
  selector: "app-clinicare-view-dialog",
  templateUrl: "./clinicare-view-dialog.component.html",
  styleUrls: ["./clinicare-view-dialog.component.scss"],
  providers: [DatePipe],
})
export class ClinicareViewDialogComponent implements OnInit,OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  public visitNoFilterCtrl: FormControl = new FormControl();
  public filteredVisitNo: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected subscriptionList = new Subscription();
  searchList: any[] = CLINICARE_SEARCH_CONST.patientSearch;
  recordsLength: string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = CLICARE_TABLE_PROPS.searchDisplayColumns;
  searchCriteria: any = null;
  patientList: any;
  refreshTime: string = "";
  hidePatientSearch = false;
  patientInfoDisplay: string = "";
  patientImage: string = "";
  resultFound: boolean = false;
  searchForm: FormGroup;
  encounterDate: string;
  speciality: string;
  doctorName: string;
  visitNo: string;
  visitDetailList: visitDetails[] = [];
  visitNoDetails: visitDetails;
  previousVisitSelected: visitDetails;
  url: string = "";
  urlSafe: SafeResourceUrl;
  orderType: string = "";
  orderName :string="";
  selectedPatients: any = [];

  constructor(
    public dialogRef: MatDialogRef<ClinicareViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private baseservice: AppBaseService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private langService: MultiLanguageService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private cookieService: CookieService,
    public sanitizer: DomSanitizer,
    private env: EnvoirnmentService
  ) {}
  
  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.subscriptionList.unsubscribe();
  }

  ngOnInit(): void {
    this.orderType = this.data.orderType;
    this.orderName=this.data.orderName;
    this.selectedPatients = this.data.selectedRows;
    this.setFormBuilder();
    if(this.selectedPatients){
      this.patientOnSelect(this.selectedPatients[0]);
    }
  }
  /**
   *
   * @param event
   */
  searchByMRN(event) {
    let result = { MRN: event.target.value };
    this.setSearch(result);
  }
  /**
   *
   * @param data
   */
  setSearch(data) {
    let keysArray = Object.keys(data);
    let hasValue = false;
    for (let i = 0; i < keysArray.length; i++) {
      let value = data[keysArray[i]];
      if (value !== "" && value !== null && value !== undefined) {
        hasValue = true;
        break;
      }
    }
    if (hasValue) {
      const birthValue = data.dob
        ? moment(data.dob, "DD/MM/YYYY hh:mm:ss aa").format("YYYY-MM-DD")
        : "";
      var partientSearch = {
        mrn: data.MRN ? data.MRN : "",
        PatientName: data.PatientName ? data.PatientName : "",
        identificationNumber: data.identificationNumber
          ? data.identificationNumber
          : "",
        mobileNo: data.MobileNo ? data.MobileNo : "",
      };
      this.clearSearch();
      const payloadData = {
        mrn: partientSearch.mrn,
        patientName: partientSearch.PatientName,
        identificationNo: partientSearch.identificationNumber,
        mobileNo: partientSearch.mobileNo,
        limit: 10,
        offset: 0,
        countryId: "",
        stateId: "",
        identificationTypeId: "",
        dob: 0,
      };
      this.baseservice.setResourceURL(MICROSERVICES.LAB_SERVICE);
      this.baseservice
        .postResource(UICONFIGSERVICE.GET_PATIENT, payloadData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.patientList = res.patientResponseList;
          this.recordsLength = res.patientResponseList.length;
          this.resultFound = true;
          this.dataSource = new MatTableDataSource<any>(
            res.patientResponseList
          );
        });
    } else {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.onesearchparameter"),
        this.langService.get("CommonAppoinment.warning"),
        {
          duration: 3000,
        }
      );
    }
  }
  /**
   *
   */
  clearSearch() {
    this.searchList.forEach((search) => {
      search.controls.forEach((control) => {
        this[control.name] = "";
      });
    });
  }
  /**
   *
   * @param patient
   */
  patientOnSelect(patient) {
    this.dataSource = new MatTableDataSource<any>();
    this.resultFound = false;
    var dobFormatted = "";
    if (patient.dob) {
      dobFormatted = this.datepipe.transform(patient.dob, "dd/MM/yyyy");
    }
    let patientAge: any = this.patientService.calculateAge(patient.dob);
    var age =
      patientAge.years + " " + patientAge.months + " " + patientAge.days;
    patient.fullName = this.patientInfoDisplay =
      "MRN" +
      " " +
      patient.mrn +
      " : " +
      patient.patientName +
      " / " +
      (patient.gender ? patient.gender : "Unknown") +
      " / " +
      (patient.dob ? dobFormatted : "") +
      " (" +
      age +
      ")";
    this.patientImage = patient.image ? patient.image.url : "";
    this.getVisitList(patient.mrn, "");
  }
  /**
   *
   */
  setFormBuilder() {
    this.searchForm = this.formBuilder.group({
      visitNo: new FormControl({ value: this.visitNo }),
      encounterDate: new FormControl({
        value: this.encounterDate,
        disabled: true,
      }),
      speciality: new FormControl({
        value: this.speciality,
        disabled: true,
      }),
      doctorName: new FormControl({
        value: this.doctorName,
        disabled: true,
      }),
    });

    this.visitNoFilterCtrl.valueChanges.subscribe(() => {
      this.filterVisitNo();
    });
  }
  /**
   *
   * @param patientId
   * @param type
   */
  getVisitList(mrn: string, type: string) {
    this.baseservice.setResourceURL(MICROSERVICES.LAB_SERVICE);
    this.baseservice
      .getResource(
        UICONFIGSERVICE.GET_PATIENT_VISIT_DETAILS +
          mrn +
          "&visitType=" +
          type
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((visits: any) => {
        if (visits) {
          let visitList = [];
          if (visits && visits.length > 0) {
            visits.forEach((element) => {
              visitList.push(element);
            });
            this.visitDetailList = visitList;
            this.filteredVisitNo.next(this.visitDetailList.slice());
            if(this.selectedPatients){
              if(this.orderType == MasterMenus.Lab.toLowerCase()){
                let visitNo:any =  this.visitDetailList.filter(visit => {
                  return this.selectedPatients[0].listSampleDetails.some(detail => 
                    detail.isPselect && String(detail.visitNo).trim() == String(visit.visitNo).trim()
                  );
                });
                if(this.selectedPatients.length > 1){
                  let hasDuplicates = this.hasDuplicateVisitNo(this.selectedPatients[0].listSampleDetails);
                  if(hasDuplicates){
                    this.changeEncounter(visitNo[0]);
                  }
                }
                else{
                  this.changeEncounter(visitNo[0]);
                }
              }
              else{
                let visitNo = this.visitDetailList.find(item => item.visitNo == this.selectedPatients[0].visitNo);
                if(this.selectedPatients.length > 1){
                  let hasDuplicates = this.hasDuplicateVisitNo(this.selectedPatients);
                  if(hasDuplicates){
                    this.changeEncounter(visitNo);
                  }
                }
                else{
                  this.changeEncounter(visitNo);
                }
              }
            }
            else{
            this.changeEncounter(this.visitDetailList[0]);
            }
          } else {
            this.snackBar.open(
              this.langService.get("billdialogs.opNoVisits"),
              this.langService.get("CommonAppoinment.warning"),
              {
                duration: 3000,
              }
            );
            this.clearAll();
          }
        }
      });
  }

  hasDuplicateVisitNo(array): boolean {
    const visitNos = array.map(item => item.visitNo);
    const uniqueVisitNos = new Set(visitNos);
    return visitNos.length !== uniqueVisitNos.size;
  }
  
  /**
   *
   * @returns
   */
  filterVisitNo(): any {
    if (!this.visitDetailList) {
      return;
    }
    let search = this.visitNoFilterCtrl.value;
    if (!search) {
      this.filteredVisitNo.next(this.visitDetailList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredVisitNo.next(
      this.visitDetailList.filter(
        (e) => e.visitNo.toLowerCase().indexOf(search) > -1
      )
    );
  }

  /**
   *
   * @param event
   */
  validateChangeEncounter(event) {
    if (this.visitNoDetails) {
      const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
        width: "30%",
        data: { displayText: "changeVisit" },
      });
      this.subscriptionList.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (
            result != undefined &&
            result.confirm &&
            result.confirm == "yes"
          ) {
            this.changeEncounter(event);
          } else {
            this.visitNoDetails = this.previousVisitSelected;
          }
        })
      );
    }
  }
  /**
   *
   * @param visitEvent
   */
  changeEncounter(visitEvent: visitDetails) {
    this.previousVisitSelected = visitEvent;
    this.visitNoDetails = visitEvent;
    this.visitNo = visitEvent.visitNo;
    this.encounterDate = this.datepipe.transform(
      new Date(visitEvent.visitDate),
      "dd/MM/yyyy"
    );
    this.doctorName = visitEvent.employeeDetails
      ? visitEvent.employeeDetails.desc
      : "";
    this.speciality = visitEvent.departmentDetails?.desc;
    this.getClinicalDetails();
  }

  /**
   *
   */
  getClinicalDetails() {
    this.authService.getUser().then((res: any) => {
      let authValue = res.authorization.replace("Bearer", "").trim();
      this.cookieService.set(btoa("accessToken"), btoa(authValue), 1, "/");
      this.cookieService.set(btoa("USER_NAME"), btoa(res.userEmailId), 1, "/");
    });

    let encounterNumber = btoa(this.visitNo);
    this.url =
      this.env.clinicareUrl +
      UICONFIGSERVICE.GET_CLINIC_DETAILS +
      this.orderType +
      "&encounterNumber=" +
      encounterNumber;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    //window.open(this.url);
  }
  clearAll() {
    this.resultFound = null;
    this.visitDetailList = [];
    this.visitDetailList = [];
    this.visitNo = "";
    this.searchForm.reset();
    this.visitNoDetails = undefined;
    this.encounterDate = undefined;
  }

  onNoClick() {
    this.clearAll();
    this.dialogRef.close();
  }
}
