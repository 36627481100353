import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class EnvoirnmentService {
  public apiUrl: string = "";
  public enableDebug = true;
  public loginImage: string = "";
  public medcareVerison: string = "";
  public clinicareUrl: string = "";
  public clinicalAcessToken :string="";
  public medcareUrl:string ="";
  public templateUrl: string = "";
  public socketUrl: string = "";
  public logMessages: boolean = false;
  public showPushNotification: boolean = true;
  public isUserNameAndPasscodeLogin: boolean = true;
  public baseCurrency: string = "";
  public appointmentPortalUrl: string = "";
  public loginPassCode: boolean = true;
  public loginPassword: boolean = true;
  public updatePatientPasscode: boolean = true;
  public updatePatientPassword: boolean = false;
  public loginActiveDirectory: boolean = true;
  public uploadLimit : number;
  constructor() {}
}
