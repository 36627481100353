import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, ConfirmDialogComponent } from 'medcare-core-ui';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { OPD_CONST } from '../../regproperties/opd-static-properties';
import { OPD_API_URL } from '../../regproperties/opd-url-properties';
import { AppointmentService } from '../../regservices/Sappointment.service';
import { GeneralUrlService } from '../../regservices/SgeneralUrl.service';
import { MasterUrlService } from '../../regservices/SmasterUrl.service';
import { DatePipe } from "@angular/common";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultiLanguageService } from 'projects/medcare-core-ui/src/app/core/multi-language/multi-language.service';

@Component({
  selector: 'app-patient-appointment-dialog',
  templateUrl: './patient-appointment-dialog.component.html',
  styleUrls: ['./patient-appointment-dialog.component.scss'],
  providers: [
    DatePipe,
    AuthService,
  ]
})
export class PatientAppointmentDialogComponent implements OnInit , OnDestroy{
  public deptCtrl: FormControl = new FormControl();
  public deptFilterCtrl: FormControl = new FormControl();
  public teleCtrl: FormControl = new FormControl();
  public teleFilterCtrl: FormControl = new FormControl();
  public appointmentTypeCtrl: FormControl = new FormControl();
  public appointmentTypeDFltrCtrl: FormControl = new FormControl();
  public doctorCtrl: FormControl = new FormControl();
  public doctorFltrCtrl: FormControl = new FormControl();
  public filteredDepts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredTelecom: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredDocs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredappointmentType: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);
  protected _onDestroy = new Subject<void>();
  private subscriptionList = new Subscription();
  public appointmentType: any = {};
  public appointmentTypeList: any = [];
  public Teleconsultations: any = [];
  public departmentList: any = [];
  public doctorList: any = [];
  doctorsCount: string = "";
  multiLangDoctorList: any[] = [];
  public departmentName: string;
  public department: any = {};
  public doctor: any = {};
  allSlots: any[] = [];
  dayWiseSlots: any;
  blockTime: any;
  public events = [];
  public activeDoctor: any = {
    code: "",
    id: "",
    name: "",
    department: "",
    email: "",
  };
  public searchResult: any = {
    appointmentDate: {
      start: new Date(),
      end: new Date(),
    },
    doctor: "",
    mrnno: "",
  };
  customArray: any[] = [];
  mulipleDoctor: boolean = false;
  multipleDoctorSlected: any[] = [];
  multipleDoctors: any[] = [];
  multiDocCheckbox: boolean = true;
  public deptFlag = false;
  unitId: any;
  unitCode: any;
  isEncounter:boolean=false;
  isEncounterData:any;
  public currentDate: string;
  parentMessage: any;
  public teleconsultaion: any = {};
  orgId: any;
  apptType: any;
  slectedSlotIdDaywise: any[] = [];
  selectedencounterSlots: any = {};

  constructor( 
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PatientAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientAppointmentDialogComponent,
    public masterApiService: MasterUrlService,
    public generalApiService: GeneralUrlService,
    public datepipe: DatePipe,
    private appoinmentService: AppointmentService,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private langService: MultiLanguageService,) { }

  /**
   *
   *
   * @memberof PatientAppointmentDialogComponent
   */
  ngOnInit(): void {
    this.authService.getUnit().then((value: any) => {
      this.unitId = value.unitId;
      this.unitCode = value.unitCode;
      this.orgId = value.orgId;
    });
    this.authService.getGeneralSettingInfo().then((value: any) => {
      this.blockTime = value.slotTime;
    });
    this.subscriptionList.add(
      this.appointmentTypeDFltrCtrl.valueChanges.subscribe(() => {
        this.filterappointmentType();
      })
    );
    this.getAppointmentTypeMaster();
    this.getTeleApptype();
  }
  /**
   *Destroy method
   *
   * @memberof PatientAppointmentDialogComponent
   */
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptionList.unsubscribe();
    if (localStorage.getItem(OPD_CONST.appoinmentRef.appointmentEncounter)) {
      localStorage.removeItem(OPD_CONST.appoinmentRef.appointmentEncounter);
    }
  }
 
    /**
 search method
 *
 * @memberof PatientAppointmentComponent
 */
 initAutoComplete() {
  this.filteredDepts.next(this.departmentList.slice());
  this.subscriptionList.add(
    this.deptFilterCtrl.valueChanges.subscribe(() => {
      this.filterDepts();
    })
  );
  this.subscriptionList.add(
    this.doctorFltrCtrl.valueChanges.subscribe(() => {
      this.filterDocs();
    })
  );
}
 /**
   *change single to multiple
   *
   * @memberof PatientAppointmentComponent
   */
   changeSingleToMultiple() {
    if (!this.mulipleDoctor) {
      this.apptType = OPD_CONST.aptref.resource;
    } else {
      this.doctorList.forEach((element) => {
        element.slected = "";
      });
      this.multipleDoctorSlected = [];
      this.parentMessage = {
        res: [],
        appointmentType: this.appointmentType,
        doctor: this.activeDoctor,
        department: this.department,
        currentDate: this.currentDate,
      };
      this.apptType = OPD_CONST.aptref.appt;
    }
  }
    /**
   *filter departments method
   *
   * @protected
   * @return {*}
   * @memberof PatientAppointmentComponent
   */
   protected filterDepts() {
    if (!this.departmentList) {
      return;
    }
    let search = this.deptFilterCtrl.value;
    if (!search) {
      this.filteredDepts.next(this.departmentList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDepts.next(
      this.departmentList.filter(
        (dept) => dept.desc.toLowerCase().indexOf(search) > -1
      )
    );
  }
  /**
   *filter teleconsulation list
   *
   * @protected
   * @return {*}
   * @memberof PatientAppointmentComponent
   */
  protected filterteleconsulation() {
    if (!this.Teleconsultations) {
      return;
    }
    let search = this.teleFilterCtrl.value;
    if (!search) {
      this.filteredTelecom.next(this.Teleconsultations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTelecom.next(
      this.Teleconsultations.filter(
        (dept) => dept.desc.toLowerCase().indexOf(search) > -1
      )
    );
  }
  /**
   *filter appointmetn Type method
   *
   * @protected
   * @return {*}
   * @memberof PatientAppointmentComponent
   */
  protected filterappointmentType() {
    if (!this.appointmentTypeList) {
      return;
    }
    let search = this.appointmentTypeDFltrCtrl.value;
    if (!search) {
      this.filteredappointmentType.next(this.appointmentTypeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredappointmentType.next(
      this.appointmentTypeList.filter(
        (e) => e.desc.toLowerCase().indexOf(search) > -1
      )
    );
  }
  /**
   *get filter docs
   *
   * @protected
   * @return {*}
   * @memberof PatientAppointmentComponent
   */
  protected filterDocs() {
    if (!this.doctorList) {
      return;
    }
    let search = this.doctorFltrCtrl.value;
    if (!search) {
      this.filteredDocs.next(this.doctorList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDocs.next(
      this.doctorList.filter(
        (dept) => dept.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
  

    /**
   *appointment type change method
   *
   * @param {*} event
   * @memberof PatientAppointmentComponent
   */
   public appointmentTypeChange(event) {
    this.activeDoctor.code = "";
    this.activeDoctor.id = "";
    this.activeDoctor.name = "";
    this.activeDoctor.department = "";
    this.activeDoctor.email = "";
    this.dayWiseSlots=[];
    this.doctorList=[];
    this.doctorsCount="";
    if (event.value == 1) {
      this.doctorList = [];
      this.multiDocCheckbox = false;
      this.teleconsultaion={};
      this.department={};
    } else {
      this.doctorList = [];
      this.multiDocCheckbox = true;
      this.teleconsultaion={};
      this.department={};
    }
  }
  /**
   *consultaoin change method
   *
   * @param {*} teleconsultaion
   * @param {*} isReschedule
   * @memberof PatientAppointmentComponent
   */
  public consultationOnchange(teleconsultaion, isReschedule) {
    if (isReschedule) {
      teleconsultaion == OPD_CONST.aptref.clinicCode
        ? this.getDepartment(false)
        : this.getDepartment(true);
    } else {
      teleconsultaion.value == OPD_CONST.aptref.clinicCode
        ? this.getDepartment(false)
        : this.getDepartment(true);
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
  /**
   *get appointmenttype master data
   *
   * @memberof PatientAppointmentComponent
   */
  public getAppointmentTypeMaster() {
    this.subscriptionList.add(
      this.masterApiService
        .getTypeMaster(OPD_API_URL.M_APPOINTEMENT_TYPE)
        .subscribe((data: any) => {
          let list=[]
          data.forEach(element => {
            if(element.code!=OPD_CONST.aptref.apttTypeHscCode)
            {
               list.push(element);
            }
          });
          this.appointmentTypeList = list;
          this.checkRequestHasEncounter();
          this.filteredappointmentType.next(this.appointmentTypeList.slice());
        })
    );
  }
 
  /**
   *get appointmentTeleconsultation master data
   *
   * @memberof PatientAppointmentComponent
   */
  getTeleApptype() {
    this.subscriptionList.add(
      this.masterApiService
        .getTypeMaster(OPD_API_URL.M_TELE_APPOINTMENT_TYPE)
        .subscribe((data: any) => {
          this.Teleconsultations = data;
          this.filteredTelecom.next(this.Teleconsultations.slice());
        })
    );
  }
  /**
   *get department master data
   *
   * @param {*} isClinicConsultation
   * @memberof PatientAppointmentComponent
   */
  public getDepartment(isClinicConsultation) {
    this.customArray = [];
    this.subscriptionList.add(
      this.masterApiService
        .getTypeMaster(OPD_API_URL.M_DEPT)
        .subscribe((data) => {
          if (data && data.length > 0) {
            isClinicConsultation == true
              ? (this.departmentList = data.filter((i) => i.isTeleconsultation))
              : (this.departmentList = data);
          }
          if (data != null && data.length > 0) {
            this.initAutoComplete();
            this.selectDepartment();
            this.filteredDepts.next(this.departmentList.slice());
          }
        })
    );
  }
  /**
   *select department method
   *
   * @memberof PatientAppointmentComponent
   */
  selectDepartment() {
    if (localStorage.getItem(OPD_CONST.aptref.rescheduleApp)) {
      const appointment = JSON.parse(
        localStorage.getItem(OPD_CONST.aptref.rescheduleApp)
      );
      this.department = this.departmentList.find(
        (i) => i.desc == appointment.specialty
      );
      this.department.value = {
        code: this.department.code,
      };
    }
  }
  /**
   *department change method
   *
   * @param {*} department
   * @param {*} isReschedule
   * @memberof PatientAppointmentComponent
   */
  public departmentOnChange(department, isReschedule) {
    this.deptFlag = true;
       this.doctorList=[];
       this.doctorsCount="";
    if (this.appointmentType == OPD_CONST.aptref.aptTypeCode) {
      let payload;
      if (isReschedule) {
        payload = {
          limit: 0,
          unitCode: this.unitCode,
          departmentCode: department,
          isIncludeDoctorSlot: true,
          typeCodeList: ["DR"],
        };
      } else {
        payload = {
          limit: 0,
          unitCode: this.unitCode,
          departmentCode: department.value,
          isIncludeDoctorSlot: true,
          typeCodeList: ["DR"],
        };
      }
      this.subscriptionList.add(
        this.masterApiService
          .postTypeMaster(OPD_API_URL.EMPLOYEE_LIST, payload)
          .subscribe((content: any) => {
            let data =
              content.payload && content.payload.employeeResponseList
                ? content.payload.employeeResponseList
                : [];
            let list = [];
            let letmultilangdoclist = [];
            if (data != null && data.length > 0) {
              data.forEach((element) => {
                list.push({
                  id: element.id,
                  code: element.code,
                  desc: element.desc,
                  imagePath: this.getImage(element.empImagePath, element.code),
                  available:
                    element.doctoreAvailable == OPD_CONST.noSlotsAvailable
                      ? element.doctoreAvailable
                      : OPD_CONST.nextAvailblity + element.doctoreAvailable,
                });
                letmultilangdoclist.push(element);
              });
            }
            this.multiLangDoctorList = letmultilangdoclist;
            this.doctorList = list;
            this.doctorsCount =
              this.doctorList.length > 0 ? this.doctorList.length : "";
            this.filteredDocs.next(this.doctorList.slice());
            if (isReschedule) {
                let docValue = this.doctorList.find(
                  (i) => i.code == this.isEncounterData.doctor
                );
                this.doctorOnChange(docValue);
            }
           
          })
      );
    } else {
     
      this.getEncounterBindingAppointment();
     
      
    }
  }
  /**
   *
   *
   * @param {*} path
   * @param {*} code
   * @memberof PatientAppointmentDialogComponent
   */
  getImage(path, code) {
    if (path) {
    let resPath = path.replace("/file-service/", "");
    this.subscriptionList.add(
      this.generalApiService
        .getTypeApi(OPD_API_URL.FILE_SERVICE, resPath)
        .subscribe((res: any) => {
          if (res) {
            this.doctorList.forEach((element) => {
              if (element.code == code) {
                element.imagePath = res.url ? res.url : "";
              }
            });
          }
        })
    );
      }
  }
  /**
   *doctor change method
   *
   * @param {*} doctor
   * @memberof PatientAppointmentComponent
   */
  public doctorOnChange(doctor) {
    if (this.mulipleDoctor) {
      doctor.slected = OPD_CONST.aptref.active;
      var slecteDoctors = this.doctorList.filter((doctor) => {
        return doctor.slected == OPD_CONST.aptref.active;
      });
      this.multipleDoctorSlected = slecteDoctors;
      this.getMultipleDoctors();
    } else {
      this.dayWiseSlots=[];
      this.deptFlag = false;
      this.activeDoctor.id = doctor.id;
      this.activeDoctor.code = doctor.code;
      this.activeDoctor.name = doctor.desc;
      this.activeDoctor.email = doctor.email ? doctor.email : "";
      this.doctor = doctor;
      this.searchResult.doctor = doctor.name;
      this.getEncounterBindingAppointment();
     
    }
  }

  /**
   *change multipe doctors
   *
   * @memberof PatientAppointmentComponent
   */
   getMultipleDoctors() {
    this.currentDate = this.datepipe.transform(
      new Date(),
      OPD_CONST.aptref.dateFormat
    );
    var docs: any[] = [];
    this.multipleDoctorSlected.forEach((element) => {
      docs.push(element.code);
    });

    if (this.appointmentType.id) {
      let start = (
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000
      ).toFixed(0);
      let end = (
        new Date(new Date().setHours(23, 59, 0, 0)).getTime() / 1000
      ).toFixed(0);
      let url = this.appoinmentService.setURLmultipleDoctor(this, start, end);
      this.subscriptionList.add(
        this.generalApiService
          .getTypeApi(OPD_API_URL.OPD_SERVICE, url)
          .subscribe((res) => {
            this.parentMessage = {
              res: res,
              appointmentType: this.appointmentType,
              doctor: this.activeDoctor,
              department: this.department,
              currentDate: this.currentDate,
            };
            this.multipleDoctors = res[0].Slots;
          })
      );
    }
  }
   /**
   *
   *
   * @memberof PatientAppointmentComponent
   */
   checkRequestHasEncounter(){
    if (localStorage.getItem(OPD_CONST.appoinmentRef.appointmentEncounter)) {
      let encounterdetails: any = JSON.parse(
        localStorage.getItem(OPD_CONST.appoinmentRef.appointmentEncounter)
      );
       if(encounterdetails.isDepartment)
       {
        this.isEncounter=true;
        this.appointmentType=OPD_CONST.aptref.apttypeDeptCode;
        this.teleconsultaion = "CC";
        this.consultationOnchange(this.teleconsultaion, true);
        this.department = encounterdetails.department.code;
        this.departmentOnChange(this.department, true);
        this.isEncounterData = encounterdetails;
       }
       else{
        if(encounterdetails.doctor)
        {
        this.isEncounter=true;
        this.appointmentType=OPD_CONST.aptref.aptTypeCode;
        this.teleconsultaion = "CC";
        this.consultationOnchange(this.teleconsultaion, true);
        this.department = encounterdetails.department.code;
        this.departmentOnChange(this.department, true);
        this.isEncounterData = encounterdetails;
        }
       }
    }
  }
/**
 *
 *
 * @memberof PatientAppointmentDialogComponent
 */
getEncounterBindingAppointment() {

    let startDate: number = Math.round(
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000
    );
    let endDate: number = Math.round(
      new Date(new Date().setHours(23, 59, 0, 0)).getTime() / 1000
    );
    let aptmentTypeValue = this.appointmentTypeList.find(
      (i) => i.code == this.appointmentType
    );
    let url;
    if (
      aptmentTypeValue.code == OPD_CONST.aptref.apttypeDeptCode ||
      aptmentTypeValue.code == OPD_CONST.aptref.apttTypeHscCode
    ) {
      url = this.appoinmentService.setURlslotData(this, startDate, endDate);
    } else {
      url = this.appoinmentService.setURLmultipleDoctor(
        this,
        startDate,
        endDate
      );
    }
    
    if (aptmentTypeValue.id) {
      this.subscriptionList.add(
        this.generalApiService
          .getTypeApi(OPD_API_URL.OPD_SERVICE, url)
          .subscribe((res: any[]) => {
            this.allSlots = res;
            this.events = [];
           
            if( res.length>0)
            {
            this.allSlots[0].Slots.forEach((element) => {
             
                var currentTime = new Date().getTime() / 1000;
                if (currentTime < element.startDateTimeEpoch) {
                  var minutesToAdd = this.blockTime;
                  var currentDate = new Date();
                  var cuurentTimeappconfig =
                    Number(new Date(currentDate.getTime() + minutesToAdd * 60000)) /
                    1000;
                  if (cuurentTimeappconfig > element.startDateTimeEpoch) {
                    element[OPD_CONST.aptref.selected] = false;
                    element[OPD_CONST.aptref.past] = false;
                    element[OPD_CONST.aptref.blocked] = true;
                  } else {
                    element[OPD_CONST.aptref.selected] = false;
                    element[OPD_CONST.aptref.past] = false;
                    element[OPD_CONST.aptref.blocked] = false;
                  }
                } else {
                  element[OPD_CONST.aptref.selected] = false;
                  element[OPD_CONST.aptref.past] = true;
                }
             
            });
            this.dayWiseSlots= this.allSlots[0].Slots;
          }
          })
      );
    }
   
  }
/**
 *
 *
 * @param {*} slot
 * @memberof PatientAppointmentDialogComponent
 */
slectedSlot(slot) {
    if (slot.past) {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.warning"),
        this.langService.get("CommonAppoinment.slotIsUnavailable"),
        {
          duration: 3000,
        }
      );
    } else {
      if (slot.blocked) {
        this.snackBar.open(
          this.langService.get("CommonAppoinment.warning"),
          this.langService.get("CommonAppoinment.slotIsBlocked"),
          {
            duration: 3000,
          }
        );
      } else {
        if (slot.selected) {
          slot.selected = false;
        } else {
          this.dayWiseSlots.forEach((element) => {
            if (element.selected === true) {
              element.selected = false;
            }
          });
          var intialselectedIndex = this.dayWiseSlots.findIndex(
            (i) => i.selected === true
          );
          var selectedIndex = this.dayWiseSlots.findIndex(
            (i) => i.id === slot.id
          );

          if (intialselectedIndex <= 0) {
            slot.selected = true;
          }
          if (
            selectedIndex === intialselectedIndex - 1 ||
            selectedIndex === intialselectedIndex + 1
          ) {
            slot.selected = true;
          }
          var prev = this.dayWiseSlots[selectedIndex - 1].selected;
          if(this.dayWiseSlots.length-1>selectedIndex)
          var next = this.dayWiseSlots[selectedIndex + 1].selected;
          if (prev || next) {
            slot.selected = true;
          }
        }
        this.slotSelected();
      }
    }
  }
  /**
   *
   *
   * @memberof PatientAppointmentDialogComponent
   */
  slotSelected() {
    this.slectedSlotIdDaywise = [];
    var slected: any = [];
    this.dayWiseSlots.forEach((element) => {
      if (element.selected) {
        slected.push(element);
        this.slectedSlotIdDaywise.push(element);
      }
    });

    this.selectedencounterSlots = {
      start: slected[0].start,
      end: slected[slected.length - 1].end,
    };
  }
  /**
   *
   *
   * @memberof PatientAppointmentDialogComponent
   */
  confirmClearPatientAppointment()
  {
    const dialogRefConfirm = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { confirm: "no" },
    });
    this.subscriptionList.add(
      dialogRefConfirm.afterClosed().subscribe((result) => {
        if (result.confirm == "yes") {
        
    this.allSlots=[];
    this.dayWiseSlots=[];
    this.doctorList=[];
    this.appointmentType={};
    this.department={}
    this.teleconsultaion={};
    this.doctor={};
    this.doctorsCount="";
        }
      })
    );

  }
  /**
   *
   *
   * @memberof PatientAppointmentDialogComponent
   */
  saveAppointment()
  {
    if(this.slectedSlotIdDaywise.length>0)
    {
      let payload={
        slotId:this.slectedSlotIdDaywise[0].id,
        fromTime:this.slectedSlotIdDaywise[0].start,
        toTime:this.slectedSlotIdDaywise[0].end,
        appointmentType:this.appointmentType,
        department:this.department,
        doctor:this.doctor
      }
      this.dialogRef.close(payload);
    }
         
  }
  /**
   *time stamp method
   *
   * @param {*} strDate
   * @return {*}
   * @memberof PatientAppointmentComponent
   */
  toTimestamp(strDate) {
    const datum = Date.parse(strDate);
    return datum / 1000;
  }
}
