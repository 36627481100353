<div>
  <form data-automation-attribute="form-confirm-dialog">
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">{{ "AppointmentListPage.confirm" | translate }}</h6>
      <span aria-label="Close" class="close" (click)="buttonClickNo('no')">
        <mat-icon  matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
      </span>
    </div>
    <br>
    <div class="modal-body bodyCreatePasscode">
      <mat-form-field class="full-width" appearance="outline" rowHeight="40px">
        <mat-label>{{ "billing.reason" | translate }}</mat-label>
        <mat-select [(ngModel)]="reason" name="first" required>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="reasonSearch"
              [placeholderLabel]="'reason'"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let reason of reasons" [value]="reason.code">
            {{ reason.desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
<br>
      <mat-form-field class="full-width" appearance="outline" rowHeight="40px">
        <mat-label>{{ "AppointmentListPage.remarks" | translate }} </mat-label>
        <textarea
          matInput
          [(ngModel)]="remarks"
          name="remark"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="modal-footer modalFooterConfirmDialog">
      <div>
        <button
          mat-mini-fab
          class="btnCyan"
          type="submit"
          data-automation-attribute="button-save-dialog"
        >
          <mat-icon matTooltip="{{ 'payerInfo.yes' | translate }}" (click)="buttonClick('yes')"
            >done</mat-icon
          >
        </button>
        &nbsp;
        <button
          mat-mini-fab
          class="btnRed"
          type="submit"
          data-automation-attribute="button-cancel-dialog"
        >
          <mat-icon matTooltip="{{ 'payerInfo.no' | translate }}" (click)="buttonClickNo('no')"
            >close</mat-icon
          >
        </button>
      </div>
    </div>
  </form>
</div>
