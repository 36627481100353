<div class="div-settings-dialog">
  <div class="modal-header modalHeaderCyan">
    <h6 class="modal-title">Managed Account Info</h6>
    <!-- <span aria-label="Close" class="close" (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </span> -->
  </div>
  <!-- <div class="modal-body bodyCreatePasscode">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            System
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>system settings goes here</p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            Account
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Account settings goes here</p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            Privacy
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Privacy settings goes here</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="modal-footer" style="float: right;">
    <div>
      <button mat-mini-fab class="btnCyan btnSave">
        <mat-icon>sim_card</mat-icon>
      </button>
      <button mat-mini-fab class="btnRed btnCancel">
        <mat-icon>undo</mat-icon>
      </button>
    </div>
  </div> -->
  <div class="screen">
    <div>
       
      <mat-label style="font-size: large; font-weight: bold;margin-left: 15px;">
        My account</mat-label
      >
      <mat-icon
        style="float: right;margin-right: 10px;"
        matTooltip="Security Key"
        (click)="onSecurityKey()"
        >vpn_key</mat-icon
      >
    </div>
    <br />
    <div class="imgProfile" *ngIf="!isClickOnKey">
      <br>
      <div class="container">
        <img class="img-round" [src]= "imageUrl ? imageUrl : '../assets/images/user-black.png'" />
        <div class="olc-10">
          <label class="green ml-10">Information</label><br>
          <div class="grid">
            <mat-label class="title">Name :</mat-label>
            <mat-label class="title">{{name}}</mat-label>
            <mat-label class="title">Email :</mat-label>
            <mat-label class="title">{{email}}</mat-label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isClickOnKey">
      <mat-container  >
        <div class="secondContener">
          <label class="green ml-10">Security Key</label><br>
          <mat-grid-list cols="2" rowHeight="65px">
            <mat-grid-tile [colspan]="1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label><mat-icon>lock</mat-icon> New Key</mat-label>
                <input matInput placeholder="Enter New Key" [type]="hide ? 'password' : 'text'" [formControl]="newKeyFormControl" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
            </mat-grid-tile>
          
            <mat-grid-tile [colspan]="1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> <mat-icon>lock</mat-icon> Confirm Key</mat-label>
                <input matInput placeholder="Enter Confirm Key" [type]="hideConfirmKey ? 'password' : 'text'" [formControl]="confirmKeyFormControl"  />
                <button mat-icon-button matSuffix (click)="hideConfirmKey = !hideConfirmKey" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmKey">
                  <mat-icon>{{ hideConfirmKey ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <br />
        <button
        style="float: right;margin-right: 10px;"
          mat-button
          class="otpBtn"
          *ngIf="!isClickOnSendOtp"
          data-automation-attribute="button-OTP"
          (click)="onSendOtpClick()"
        >
          Send OTP
        </button>
        <div *ngIf="isClickOnSendOtp" style="height: 300px;">
          <p style="text-align-last: center;white-space: nowrap;">Please enter the verification code send to {{convertedEmail}}</p>
          <div class="mt-10" style="text-align: -webkit-center;">
            <ng-container *ngFor="let input of inputArray">
              <input
                autofocus
                id="tab{{ input }}"
                type="password"
                class="input-pin"
                maxlength="1"
                (keyup.backspace)="onBackspace('tab', input)"
                (input)="onInputEntry('tab', input)"
                (focus)="$event.target.select()"
                (keyup)="loginMethod()"
                #myInput
              />
            </ng-container>
          </div>
          <div style="text-align: center;">
          <button  mat-button  class="otpBtn" (click)="onSendOtpClick()">
            Resend OTP
          </button>    
          <button  mat-button (click)="varifyOtp()" class="otpBtn">Verify Code</button>
        </div>
      </div>
      </mat-container>
    </div>
  </div>
</div>