<div>
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">{{ 'inventory.uploadFile' | translate }}</h6>
      <span aria-label="Close" class="close" (click)="onNoClick()">
            <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
      </span>
    </div>
  
    <div class="modal-body bodyCreatePasscode"> 
        <p></p>   
        <table>
            <tr>
                <td>
                    <input type="file" #fileUpload id="fileUpload" (change)="getFile($event)" multiple>
                    <span class="upload-limit">(Maximum size: {{ env.uploadLimit }} MB)</span>
                </td>
            </tr>
         </table>
     </div>
  
  <div class="modal-footer modalFooterAddItem">
      <div mat-dialog-actions>
          <button mat-mini-fab class="btnOrange" style="cursor:pointer;">
              <mat-icon matTooltip="{{ 'inventory.upload' | translate }}"  (click)="uploadFile();" > backup </mat-icon>
            </button>
      </div>
  </div> 
   
  </div>