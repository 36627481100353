import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppBaseService, APPLICATIONSERVICE, AuthService, ConfirmDynamicDialogComponent } from 'medcare-core-ui';
import { MultiLanguageService } from 'projects/medcare-core-ui/src/app/core/multi-language/multi-language.service';
import { ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from 'projects/medcare-core-ui/src/app/services/file-upload.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { OPD_API_URL } from '../../regproperties/opd-url-properties';
import { OPD_CONST } from '../../regproperties/opd-static-properties';
import { glViewDetails, glVisitDetails, glremarksDetails } from '../sponsorinterfaces/glAttachmentDetails';
import { baseObject } from '../sponsorinterfaces/IerRegistration';
import { statisGlApprovalType } from '../encounter-status.enum';
import { UploadfileComponent } from '../../regshared/uploadfile/uploadfile.component';
import { ViewDocumentComponent } from '../../regshared/view-document/view-document.component';
import { DocumentUploadEnum } from '../../enums/configuration-modules.enum';

@Component({
  selector: 'app-gl-attachment',
  templateUrl: './gl-attachment.component.html',
  styleUrls: ['./gl-attachment.component.scss']
})
export class GlAttachmentComponent implements OnInit, OnDestroy {
  status: string = "";
  department: string = "";
  remark: string = "";
  relation: any = undefined;
  customerGroup: any = undefined;
  customerName: any = "";

  glApprovalForm = this.formBuilder.group({
    policyNumber: [],
    policyDate: [],
    holderName: [],
    glReferenceNumber: [],
    glDate: [],
    glEffectiveDate: [],
    expiryDate: [],
    department: [this.department],
    glAmount: [],
    usedAmount: [],
    available: [],
    status: [this.status],
    coverageAmount: [],
    totalOpVisit: [],
    numberOfAdmission: [],
    numberOfVisit: [],
    numberOfActualVisit: [],
    balanceAdmissions: [],
    numberOfActualAdmission: [],
    name: [],
    designation: [],
    contactNo: [],
    remark: [this.remark],
    relation: [this.relation],
    customerGroup: [this.customerGroup],
    customerName: [this.customerName],
    employeeName: [],
    employeeNo: [],
    noOfVisits: [],
    noOfBalVisits: [],
    maxLimitAmt: [],
    patientType: [],
    duration: [],
    inclusiveCoverage: [],
  });
  glViewDetails: glViewDetails;
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  allocatedVisits: number = 0;
  balanceVisit:number = 0;
  encounterList: glVisitDetails[] = [];
  dataSourceRemarks = new MatTableDataSource<any>();
  relationList: baseObject[];
  customerGroupList: baseObject[];
  
  //Required Flag
  requiredFlg: boolean;
  coverageRequiredFlg: boolean;
  approvedFlg: boolean;
  inclusiveFlag: boolean;

  //Date picker limits
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();
  minGeExp = new Date(this.year, this.month, this.day);
  minGxExp = new Date(this.year, this.month, this.day);
  minExpDate = new Date(this.year, this.month, this.day);
  userName: string;
  glTypes: baseObject[];
  departmentList: baseObject[];
  fileUploadList:any=[];
  documentTypelist:any[]=[];
  dataSourceGLAttachment = new MatTableDataSource<any>();
  private subscriptionList = new Subscription();
  displayedColumnsRemarks: string[] = OPD_CONST.glRemarksdisplayedColumns;
  displayedColumnsGLAttachment: string[] =
  OPD_CONST.fileUploadColumns;
  specialRights: any = [];
  approvedGleditFlag: boolean = false;

  public departmentNameFilterCtrl: FormControl = new FormControl();
  public filteredDepartmentName: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public relationFilterCtrl: FormControl = new FormControl();
  public filteredRelation: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public gltypeFilterCtrl: FormControl = new FormControl();
  public filteredGltypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  visitDate: Date;
  previousStatus: string = "";
  docList: any[]=[];
  glRefranceId: string="";

  constructor(
    public dialogRef: MatDialogRef<GlAttachmentComponent>,
    private langService: MultiLanguageService,
    @Inject(MAT_DIALOG_DATA) public data,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private appBaseService:AppBaseService,
    public authService: AuthService,
    private sfileUploadService: FileUploadService,
    private dialog: MatDialog,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.setInitializer();
    this.getUserInfo();
    this.getDocumentTypeList();
    setTimeout(() => {
      this.getDocuments(this.data?.mrn);
    }, 500); 
    this.getGlEditInfo();
  }
  /**
   *Destroy Method
   *
   * @memberof GlAttachmentComponent
   */
   ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }

  getGlEditInfo() {
    this.authService.getData().then((value: any) => {
      this.specialRights = value.userSpecialRightsList;
      this.specialRights.forEach((item)=>{
        if(item.specialRightCode == "SP11"){
          this.approvedGleditFlag = true;
        }
      })
    });
  }

  /**
   *set initiailizer
   *
   * @memberof GlAttachmentComponent
   */
  setInitializer() {
    this.getGLTypes();
    this.getDepartmentList();
    this.getRelationList();
    this.getCustomerGroupList();
    this.getDocTypeList();
    this.glRefranceId=this.data?this.data.data?.glReferenceId:'';
    this.minExpDate.setDate(this.minExpDate.getDate() + 1);
    this.glApprovalForm.enable();
    this.requiredFlg = false;
    this.coverageRequiredFlg = false;
    this.inclusiveFlag = false;
    this.status = statisGlApprovalType.PG;
    if(this.data?.data?.glReferenceId){
      this.getGLApproval(this.data?.data?.glReferenceId);
    }
    this.departmentNameFilterCtrl.valueChanges.subscribe(() => {
      this.filterDepartmentName();
    });
    this.relationFilterCtrl.valueChanges.subscribe(() => {
      this.filterRelation();
    });
    this.gltypeFilterCtrl.valueChanges.subscribe(() => {
      this.filterGltypes();
    });
  }

     /**
   *
   *
   * @return {*}
   * @memberof GlAttachmentComponent
   */
     filterDepartmentName(): any {
      if (!this.departmentList) {
        return;
      }
      // get the search keyword
      let search = this.departmentNameFilterCtrl.value;
      if (!search) {
        this.filteredDepartmentName.next(this.departmentList.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredDepartmentName.next(
        this.departmentList.filter(
          (e) => e.desc.toLowerCase().indexOf(search) > -1
        )
      );
    }

      /**
   *
   *
   * @return {*}
   * @memberof GlAttachmentComponent
   */
      filterRelation(): any {
        if (!this.relationList) {
          return;
        }
        // get the search keyword
        let search = this.relationFilterCtrl.value;
        if (!search) {
          this.filteredRelation.next(this.relationList.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the banks
        this.filteredRelation.next(
          this.relationList.filter(
            (e) => e.desc.toLowerCase().indexOf(search) > -1
          )
        );
      }

        /**
   *
   *
   * @return {*}
   * @memberof GlAttachmentComponent
   */
     filterGltypes(): any {
      if (!this.glTypes) {
        return;
      }
      // get the search keyword
      let search = this.gltypeFilterCtrl.value;
      if (!search) {
        this.filteredGltypes.next(this.glTypes.slice());
        return;
      } else {
        search = search.toLowerCase();
      }
      // filter the banks
      this.filteredGltypes.next(
        this.glTypes.filter(
          (e) => e.desc.toLowerCase().indexOf(search) > -1
        )
      );
    }
  /**
   *Userinfo details
   *
   * @memberof GlAttachmentComponent
   */
  getUserInfo() {
    this.authService.getUser().then((value: any) => {
      this.userName = value.userName;
    });
  }
  /**
   *get Gltype master
   *
   * @memberof GlAttachmentComponent
   */
   getGLTypes() {
    this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_GLTYPE_MASTER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: baseObject[]) => {
        if (res) {
          this.glTypes = res;
          this.filteredGltypes.next(this.glTypes.slice());
        }
      });
  }

  /**
   *get department master
   *
   * @memberof GlAttachmentComponent
   */
  getDepartmentList() {
    this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_DEPARTMENT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: baseObject[]) => {
        if (res) {
          this.departmentList = res;
          this.filteredDepartmentName.next(this.departmentList.slice());
        }
      });
  }

  /**
   *get department master
   *
   * @memberof GlAttachmentComponent
   */
  getRelationList() {
    this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_RELATION_MASTER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: baseObject[]) => {
        if (res){
          this.relationList = res;
          this.filteredRelation.next(this.relationList.slice());
        } 
      });
  }

  /**
   *get department master
   *
   * @memberof GlAttachmentComponent
   */
  getCustomerGroupList() {
    this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_CUSTOMER_GROUP_MASTER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: baseObject[]) => {
        if (res) this.customerGroupList = res;
      });
  }
  /**
   *Get Gl approval method
   *
   * @param {*} id
   * @memberof GlAttachmentComponent
   */
   getGLApproval(id) {
    this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_GLVIEWDETAILS + id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res) {
          this.glViewDetails = res;
          this.allocatedVisits= res.allocatedVisits;
          this.balanceVisit = res.noOfBalVisits;
          let actualVisitsOP = 0;
          let actualVisitsIP = 0;
          if (this.encounterList && this.encounterList.length > 0) {
            actualVisitsOP = this.encounterList.filter(
              (i) => i.visitType == "OPD"
            ).length;
            actualVisitsIP = this.encounterList.filter(
              (i) => i.visitType == "IPD"
            ).length;
          }
          this.glApprovalForm.patchValue({
            glReferenceNumber: this.glViewDetails.glAttachmentNo,
            policyNumber: this.glViewDetails.policyNo,
            policyDate: this.glViewDetails.policyDt,
            holderName: this.glViewDetails.holderName ? this.glViewDetails.holderName : this.data?.patientName,
            glDate: this.glViewDetails.glAttachmentCreatedDt
              ? new Date(this.glViewDetails.glAttachmentCreatedDt)
              : null,
            glEffectiveDate: this.glViewDetails.effectiveDate
              ? new Date(this.glViewDetails.effectiveDate)
              : null,
            expiryDate: this.glViewDetails.expiryDate
              ? new Date(this.glViewDetails.expiryDate)
              : null,
            glAmount: this.glViewDetails.glAmt,
            coverageAmount: this.glViewDetails.coverageAmt,
            usedAmount:
              this.glViewDetails.coverageAmt - this.glViewDetails.availableBal,
            available: this.glViewDetails.availableBal,
            contactNo: this.glViewDetails.contactNo
              ? this.glViewDetails.contactNo
              : "",
              employeeName: this.glViewDetails.employeeName,
              employeeNo: this.glViewDetails.employeeNo,
              noOfVisits: this.glViewDetails.noOfVisits,
              noOfBalVisits: this.glViewDetails.noOfBalVisits,
              maxLimitAmt: this.glViewDetails.maxLimitAmt,
              patientType: this.glViewDetails.patientType,
              customerName: this.glViewDetails.customerName,
              inclusiveCoverage: this.glViewDetails.isAllInclusiveCourage
          });
          this.dataSourceRemarks = new MatTableDataSource<glremarksDetails>(
            this.glViewDetails.glRemarkResponseList
          );
          this.visitDate = this.glViewDetails.visitDate ? new Date(this.glViewDetails.visitDate): null;
          this.minGxExp = this.visitDate;
          this.minExpDate = this.glViewDetails.effectiveDate ? new Date(this.glViewDetails.effectiveDate): this.glViewDetails.visitDate;
          this.minGeExp = this.visitDate;
          this.status = this.glViewDetails.glStatus
            ? this.glViewDetails.glStatus.code
            : "";
          this.previousStatus = this.glViewDetails.glStatus
            ? this.glViewDetails.glStatus.code
            : "";
          this.department = this.glViewDetails.departmentMaster
            ? this.glViewDetails.departmentMaster.code
            : "";
          let relation: any = this.glViewDetails.relationMaster
            ? this.relationList.find(
                (data) => data.id == this.glViewDetails.relationMaster.id
              )
            : "";
          this.relation = relation.id;
          let customerGroup: any = this.glViewDetails.customerGroupMaster
            ? this.customerGroupList.find(
                (data) => data.id == this.glViewDetails.customerGroupMaster.id
              )
            : "";
          this.customerGroup = customerGroup.id;
          this.customerName = this.glViewDetails.customerName;
          if (this.status == statisGlApprovalType.DG) {
            this.requiredFlg = true;
            this.approvedFlg = true;
            this.inclusiveFlag = true;
            this.glApprovalForm.disable();
            this.glApprovalForm.controls.remark.enable()
          } else if (this.status == statisGlApprovalType.AG) {
            if(this.glApprovalForm.controls.inclusiveCoverage.value == true){
              this.coverageRequiredFlg = false;
              this.inclusiveFlag = false;
            }
            else{
              this.coverageRequiredFlg = true;
              this.inclusiveFlag = true;
            }
            if(this.approvedGleditFlag == true){
              if(res.isBill == false){
                this.requiredFlg = true;
                this.approvedFlg = true;
                this.glApprovalForm.enable();
              }
              else if(res.isBill == true){
                this.requiredFlg = true;
                this.approvedFlg = true;
                this.glApprovalForm.disable();
                let controls = this.glApprovalForm.controls;
                controls.glEffectiveDate.enable();
                controls.expiryDate.enable();
                controls.duration.enable();
                controls.glAmount.enable();
                controls.coverageAmount.enable();
                controls.noOfVisits.enable();
              }
            }
            else{
            this.requiredFlg = true;
            this.glApprovalForm.disable();
            this.glApprovalForm.controls.remark.enable()
            this.approvedFlg = true;
            }
          } else if (this.status == statisGlApprovalType.PG || this.status == statisGlApprovalType.IP || this.status == statisGlApprovalType.R || this.status == statisGlApprovalType.C || this.status == statisGlApprovalType.O) {
            this.requiredFlg = false;
            this.approvedFlg = false;
            this.coverageRequiredFlg = false;
            this.inclusiveFlag = false;
            this.glApprovalForm.enable();
          }
          this.calculateDuration();
        }
      });
  }
  calculateDuration(event?){
    if(event && event.target.value){
      let days= event.target.value;
      if(this.glApprovalForm.controls.glEffectiveDate.value){
        let year = this.glApprovalForm.controls.glEffectiveDate.value.getFullYear(); 
        let month = this.glApprovalForm.controls.glEffectiveDate.value.getMonth(); 
        let day = this.glApprovalForm.controls.glEffectiveDate.value.getDate(); 

        let date = new Date(year,month,day);
        // let date1 = new Date(this.glApprovalForm.controls.expiryDate.value);
        let date1= new Date(date.setDate(date.getDate() + Number(days)));
        this.glApprovalForm.controls.expiryDate.setValue(date1); 
        this.glApprovalForm.controls.duration.setValue(days);
      }
      else{
        //show msg to select effective date
        this.snackBar.open(
          this.langService.get("billing.error"),
          this.langService.get("billing.selectEffectiveDate"),
          {
            duration: 3000,
          }
        );
      }
    } 
    else if(event && event.target.value == ""){
      this.glApprovalForm.controls.duration.setValue(event.target.value);
    }
    else{
      if(this.glApprovalForm.controls.expiryDate.value == null || this.glApprovalForm.controls.glEffectiveDate.value == null){
        this.glApprovalForm.controls.duration.setValue(0);
      }
      else{
      let date1 = new Date(this.glApprovalForm.controls.expiryDate.value);
      let date2 = new Date(this.glApprovalForm.controls.glEffectiveDate.value);
  
      // Calculate the difference in milliseconds
      const differenceInMilliseconds = date1.getTime() - date2.getTime();

      // Convert milliseconds to days
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

      let days = Math.floor(differenceInDays); // Return the difference in whole days
      this.glApprovalForm.controls.duration.setValue(days);
      }
    }
  }

   

  /**
   *on close method
   *
   * @memberof GlAttachmentComponent
   */
  onNoClick(): void {
    // let data = this.fileUploadList;
    this.dialogRef.close();
  }
  
  calculateBalanceVisit(event){
    if(event && event != "undefined"){
      let balanceVisit = Number(event.target.value) - this.allocatedVisits;
      if(balanceVisit < 0){
        this.glApprovalForm.controls.noOfBalVisits.setValue(this.balanceVisit);
      } else{
        this.glApprovalForm.controls.noOfBalVisits.setValue(balanceVisit);
      }
    }
  }

  changePatientType(event){
    this.glApprovalForm.patchValue({
      patientType: event.value,
    });
  }

   /**
   *select file method
   *
   * @memberof GlAttachmentComponent
   */
   selectFile() {
   
    const dialogRef = this.dialog.open(UploadfileComponent, {
      height: "auto",
      width: "300px",
      data: { mrn:this.data?.mrn, encounter: "" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((res) => {
        res.forEach((element) => {
          let obj={
            type: element.type,
            fileName: element.name,
            files: element.files,
            filetypevalue: "" ,
            fileDescription: element.name,
            docFileType: DocumentUploadEnum.FINAL,
            doctype: DocumentUploadEnum.GL_SCREEN,
            screenCode:DocumentUploadEnum.GL_SCREEN,
            isUploaded: false,
          }
          this.fileUploadList.push(obj)     
      });
      this.dataSourceGLAttachment = new MatTableDataSource(this.fileUploadList);
    }));
  }
  confirmDelete(element) {
    const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
      width: "30%",
      data: { displayText: "deleteConfirm" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined && result.confirm && result.confirm == "yes") {
          this.removeFile(element);
        }
      })
    );
  }

  removeFile(value){
    const index: number = this.dataSourceGLAttachment.data.indexOf(value);
    if (index !== -1) {
      if(!value.isUploaded){
      this.dataSourceGLAttachment.data.splice(index, 1);
      this.dataSourceGLAttachment = new MatTableDataSource<Element>(this.dataSourceGLAttachment.data);
      }
      else{
        this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
        this.appBaseService
          .deleteResource(
            OPD_API_URL.REMOVE_UPLODED_FILE + value.id)
          .pipe(takeUntil(this._onDestroy))
          .subscribe((response: any) => {
            if (response.statusCode=="200") {
              this.snackBar.open(
                this.langService.get("EncounterPage.fileDeletedSucces"),
                this.langService.get("CommonAppoinment.success"),
                {
                  duration: 1000,
                }
              );
              this.dataSourceGLAttachment.data.splice(index, 1);
              this.dataSourceGLAttachment = new MatTableDataSource(this.dataSourceGLAttachment.data);
            }
          });
      }
    }

  }
  getDocuments(mrn: string) {
    this.fileUploadList = [];
    let payload={
      offset: 0,
      mrn:mrn,
      limit: 10,
      identificationNo:this.glRefranceId,
      screenCode:DocumentUploadEnum.GL_SCREEN,
      visitNo:this.data.visitNo
    }
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .postResource(APPLICATIONSERVICE.GET_DOCUMENT,payload)
      .subscribe((res) => {
        if (res.payload) {
          res.payload.fileResponse.forEach((element) => {
            let docTypeValue=this.docList.find((data)=>{return data.desc==element.documentType});
            let fileTypeValue=this.documentTypelist.find((data)=>{return data.desc==element.docVersion});
            this.fileUploadList.push({
              mrn: element.mrn,
              docFileType: fileTypeValue?fileTypeValue.code:'',
              fileName: element.fileName,
              id: element.id,
              files: element.files,
              fileDescription: element.documentName,
              doctype:docTypeValue?docTypeValue.code:'',
              isUploaded: true,
            });
          });
        }
        this.dataSourceGLAttachment = new MatTableDataSource(this.fileUploadList);
      });
  }
  downloadDocument(element) {
    if(element.id){
    this.appBaseService.setResourceURL("/doc-service/");
    this.appBaseService
      .getBlobFileResource(OPD_API_URL.OPD_DOWNLOAD_FILE+element.id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(res => {
        var b = new Blob([res], { type: res.type });
        saveAs(b, element.fileName ? element.fileName : element.documentName);
      });
    }
  }

  viewFile(element){
    const fileExtension = element.fileName.split('.').pop();
    if(fileExtension ==="xlsx" || fileExtension ==="doc"){
      this.downloadDocument(element)
    }else{
      const dialogRef =this.dialog.open(ViewDocumentComponent, {
        height: "80vh",
        width: "50%",
        data:{value:element.id}
      });
      this.subscriptionList.add(
        dialogRef.afterClosed().subscribe(() => {})
      );
    }
  }
  
  getDocumentTypeList() {
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.DOCUMENTTYPEMASTER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.documentTypelist = res;
      });
  }

  /**
   *download file
   *
   * @memberof GlAttachmentComponent
   */
  downloadAttachment() {
    //once file service method done will add code here
  }

  /**
   *Calculate avialble amount
   *
   * @memberof GlAttachmentComponent
   */
  calculateAvailableAmt() {
    let totalCoverageAmount = this.glApprovalForm.controls.coverageAmount.value;
    let usedAmount = this.glApprovalForm.controls.usedAmount.value;
    this.glApprovalForm.patchValue({
      available: totalCoverageAmount > 0 ? totalCoverageAmount - usedAmount : 0,
    });
  }


  /**
   *save GL method
   *
   * @return {*}
   * @memberof GlAttachmentComponent
   */
  saveGLApproval() {
    if ((this.previousStatus == statisGlApprovalType.DG && this.glApprovalForm.controls.status.disabled) || (this.previousStatus == statisGlApprovalType.AG && !this.approvedGleditFlag)) {
        let controls = this.glApprovalForm.controls;
        let remarks = [];
        this.dataSourceRemarks.data?.forEach((element) => {
          if(element.id == 0){
            remarks.push({
              remarkText: element.remarkText,
              id: element.id,
            });
          }
        });
        if(remarks.length != 0){
          let status = this.glTypes.find((i) => i.code == this.status);
          let dept = this.departmentList.find((i) => i.code == this.department);
          let requestPayload = {
            id: this.glViewDetails.id,
            departmentId: dept ? dept.id : 0,
            glAttachmentNo: controls.glReferenceNumber.value
              ? controls.glReferenceNumber.value
              : null,
            policyNo: controls.policyNumber.value
              ? controls.policyNumber.value
              : null,
            policyDt: controls.policyDate.value ? controls.policyDate.value : null,
            holderName: controls.holderName.value
              ? controls.holderName.value
              : null,
            effectiveDate: controls.glEffectiveDate.value
              ? controls.glEffectiveDate.value
              : null,
            glAttachmentCreatedDt: controls.glDate.value
              ? controls.glDate.value
              : null,
            expiryDate: controls.expiryDate.value
              ? controls.expiryDate.value
              : null,
            glAmt: controls.glAmount.value ? controls.glAmount.value : 0,
            coverageAmt: controls.coverageAmount.value
              ? controls.coverageAmount.value
              : 0,
            availableBal: controls.available.value ? controls.available.value : 0,
            glStatusId: status ? status.id : null,
            contactNo: controls.contactNo.value ? controls.contactNo.value : null,
            noOfVisits: controls.noOfVisits.value ? controls.noOfVisits.value : 0,
            noOfBalVisits: controls.noOfBalVisits.value ? controls.noOfBalVisits.value : 0,
            maxLimitAmt: controls.maxLimitAmt.value ? controls.maxLimitAmt.value : 0,
            employeeName: controls.employeeName.value ? controls.employeeName.value : null,
            employeeNo: controls.employeeNo.value ? controls.employeeNo.value : null,
            patientType: controls.patientType.value ? controls.patientType.value : null,
          
            glRemarkRequestList: remarks,
            glRelationId: this.relation,
            customerGroupId: this.customerGroup,
            customerName: controls.customerName.value ? controls.customerName.value : null,
            isAllInclusiveCourage: controls.inclusiveCoverage.value ? controls.inclusiveCoverage.value : null,
          };
          this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
          this.appBaseService
            .postResource(OPD_API_URL.SAVE_GLATTACHMENT,
              requestPayload
            )
            .pipe(takeUntil(this._onDestroy))
            .subscribe((res) => {
              if (res) {
                this.snackBar.open(
                  this.langService.get("RegistrationPage.successHeader"),
                  this.langService.get(res.message),
                  {
                    duration: 1000,
                  }
                );
                this.uploadFiles();
              }
              this.dialogRef.close("Success");
            });
        } 
        else{
          this.uploadFiles();
          this.dialogRef.close("Success");
        }
    }
    else if(this.previousStatus == statisGlApprovalType.PG || this.previousStatus == statisGlApprovalType.IP || this.previousStatus == statisGlApprovalType.R || this.previousStatus == statisGlApprovalType.C || this.previousStatus == statisGlApprovalType.O || (this.previousStatus == statisGlApprovalType.AG && this.approvedGleditFlag) || (this.previousStatus == statisGlApprovalType.DG && !this.glApprovalForm.controls.status.disabled)){
    let controls = this.glApprovalForm.controls;

    if (!this.glApprovalForm.valid) {
      this.snackBar.open(
        this.langService.get("billing.error"),
        this.langService.get("master.EnterAllFields"),
        {
          duration: 3000,
        }
      );
      return;
    }
    if (this.coverageRequiredFlg) {
      if (controls.coverageAmount.value <= 0) {
        this.snackBar.open(
          this.langService.get("billing.error"),
          this.langService.get("billing.covgAmtNotLess"),
          {
            duration: 3000,
          }
        );
        return;
      }
    }
    if (controls.coverageAmount.value < controls.usedAmount.value) {
      this.snackBar.open(
        this.langService.get("billing.error"),
        this.langService.get("billing.covgAmtNotLessUsedAmt"),
        {
          duration: 3000,
        }
      );
      return;
    }

    if (controls.coverageAmount.value > controls.glAmount.value) {
      this.snackBar.open(
        this.langService.get("billing.error"),
        this.langService.get("billing.covgAmtNotgrtGLAmt"),
        {
          duration: 3000,
        }
      );
      return;
    }

    if (this.status == statisGlApprovalType.AG) {
      if (!controls.glReferenceNumber.value) {
        this.snackBar.open(
          this.langService.get("billing.error"),
          this.langService.get("billing.glRefNoCannotEmpty"),
          {
            duration: 3000,
          }
        );
        return;
      }
    }

    if (this.glApprovalForm.valid) {
      let remarks = [];
      this.dataSourceRemarks.data?.forEach((element) => {
        remarks.push({
          remarkText: element.remarkText,
          id: element.id,
        });
      });

      let status = this.glTypes.find((i) => i.code == this.status);
      let dept = this.departmentList.find((i) => i.code == this.department);
      let requestPayload = {
        id: this.glViewDetails.id,
        departmentId: dept ? dept.id : 0,
        glAttachmentNo: controls.glReferenceNumber.value
          ? controls.glReferenceNumber.value
          : null,
        policyNo: controls.policyNumber.value
          ? controls.policyNumber.value
          : null,
        policyDt: controls.policyDate.value ? controls.policyDate.value : null,
        holderName: controls.holderName.value
          ? controls.holderName.value
          : null,
        effectiveDate: controls.glEffectiveDate.value
          ? controls.glEffectiveDate.value
          : null,
        glAttachmentCreatedDt: controls.glDate.value
          ? controls.glDate.value
          : null,
        expiryDate: controls.expiryDate.value
          ? controls.expiryDate.value
          : null,
        glAmt: controls.glAmount.value ? controls.glAmount.value : 0,
        coverageAmt: controls.coverageAmount.value
          ? controls.coverageAmount.value
          : 0,
        availableBal: controls.available.value ? controls.available.value : 0,
        glStatusId: status ? status.id : null,
        contactNo: controls.contactNo.value ? controls.contactNo.value : null,
        noOfVisits: controls.noOfVisits.value ? controls.noOfVisits.value : 0,
        noOfBalVisits: controls.noOfBalVisits.value ? controls.noOfBalVisits.value : 0,
        maxLimitAmt: controls.maxLimitAmt.value ? controls.maxLimitAmt.value : 0,
        employeeName: controls.employeeName.value ? controls.employeeName.value : null,
        employeeNo: controls.employeeNo.value ? controls.employeeNo.value : null,
        patientType: controls.patientType.value ? controls.patientType.value : null,
       
        glRemarkRequestList: remarks,
        glRelationId: this.relation,
        customerGroupId: this.customerGroup,
        customerName: controls.customerName.value ? controls.customerName.value : null,
        isAllInclusiveCourage: controls.inclusiveCoverage.value ? controls.inclusiveCoverage.value : null,
      };
      this.appBaseService.setResourceURL(OPD_API_URL.BILLING_SERVICE);
      this.appBaseService
        .postResource(OPD_API_URL.SAVE_GLATTACHMENT,
          requestPayload
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          if (res) {
            this.snackBar.open(
              this.langService.get("RegistrationPage.successHeader"),
              this.langService.get(res.message),
              {
                duration: 1000,
              }
            );
            this.uploadFiles();
          }
          this.dialogRef.close("Success");
        });
    }
    }
  }

  /**
   * //once file service method done will add code here
   *
   * @memberof GlAttachmentComponent
   */
  handleFileInput(e) {
    //once file service method done will add code here
  }

  /**
   *
   *
   * @memberof GlAttachmentComponent
   */
   uploadFiles() {
      this.sfileUploadService.getFileUpload(
        this.fileUploadList,
        this.data?.mrn,
        this.data?.visitNo,
        statisGlApprovalType.GL,
        "MRN",
        this.glRefranceId
      );
  }

  /**
   *set GlExpDate
   *
   * @param {*} event
   * @memberof GlAttachmentComponent
   */
  setGlExpDate(event) {
    if (event && event != "undefined") {
      let glYear = event?._d.getFullYear();
      let glMonth = event?._d.getMonth();
      let glDay = event?._d.getDate();
      // this.minGxExp = new Date(glYear, glMonth, glDay);
      this.minExpDate = new Date(glYear, glMonth, glDay);
      this.minExpDate.setDate(this.minExpDate.getDate() + 1);
    }
  }

  /**
   *set Exp date
   *
   * @param {*} event
   * @memberof GlAttachmentComponent
   */
  setExpDate(event) {
    if (event && event != "undefined") {
      let glYear = event?._d.getFullYear();
      let glMonth = event?._d.getMonth();
      let glDay = event?._d.getDate();
      this.minExpDate = new Date(glYear, glMonth, glDay);
      this.minExpDate.setDate(this.minExpDate.getDate() + 1);
      this.calculateDuration();
    }
  }

  /**
   *To make or remove the fields as mandatory
   *
   * @param {*} event
   * @memberof GlAttachmentComponent
   */
  AddMandatory(event) {
    if ((event.value == statisGlApprovalType.PG || event.value == statisGlApprovalType.IP || event.value == statisGlApprovalType.R || event.value == statisGlApprovalType.C || event.value == statisGlApprovalType.O ) && !this.approvedFlg) {
      this.requiredFlg = false;
      this.coverageRequiredFlg = false;
      this.inclusiveFlag = false;
    } else if (event.value == statisGlApprovalType.AG) {
      this.requiredFlg = true;
      if(this.glApprovalForm.controls.inclusiveCoverage.value == true){
        this.coverageRequiredFlg = false;
        this.inclusiveFlag = false;
      }
      else{
        this.coverageRequiredFlg = true;
        this.inclusiveFlag = true;
      }
      if (!this.approvedFlg) this.glApprovalForm.enable();
    } else if (event.value == statisGlApprovalType.DG) {
      this.coverageRequiredFlg = false;
      this.requiredFlg = true;
      this.inclusiveFlag = true;
      if (!this.approvedFlg) this.glApprovalForm.enable();
    }
  }

  /**
   *clear all method
   *
   * @memberof GlAttachmentComponent
   */
  clearAll() {
    this.approvedFlg = false;
    this.glApprovalForm.reset();
    this.dataSourceRemarks = new MatTableDataSource<any>();
  }

  /**
   *mobile format method
   *
   * @param {string} value
   * @memberof GlAttachmentComponent
   */
  mobileFormat(value: string) {
    var r = /(\D+)/g,
      npa = "",
      last4 = "";
    value = value.replace(r, "");
    npa = value.substr(0, 3);
    last4 = value.substr(3, 10);
    value = npa + "-" + last4;
    if (value.replace("-", "").trim() == "") value = "";

    this.glApprovalForm.controls.contactNo.setValue(value);
  }

    /**
   *add remark method
   *
   * @memberof GlAttachmentComponent
   */
   addRemark() {
    if (this.remark && this.remark.trim() != "") {
      let data = this.dataSourceRemarks.data;
      data.push({
        createdDate: new Date(),
        addedBy: this.userName,
        remarkText: this.remark,
        id: 0,
      });
      this.dataSourceRemarks = new MatTableDataSource<glremarksDetails>(data);
    }
    this.remark = "";
  }

  onChange(e){
    if(this.status == statisGlApprovalType.AG){
      if(e.checked == true){
          this.coverageRequiredFlg = false;
          this.inclusiveFlag = false;
        }
      else{
        this.coverageRequiredFlg = true;
        this.inclusiveFlag = true;
      }
    }
  }
  getDocTypeList(){
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .getResource(APPLICATIONSERVICE.DOCUMENT_TYPE_CATEGORY_PAT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.docList = res;
      });
    }
}
