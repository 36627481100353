
import { Injectable } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { AppBaseService } from "../../services/http.service";
import { TranslateService } from '@ngx-translate/core';
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";
import localForage from "localforage";

@Injectable()
export class MultiLanguageService{
    private cache = new Map<string, string>();
    constructor( 
        public baseservice: AppBaseService,
        public authService: AuthService,
        public translate: TranslateService,
    ) { }

    public getLanguageList() {
        let languageList = {};
        let languageDescriptionList = [];
        this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
        this.baseservice
        .getResource("Masters/LanguageConfigurationMaster")
        .subscribe( res => {
            res.forEach(element => {
                let key = element.code;
                languageList[key] = element.desc;

                languageDescriptionList.push({
                    id: element.code,
                    code: element.desc.toLowerCase(),
                    desc: element.fullDescription
                });
            });

            localStorage.setItem("langList", JSON.stringify(languageList));
            localStorage.setItem("langDescList", JSON.stringify(languageDescriptionList));
        });
    }

    private getLangList(){
        this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
        return this.baseservice.getResource("Masters/LanguageConfigurationMaster");
    }

    public getLanguageListPromise(){
        return new Promise((resolve, reject) => {
            localForage.getItem('langDescList').then(data => {
                if(data){
                    resolve(data);
                }else{
                    this.getLangList().subscribe({
                        next: (res) => {
                            let languageDescriptionList = [];
                            let languageList = {};
                            res.forEach(element => {
                                let key = element.code;
                                languageList[key] = element.desc;

                                languageDescriptionList.push({
                                    id: element.code,
                                    code: element.desc.toLowerCase(),
                                    desc: element.fullDescription
                                });
                            });
                            localStorage.setItem("langList", JSON.stringify(languageList));
                            localForage.setItem('langDescList', languageDescriptionList).then(data => {
                                resolve(data);
                            });
                            
                        },
                        error: (err) => {
                            reject(err);
                        }
                    })
                }
            })
        });
    }

    public createObjectList(object) {
        let languageList = JSON.parse(localStorage.getItem("langList"));
        let list = [];
        for(let langKey in languageList) {
            for(let objKey in object) {
                if(langKey == objKey) {
                    let listItem = {
                        system: languageList[langKey].toString(),
                        code: object.code,
                        display: object[objKey].toString()
                    }

                    list.push(listItem);
                }
            }
        }
        if(list.length==0){
            return list=null;
        }
        return list;
    }

    getUnitStores() {
        this.authService.getUnit().then(function (value: any) {
          let obj = {
            unitCode:value.unitCode,
            unitName:value.unitName,
            orgCode:value.orgCode,
          }
          localStorage.setItem("UnitDetails",JSON.stringify(obj));
        });
    }

    public createObjectListInventory(object){
        let languageList = JSON.parse(localStorage.getItem("langList"));
        let list = [];
        for(let langKey in languageList) {
            for(let objKey in object) {
                if(langKey == objKey) {
                    let listItem = {
                        languageDesc: languageList[langKey].toString(),
                        code: object.code ?object.code :object.itemCode,
                        desc: object[objKey].toString()
                    }

                    list.push(listItem);
                }
            }
        }
        if(list.length==0){
            return list=null;
        }
        return list;
    }

    public createObjectListappointment(object) {
        let languageList = JSON.parse(localStorage.getItem("langList"));
        let list = [];
        for(let langKey in languageList) {
            for(let objKey in object) {
                if(langKey == objKey) {
                    let listItem = {
                        id: languageList[langKey].toString(),
                        code: object.code,
                        desc: object[objKey].toString()
                    }
                    list.push(listItem);
                }
            }
        }
        if(list.length==0){
        return list=null;
        }
        return list;
    }

    async getUnitDetails() : Promise<any>{
        this.baseservice.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
        let unitInfo = await this.baseservice.getResource("Masters/UnitMaster").toPromise();
        return unitInfo;
    }

    async getCurrentLanguage(): Promise<string> {
        let responeData = localStorage.getItem("lan");
        return await responeData;
    }

    async changeFooterLaber():Promise<string> {
        let footerLabel;
        let lan = localStorage.getItem("lan");
        if(lan =='en')
        footerLabel ='Items per Page';
        else if(lan =='ms')
        footerLabel ='Item setiap Halaman';
        else if(lan =='my')
        footerLabel ='Item setiap Halaman';
        else if(lan =='th')
        footerLabel ='รายการต่อหน้า';
        else if(lan =='id')
        footerLabel ='Item per halaman';
        else if(lan =='hn')
        footerLabel ='आइटम प्रति पेज';
        else if(lan =='cn')
        footerLabel ='每頁項目';

        return await footerLabel;
    }

    get(key: string): string {
        const value = this.cache.get(key);
        if (value) {
          return value;
        } else {
          this.translate.stream(key).subscribe((translation) => {
            this.cache.set(key, translation);
          });
    
          return this.cache.get(key);
        }
    }

    isCacheLoaded(key: string){
        var promise = new Promise((resolve) => {
            this.translate.stream(key).subscribe((translation) => {
                resolve(translation);
            });
        });
        return promise;
    }
}

