export enum EncounterStatus {
  "planned" = "unknown",
  "arrived" = "Consultation Pending",
  "triaged" = "unknown",
  "in-progress" = "unknown",
  "onleave" = "unknown",
  "finished" = "Consultation Complete",
  "cancelled" = "Visit Cancelled",
  "entered-in-error" = "unknown",
  "unknown" = "unknown"
}
export enum ERRegistration{
  received="Received",
  inProgress="In Progress",
  completed="Completed",
  others="Others",
  pendingGl="PENDING GL",
  approvalGl="APPROVED GL",
  declinedGl="DECLINED GL",
  na="NA",
  declined="Declined",
  approved="Approved",
  pending="Pending",
  male="MALE",
  female="FEMALE",
  close="CLOSED",
}
export enum ERRegistrationStatus{
  requested="REQUESTED",
  rejected="REJECTED",
  acknowledged="ACKNOWLEDGED",
  arrived="ARRIVED",
  cancelled="CANCELLED",
  deschargeInitiated="DISCHARGE INITIATED",
  readyForBilling="READY FOR BILLING",
  billingCompleted="BILLING COMPLETED",
  discharge="DISCHARGED",
  convertToOp="CONVERTED TO OP",
  admitted="ADMITTED",
  checkout="CHECKOUT",
  clinicalDischarge="Clinical Discharged",
  finalDischarge="Final Discharged",
  FINALDISCHARGED="FINALDISCHARGED", 
  close="CLOSED"
}
export enum ERStatusColor{
  requested="#B3E5FC",
  rejected="#ECCFFF",
  acknowledged="#FFD8B2",
  arrived="#9AF900",
  cancelled="#FFCDD2",
  deschargeInitiated="#F6DEA0",
  readyForBilling="#E0E506",
  billingCompleted="#5ADB8E",
  discharge="#65DEEE",
  convertToOp="#d89b9b",

  received="babyblue",
  inProgress="#f5c398",
  completed="#f7d577",
  others="lightskyblue",
  pendingGl="#f09390",
  approvalGl="#a4f5d3",
  declinedGl="#f25a5a",
  na="",
  deschargeinitiated="violet",
  admitted="green",
  canceled="palevioletred",
  checkout="#13137e",
  readyBilling="#938015",
  default="black",
  close="#eb7a4d"
}
export enum Identification{
  staff="Staff",
  student="Student",
  staffCode="STF",
  studentCode="STU"
}
export const statisGlApprovalType = {
  PG: 'PENDING GL',
  AG: 'APPROVED GL',
  DG: 'DECLINED GL',
  GL:'GL',
  R: 'GLTY001',
  IP: 'GLTY002',
  C: 'GLTY004',
  O: 'GLTY007'
}
export const payerCode = {
  selfPayer : 'SELF'
}