import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { CurrencyCode, CurrencyRate } from "../enums/currency-code.enum";
import { AppBaseService } from "./http.service";

@Injectable({
    providedIn: "root"
})
export class CurrencyConversionService {

    public endpoint = 'https://free.currconv.com/api/v7/convert?q=';
    public apiKey = '&compact=ultra&apiKey=14ea7f3f65a390c7c8c7';

    constructor(
        private baseservice: AppBaseService,
    ){}

    getCurrencyRates(baseCurrency: string, convertCurrency: string){
        let baseCode = this.getCurrencyCode(baseCurrency);
        let convertCode = this.getCurrencyCode(convertCurrency);
        if(baseCode == CurrencyCode.Hongkong && convertCode == CurrencyCode.Ringgit) {
            return CurrencyRate.HongtoRing;
        } else if (baseCode == CurrencyCode.US && convertCode == CurrencyCode.Ringgit) {
            return CurrencyRate.UStoRing;
        } else if (baseCode == CurrencyCode.Ringgit && convertCode == CurrencyCode.Hongkong) {
            return CurrencyRate.RingtoHong;
        } else if (baseCode == CurrencyCode.Ringgit && convertCode == CurrencyCode.US) {
            return CurrencyRate.RingtoUS;
        }
    }

    getCurrencyCode(currency: string) {
        if(currency == "1") {
            currency = CurrencyCode.Ringgit;
        }else if(currency == "2") {
            currency = CurrencyCode.Hongkong;
        } else if(currency == "3") {
            currency = CurrencyCode.US;
        }
        return currency;
    }

    getCurrentRates(baseCurrency: string, convertCurrency: string): Observable<string> {
        let baseCode = this.getCurrencyCode(baseCurrency);
        let convertCode = this.getCurrencyCode(convertCurrency);
        var subject = new BehaviorSubject<any>([]);
        this.baseservice.getExternalResource(this.endpoint + baseCode + "_" + convertCode + this.apiKey).subscribe({
            next: (res) => {
                if(res) {
                    subject.next(res);
                }
            },
            error: (error) => {
                console.log(error);
                subject.next(this.getCurrencyRates(baseCurrency, convertCurrency));
            }
        });
        return subject.asObservable();
    }

} 
