export const BirthPlaceList = [
    { birthPlaceNumber: '01', birthPlaceName: 'Johor' },
    { birthPlaceNumber: '02', birthPlaceName: 'Kedah' },
    { birthPlaceNumber: '03', birthPlaceName: 'Kelantan' },
    { birthPlaceNumber: '04', birthPlaceName: 'Malacca' },
    { birthPlaceNumber: '05', birthPlaceName: 'Negeri Sembilan' },
    { birthPlaceNumber: '06', birthPlaceName: 'Pahang' },
    { birthPlaceNumber: '07', birthPlaceName: 'Penang' },
    { birthPlaceNumber: '08', birthPlaceName: 'Perak' },
    { birthPlaceNumber: '09', birthPlaceName: 'Perlis' },
    { birthPlaceNumber: '10', birthPlaceName: 'Selangor' },
    { birthPlaceNumber: '11', birthPlaceName: 'Terengganu' },
    { birthPlaceNumber: '12', birthPlaceName: 'Sabah' },
    { birthPlaceNumber: '13', birthPlaceName: 'Sarawak' },
    { birthPlaceNumber: '14', birthPlaceName: 'Federal Territory of Kuala Lumpur' },
    { birthPlaceNumber: '15', birthPlaceName: 'Federal Territory of Labuan' },
    { birthPlaceNumber: '16', birthPlaceName: 'Federal Territory of Putrajaya' },
    { birthPlaceNumber: '21', birthPlaceName: 'Johor' },
    { birthPlaceNumber: '22', birthPlaceName: 'Johor' },
    { birthPlaceNumber: '23', birthPlaceName: 'Johor' },
    { birthPlaceNumber: '24', birthPlaceName: 'Johor' },
    { birthPlaceNumber: '25', birthPlaceName: 'Kedah' },
    { birthPlaceNumber: '26', birthPlaceName: 'Kedah' },
    { birthPlaceNumber: '27', birthPlaceName: 'Kedah' },
    { birthPlaceNumber: '28', birthPlaceName: 'Kelantan' },
    { birthPlaceNumber: '29', birthPlaceName: 'Kelantan' },
    { birthPlaceNumber: '30', birthPlaceName: 'Malacca' },
    { birthPlaceNumber: '31', birthPlaceName: 'Negeri Sembilan' },
    { birthPlaceNumber: '32', birthPlaceName: 'Pahang' },
    { birthPlaceNumber: '33', birthPlaceName: 'Pahang' },
    { birthPlaceNumber: '34', birthPlaceName: 'Penang' },
    { birthPlaceNumber: '35', birthPlaceName: 'Penang' },
    { birthPlaceNumber: '36', birthPlaceName: 'Perak' },
    { birthPlaceNumber: '37', birthPlaceName: 'Perak' },
    { birthPlaceNumber: '38', birthPlaceName: 'Perak' },
    { birthPlaceNumber: '39', birthPlaceName: 'Perak' },
    { birthPlaceNumber: '40', birthPlaceName: 'Perlis' },
    { birthPlaceNumber: '41', birthPlaceName: 'Selangor' },
    { birthPlaceNumber: '42', birthPlaceName: 'Selangor' },
    { birthPlaceNumber: '43', birthPlaceName: 'Selangor' },
    { birthPlaceNumber: '44', birthPlaceName: 'Selangor' },
    { birthPlaceNumber: '45', birthPlaceName: 'Terengganu' },
    { birthPlaceNumber: '46', birthPlaceName: 'Terengganu' },
    { birthPlaceNumber: '47', birthPlaceName: 'Sabah' },
    { birthPlaceNumber: '48', birthPlaceName: 'Sabah' },
    { birthPlaceNumber: '49', birthPlaceName: 'Sabah' },
    { birthPlaceNumber: '50', birthPlaceName: 'Sarawak' },
    { birthPlaceNumber: '51', birthPlaceName: 'Sarawak' },
    { birthPlaceNumber: '52', birthPlaceName: 'Sarawak' },
    { birthPlaceNumber: '53', birthPlaceName: 'Sarawak' },
    { birthPlaceNumber: '54', birthPlaceName: 'Federal Territory of Kuala Lumpur' },
    { birthPlaceNumber: '55', birthPlaceName: 'Federal Territory of Kuala Lumpur' },
    { birthPlaceNumber: '56', birthPlaceName: 'Federal Territory of Kuala Lumpur' },
    { birthPlaceNumber: '57', birthPlaceName: 'Federal Territory of Kuala Lumpur' },
    { birthPlaceNumber: '58', birthPlaceName: 'Federal Territory of Labuan' },
    { birthPlaceNumber: '59', birthPlaceName: 'Negeri Sembilan' },
    { birthPlaceNumber: '60', birthPlaceName: 'Brunei' },
    { birthPlaceNumber: '61', birthPlaceName: 'Indonesia' },
    { birthPlaceNumber: '62', birthPlaceName: 'Cambodia / Democratic Kampuchea / Kampuchea' },
    { birthPlaceNumber: '63', birthPlaceName: 'Laos' },
    { birthPlaceNumber: '64', birthPlaceName: 'Myanmar' },
    { birthPlaceNumber: '65', birthPlaceName: 'Philippines' },
    { birthPlaceNumber: '66', birthPlaceName: 'Singapore' },
    { birthPlaceNumber: '67', birthPlaceName: 'Thailand' },
    { birthPlaceNumber: '68', birthPlaceName: 'Vietnam' },
    { birthPlaceNumber: '74', birthPlaceName: 'China' },
    { birthPlaceNumber: '75', birthPlaceName: 'India' },
    { birthPlaceNumber: '76', birthPlaceName: 'Pakistan' },
    { birthPlaceNumber: '77', birthPlaceName: 'Saudi Arabia' },
    { birthPlaceNumber: '78', birthPlaceName: 'Sri Lanka' },
    { birthPlaceNumber: '79', birthPlaceName: 'Bangladesh' },
    { birthPlaceNumber: '82', birthPlaceName: 'Unknown state' },
    { birthPlaceNumber: '83', birthPlaceName: 'Asia-Pacific' },
    { birthPlaceNumber: '84', birthPlaceName: 'South America' },
    { birthPlaceNumber: '85', birthPlaceName: 'Africa' },
    { birthPlaceNumber: '86', birthPlaceName: 'Europe' },
    { birthPlaceNumber: '87', birthPlaceName: 'Middle East' },
    { birthPlaceNumber: '88', birthPlaceName: 'Britain / Great Britain / Ireland' },
    { birthPlaceNumber: '89', birthPlaceName: 'Far East' },
    { birthPlaceNumber: '90', birthPlaceName: 'Caribbean' },
    { birthPlaceNumber: '91', birthPlaceName: 'North America' },
    { birthPlaceNumber: '92', birthPlaceName: 'Soviet Union / USSR' },
    { birthPlaceNumber: '93', birthPlaceName: 'Afghanistan / Andorra / Antarctica / Antigua and Barbuda / Azerbaijan / Benin / Bermuda / Bhutan / Bora Bora / Bouvet Island / British Indian Ocean Territory / Burkina Faso / Cape Verde / Cayman Islands / Comoros / Dahomey / Equatorial Guinea / Falkland Islands / French Southern Territories / Gibraltar / Guinea-Bissau / Hong Kong / Iceland / Ivory Coast / Kazakhstan / Kiribati / Kyrgyzstan / Lesotho / Libya / Liechtenstein / Macau / Madagascar / Maghribi / Malagasy / Maldives / Mauritius / Mongolia / Montserrat / Nauru / Nepal / Northern Marianas Islands / Outer Mongolia / Palau / Palestine / Pitcairn Islands / Saint Helena / Saint Lucia / Saint Vincent and the Grenadines / Samoa / San Marino / São Tomé and Príncipe / Seychelles / Solomon Islands / Svalbard and Jan Mayen Islands / Tajikistan / Turkmenistan / Tuvalu / Upper Volta / Uzbekistan / Vanuatu / Vatican City / Virgin Islands (British) / Western Samoa / Yugoslavia' },
    { birthPlaceNumber: '98', birthPlaceName: 'Stateless / Stateless Person Article 1/1954' },
    { birthPlaceNumber: '99', birthPlaceName: 'Mecca / Neutral Zone / No Information / Refugee / Refugee Article 1/1951 / United Nations Specialized Agency / United Nations Organization / Unspecified Nationality' },
  ];