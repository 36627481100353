import { Injectable } from "@angular/core";
import { AppBaseService, SearchService } from "medcare-core-ui";
import { catchError, Observable, of, Subject } from "rxjs";
import { Cacheable } from "ts-cacheable";
import { PatientSearchModel } from "../regmodels/MpatientSearchModel";
import { opdEnvironment } from "../regproperties/opd-field-properties";
import { OPD_API_URL } from "../regproperties/opd-url-properties";

const cacheBuster$ = new Subject<void>();

@Injectable()
export class SearchHeaderService {
  constructor(
    private baseservice: AppBaseService,
    private searchService: SearchService
  ) {}

  search: PatientSearchModel = new PatientSearchModel();
  /**
   *
   *
   * @param {string} urlType
   * @return {*}  {Observable<any[]>}
   * @memberof MasterUrlService
   */
  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxCacheCount: 50,
    maxAge: 30000,
  })
  getTypeSearch(urlValue: string): Observable<any[]> {
    this.baseservice.setResourceURL(OPD_API_URL.MASTER_SERVICE);

    return this.baseservice
      .getResource(urlValue)
      .pipe(catchError(this.handleError<any[]>()));
  }

  /**
   *update search header method
   *
   * @param {any[]} data
   * @return {*}
   * @memberof SearchHeaderService
   */
  updateSearchHeader(data: any[]) {
    if (data.length > 0 && data[0].uiConfig != null) {
      const searchList = [];
      data[0].uiConfig.forEach((element: { controls: any[] }) => {
        const controlList = [];
        element.controls.forEach((control: IplaceHolder) => {
          controlList.push({
            type: control.type,
            placeholder: control.placeholder, //opdEnvironment.placeholder[control.placeholder],
            value: "",
            column: control.column,
            name: control.name,
            url: control.url ? control.url : "",
            selectType: "",
            radios: control.radios,
          });
        });
        searchList.push({ controls: controlList });
      });
      this.searchService.setSearch(searchList);
      return searchList;
    }
  }

  /**
   *set patient search method
   *
   * @param {*} result
   * @return {*}
   * @memberof SearchHeaderService
   */
  // SetPatientSearch(result) {
  //   this.search.MRN = result.mrn ? result.mrn : "";
  //   this.search.patientName = result.patientName;
  //   this.search.dob = result.dob;
  //   this.search.gender = result.gender ? result.gender.code : "";
  //   this.search.mobileNo = result.MobileNo;
  //   this.search.identificationType = result.identificationType
  //     ? result.identificationType.code
  //     : "";
  //   this.search.identificationID = result.identificationID;
  //   this.search.unit = result.unit ? result.unit : "";
  //   this.search.country = result.country ? result.country.code : "";
  //   this.search.state = result.state ? result.state.code : "";
  //   this.search.city = result.city ? result.city.code : "";
  //   this.search.area = result.area ? result.area.code : "";
  //   this.search.pincode = result.pincode;
  //   if (result.daterange && result.daterange) {
  //     if (result.daterange.startDate && result.daterange.startDate) {
  //       this.search.startDate = result.daterange.startDate._d
  //         ? result.daterange.startDate._d
  //         : "";
  //       this.search.endDate = result.daterange.endDate._d
  //         ? result.daterange.endDate._d
  //         : "";
  //     }
  //   } else {
  //     this.search.startDate = result.fromDate;
  //     this.search.endDate = result.toDate;
  //   }
  //   return this.search;
  // }

  SetPatientSearch(result) {
    let employNo = "";
    if (result.userType != "Patient")
      employNo =
        result.userType == "Staff"
          ? result.staffId
          : result.userType == "Student"
          ? result.studentId
          : result.staffId;
    this.search.MRN = result.mrn ? result.mrn : "";
    this.search.userType = result.userType ? result.userType : "";
    this.search.employeeNo = employNo;
    this.search.patientName = result.userType == "Staff"
          ? result.staffName
          : result.userType == "Student"
          ? result.studentName
          : result.patientName;
    this.search.dob = result.dob;
    this.search.gender = result.gender ? result.gender.code : "";
    this.search.mobileNo = result.MobileNo;
    this.search.identificationType = result.identificationType
      ? result.identificationType.code
      : "";
    this.search.identificationID = result.identificationID;
    this.search.unit = result.unitCode ? result.unitCode.code : "";
    this.search.country = result.country ? result.country.code : "";
    this.search.state = result.state ? result.state.code : "";
    this.search.city = result.city ? result.city.code : "";
    this.search.area = result.area ? result.area.code : "";
    this.search.pincode = result.pincode;
    this.search.offset=0;

    if (
      result.daterange &&
      result.daterange.startDate &&
      result.daterange.endDate
    ) {
      this.search.startDate = result.daterange.startDate._d
        ? result.daterange.startDate._d
        : "";
      this.search.endDate = result.daterange.endDate._d
        ? result.daterange.endDate._d
        : "";
    } else {
      this.search.startDate = result.fromDate;
      this.search.endDate = result.toDate;
    }

    return this.search;
  }

  // Function to search for patient entries
  searchPatients() {
    var searchTerm = (<HTMLInputElement>document.getElementById("searchTerm"))
      .value;
    var results = [];

    // Iterate over the patient data to find matches
    for (var i = 0; i < results.length; i++) {
      var patientName = results[i].patientName.toLowerCase();
      var searchTermLower = searchTerm.toLowerCase();

      // Check if the patient name contains the search term
      if (patientName.includes(searchTermLower)) {
        results.push(this.SetPatientSearch(results[i]));
      }
    }

    // Handle the results
    if (results.length > 0) {
      // Display the results
      // displayResults(results);
    } else {
      // No matching patients found
      // displayNoResults();
    }
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {
      console.log(`failed: ${error.message}`);

      return of(error);
    };
  }
}

export interface IplaceHolder {
  type?: any;
  placeholder?: string | number;
  column?: any;
  name?: string;
  url?: string;
  radios?:any;
}
