<div [formGroup]="parentForm">
  <mat-form-field class="full-width" appearance="outline" [ngClass]="[isSubmitted && !isValid? 'mat-form-field-invalid' : '']">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]="formControlName" (selectionChange)="validateField()"
      [placeholder]="'SearchHeader.placeholder' | translate" [required]="isRequired"  [disabled]="isDisabled">
      <mat-option>
        <ngx-mat-select-search [formControl]="filterCtrl" class="full-width"
          ngDefaultControl [placeholderLabel]="'RegistrationPage.search' | translate"></ngx-mat-select-search>
      </mat-option>
      <mat-option>{{"RegistrationPage.none" | translate}}</mat-option>
      <mat-option *ngFor="let option of filteredList | async" [value]="option">
        {{ option.desc }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>