import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    constructor() { }

    /**
     * To calcualte age based on date of birth
     * supports default javascript format(MMDDYYYY)
     * @param dateString 
     * @returns Calculated age in number
     */
    getAge(date : Date) {
        var today = new Date();
        var birthDate = date;
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * Generates new date with numberical days, months and years as input
     * @param days 
     * @param months 
     * @param years 
     * @returns new date 
     */
    createDate(days: number, months: number, years: number) {
        var date = new Date(); 
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + (months -1));
        date.setFullYear(date.getFullYear() + years);
        return date;    
    }

    /**
     * To get days, months and years from date
     * @param date
     * @returns days, months and years object
     */
    getAgeNumberals(dateOfBirth: Date):{
        years: number;
        months: number;
        days: number;
      }{
        if (dateOfBirth) {
            let date_1 = new Date(dateOfBirth);
            let date_2 = new Date();
            let date2_UTC = new Date(
              Date.UTC(
                date_2.getUTCFullYear(),
                date_2.getUTCMonth(),
                date_2.getUTCDate()
              )
            );
            var date1_UTC = new Date(
              Date.UTC(
                date_1.getUTCFullYear(),
                date_1.getUTCMonth(),
                date_1.getUTCDate()
              )
            );
            let days = date2_UTC.getDate() - date1_UTC.getDate();
            if (days < 0) {
              date2_UTC.setMonth(date2_UTC.getMonth() - 1);
              days += this.daysInMonth(date2_UTC);
            }
            let months = date2_UTC.getMonth() - date1_UTC.getMonth();
            if (months < 0) {
              date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
              months += 12;
            }
            let years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
            if (days == 0) days = 1;
            return {
              years: years,
              months: months,
              days: days
            };
          } else {
            return { years: 0, months: 0, days: 0 };
          }
    }

    daysInMonth(date2_UTC) {
        var monthStart: any = new Date(
          date2_UTC.getFullYear(),
          date2_UTC.getMonth(),
          1
        );
        var monthEnd: any = new Date(
          date2_UTC.getFullYear(),
          date2_UTC.getMonth() + 1,
          1
        );
        var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
        return monthLength;
    }

    calculateCompleteAge(dob: Date):{
        years: number;
        months: number;
        days: number;
      }{
        if(dob)
        {
        var dobYear = dob.getFullYear();
        var dobMonth = dob.getMonth();
        var dobDate = dob.getDate();
          
        //get the current date from the system
        var now = new Date();
        //extract the year, month, and date from current date
        var currentYear = now.getFullYear();
        var currentMonth = now.getMonth();
        var currentDate = now.getDate();
        
        //get years
        let yearAge = currentYear - dobYear;
          
        //get months
        if (currentMonth >= dobMonth)
          //get months when current month is greater
          var monthAge = currentMonth - dobMonth;
        else {
          yearAge--;
          var monthAge = 12 + currentMonth - dobMonth;
        }
      
        //get days  
        if (currentDate >= dobDate)
          //get days when the current date is greater
          var dateAge = currentDate - dobDate;
        else {
          monthAge--;
          var dateAge = 31 + currentDate - dobDate;
      
          if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
          }
        }
        return {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };
      }
    }
}
