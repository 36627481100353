<div class="webCamDialog" data-automation-attribute="div-camera-dialog">
  <div class="modal-header modalHeaderDefault">
    <label class="modal-title">
      {{ "EncounterPage.pleaseSelectImage" | translate }}</label
    >
  </div>

  <div class="modal-body">
    <div *ngIf="webcamImage" class="center">
      <img [src]="webcamImage.imageAsDataUrl" class="dashbedBorder" /><br>
    </div>

    <div class="center" *ngIf="!imageUser">
      <webcam
        [height]="200"
        [width]="180"
        *ngIf="showWebcam"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        (initError)="handleInitError($event)"
      ></webcam>
    </div>
    
    <div class="center" *ngIf="!showWebcam">
      <img
        src="assets/images/noavatar.png"
        [height]="180"
        [width]="180"
        alt="Patient"
        title="Patient"
        *ngIf="!imageChangedEvent && !imageshow"
        data-automation-attribute="imgDefaultUser"
       />

       <img
       id="image"
       [src]="imageUser"
       [height]="180"
       [width]="180"
       *ngIf="imageshow"
       [hide]="!imageChangedEvent"
       class="user-img"
       />
     
       <image-cropper
       id="image"
       [imageBase64] = "imageUser"
       [imageChangedEvent]="imageChangedEvent"
       [maintainAspectRatio]="true"
       [aspectRatio]="4 / 4"
       [resizeToWidth]="256"
       (imageCropped)="cropImg($event)"
       (imageLoaded)="imgLoad()"
       (cropperReady)="initCropper()"
       (loadImageFailed)="imgFailed()"
       *ngIf="imageChangedEvent"
        [@flipStateV]="flipV"
       [@flipStateH]="flipH"
     >
     </image-cropper>

      <input
        type="file"
        class="hide"
        [src]="imageUser"
        id="fileAttachment"
        multiple="multiple"
        accept="application/Image"
        (change)="handleFileSelect($event)"
        (change)="onFileChange($event)"
      />
    </div>

    <div  *ngIf="!saveImageEdit">
          <div class="btnActions center mt-10" >
            <div class="center" *ngIf="imageUser">
              <button (click)="onCropClick()"><mat-icon>crop</mat-icon></button>&nbsp;
              <button (click)="rotateImageright()"><mat-icon>rotate_right</mat-icon></button>&nbsp;
              <button (click)="rotateImageleft()" class="button-wrapper"><mat-icon>rotate_left</mat-icon></button>&nbsp; 
              <button (click)="FlipVertical()"><mat-icon>swap_vertical_circle</mat-icon></button>&nbsp;
              <button (click)="FlipHorizontal()"><mat-icon>swap_horizontal_circle</mat-icon></button>&nbsp;
              <button (click)="undo()"><mat-icon>undo</mat-icon></button>&nbsp;
              <button (click)="redo()"><mat-icon>redo</mat-icon></button>
            </div>
            
                <button
                  id="btnUploadPhoto"
                  class="btnCyan"
                  mat-flat-button
                  data-automation-attribute="button-upload-photo"
                  (click)="toggleWebcam()"
                >
                  <mat-icon class="iconCamera" style="vertical-align: middle">camera_alt</mat-icon>
                  {{ "RegistrationPage.webcam" | translate }}
                </button>
                &nbsp;
                <button
                  id="btnUploadPhoto"
                  class="btnBlue"
                  mat-flat-button
                  (click)="triggerSnapshot()"
                  data-automation-attribute="button-capture-photo"
                >
                  <mat-icon class="iconCamera" style="vertical-align: middle">cloud_upload</mat-icon>
                  {{ "RegistrationPage.clickPhoto" | translate }}
                </button>
                &nbsp;
                <button
                id="btnUploadPhoto"
                class="btnCyan"
                mat-flat-button
                data-automation-attribute="button-upload-photo"
                (click)="selectFile()"
                >
                <mat-icon class="iconCamera" style="vertical-align: middle">cloud_upload</mat-icon>
                {{ "RegistrationPage.uploadimage" | translate }}
                </button>
          </div>
  
          <div class="modal-footer right">
                <button
                class="btnBlue"
                (click)="back()"
                mat-mini-fab
                type="button"
                [hidden]="!backButton"
              >
              <mat-icon>arrow_back</mat-icon>
              </button>
                &nbsp;
                <button
                  class="btnOrange"
                  (click)="onNoClick('no')"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon
                    matTooltip="{{ 'AddInternalNotes.close' | translate }}"
                    data-automation-attribute="btnReset"
                    >close
                  </mat-icon>
                </button>
                &nbsp;
                <button
                  (click)="onNoClick('yes')"
                  class="btnCyan"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon
                    matTooltip="{{ 'payerInfo.save' | translate }}"
                    data-automation-attribute="btnReset"
                    >sim_card
                  </mat-icon>
                </button>
          </div>
    </div>

  <div class="btnActions center mt-10" *ngIf="saveImageEdit">
    <div class="modal-body">
      
      <button
        id="btnchangePhoto"
        class="btnCyan"
        mat-flat-button
        data-automation-attribute="button-upload-photo"
        (click)="change()"
      >
        <mat-icon class="iconCamera" style="vertical-align: middle">camera_alt</mat-icon>
       Change
      </button>
      &nbsp;
      
      <button
      id="btnRemovePhoto"
      class="btnCyan"
      mat-flat-button
      data-automation-attribute="button-remove-photo"
      (click)="remove()"
      *ngIf="imageUser"
      >
      <mat-icon  class="iconCamera" style="vertical-align: middle">remove_circle</mat-icon>
      Remove
      </button>     
 </div>
  </div>
</div>
