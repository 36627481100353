import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppBaseService } from "projects/medcare-core-ui/src/app/services/http.service";
import { MICROSERVICES } from "projects/medcare-core-ui/src/app/constants/web-services";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import * as temp from 'moment';
const moment = temp["default"];

@Component({
  selector: "app-quick-appointments",
  templateUrl: "./quick-appointments.component.html",
  styleUrls: ["./quick-appointments.component.scss"]
})
export class QuickAppointmentsComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  appointmentList: any[] = [];
  filteredAppointmentList: any[] = [];
  doctorName: string = "";

  constructor(private httpService: AppBaseService) {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  doctorOnKeyUp() {
    let doctorName: string = this.doctorName;
    this.filteredAppointmentList = this.appointmentList.filter(i =>
      i.doctorName
        .toString()
        .toLowerCase()
        .includes(doctorName.toLowerCase())
    );
  }
}
