<div *ngIf="enableEditor" style="height: 100%;">
  <ejs-richtexteditor [id]='autoSaveTempId' 
                      #autosaveRTE [toolbarSettings]='tools' 
                      [iframeSettings]='iframe' 
                      [height]='height' 
                      (created)="onRTECreate($event)"
                      (change)='onChange()' 
                      value={{editedText}}>
  </ejs-richtexteditor>
</div>

<div *ngIf="internalTextBox" class="input-div">
    <mat-form-field class="full-width form-field-width" appearance="outline">
        <textarea matInput name="dataw" placeholder="Speak to Capture" [(ngModel)]="internalSpeech" class="text-area"></textarea>
    </mat-form-field>
    <button mat-mini-fab class="check-btn" matTooltip="Receive Sample"
      (click)="addTextToTemplate()" aria-label="Example icon button with a bookmark icon">
      <mat-icon>check</mat-icon>
    </button>
</div>