import { Component, HostListener, Input, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnInit {
  protected subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  @Input() data;
  dataSource: any[];
  columnDefs: any[];
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let card = document.getElementById(this.data.id);
    let table = document.getElementById("table" + this.data.id) as HTMLElement;
    table.style.height = card.offsetHeight - 32 + "px";
    table.style.width = card.offsetWidth + "px";
  }
  constructor(private dataService: DataService) {}
  ngOnInit(): void {
    this.subscriptionList.add(
      this.dataService.getDashboardData().subscribe((data) => {
        {
          if (this.data && this.data.chartData) {
            let obj = this.data.chartData;
            if (obj.data && obj.data.length) {
              this.columnDefs = [];
              let columns = [];
              for (let key in obj.data[0]) {
                let arr = key.split("_");
                let columnName = "";
                arr.map((data) => {
                  columnName =
                    columnName +
                    (data.charAt(0).toUpperCase() + data.slice(1)) +
                    " ";
                });
                this.columnDefs.push({ label: columnName, entity: key });
              }
            }
            if (
              this.data.xCoordinate == "isAmount" &&
              obj.data &&
              obj.data.length
            ) {
              obj.data.map((data) => {
                data.count_data = data.count_data.toFixed(2);
              });
            }
            this.dataSource = obj.data;
          }

          let card = document.getElementById(this.data.id);
          let table = document.getElementById("table") as HTMLElement;
          table.id = "table" + this.data.id;
          table.style.height = card.offsetHeight - 32 + "px";
          table.style.width = card.offsetWidth + "px";
        }
      })
    );
  }
}
