import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuickLinkService {

  constructor() { }
  private showLink = new Subject<any>();
  getQuickLink = this.showLink.asObservable();

  setLink(link?: any) {
    this.showLink.next(link);
  }
}
